import React from 'react';
import { useHistory } from 'react-router-dom';

import { colorPalette as colors } from '../../theme';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import DoubleArrowIcon from '@material-ui/icons/DoubleArrow';
import ArrowForward from '@material-ui/icons/ArrowForward';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
		borderStyle: 'solid',
		borderColor: colors[3],
		padding: 5,
		borderRadius: '5px',
		color: colors[0],
		fontSize: 30,
		marginBottom: 75,
	},
	paper: {
		padding: theme.spacing(2),
		textAlign: 'center',
		color: colors[0],
		margin: 10,
		fontSize: 18,
	},
	p: {
		color: colors[4],
		fontSize: 17,
		marginTop: -5,
		marginBottom: 3,
		fontStyle: 'italic',
		fontWeight: 'bold',
	},
	h1: {
		paddingLeft: 5,
		marginTop: 10,
		fontSize: '23px',
		background: 'red',
	},
	item: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
		padding: 20,
		width: '100%',
	},
	button: {
		color: '#ffffff',
		backgroundColor: colors[6],
		'&:hover': {
			backgroundColor: colors[0],
		},
		paddingTop: 10,
		paddingBottom: 10,
		textAlign: 'center',
	},
	buttonText: {
		marginRight: 20,
		marginLeft: 20,
		fontSize: '1.2em',
		fontWeight: 'bold',
	},
	buttonIcon: {
		fontSize: '2em',
	},
	arrow: {
		margin: 0,
		position: 'absolute',
		width: '5vw',
		top: '50%',
		left: '50%',
		marginRight: '-50%',
		transform: 'translate(-50%, -50%)',
	},
	navigationContainer: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		flexDirection: 'column',
		textAlign: 'center',
		width: '100%',
		height: '100%',
	},
	navigationTextContainer: {
		display: 'flex',
		alignItems: 'center',
		flexDirection: 'column',
		textAlign: 'center',
		marginBottom: 40,
		width: '100%',
	},
	navigationTitle: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		marginTop: 0,
		marginBottom: 10,
	},
	navigationIcon: {
		color: colors[0],
		fontSize: '1.5em',
	},
}));

export default function LandingPage() {
	const classes = useStyles();
	const history = useHistory();

	function handleNavigationOpportunities() {
		history.push('/opportunities');
	}

	function handleNavigationABS() {
		history.push('/abs');
	}

	function handleNavigationLending() {
		history.push('/lending');
	}

	return (
		<Paper style={{ width: '80vw', margin: '0 auto' }}>
			<h1
				style={{
					textAlign: 'center',
					fontSize: '3em',
					marginTop: 75,
					marginBottom: 50,
				}}
			>
				Karus - The AI Engine for Auto Finance
			</h1>
			<hr style={{ color: '#616161' }} />
			<br />
			<Grid container className={classes.root}>
				<Grid className={classes.item} item xs>
					<div className={classes.navigationContainer}>
						<div className={classes.navigationTextContainer}>
							<h5 className={classes.navigationTitle}>
								Auto Receivables Opportunities
							</h5>
							<p className={classes.p}>$2.7 Billion</p>
						</div>
						<Button
							variant="contained"
							className={classes.button}
							onClick={handleNavigationOpportunities}
						>
							<ArrowForward
								className={classes.buttonIcon}
								style={{ visibility: 'hidden' }}
							/>
							<span className={classes.buttonText}>Access</span>
							<ArrowForward className={classes.buttonIcon} />
						</Button>
					</div>
				</Grid>
				<Divider orientation="vertical" flexItem />
				<Grid className={classes.item} item xs>
					<div className={classes.navigationContainer}>
						<div className={classes.navigationTextContainer}>
							<h5 className={classes.navigationTitle}>Auto ABS</h5>
							<p className={classes.p}>$300 Billion</p>
						</div>

						<Button
							variant="contained"
							className={classes.button}
							onClick={handleNavigationABS}
						>
							<ArrowForward
								className={classes.buttonIcon}
								style={{ visibility: 'hidden' }}
							/>
							<span className={classes.buttonText}>Access</span>
							<ArrowForward className={classes.buttonIcon} />
						</Button>
					</div>
				</Grid>
				<Divider orientation="vertical" flexItem />
				<Grid className={classes.item} item xs>
					<div className={classes.navigationContainer}>
						<div className={classes.navigationTextContainer}>
							<h5 className={classes.navigationTitle}>Karus Lending</h5>
							<p className={classes.p}>Accurately Priced Borrowers</p>
						</div>
						<Button
							variant="contained"
							className={classes.button}
							onClick={handleNavigationLending}
						>
							<ArrowForward
								className={classes.buttonIcon}
								style={{ visibility: 'hidden' }}
							/>
							<span className={classes.buttonText}>Access</span>
							<ArrowForward className={classes.buttonIcon} />
						</Button>
					</div>
				</Grid>
			</Grid>
		</Paper>
	);
}
