import React, { Component, useEffect, useState } from 'react';
import { colorPalette as colors } from '../../theme';
import { useSummary, useRemainder } from '../../api/query-hooks';
import LoadingSpinner from '../common/LoadingSpinner';
import { useSelector } from 'react-redux';
import formatters from '../../helpers/formatters';
import NoData from '../common/NoData';

// Material UI
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import HelpIcon from '@material-ui/icons/Help';

import Divider from '@material-ui/core/Divider';
const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
		borderStyle: 'solid',
		borderColor: colors[3],
		padding: 5,
		borderRadius: '5px',
		color: colors[0],
		fontSize: 30,
		minWidth: 1080,
	},
	paper: {
		padding: theme.spacing(2),
		textAlign: 'center',
		color: colors[0],
		margin: 10,
		fontSize: 18,
	},
	p: {
		color: colors[4],
		fontSize: 15,
		marginTop: -5,
		marginBottom: 3,
	},
	h1: {
		paddingLeft: 5,
		marginTop: 10,
		fontSize: '23px',
	},
	item: {
		padding: 10,
		paddingLeft: 20,
	},
}));

export default function KeyMetrics() {
	//--------------------------------------------private:STRUCTURED::ABS:UNSTRUCTURED
	const [chargeOffRate, setChargeOffRate] = useState(); //first in both
	const [recoveryRate, setRecoveryRate] = useState(); //second in both
	const [fairValue, setFairValue] = useState(); //private only
	const [fairValuePrice, setFairValuePrice] = useState(); //private only
	const [equityEstimation, setEquityEstimation] = useState(); //ABS only
	//---------------------------------------------
	const summary = useSummary();
	const remainder = useRemainder();
	const classes = useStyles();
	const [deal, setDeal] = useState(
		useSelector((state) => state.data.deal.type),
	);
	const getDeal = useSelector((state) => state.data.deal.type);
	const NA = 'Values not available';

	useEffect(() => {
		summary.refetch();
		remainder.refetch();
		setDeal(getDeal);
	}, [useSelector((state) => state.data.deal.id)]);

	function checkNA(params) {
		return params === NA ? undefined : params;
	}

	useEffect(() => {
		if (summary.data) {
			// Lending deals do not use "current"
			let chargeOffRateActive = undefined;
			let recoveryRateActive = undefined;
			let equityEstimationActive = undefined;
			let fairValueActive = undefined;
			let fairValuePriceActive = undefined;
			if (deal === 'STRUCTURED' || deal === 'UNSTRUCTURED') {
				chargeOffRateActive = checkNA(
					summary.data
						?.find((e) =>
							['Charge-off rate', 'Charged off rate'].includes(e.field),
						)
						?.active.includes('%')
						? summary.data?.find((e) =>
								['Charge-off rate', 'Charged off rate'].includes(e.field),
						  )?.active
						: formatters.toPercentage(
								summary.data?.find((e) =>
									['Charge-off rate', 'Charged off rate'].includes(e.field),
								)?.active,
						  ),
				);
				recoveryRateActive = checkNA(
					summary.data
						?.find((e) => e.field === 'Recovery rate')
						?.active.includes('%')
						? summary.data?.find((e) => e.field === 'Recovery rate')?.active
						: `${(
								summary.data?.find((e) => e.field === 'Recovery rate')?.active *
								100
						  ).toFixed(2)}%`,
				);
				equityEstimationActive = checkNA(
					formatters.toCurrency(remainder.data?.balance),
				);
				fairValueActive = checkNA(
					summary.data
						?.find((e) => e.field === 'Fair value')
						?.active.includes('$')
						? summary.data?.find((e) => e.field === 'Fair value')?.active
						: formatters.toCurrency(
								summary.data?.find((e) => e.field === 'Fair value')?.active,
						  ),
				);
				fairValuePriceActive = checkNA(
					summary.data?.find((e) => e.field === 'Fair value price')?.active,
				);
			}
			let chargeOffRateCurrent = checkNA(
				summary.data
					?.find((e) =>
						['Charge-off rate', 'Charged off rate'].includes(e.field),
					)
					?.current.includes('%')
					? summary.data?.find((e) =>
							['Charge-off rate', 'Charged off rate'].includes(e.field),
					  )?.current
					: formatters.toPercentage(
							summary.data?.find((e) =>
								['Charge-off rate', 'Charged off rate'].includes(e.field),
							)?.current,
					  ),
			);
			let recoveryRateCurrent = checkNA(
				summary.data
					?.find((e) => e.field === 'Recovery rate')
					?.current.includes('%')
					? summary.data?.find((e) => e.field === 'Recovery rate')?.current
					: `${(
							summary.data?.find((e) => e.field === 'Recovery rate')?.current *
							100
					  ).toFixed(2)}%`,
			);
			let equityEstimationCurrent = checkNA(
				formatters.toCurrency(remainder.data?.balance),
			);
			let fairValueCurrent = checkNA(
				summary.data
					?.find((e) => e.field === 'Fair value')
					?.current.includes('$')
					? summary.data?.find((e) => e.field === 'Fair value')?.current
					: formatters.toCurrency(
							summary.data?.find((e) => e.field === 'Fair value')?.current,
					  ),
			);
			let fairValuePriceCurrent = checkNA(
				summary.data?.find((e) => e.field === 'Fair value price')?.current,
			);

			//------------------------------------------------------
			let chargeOffRate = '';
			let recoveryRate = '';
			let equityEstimation = '';
			let fairValue = '';
			let fairValuePrice = '';

			if (deal === 'STRUCTURED' || deal === 'LENDING') {
				if (
					chargeOffRateActive === undefined &&
					recoveryRateActive === undefined &&
					equityEstimationActive === undefined
				) {
					chargeOffRate = chargeOffRateCurrent;
					recoveryRate = recoveryRateCurrent;
					equityEstimation = equityEstimationCurrent;
					fairValue = fairValueCurrent;
					fairValuePrice = fairValueCurrent;
				} else {
					chargeOffRate = chargeOffRateActive;
					recoveryRate = recoveryRateActive;
					equityEstimation = equityEstimationActive;
					fairValue = fairValueActive;
					fairValuePrice = fairValuePriceActive;
				}
			} else if (deal === 'UNSTRUCTURED') {
				if (
					(chargeOffRateActive === undefined ||
						chargeOffRateActive === null ||
						chargeOffRateActive === 'Values not available') &&
					(recoveryRateActive === undefined ||
						recoveryRateActive === null ||
						recoveryRateActive === 'Values not available') &&
					(fairValueActive === undefined ||
						fairValueActive === null ||
						fairValueActive === 'Values not available') &&
					(fairValuePriceActive === undefined ||
						fairValuePriceActive === null ||
						fairValuePriceActive === 'Values not available')
				) {
					chargeOffRate = chargeOffRateCurrent;
					recoveryRate = recoveryRateCurrent;
					equityEstimation = equityEstimationCurrent;
					fairValue = fairValueCurrent;
					fairValuePrice = fairValuePriceCurrent;
				} else {
					chargeOffRate = chargeOffRateActive;
					recoveryRate = recoveryRateActive;
					equityEstimation = equityEstimationActive;
					fairValue = fairValueActive;
					fairValuePrice = fairValuePriceActive;
				}
			}
			setChargeOffRate(chargeOffRate === undefined ? NA : chargeOffRate);
			setRecoveryRate(recoveryRate === undefined ? NA : recoveryRate);
			setEquityEstimation(
				equityEstimation === undefined ? NA : equityEstimation,
			);
			setFairValue(fairValue === undefined ? NA : fairValue);
			setFairValuePrice(fairValuePrice === undefined ? NA : fairValuePrice);
		}
	}, [summary.data, remainder.data]);

	if (summary.status === 'loading' || remainder.status === 'loading')
		return <LoadingSpinner />;
	if (summary.status === 'error') return `Error: ${summary.error.message}`;
	if (remainder.status === 'error') return `Error: ${remainder.error.message}`;

	return !summary.data || !remainder.data ? (
		<Paper
			elevation={25}
			style={{
				padding: 10,
			}}
		>
			<NoData
				chartTitle="Headline Insights"
				chartTitleTooltip="Deal insight derived from Karus ML models. The charge-off and
				recovery predictions apply to the active accounts, as do fair
				value and fair value price predictions for unstructured deals."
			/>
		</Paper>
	) : (
		<Paper
			elevation={25}
			style={{
				padding: 10,
			}}
		>
			<Typography
				style={{
					fontSize: '1.7em',
					fontWeight: 'bold',
					marginBottom: 12,
				}}
			>
				Headline Insights{' '}
				<Tooltip
					placement="right"
					title={
						<Typography style={{ fontSize: '1.4em' }}>
							Deal insight derived from Karus ML models. The charge-off and
							recovery predictions apply to the active accounts, as do fair
							value and fair value price predictions for unstructured deals.
						</Typography>
					}
				>
					<IconButton>
						<HelpIcon fontSize="small" />
					</IconButton>
				</Tooltip>
			</Typography>
			<Grid container className={classes.root}>
				{(deal == 'STRUCTURED' ||
					deal == 'UNSTRUCTURED' ||
					deal == 'LENDING') && (
					<>
						<Grid className={classes.item} item xs>
							{chargeOffRate}
							<p className={classes.p}>Charge Off Rate</p>
						</Grid>

						<Divider orientation="vertical" flexItem />
						<Grid className={classes.item} item xs>
							{recoveryRate}
							<p className={classes.p}>Recovery Rate</p>
						</Grid>

						<Divider orientation="vertical" flexItem />
					</>
				)}
				{deal == 'UNSTRUCTURED' && (
					<>
						<Grid className={classes.item} item xs>
							{fairValue}
							<p className={classes.p}>Fair Value</p>
						</Grid>

						<Divider orientation="vertical" flexItem />
						<Grid className={classes.item} item xs>
							{fairValuePrice}
							<p className={classes.p}>Fair Value Price</p>
						</Grid>
					</>
				)}
				{deal == 'STRUCTURED' ||
					(deal == 'LENDING' && (
						<>
							<Grid className={classes.item} item xs>
								{equityEstimation}
								<p className={classes.p}>Equity Estimation</p>
							</Grid>
						</>
					))}
			</Grid>
		</Paper>
	);
}
