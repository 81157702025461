import React, { useState, useEffect } from 'react';

import BarChart from './BarChart';
import { chartColors as colors } from '../../theme';
import { getFilteredData } from '../../helpers/helpers';

import LoadingSpinner from '../common/LoadingSpinner';
import NoData from '../common/NoData';

import { Grid, Paper } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import HelpIcon from '@material-ui/icons/Help';
import LegendTooltip from '../common/LegendTooltip';

import { legendGenerator } from '../../helpers/helpers';

export default function CategoricalRisk(props) {
	const [axisLabels, setAxisLabels] = useState([]);
	const [dataValues, setDataValues] = useState([]);

	const [filteredData, setFilteredData] = useState([]);
	const [selectedLegend, setSelectedLegend] = useState({
		history: true,
		predicted: true,
	});

	const [loading, setLoading] = useState(true);
	const [chartData, setChartData] = useState([]);

	const { status, data, error, feature, isFetching } = props;

	useEffect(() => {
		if (!data?.data) return;
		setChartData(data?.data);
	});

	useEffect(() => {
		if (!chartData?.map || !feature) return;
		setFilteredData(
			chartData
				.map(getFilteredData(feature, 'feature', null))
				.filter((datum) => datum !== null)
				.filter((d) => d['avg_default_rate'] > 0.01),
		);
	}, [chartData, feature]);

	const alignHistoryAndPredicted = (
		history,
		predicted,
		alignProperty,
		valueProperty,
	) => {
		let allCategories = new Set(); // Hold all categories
		let historyMap = {}, // The maps of alignProperty: valueProperty
			predictedMap = {};
		history.forEach((e) => {
			allCategories.add(e[alignProperty]); // Add to align category
			historyMap[e[alignProperty]] = e[valueProperty]; // Add to the map
		});
		predicted.forEach((e) => {
			allCategories.add(e[alignProperty]);
			predictedMap[e[alignProperty]] = e[valueProperty];
		});

		let newHistory = [],
			newPredicted = [],
			labels = [];

		allCategories.forEach((category) => {
			if (historyMap[category]) {
				labels.push(category);
				// If the category exists in history
				newHistory.push(historyMap[category]);
			} else {
				newHistory.push(null);
			}
			if (predictedMap[category]) {
				newPredicted.push(predictedMap[category]);
			} else {
				newPredicted.push(null);
			}
		});
		return { history: newHistory, predicted: newPredicted, labels: labels };
	};

	const seriesLabels = ['Average Default Rate'];

	useEffect(() => {
		if (filteredData?.map !== undefined) {
			const histData = filteredData
				.map(getFilteredData('History', 'status', null))
				.filter((d) => d);
			const predData = filteredData
				.map(getFilteredData('Predicted', 'status', null))
				.filter((d) => d);

			const alignedData = alignHistoryAndPredicted(
				histData,
				predData,
				'category',
				'avg_default_rate',
			);

			const datasets = [];
			datasets.push({
				label: seriesLabels[0] + ', History',
				backgroundColor: colors.blue,
				fill: false,
				data: selectedLegend.history ? alignedData.history : null,
			});
			datasets.push({
				label: seriesLabels[0] + ', Predicted',
				backgroundColor: colors.yellow,
				fill: false,
				data: selectedLegend.predicted ? alignedData.predicted : null,
			});
			// datasets.push({
			// 	label: seriesLabels[0] + ', Predicted',
			// 	backgroundColor: colors.yellow,
			// 	fill: false,
			// 	data: selectedLegend.predicted
			// 		? filteredData
			// 				.map(getFilteredData('Predicted', 'status', 'avg_default_rate'))
			// 				.filter((d) => d)
			// 		: null,
			// });

			setDataValues(datasets);
			setAxisLabels(alignedData.labels);
			setLoading(false);
		}
	}, [filteredData, selectedLegend]);

	useEffect(() => {
		if (status === 'loading' || !data || isFetching) setLoading(true);
		else setLoading(false);
	}, [isFetching, status, chartData, axisLabels]);

	const legendInfo = [
		{
			label: 'History',
			backgroundColor: colors.blue,
			status: selectedLegend.history,
			onClick: () => {
				setSelectedLegend({
					...selectedLegend,
					history: !selectedLegend.history,
				});
			},
		},
		{
			label: 'Predicted',
			backgroundColor: colors.yellow,
			status: selectedLegend.predicted,
			onClick: () => {
				setSelectedLegend({
					...selectedLegend,
					predicted: !selectedLegend.predicted,
				});
			},
		},
	];

	if (status === 'error') return `Error: ${error.message}`;

	return loading ? (
		<LoadingSpinner />
	) : !chartData?.length ? (
		<NoData
			chartTitle="Categorical Risk History"
			chartTitleTooltip="Historical vs. predicted default rates over select categorical features included in Karus ML models."
		/>
	) : (
		<div style={{ maxWidth: '99%' }}>
			<Typography
				style={{
					fontSize: '1.7em',
					fontWeight: 'bold',
					marginBottom: 12,
					position: 'relative',
				}}
			>
				Categorical Risk
				<Tooltip
					placement="right"
					title={
						<Typography style={{ fontSize: '1.4em' }}>
							Historical vs. predicted default rates over select categorical
							features included in Karus ML models.
						</Typography>
					}
				>
					<IconButton>
						<HelpIcon fontSize="small" />
					</IconButton>
				</Tooltip>
			</Typography>
			{/* {legendGenerator(legendInfo)} */}
			<Grid
				direction="row"
				justify="flex-start"
				alignItems="flex-start"
				spacing={1}
				// style={{ display: 'flex' }}
			>
				<Grid
					item
					xs={12}
					style={
						{
							// display: selectedLegend.history ? 'inline-block' : 'none',
						}
					}
				>
					<Paper>
						<BarChart
							chartTitle="Categorical Risk History"
							chartTitleTooltip="Categorical Risk"
							data={dataValues}
							axisLabels={axisLabels}
							vertical={true}
							hideTop={true}
							showLegend={true}
							xAxisValue={'Category'}
						/>
					</Paper>
				</Grid>
			</Grid>
		</div>
	);
}
