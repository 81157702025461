import React from 'react';
import './authForm.css';
import { useAuth0 } from '@auth0/auth0-react';
import logo from '../../assets/karusLogo.svg';

/*  
		Redirects user to Auth0 universal login page. Upon successful authentication, will redirect user 
		to redirectURI specified in Auth0Provider component.
*/
const LoginButton = () => {
	const { loginWithRedirect } = useAuth0();
	return (
		<button
			className="Homepage-login-button"
			onClick={() => loginWithRedirect()}
		>
			Log In
		</button>
	);
};

const AuthForm = () => {
	return (
		<div className="Homepage-container">
			<img
				className="Homepage-logo"
				src={logo}
				style={{ width: '20vw' }}
				alt="Karus logo"
			/>
			<LoginButton />
		</div>
	);
};

export default AuthForm;
