import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import MixedBarLineChart from './MixedBarLineChart';
import { chartColors as colors } from '../../theme';
import { getFilteredData } from '../../helpers/helpers';

import LoadingSpinner from '../common/LoadingSpinner';
import formatters from '../../helpers/formatters';

import { std, min, max } from 'mathjs';
import NoData from '../common/NoData';

// import { writeJsonFile } from 'write-json-file';

/*const colors = [
	'#05650f',
	'#0091a8',
	'#96caee',
	'#74a979',
	'#007082',
	'#71c1ce',
];*/

const chartColors = {
	red: '#EC7357', // Terra Cotta
	blue: '#2D71EF', // Blue Crayola
	yellow: '#FFB400', // Honey Yellow
	green: '#42E2B8', // Medium Aquamarine
	lavendar: '#CEC2FF', // Lavendar Blue
};

export default function PoolFactorChart(props) {
	const { status, data, error, isFetching, refetch } = props;
	const [filteredData, setFilteredData] = useState([]);
	const [axisLabels, setAxisLabels] = useState([]);
	const [dataValues, setDataValues] = useState([]);
	const [maxValueOfDatafinal, setMaxValueOfData] = useState([]);
	const [selectedLegend, setSelectedLegend] = useState({
		currentBalanceHistory: true,
		currentBalancePredicted: true,
		poolFactor: true,
	});
	const [yaxisInfo, setYaxisInfo] = useState({
		balanceMin: 0,
		poolMin: 0,
		poolMax: 1.0,
	});

	const setAxis = () => {
		// maxValToCut = (filteredData.max() - filteredData.min())*0.16
		// to_cut = 0.7*filteredData.std()
		// to_cut = min(to_cut, maxValToCut)  # If it's greater than maxValToCut, choose maxValToCut
		// to_cut = max(to_cut, 100)  # The min is 100
		// minVal = filteredData.min() - to_cut
		// minVal = max(minVal, 0)  # At least 0 (can't be negative)
		if (filteredData?.map && data?.map && filteredData.length && data.length) {
			// Only if they exist and have some data in it (otherwise mathjs throws error)
			let balanceData = filteredData.map(
				// Balance data for filtered columns
				(d) => +d.currentBalance,
			);
			let globalBalanceData = data.map(
				// All Balance data
				(d) => +d.currentBalance,
			);
			let globalPoolData = data.map((d) => +d.poolFactor); // The global pool data
			let maxValToCut = (max(balanceData) - min(balanceData)) * 0.14; // The Maximum value to cut based on the range
			let toCut = std(balanceData) * 0.8; // The preliminary value to cut from min value in balanceData
			toCut = min(toCut, maxValToCut); // It can't be greater than the max value to cut we found
			toCut = max(toCut, 10); // It can't be less than 10 either
			let minBalanceVal = min(balanceData) - toCut; // Get the min value for the chart
			minBalanceVal = max(minBalanceVal, 0); // It can't be less than 0 either
			let minPoolVal =
				(minBalanceVal / max(globalBalanceData)) * max(globalPoolData); // Minimum Pool value is the equivalent portion of minBalanceVal for the balance to pool data
			let maxPoolVal =
				(max(balanceData) / max(globalBalanceData)) * max(globalPoolData); // Max Pool value is the equivalent portion of max balance val for the balance to pool data
			setYaxisInfo({
				balanceMin: minBalanceVal,
				poolMax: maxPoolVal,
				poolMin: minPoolVal,
			});
		}
	};
	const color = [];

	useEffect(() => {
		refetch();
	}, [useSelector((state) => state.data.deal.id)]);

	useEffect(() => {
		if (data?.map !== undefined)
			setFilteredData(
				data
					.map(getFilteredData(['Predicted', 'History'], 'status', null))
					.filter((datum) => datum !== null),
			);
	}, [data]);

	useEffect(setAxis, [filteredData]);

	const resetXAxis = () => {
		setFilteredData(data);
	};

	const removeXAxis = (sectionName) => {
		if (sectionName === 'History') {
			setFilteredData(
				data
					.map(getFilteredData(['Predicted', ''], 'status', null))
					.filter((datum) => datum !== null),
			);
		}
		if (sectionName === 'Predicted') {
			setFilteredData(
				data
					.map(getFilteredData(['History', ''], 'status', null))
					.filter((datum) => datum !== null),
			);
		}
	};

	useEffect(() => {
		if (filteredData?.map !== undefined) {
			const labels = formatters.dataToDateString(filteredData);
			const datasets = [];
			var maxValueOfData = 0;
			filteredData.map((d) => {
				parseInt(d.currentBalance) >= maxValueOfData
					? (maxValueOfData = d.currentBalance)
					: '';
			});
			datasets.push({
				label: 'Current Balance, History',
				//yAxisID: 'leftAxis',
				data: selectedLegend.currentBalanceHistory
					? filteredData.map(
							getFilteredData('History', 'status', 'currentBalance'),
					  )
					: null,
				order: 1,
				backgroundColor: colors.blue,
			});
			datasets.push({
				label: 'Current Balance, Predicted',
				data: selectedLegend.currentBalancePredicted
					? filteredData.map(
							getFilteredData('Predicted', 'status', 'currentBalance'),
					  )
					: null,
				order: 1,
				backgroundColor: colors.yellow,
			});
			datasets.push({
				type: 'line',
				label: 'Pool Factor',
				yAxisID: 'leftAxis',
				data: selectedLegend.poolFactor
					? filteredData.map((datum) => +datum.poolFactor)
					: null,
				order: 0,
				fill: false,
				borderColor: colors.green,
			});
			setAxisLabels(labels);
			setDataValues(datasets);
			setMaxValueOfData(maxValueOfData);
		}
	}, [filteredData, selectedLegend]);

	// Props updated to re-render axis labels when parent filter wrapper changes state
	useEffect(() => {
		if (!filteredData?.map) return;
		let labels;
		if (props.xAxisValue === 'MDY') {
			labels = formatters.dataToDateString(filteredData);
			setAxisLabels(labels);
		} else if (props.xAxisValue === 'month-index') {
			labels = formatters.dataToMonthSequence(filteredData);
			setAxisLabels(labels);
		}
	}, [props.yAxisValue, props.xAxisValue, filteredData]);

	if (status === 'loading' || !data || isFetching) return <LoadingSpinner />;
	if (status === 'error') return `Error: ${error.message}`;

	const legendInfo = [
		{
			label: 'Current Balance, History',
			backgroundColor: colors.blue,
			status: selectedLegend.currentBalanceHistory,
			onClick: () => {
				if (selectedLegend.currentBalanceHistory) {
					// If we are showing history currently and it was clicked
					removeXAxis('History'); // Remove that section from X axis
				} else {
					resetXAxis();
				}
				setSelectedLegend({
					...selectedLegend,
					currentBalanceHistory: !selectedLegend.currentBalanceHistory,
				});
				setAxis();
			},
		},
		{
			label: 'Pool Factor',
			backgroundColor: colors.green,
			status: selectedLegend.poolFactor,
			onClick: () => {
				setSelectedLegend({
					...selectedLegend,
					poolFactor: !selectedLegend.poolFactor,
				});
			},
		},
		{
			label: 'Current Balance, Predicted',
			backgroundColor: colors.yellow,
			status: selectedLegend.currentBalancePredicted,
			onClick: () => {
				if (selectedLegend.currentBalancePredicted) {
					// If we are showing history currently and it was clicked
					removeXAxis('Predicted'); // Remove that section from X axis
				} else {
					resetXAxis(); // Reset the X axis
				}
				setSelectedLegend({
					...selectedLegend,
					currentBalancePredicted: !selectedLegend.currentBalancePredicted,
				});
				setAxis();
			},
		},
	];
	return dataValues?.length === 0 ? (
		<NoData
			chartTitle="Pool Factor Over Time"
			chartTitleTooltip="Pool factor by month or month index."
		/>
	) : (
		<MixedBarLineChart
			chartTitle="Pool Factor Over Time"
			chartTitleTooltip="Pool factor by month or month index."
			data={dataValues}
			axisLabels={axisLabels}
			xAxisValue={props.xAxisValue}
			tickLabelFormat="M"
			loanPoolMaxValue={maxValueOfDatafinal}
			legendInfo={legendInfo}
			barMin={yaxisInfo.balanceMin}
			lineMax={yaxisInfo.poolMax}
			lineMin={yaxisInfo.poolMin}
			barTitle={'Current Balance'}
			lineTitle={'Pool Factor'}
			poolBig={props.poolBig}
		/>
	);
}
