// React
import React, { useState, useEffect } from 'react';

// Auth0
import { useAuth0 } from '@auth0/auth0-react';

// Redux, Query
import { useSelector } from 'react-redux';
import { useFeatureSelection } from '../../api/query-hooks';

// Common
import formatters from '../../helpers/formatters';
import LoadingSpinner from '../common/LoadingSpinner';
import NoData from '../common/NoData';
import AxiosClient from '../../api/axios-client';

// Material-UI
import { ThemeProvider } from '@material-ui/core';
import { createMuiTheme } from '@material-ui/core/styles';
import MUIDataTable from 'mui-datatables';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import HelpIcon from '@material-ui/icons/Help';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

// Visualization
import FeatureRisk from '../graphs/FeatureRisk';
import FeatureTrackingFilters from '../filters/FeatureTrackingFilters';
import MapChart from '../graphs/MapChart';
import CategoricalRisk from '../graphs/CategoricalRisk';
import CategoricalRiskFilters from '../filters/CategoricalRiskFilters';

const theme = createMuiTheme({
	palette: {
		type: 'dark',
	},
	overrides: {
		MUIDataTableHeadCell: {
			data: {
				fontSize: '0.7em',
				textAlign: 'center',
			},
		},
		MUIDataTableBodyRow: {
			root: {
				'&:nth-child(odd)': {
					backgroundColor: '#666666',
				},
			},
		},
		MuiTableCell: {
			root: {
				padding: '2px',
				fontSize: '0.7em',
				textAlign: 'left',
			},
		},
		MUIDataTableToolbar: {
			icon: {
				'&:hover': {
					color: '#32DB60',
				},
			},
		},
	},
});

const featureSelectionColumns = [
	{
		name: 'measure',
		label: 'Measure',
		options: {
			filter: false,
			customBodyRender: (value) => (value ? value : `Values not available`),
		},
	},
	{
		name: 'mean_default',
		label: 'Mean Default',
		options: {
			filter: false,
			customBodyRender: (value) => (value ? value : `Values not available`),
		},
	},
	{
		name: 'mean_solvent',
		label: 'Mean Solvent',
		options: {
			filter: false,
			customBodyRender: (value) => (value ? value : `Values not available`),
		},
	},
	{
		name: 't_value',
		label: 'T Value',
		options: {
			filter: false,
			customBodyRender: (value) => (value ? value : `Values not available`),
		},
	},
	{
		name: 'p_value',
		label: 'P Value',
		options: {
			filter: false,
			customBodyRender: (value) =>
				value ? formatters.toDecimal(value) : `Values not available`,
		},
	},
	{
		name: 'significant',
		label: 'Significant',
		options: {
			filter: false,
			customBodyRender: (value) => (value ? value : `Values not available`),
		},
	},
	{
		name: 'comment',
		label: 'Comment',
		options: {
			filter: false,
			customBodyRender: (value) => (value ? value : `Values not available`),
		},
	},
	{
		name: 'model_inclusion',
		label: 'Model Inclusion',
		options: {
			filter: false,
			customBodyRender: (value) => (value ? value : `Values not available`),
		},
	},
];

function FeatureSelectionTable() {
	const { status, data, error, refetch } = useFeatureSelection();

	useEffect(() => {
		refetch();
	}, [useSelector((state) => state.data.deal.id)]);

	if (status === 'loading') return <LoadingSpinner />;
	if (status === 'error') return `Error: ${error.message}`;

	const options = {
		elevation: 0,
		filter: false,
		selectableRows: 'none',
		search: false,
		download: false,
		print: false,
		viewColumns: false,
		pagination: true,
		rowsPerPage: 15,
	};

	return data.length === 0 ? (
		<NoData
			chartTitle="Feature Discovery"
			chartTitleTooltip="Tests of statistical significance over numerical features included in the Karus ML models."
		/>
	) : (
		<MUIDataTable
			title={
				<Typography
					style={{
						fontSize: '1.7em',
						fontWeight: 'bold',
						marginBottom: 12,
					}}
				>
					Feature Discovery{' '}
					<Tooltip
						placement="right"
						title={
							<Typography style={{ fontSize: '1.4em' }}>
								Tests of statistical significance over numerical features
								included in the Karus ML models.
							</Typography>
						}
					>
						<IconButton>
							<HelpIcon fontSize="small" />
						</IconButton>
					</Tooltip>
				</Typography>
			}
			columns={featureSelectionColumns}
			data={data}
			options={options}
		/>
	);
}

export default function UnderwritingInsight() {
	// Use if we need to hide / display geographic chart for certain deal types
	const [showGeoChart, setShowGeoChart] = useState(true);
	const { user } = useAuth0();
	const isAdmin = user['http://qa-app.karus.ai/roles'].includes(
		'Super Administrator',
	);
	const [hasAccess, setHasAccess] = useState();

	async function getAccess() {
		const response = await AxiosClient.get(
			`/organization/access/${user.email}`,
		);
		if (response.data.length) {
			setHasAccess(response.data[0].hasAdditionalAccess);
		}
	}

	useEffect(() => {
		getAccess();
	}, []);

	return (
		<>
			{!isAdmin && !hasAccess ? (
				<>
					<div
						style={{
							position: 'absolute',
							zIndex: '10',
							textDecoration: 'solid underline #32DB60 4px',
						}}
					>
						<h1>Contact Karus for access</h1>
					</div>
					<Grid container spacing={2} style={{ filter: 'blur(6px)' }}>
						<Grid item xs={12} lg={showGeoChart ? 6 : 12}>
							<Paper
								elevation={24}
								style={{
									height: '100%',
									display: 'flex',
									flexDirection: 'column',
								}}
							>
								<FeatureTrackingFilters>
									<FeatureRisk />
								</FeatureTrackingFilters>
							</Paper>
						</Grid>
						<Grid
							item
							xs={12}
							lg={6}
							style={{ display: showGeoChart ? 'initial' : 'none' }}
						>
							<Paper
								elevation={24}
								style={{
									height: '100%',
									display: 'flex',
									flexDirection: 'column',
								}}
							>
								<MapChart setShowGeoChart={setShowGeoChart} />
							</Paper>
						</Grid>
						<Grid item xs={12}>
							<Paper
								elevation={24}
								style={{
									height: '100%',
									display: 'flex',
									flexDirection: 'column',
								}}
							>
								<CategoricalRiskFilters>
									<CategoricalRisk />
								</CategoricalRiskFilters>
							</Paper>
						</Grid>
						<ThemeProvider theme={theme}>
							<Grid item xs={12} style={{ width: '80vw', overflow: 'none' }}>
								<Paper
									elevation={24}
									style={{
										padding: 10,
										width: '100%',
										maxWidth: '100%',
										overflowX: 'auto',
									}}
								>
									<FeatureSelectionTable />
								</Paper>
							</Grid>
						</ThemeProvider>
					</Grid>
				</>
			) : (
				<Grid container spacing={2}>
					<Grid item xs={12} lg={showGeoChart ? 6 : 12}>
						<Paper
							elevation={24}
							style={{
								height: '100%',
								display: 'flex',
								flexDirection: 'column',
							}}
						>
							<FeatureTrackingFilters>
								<FeatureRisk />
							</FeatureTrackingFilters>
						</Paper>
					</Grid>
					<Grid
						item
						xs={12}
						lg={6}
						style={{ display: showGeoChart ? 'initial' : 'none' }}
					>
						<Paper
							elevation={24}
							style={{
								height: '100%',
								display: 'flex',
								flexDirection: 'column',
							}}
						>
							<MapChart setShowGeoChart={setShowGeoChart} />
						</Paper>
					</Grid>
					<Grid item xs={12}>
						<Paper
							elevation={24}
							style={{
								height: '100%',
								display: 'flex',
								flexDirection: 'column',
							}}
						>
							<CategoricalRiskFilters>
								<CategoricalRisk />
							</CategoricalRiskFilters>
						</Paper>
					</Grid>
					<ThemeProvider theme={theme}>
						<Grid item xs={12} style={{ width: '80vw', overflow: 'none' }}>
							<Paper
								elevation={24}
								style={{
									padding: 10,
									width: '100%',
									maxWidth: '100%',
									overflowX: 'auto',
								}}
							>
								<FeatureSelectionTable />
							</Paper>
						</Grid>
					</ThemeProvider>
				</Grid>
			)}
		</>
	);
}
