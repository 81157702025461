import React, { Component, useState } from 'react';

import { ThemeProvider } from '@material-ui/core';
import { createMuiTheme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';

import MobileStepper from '@material-ui/core/MobileStepper';
import LoadingSpinner from '../common/LoadingSpinner';

import ID from './UnderwritingSteps/ID';
import Results from './UnderwritingSteps/Results';

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
	},
	button: {
		marginRight: theme.spacing(1),
	},
	buttonPrimary: {
		marginRight: theme.spacing(1),
		backgroundColor: '#32DB60',
		'&:hover': {
			backgroundColor: '#32DB60',
		},
	},
	instructions: {
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(1),
		minHeight: '50%',
		minWidth: '50%',
		justifyContent: 'center',
	},
	completed: {
		'& $line': {
			borderColor: '#32DB60',
		},
	},
	progressBar: {
		width: '100%',
		flexGrow: 1,
		justifyContent: 'center',
		marginBottom: '20px',
		'& .MuiLinearProgress-bar': {
			backgroundColor: 'red',
		},
	},
	circle: {
		width: '50px',
		height: '50px',
		display: 'flex',
		'& > * + *': {
			marginLeft: theme.spacing(2),
		},
	},
}));

const theme = createMuiTheme({
	palette: {
		type: 'dark',
		primary: { main: '#32DB60' },
	},
	overrides: {
		MuiStepper: {
			root: {
				borderRadius: '4px',
				boxShadow:
					'0px 11px 15px -7px rgba(0,0,0,0.2),0px 24px 38px 3px rgba(0,0,0,0.14),0px 9px 46px 8px rgba(0,0,0,0.12)',
			},
		},
		MuiStepIcon: {
			root: {
				'&$completed': {
					color: '#32DB60',
				},
				'&$active': {
					color: '#32DB60',
				},
			},
			active: {},
			completed: {},
		},
		MuiMobileStepper: {
			progress: {
				'& .MuiLinearProgress-bar': {
					backgroundColor: 'red',
				},
			},
		},
	},
});

function getSteps() {
	return ['ID', 'Results'];
}

function getStepContent(step) {
	switch (step) {
		case 0:
			return <ID />;
		case 1:
			return <Results />;
		default:
			return 'Unknown step';
	}
}

export default function Overview() {
	const classes = useStyles();
	const [activeStep, setActiveStep] = useState(0);
	const [loading, setLoading] = useState(false);
	const [skipped, setSkipped] = useState(new Set());
	const steps = getSteps();

	const isStepOptional = (step) => {
		return step === 1;
	};

	const isStepSkipped = (step) => {
		return skipped.has(step);
	};

	const handleNext = () => {
		setActiveStep((prevActiveStep) => prevActiveStep + 1);
	};

	const handleBack = () => {
		setActiveStep((prevActiveStep) => prevActiveStep - 1);
	};

	const handleReset = () => {
		setActiveStep(0);
	};

	return (
		<ThemeProvider theme={theme}>
			<div className={classes.root}>
				{activeStep !== 2 && (
					<Stepper activeStep={activeStep}>
						{steps.map((label, index) => {
							const stepProps = {};
							const labelProps = {};
							return (
								<Step key={label} {...stepProps}>
									<StepLabel {...labelProps}>{label}</StepLabel>
								</Step>
							);
						})}
					</Stepper>
				)}
				<div>
					{activeStep === steps.length ? (
						<div>
							<Grid container className={classes.instructions}>
								<Results />
							</Grid>
							<Button onClick={handleReset} className={classes.button}>
								Reset
							</Button>
						</div>
					) : (
						<div>
							<Grid container className={classes.instructions}>
								{getStepContent(activeStep)}
							</Grid>
							<div>
								<MobileStepper
									variant="progress"
									steps={2}
									position="bottom"
									activeStep={activeStep}
									className={classes.progressBar}
								/>
								<Button
									disabled={activeStep === 0}
									onClick={handleBack}
									className={classes.button}
								>
									Back
								</Button>
								<Button
									variant="contained"
									color="primary"
									onClick={handleNext}
									className={classes.buttonPrimary}
								>
									{activeStep === steps.length - 1 ? 'Finish' : 'Next'}
								</Button>
							</div>
						</div>
					)}
				</div>
				{loading == true && (
					<div
						style={{
							position: 'absolute',
							left: '50%',
							right: '50%',
							zIndex: 5000,
						}}
					>
						<LoadingSpinner />
					</div>
				)}
			</div>
		</ThemeProvider>
	);
}
