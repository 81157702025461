import { isInteger, isNumeric } from 'mathjs';

const dataToDateString = (data) => {
	return data.map((d) =>
		new Date(d.date).toLocaleDateString('en-US', { timeZone: 'UTC' }),
	);
};

const dataToMonthSequence = (data) => {
	return Array.from(Array(data.length).keys());
};

const camelToHuman = (camel) => {
	let human = camel[0].toUpperCase();

	for (let i = 1; i < camel.length; i++) {
		const char = camel[i];
		isUpper(char) ? (human += ` ${char}`) : (human += char);
	}
	return human;

	function isUpper(char) {
		return char !== char.toLowerCase();
	}
};

const humanToCamel = (human) => {
	if (!human) return '';
	const pascal = human.split(' ').join('');
	const camel = human[0].toLowerCase() + pascal.slice(1);
	return camel;
};

// Formatters used by tables (Overview tab, Tranche tab, etc.)

function toCurrency(value) {
	if (isValid(value)) {
		if (!isNaN(value)) {
			return `$${parseFloat(value)
				.toFixed(0)
				.toString()
				.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;
		} else {
			return value;
		}
	} else {
		return `Values not available`;
	}
}

function addCommas(value) {
	if (isValid(value)) {
		if (!isNaN(value)) {
			return `${parseFloat(value)
				.toFixed(0)
				.toString()
				.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;
		} else {
			return value;
		}
	} else {
		return `Values not available`;
	}
}

function toPercentage(value) {
	if (isValid(value)) {
		if (!isNaN(value)) {
			return `${parseFloat(value * 100).toFixed(2)}%`;
		} else {
			return value;
		}
	} else {
		return `Values not available`;
	}
}

function toDouble(value) {
	if (isValid(value)) {
		if (!isNaN(value)) {
			return `${parseFloat(value).toFixed(2)}`;
		} else {
			return value;
		}
	} else {
		return `Values not available`;
	}
}

function toDecimal(value) {
	if (isValid(value)) {
		if (!isNaN(value)) {
			return `${parseFloat(value).toFixed(2)}`;
		} else {
			return value;
		}
	} else {
		return `Values not available`;
	}
}

function isValid(value) {
	if (
		typeof value === 'undefined' ||
		value === null ||
		value === 'No active accounts'
	) {
		return false;
	} else {
		return true;
	}
}

function numDecimals(value) {
	if (isInteger(value) || isNaN(value)) {
		return 0; // Contains no decimal part
	} else {
		return value.toString().split('.')[1].length || 0; // Contains decimal part
	}
}

export default {
	dataToDateString,
	dataToMonthSequence,
	camelToHuman,
	humanToCamel,
	toCurrency,
	addCommas,
	toPercentage,
	toDouble,
	toDecimal,
	isValid,
	numDecimals,
};
