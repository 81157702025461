import React, { useEffect, useState } from 'react';
import {
	useDeals,
	useOrganizations,
	useAllUsers,
	useLendingOriginators,
} from '../../../api/query-hooks';
import AxiosClient from '../../../api/axios-client';

// Material UI
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import PersonIcon from '@material-ui/icons/Person';
import CloseIcon from '@material-ui/icons/Close';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

const useStyles = makeStyles({
	list: {
		backgroundColor: '#666666',
		marginTop: '16px',
		marginBottom: '16px',
		padding: '16px',
		height: 400,
		overflow: 'auto',
	},
	listItem: {
		border: '1px solid #E1E1E1',
		borderRadius: '6px',
		padding: '16px',
		marginBottom: '4px',
	},
});

export default function EditOrganizations() {
	// Styling
	const classes = useStyles();

	// Organizations
	const [selectedOrganization, setSelectedOrganization] = useState({
		id: '',
		name: '',
		hasAdditionalAccess: false,
	});
	const organizationsResponse = useOrganizations();

	async function handleChangeOrganization(event) {
		setSelectedOrganization(event.target.value);

		const dealsResponse = await AxiosClient.get(
			`/deals/organization/${event.target.value.id}`,
		);
		setDeals(dealsResponse.data);

		const usersResponse = await AxiosClient.get(
			`/organization/users/${event.target.value.id}`,
		);
		setUsers(usersResponse.data);

		const lendingOriginatorsResponse = await AxiosClient.get(
			`/lending/originators/${event.target.value.id}`,
		);
		setLendingOriginators(lendingOriginatorsResponse.data);
	}

	function handleChangeOrganizationAccess(event) {
		setSelectedOrganization({
			...selectedOrganization,
			[event.target.name]: event.target.checked,
		});
	}

	// Users
	const [users, setUsers] = useState([]);
	const [selectedUser, setSelectedUser] = useState();
	const usersList = useAllUsers();

	function handleAddUser() {
		setUsers((users) => [...users, selectedUser]);
		setSelectedUser();
	}
	function handleChangeUser(event, value) {
		if (value) {
			setSelectedUser(value);
		} else {
			setSelectedUser(null);
		}
	}
	function handleRemoveUser(id) {
		setUsers(users.filter((user) => user.id !== id));
	}

	// Deals
	const [deals, setDeals] = useState([]);
	const [selectedDeal, setSelectedDeal] = useState();
	const dealsList = useDeals();

	function handleAddDeal() {
		setDeals((deals) => [...deals, selectedDeal]);
		setSelectedDeal();
	}
	function handleChangeDeal(event, value) {
		if (value) {
			setSelectedDeal(value);
		} else {
			setSelectedDeal(null);
		}
	}

	function handleRemoveDeal(id) {
		setDeals(deals.filter((deal) => deal.id !== id));
	}

	// Originators

	const [lendingOriginators, setLendingOriginators] = useState([]);
	const [selectedOriginator, setSelectedOriginator] = useState();
	const originatorsList = useLendingOriginators();

	function handleAddOriginator() {
		setLendingOriginators((lendingOriginators) => [
			...lendingOriginators,
			selectedOriginator,
		]);
		setSelectedOriginator();
	}

	function handleChangeOriginator(event, value) {
		if (value) {
			setSelectedOriginator(value);
		} else {
			setSelectedOriginator(null);
		}
	}

	function handleRemoveOriginator(id) {
		setLendingOriginators(
			lendingOriginators.filter((originator) => originator.id !== id),
		);
	}

	// Form
	function handleSubmit() {
		AxiosClient.post('/organization/update-organization', {
			organization: selectedOrganization,
			users: users,
			deals: deals,
			originators: lendingOriginators,
		});
		setSelectedOrganization({
			id: '',
			name: '',
			hasAdditionalAccess: false,
		});
		setUsers([]);
		setDeals([]);
		setLendingOriginators([]);
	}

	return (
		<div style={{ width: '80vw', margin: '0 auto' }}>
			<br />
			<h1>Edit Organization</h1>
			<br />
			<hr style={{ color: '#616161' }} />
			<br />
			<Paper elevation={24} style={{ textAlign: 'center' }}>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						{selectedOrganization.name ? (
							<h2>{selectedOrganization.name}</h2>
						) : (
							<></>
						)}
					</Grid>
					<Grid item xs={4}>
						<h2>Users</h2>
						<List classes={{ root: classes.list }}>
							{users ? (
								users.map((user, index) => (
									<ListItem key={index} classes={{ root: classes.listItem }}>
										<PersonIcon style={{ marginRight: '8px' }} />
										<div>
											{user.name} ({user.email})
										</div>
										<ListItemSecondaryAction>
											<IconButton
												onClick={() => handleRemoveUser(user.id)}
												edge="end"
											>
												<CloseIcon />
											</IconButton>
										</ListItemSecondaryAction>
									</ListItem>
								))
							) : (
								<></>
							)}
						</List>
						{usersList.data && selectedOrganization.name !== '' ? (
							<>
								<Autocomplete
									options={usersList.data}
									onChange={handleChangeUser}
									getOptionLabel={(user) => user.name}
									getOptionDisabled={(usersListUser) =>
										users.some((user) => usersListUser.id === user.id)
									}
									renderInput={(params) => <TextField {...params} />}
								></Autocomplete>
								<Button
									variant="contained"
									style={{
										color: '#ffffff',
										backgroundColor: '#32DB60',
									}}
									onClick={handleAddUser}
								>
									Add User
								</Button>
							</>
						) : (
							<>
								<FormControl disabled>
									<InputLabel>Add Users</InputLabel>
									<Select style={{ width: '20vw' }}></Select>
								</FormControl>
								<Button
									disabled
									variant="contained"
									style={{
										color: '#ffffff',
									}}
								>
									Add User
								</Button>
							</>
						)}
					</Grid>
					<Grid item xs={4}>
						<h2>Deals</h2>
						<List classes={{ root: classes.list }}>
							{deals ? (
								deals.map((deal, index) => (
									<ListItem key={index} classes={{ root: classes.listItem }}>
										<div>{deal.name}</div>
										<ListItemSecondaryAction>
											<IconButton
												onClick={() => handleRemoveDeal(deal.id)}
												edge="end"
											>
												<CloseIcon />
											</IconButton>
										</ListItemSecondaryAction>
									</ListItem>
								))
							) : (
								<></>
							)}
						</List>
						{dealsList.data && selectedOrganization.name !== '' ? (
							<>
								<Autocomplete
									options={dealsList.data}
									onChange={handleChangeDeal}
									getOptionLabel={(deal) => deal.name}
									getOptionDisabled={(dealListDeal) =>
										deals.some((deal) => dealListDeal.id === deal.id)
									}
									renderInput={(params) => <TextField {...params} />}
								></Autocomplete>
								<Button
									variant="contained"
									style={{
										color: '#ffffff',
										backgroundColor: '#32DB60',
									}}
									onClick={handleAddDeal}
								>
									Add Deal
								</Button>
							</>
						) : (
							<>
								<FormControl disabled>
									<InputLabel>Add Deals</InputLabel>
									<Select style={{ width: '20vw' }}></Select>
								</FormControl>
								<Button
									disabled
									variant="contained"
									style={{
										color: '#ffffff',
									}}
								>
									Add Deal
								</Button>
							</>
						)}
					</Grid>
					<Grid item xs={4}>
						<h2>Lending Originators</h2>
						<List classes={{ root: classes.list }}>
							{lendingOriginators ? (
								lendingOriginators.map((originator, index) => (
									<ListItem key={index} classes={{ root: classes.listItem }}>
										<div>{originator.name}</div>
										<ListItemSecondaryAction>
											<IconButton
												onClick={() => handleRemoveOriginator(originator.id)}
												edge="end"
											>
												<CloseIcon />
											</IconButton>
										</ListItemSecondaryAction>
									</ListItem>
								))
							) : (
								<></>
							)}
						</List>
						{originatorsList.data && selectedOrganization.name !== '' ? (
							<>
								<Autocomplete
									options={originatorsList.data}
									onChange={handleChangeOriginator}
									getOptionLabel={(originator) => originator.name}
									getOptionDisabled={(originatorListOriginator) =>
										lendingOriginators.some(
											(originator) =>
												originatorListOriginator.id === originator.id,
										)
									}
									renderInput={(params) => <TextField {...params} />}
								></Autocomplete>
								<Button
									variant="contained"
									style={{
										color: '#ffffff',
										backgroundColor: '#32DB60',
									}}
									onClick={handleAddOriginator}
								>
									Add Originator
								</Button>
							</>
						) : (
							<>
								<FormControl disabled>
									<InputLabel>Add Originators</InputLabel>
									<Select style={{ width: '20vw' }}></Select>
								</FormControl>
								<Button
									disabled
									variant="contained"
									style={{
										color: '#ffffff',
									}}
								>
									Add Originator
								</Button>
							</>
						)}
					</Grid>
					<Grid item xs={8}>
						{organizationsResponse.data ? (
							<FormControl>
								<InputLabel>Organization</InputLabel>
								<Select
									style={{ width: '30vw' }}
									value={selectedOrganization}
									onChange={handleChangeOrganization}
								>
									{organizationsResponse.data.map((organization, index) => (
										<MenuItem key={index} value={organization}>
											{organization.name}
										</MenuItem>
									))}
								</Select>
							</FormControl>
						) : (
							<></>
						)}
						<br />
						{selectedOrganization.name &&
						users.length !== 0 &&
						deals.length !== 0 ? (
							<Button
								variant="contained"
								style={{
									color: '#ffffff',
									backgroundColor: '#32DB60',
								}}
								onClick={handleSubmit}
							>
								Update Organization
							</Button>
						) : (
							<Button
								variant="contained"
								style={{
									marginTop: '2vh',
									color: '#ffffff',
								}}
								disabled
							>
								Update Organization
							</Button>
						)}
					</Grid>
					<Grid item xs={4}>
						<FormControlLabel
							name="hasAdditionalAccess"
							onChange={handleChangeOrganizationAccess}
							control={
								<Checkbox
									color="primary"
									checked={selectedOrganization.hasAdditionalAccess}
								/>
							}
							label="Has access to Underwriting and Modelling Insight tabs"
							labelPlacement="end"
						/>
					</Grid>
				</Grid>
			</Paper>
		</div>
	);
}
