import React, { useEffect, useState, useRef } from 'react';
import {
	Chart,
	PieController,
	ArcElement,
	CategoryScale,
	Legend,
	Tooltip as TooltipChartPlugin,
} from 'chart.js';

// tooltip imports
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import HelpIcon from '@material-ui/icons/Help';

// future note: we can add datalabels with https://chartjs-plugin-datalabels.netlify.app/guide/getting-started.html

import '../styles/Chart.scss';
import LegendTooltip from '../common/LegendTooltip';

export default function PieChart(props) {
	const chartRef = useRef();
	const [chart, setChart] = useState();

	const createChart = () => {
		Chart.register(
			PieController,
			ArcElement,
			CategoryScale,
			Legend,
			TooltipChartPlugin,
		);
		if (props.data && props.axisLabels) {
			setChart(
				new Chart(chartRef.current, {
					type: 'pie',
					data: {
						labels: props.axisLabels,
						datasets: props.data,
					},
					options: {
						borderColor: '#424242',
						responsive: true,
						maintainAspectRatio: false,
						plugins: {
							legend: {
								position: 'right',
								display: true,
								labels: {
									color: '#f0f0f0',
								},
								onHover: function (e, legendItem, legend) {
									e.native.target.style.cursor = 'pointer';
								}.bind(this),
								onLeave: function (e, legendItem, legend) {
									e.native.target.style.cursor = 'default';
								}.bind(this),
							},
						},
					},
				}),
			);
		}
	};

	useEffect(() => {
		if (chart) chart.destroy();
		createChart();
	}, [props.data, props.axisLabels]);

	return (
		<>
			<Typography
				style={{
					fontSize: '1.7em',
					fontWeight: 'bold',
					marginBottom: 12,
					position: 'relative',
				}}
			>
				{props.chartTitle}
				<Tooltip
					placement="right"
					title={
						<Typography style={{ fontSize: '1.4em' }}>
							{props.chartTitleTooltip}
						</Typography>
					}
				>
					<IconButton>
						<HelpIcon fontSize="small" />
					</IconButton>
				</Tooltip>
			</Typography>
			<div
				style={{ position: 'relative', height: props.loanBig }}
				className="chart-container-without-filters"
			>
				<canvas ref={chartRef} style={{ margin: 10 }} />
			</div>
		</>
	);
}
