// React
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
	fetchFilterOptions,
	setDeal,
	setRequestID,
} from '../../../redux/actions/dataActions';

// Query
import { useDeals, usePrivateOpportunities } from '../../../api/query-hooks';

// Common
import MainLoadingSpinner from '../../common/MainLoadingSpinner';
import { colorPalette as colors } from '../../../theme';

// Material UI
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
	root: {
		width: '50vw',
		margin: '0 auto',
	},
	header: {
		textAlign: 'center',
	},
	lineBreak: {
		color: '#616161',
	},
	content: {
		margin: '0 auto',
	},
	button: {
		marginTop: '2vh',
		color: '#ffffff',
		backgroundColor: '#32DB60',
	},
	backArrow: {
		color: colors[5],
	},
}));

function Alert(props) {
	return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function Opportunities() {
	const classes = useStyles();
	const history = useHistory();
	const dispatch = useDispatch();
	const dealsResponse = useDeals();
	const privateOpportunitiesResponse = usePrivateOpportunities();
	const allowedDeals = useSelector((state) => state.data.allowedDeals);

	const [descriptions, setDescriptions] = useState([]);
	const [selectedDescription, setSelectedDescription] = useState();
	const [originators, setOriginators] = useState([]);
	const [selectedOriginator, setSelectedOriginator] = useState();
	const [deals, setDeals] = useState([]);
	const [filteredDeals, setFilteredDeals] = useState([]);
	const [selectedDeal, setSelectedDeal] = useState();
	const [moveLoading, setMoveLoading] = useState(false);
	const [open, setOpen] = useState(false);

	useEffect(() => {
		if (dealsResponse.data && privateOpportunitiesResponse.data) {
			setDescriptions(privateOpportunitiesResponse.data);
			setOriginators(
				dealsResponse.data
					.filter((deal) => deal.type == 'UNSTRUCTURED')
					.map((deal) => ({
						name: deal.originator_name,
						isOpportunity: false,
					}))
					.filter((item, index, self) => self.indexOf(item) == index),
			);
			let opportunities = privateOpportunitiesResponse.data.map(
				(opportunity) => ({
					name: opportunity.name,
					isOpportunity: true,
				}),
			);
			setOriginators((originators) => [...originators, ...opportunities]);
			setDeals(
				dealsResponse.data.filter((deal) => deal.type == 'UNSTRUCTURED'),
			);
		}
	}, [dealsResponse.data, privateOpportunitiesResponse.data]);

	function handleChangeOriginator(event, value) {
		if (value !== null) {
			setSelectedOriginator((prevState) => ({
				...prevState,
				name: value.name,
				isOpportunity: value.isOpportunity,
			}));
			setFilteredDeals(
				deals.filter((deal) => deal.originator_name == value.name),
			);
		} else {
			setSelectedOriginator();
			handleChangeDeal(null);
		}
	}

	function handleChangeDeal(event, value) {
		if (value) {
			setSelectedDeal(value);
		} else {
			setSelectedDeal();
		}
	}

	function handleSubmit() {
		if (selectedDeal) {
			setMoveLoading(true);
			dispatch(fetchFilterOptions(selectedDeal.id)).then(() => {
				dispatch(setDeal(selectedDeal));
				dispatch(setRequestID(''));
				history.push('/overview');
			});
		} else if (selectedOriginator.isOpportunity) {
			const location = {
				pathname: '/private',
				state: {
					name: selectedOriginator.name,
					description: descriptions
						.filter((originator) => originator.name === selectedOriginator.name)
						.map((originator) => originator.description),
				},
			};
			history.push(location);
		} else {
			setOpen(true);
		}
	}

	function handleClose(event, reason) {
		if (reason === 'clickaway') {
			return;
		}
		setOpen(false);
	}

	function handleReturn() {
		history.goBack();
	}

	if (
		dealsResponse.status === 'loading' ||
		privateOpportunitiesResponse.status === 'loading'
	) {
		return <MainLoadingSpinner />;
	}
	if (
		dealsResponse.status === 'error' ||
		privateOpportunitiesResponse.status === 'error'
	) {
		return `Error: ${dealsResponse.error.message}`;
	}

	return moveLoading ? (
		<MainLoadingSpinner />
	) : (
		<Paper className={classes.root}>
			<Grid container>
				<Grid item xs={12}>
					<h1 className={classes.header}>Auto Receivables Opportunities</h1>
					<hr className={classes.lineBreak} />
					<br />
					<Grid item xs={6} className={classes.content}>
						<Autocomplete
							onChange={handleChangeOriginator}
							options={originators}
							getOptionLabel={(originator) => originator.name}
							getOptionSelected={(originator, value) =>
								originator.name === value.name
							}
							renderInput={(originator) => (
								<TextField {...originator} label={'Originator'} />
							)}
							renderOption={(originator) => {
								return (
									<li>
										{originator.isOpportunity
											? `Private Opportunity - ${originator.name}`
											: `${originator.name}`}
									</li>
								);
							}}
						/>
					</Grid>
					{selectedOriginator && !selectedOriginator.isOpportunity ? (
						<Grid item xs={6} className={classes.content}>
							<Autocomplete
								disabled={
									!selectedOriginator || selectedOriginator.isOpportunity
								}
								onChange={handleChangeDeal}
								options={filteredDeals}
								getOptionDisabled={(deal) => !allowedDeals.includes(deal.id)}
								getOptionLabel={(deal) => deal.name}
								getOptionSelected={(option, value) =>
									option.name === value.name
								}
								renderInput={(deal) => <TextField {...deal} label={'Deal'} />}
								renderOption={(deal) => {
									return (
										<li>
											{allowedDeals.includes(deal.id)
												? `${deal.name}`
												: `${deal.name} - Contact Karus for access`}
										</li>
									);
								}}
							/>
						</Grid>
					) : (
						<Grid item xs={6}></Grid>
					)}
				</Grid>
				<Grid item xs={6} className={classes.content}>
					{selectedOriginator &&
					(selectedOriginator.isOpportunity || selectedDeal) ? (
						<Button
							disabled={!selectedOriginator}
							variant="contained"
							className={classes.button}
							onClick={handleSubmit}
						>
							Submit
						</Button>
					) : (
						<Button
							disabled={true}
							variant="contained"
							className={classes.button}
						>
							Submit
						</Button>
					)}
				</Grid>
				<Grid item xs={12}>
					<IconButton onClick={handleReturn}>
						<ArrowBackIcon className={classes.backArrow} />
					</IconButton>
				</Grid>
			</Grid>
			<Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
				<Alert onClose={handleClose} severity="error">
					Please Select Deal to Continue
				</Alert>
			</Snackbar>
		</Paper>
	);
}
