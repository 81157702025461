// React
import React, { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';

// Query
import { useNetLosses } from '../../api/query-hooks';

// Common
import { chartColors as colors } from '../../theme';
import { colorPalette as colorPalette } from '../../theme';
import LoadingSpinner from '../common/LoadingSpinner';
import NoData from '../common/NoData';

// Material UI
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import HelpIcon from '@material-ui/icons/Help';
import IconButton from '@material-ui/core/IconButton';
import Box from '@material-ui/core/Box';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import { makeStyles } from '@material-ui/core';

// Chart JS
import {
	Chart,
	CategoryScale,
	LinearScale,
	LineController,
	LineElement,
	PointElement,
	Tooltip as TooltipChartPlugin,
} from 'chart.js';

const styles = makeStyles((theme) => ({
	list: {
		maxHeight: '100%',
		overflow: 'auto',
		position: 'relative',
	},
	radioButton: {
		'&$checked': {
			color: '#32DB60',
		},
	},
	checked: {},
	listItemText: {
		fontSize: '12px',
	},
}));

const chartColors = [
	// karus
	'#32DB60', // karus green
	'#FFC800', // karus yellow
	'#39A9DB', // karus blue
	'#FFFFFF', // white

	// primary
	'#ff4d4d', // red
	'#ffa64d', // orange
	'#ffd24d', // yellow
	'#79ff4d', // green
	'#4dd2ff', // blue
	'#d24dff', // violet
	'#ff4dff', // pink
	'#d89374', // brown
	'#8dd874', // forest green
	'#748dd8', // dark blue
	'#a674d8', // dark purple

	// gradients
	// red
	'#ffe6e6',
	'#ffb3b3',
	'#ff8080',
	'#ff1a1a',
	'#e60000',
	'#b30000',
	'#800000',
	// orange
	'#fff2e6',
	'#ffd9b3',
	'#ffbf80',
	'#ff8c1a',
	'#e67300',
	'#b35900',
	'#804000',
	// yellow
	'#fff9e6',
	'#ffecb3',
	'#ffdf80',
	'#ffc61a',
	'#e6ac00',
	'#b38600',
	'#806000',
	// green
	'#ecffe6',
	'#c6ffb3',
	'#9fff80',
	'#53ff1a',
	'#00e639',
	'#00b32d',
	'#008020',
	// blue
	'#e6f9ff',
	'#b3ecff',
	'#80dfff',
	'#1ac6ff',
	'#00ace6',
	'#0086b3',
	'#006080',
	// violet
	'#f9e6ff',
	'#ecb3ff',
	'#df80ff',
	'#c61aff',
	'#ac00e6',
	'#8600b3',
	'#600080',
	// pink
	'#ffe6ff',
	'#ffb3ff',
	'#ff80ff',
	'#ff1aff',
	'#e600e6',
	'#b300b3',
	'#800080',
	// brown
	'#f9f0eb',
	'#eed1c3',
	'#e3b29c',
	'#cd754c',
	'#b35b32',
	'#8b4727',
	'#63331c',
	// forest green
	'#eff9eb',
	'#ceeec3',
	'#ade39c',
	'#6ccd4c',
	'#53b332',
	'#408b27',
	'#2e631c',
	// dark blue
	'#ebeff9',
	'#c3ceee',
	'#9cade3',
	'#4c6ccd',
	'#3253b3',
	'#27408b',
	'#1c2e63',
	// dark purple
	'#f2ebf9',
	'#d9c3ee',
	'#bf9ce3',
	'#8c4ccd',
	'#7332b3',
	'#59278b',
	'#401c63',
];

function NetLossChart(props) {
	const [chart, setChart] = useState();
	const [labels, setLabels] = useState();
	const [datasets, setDatasets] = useState();
	const [loading, setLoading] = useState(true);

	const chartRef = useRef();

	const data_sources = [
		{
			key: 'cumulativeNetLossPercent',
			value: 'Cumulative Net Loss Percent',
			column: 'cumulative_net_loss_percent',
		},
		{
			key: 'cumulativeChargedOffPercent',
			value: 'Cumulative Charged-Off Percent',
			column: 'cumulative_chargedoff_percent',
		},
	];

	useEffect(() => {
		if (props.data && props.data.length !== 0) {
			let newDatasets = [];
			props.selectedValuesList.forEach((item) => {
				// /test
				if (item.checked) {
					newDatasets.push({
						label: `${item.value} - ${
							data_sources.find((object) => object.key === props.selectedOption)
								.value
						}`,
						data: props.data
							.filter((row) => row.feature === props.selectedFeature)
							.filter((row) => row.value === item.value && item.checked)
							.map((row) =>
								Number(
									row[
										`${
											data_sources.find(
												(object) => object.key === props.selectedOption,
											).column
										}`
									],
								),
							),
						borderColor: item.color,
					});
				}
			});
			setDatasets(newDatasets);

			// Create an array of integers from 1 to the maximum month number contained in the dataset
			setLabels(
				Array.from(
					{
						length: Math.max.apply(
							Math,
							props.data.map((row) => {
								return row.month_number;
							}),
						),
					},
					(_, i) => i + 1,
				),
			);

			setLoading(false);
		}
	}, [props]);

	useEffect(() => {
		if (props.status === 'loading' || !props.data) setLoading(true);
		else setLoading(false);
	}, [props.status, props.data]);

	useEffect(() => {
		if (chart) {
			chart.destroy();
		}
		createChart();
	}, [props.data, datasets, labels]);

	function createChart() {
		Chart.register(
			CategoryScale,
			LinearScale,
			LineController,
			LineElement,
			PointElement,
			TooltipChartPlugin,
		);
		if (props.data && datasets && labels) {
			setChart(
				new Chart(chartRef.current, {
					type: 'line',
					data: {
						labels: labels,
						datasets: datasets,
					},
					options: {
						responsive: true,
						maintainAspectRatio: false,
						elements: {
							point: {
								radius: 0,
								hitRadius: 5,
								borderWidth: 0,
								hoverBorderWidth: 0,
								backgroundColor: 'rgba(0, 0, 0, 0.0)',
								borderColor: 'rgba(0, 0, 0, 0.0)',
							},
						},
						plugins: {
							legend: {
								position: 'top',
								display: false,
							},
						},
						scales: {
							y: {
								ticks: {
									font: {
										weight: 'bold',
									},
									padding: 10,
								},
								grid: {
									drawTicks: true,
									display: true,
									zeroLineColor: '#e1e1e1',
								},
								title: {
									display: true,
									text: data_sources.find(
										(object) => object.key === props.selectedOption,
									).value,
								},
							},
							x: {
								ticks: {
									font: {
										weight: 'bold',
									},
									padding: 10,
								},
								grid: {
									drawTicks: true,
									display: true,
									zeroLineColor: '#e1e1e1',
								},
								title: {
									display: true,
									text: 'Month Index',
								},
							},
						},
					},
				}),
			);
		}
	}

	return loading ? (
		<LoadingSpinner />
	) : !props.data?.length ? (
		<NoData
			chartTitle="Net Loss"
			chartTitleTooltip="Cumulative net loss percent sorted by feature and value."
		/>
	) : (
		<>
			<Typography
				style={{
					fontSize: '1.7em',
					fontWeight: 'bold',
					marginBottom: 12,
					position: 'relative',
				}}
			>
				Net Loss
				<Tooltip
					placement="right"
					title={
						<Typography style={{ fontSize: '1.4em', paddingTop: -40 }}>
							Cumulative net loss percent sorted by feature and value.
						</Typography>
					}
				>
					<IconButton>
						<HelpIcon fontSize="small" />
					</IconButton>
				</Tooltip>
			</Typography>
			<canvas style={{ maxHeight: '80vh' }} ref={chartRef} />
		</>
	);
}

export default function NetLoss() {
	// Default feature selected - Original Interest Rate Percentage
	const [selectedOption, setSelectedOption] = useState(
		'cumulativeNetLossPercent',
	);
	const [selectedFeature, setSelectedFeature] = useState(
		'originalInterestRatePercentage',
	);
	const [selectedValuesList, setSelectedValuesList] = useState([]);
	const { data, status, refetch } = useNetLosses();
	const classes = styles();

	function groupBy(array, key) {
		if (array && array.length) {
			return array.reduce(function (row, value) {
				(row[value[key]] = row[value[key]] || []).push(value);
				return row;
			}, {});
		} else {
			return [];
		}
	}

	useEffect(() => {
		setSelectedValuesList([]);
		refetch();
	}, [useSelector((state) => state.data.deal.id)]);

	console.log(data);

	useEffect(() => {
		if (data && data.length && selectedFeature) {
			// Value data
			var counter = 0;
			setSelectedValuesList(
				Object.keys(
					groupBy(groupBy(data, 'feature')[`${selectedFeature}`], 'value'),
				)
					.sort((a, b) => {
						const months = [
							'Jan',
							'Feb',
							'Mar',
							'Apr',
							'May',
							'Jun',
							'Jul',
							'Aug',
							'Sep',
							'Oct',
							'Nov',
							'Dec',
						];
						switch (selectedFeature) {
							case 'originalLoanAmount':
							case 'originalInterestRatePercentage':
							case 'vehicleValueAmount':
								// If value includes a "less than" symbol, it is presumably the lowest value in the set
								if (a.includes('<')) {
									return -1;
								}
								// Otherwise, sort by the first integer in the range
								return (
									parseInt(a.slice(0, a.lastIndexOf('-')).replace('<', '')) -
									parseInt(b.slice(0, b.lastIndexOf('-')).replace('<', ''))
								);
							case 'originationDateR':
								// If two values have the same year, sort by month
								if (a.split('-')[1] === b.split('-')[1]) {
									return (
										months.indexOf(a.slice(0, a.lastIndexOf('-'))) -
										months.indexOf(b.slice(0, b.lastIndexOf('-')))
									);
								}
								// Otherwise, sort by year
								return a.split('-')[1] - b.split('-')[1];
							case 'vehicleManufacturerName':
							case 'obligorGeographicLocation':
							case 'vehicleModelName':
							default:
								return a.localeCompare(b);
						}
					})
					.map(function (value, index) {
						while (counter < 5) {
							counter++;
							return {
								value: value,
								checked: true,
								color:
									chartColors[index] ||
									`#${Math.floor(Math.random() * 16777215).toString(16)}`,
							};
						}
						return {
							value: value,
							checked: false,
							color:
								chartColors[index] ||
								`#${Math.floor(Math.random() * 16777215).toString(16)}`,
						};
					}),
			);
		}
	}, [data, selectedFeature]);

	function handleChangeSelectedOption(e) {
		setSelectedOption(e.target.value);
	}

	function handleChangeSelectedFeature(e) {
		setSelectedFeature(e.target.value);
	}

	function handleToggle(e) {
		let newData = [...selectedValuesList];
		newData.find((item) => item.value === e.target.value).checked =
			e.target.checked;
		setSelectedValuesList(newData);
	}

	return !data ? (
		<Paper
			elevation={24}
			style={{
				padding: 10,
			}}
		>
			<NoData
				chartTitle={'Net Loss'}
				chartTitleTooltip="Cumulative net loss percent sorted by feature and value."
			/>
		</Paper>
	) : (
		<Paper>
			<div style={{ display: 'flex', height: '85vh', width: '100%' }}>
				<div
					style={{
						flexGrow: 1,
						minWidth: 0,
						paddingRight: '12px',
					}}
				>
					<NetLossChart
						data={data}
						status={status}
						refetch={refetch}
						selectedOption={selectedOption}
						selectedFeature={selectedFeature}
						selectedValuesList={selectedValuesList}
					/>
				</div>
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						flex: '0 0 280px',
						gap: '12px',
					}}
				>
					<Paper elevation={24} style={{ flex: '0 1 120px' }}>
						<div>
							<Typography
								style={{
									fontSize: '1.2em',
									fontWeight: 'bold',
									position: 'relative',
								}}
							>
								Data
								<Tooltip
									placement="bottom"
									title={
										<Typography style={{ fontSize: '1.2em' }}>
											Switch between data types.
										</Typography>
									}
								>
									<IconButton>
										<HelpIcon fontSize="small" />
									</IconButton>
								</Tooltip>
							</Typography>
							<FormControl component="fieldset">
								<RadioGroup
									aria-label="field"
									defaultValue={selectedOption}
									onChange={handleChangeSelectedOption}
								>
									<FormControlLabel
										value="cumulativeNetLossPercent"
										control={
											<Radio
												classes={{
													root: classes.radioButton,
													checked: classes.checked,
												}}
											/>
										}
										label={
											<Typography className={classes.listItemText}>
												Cumulative Net Loss Percent
											</Typography>
										}
									/>
									<FormControlLabel
										value="cumulativeChargedOffPercent"
										control={
											<Radio
												classes={{
													root: classes.radioButton,
													checked: classes.checked,
												}}
											/>
										}
										label={
											<Typography className={classes.listItemText}>
												Cumulative Charged-Off Percent
											</Typography>
										}
									/>
								</RadioGroup>
							</FormControl>
						</div>
					</Paper>
					<Paper elevation={24} style={{ flex: '0 1 320px' }}>
						<div>
							<Typography
								style={{
									fontSize: '1.2em',
									fontWeight: 'bold',
									position: 'relative',
								}}
							>
								Selected Feature
								<Tooltip
									placement="bottom"
									title={
										<Typography style={{ fontSize: '1.2em' }}>
											Switch between features.
										</Typography>
									}
								>
									<IconButton>
										<HelpIcon fontSize="small" />
									</IconButton>
								</Tooltip>
							</Typography>
							<FormControl component="fieldset">
								<RadioGroup
									aria-label="field"
									defaultValue={selectedFeature}
									onChange={handleChangeSelectedFeature}
								>
									<FormControlLabel
										value="originalInterestRatePercentage"
										control={
											<Radio
												classes={{
													root: classes.radioButton,
													checked: classes.checked,
												}}
											/>
										}
										label={
											<Typography className={classes.listItemText}>
												Original Interest Rate Percentage
											</Typography>
										}
									/>
									<FormControlLabel
										value="vehicleManufacturerName"
										control={
											<Radio
												classes={{
													root: classes.radioButton,
													checked: classes.checked,
												}}
											/>
										}
										label={
											<Typography className={classes.listItemText}>
												Vehicle Manufacturer Name
											</Typography>
										}
									/>
									<FormControlLabel
										value="originationDateR"
										control={
											<Radio
												classes={{
													root: classes.radioButton,
													checked: classes.checked,
												}}
											/>
										}
										label={
											<Typography className={classes.listItemText}>
												Origination Date
											</Typography>
										}
									/>
									<FormControlLabel
										value="originalLoanAmount"
										control={
											<Radio
												classes={{
													root: classes.radioButton,
													checked: classes.checked,
												}}
											/>
										}
										label={
											<Typography className={classes.listItemText}>
												Original Loan Amount
											</Typography>
										}
									/>
									<FormControlLabel
										value="vehicleValueAmount"
										control={
											<Radio
												classes={{
													root: classes.radioButton,
													checked: classes.checked,
												}}
											/>
										}
										label={
											<Typography className={classes.listItemText}>
												Vehicle Value Amount
											</Typography>
										}
									/>
									<FormControlLabel
										value="obligorGeographicLocation"
										control={
											<Radio
												classes={{
													root: classes.radioButton,
													checked: classes.checked,
												}}
											/>
										}
										label={
											<Typography className={classes.listItemText}>
												Obligor Geographic Location
											</Typography>
										}
									/>
									<FormControlLabel
										value="vehicleModelName"
										control={
											<Radio
												classes={{
													root: classes.radioButton,
													checked: classes.checked,
												}}
											/>
										}
										label={
											<Typography className={classes.listItemText}>
												Vehicle Model Name
											</Typography>
										}
									/>
								</RadioGroup>
							</FormControl>
						</div>
					</Paper>
					<Paper elevation={24} style={{ flex: '1 1 auto', overflow: 'auto' }}>
						<div>
							<Typography
								style={{
									fontSize: '1.2em',
									fontWeight: 'bold',
									position: 'relative',
								}}
							>
								Selected Values
								<Tooltip
									placement="bottom"
									title={
										<Typography style={{ fontSize: '1.2em' }}>
											Toggle values.
										</Typography>
									}
								>
									<IconButton>
										<HelpIcon fontSize="small" />
									</IconButton>
								</Tooltip>
							</Typography>
							<List>
								{!selectedValuesList?.length ? (
									<Typography style={{ fontSize: '1.2em' }}>
										No data available.
									</Typography>
								) : (
									selectedValuesList.map((item, index) => {
										return (
											<ListItem key={index}>
												<ListItemIcon>
													<Checkbox
														value={item.value}
														checked={item.checked}
														checkedIcon={
															<CheckBoxIcon
																fontSize="small"
																style={{ color: '#32DB60' }}
															/>
														}
														onClick={handleToggle}
													/>
												</ListItemIcon>
												<ListItemText
													classes={{ primary: classes.listItemText }}
													style={{
														textDecoration: 'underline',
														textDecorationColor: `${item.color}`,
														textDecorationThickness: '3px',
													}}
													primary={`${item.value}`}
												/>
												<div
													style={{
														height: '24px',
														width: '24px',
														backgroundColor: item.color,
													}}
												/>
											</ListItem>
										);
									})
								)}
							</List>
						</div>
					</Paper>
				</div>
			</div>
		</Paper>
	);
}
