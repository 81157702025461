import React, { useState, useEffect } from 'react';

import PieChart from './PieChart';
import LoadingSpinner from '../common/LoadingSpinner';

// change to import for theme maintainability
import { mutedChartColors as colors } from '../../theme';
import NoData from '../common/NoData';

export default function LoanOutcomes(props) {
	const { status, data, error, isFetching } = props;
	const [axisLabels, setAxisLabels] = useState([]);
	const [dataValues, setDataValues] = useState([]);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		if (data) {
			const totalOutcomeCount = data.reduce((total, d) => {
				return total + parseInt(d.unifiedOutcomeCount);
			}, 0);
			const chartDataValues = data.map((d) => {
				return parseInt(d.unifiedOutcomeCount) / totalOutcomeCount;
			});
			const chartAxisLabels = data.map((d) => d.unifiedOutcome);

			setDataValues([
				{
					data: chartDataValues,
					backgroundColor: colors,
				},
			]);
			setAxisLabels(chartAxisLabels);
			setLoading(false);
		}
	}, [data]);

	useEffect(() => {
		if (status === 'loading' || !data || isFetching) {
			setLoading(true);
		} else {
			setLoading(false);
		}
	}, [isFetching, status, data, axisLabels]);

	if (status === 'error') return `Error: ${error.message}`;

	return loading ? (
		<LoadingSpinner />
	) : !data?.length ? (
		<NoData
			chartTitle="Account Outcomes"
			chartTitleTooltip="Percent of accounts by outcome. Toggle between historical, predicted, or combined (both) outcomes for all accounts and filter by criteria."
		/>
	) : (
		<PieChart
			chartTitle="Account Outcomes"
			chartTitleTooltip="Percent of accounts by outcome. Toggle between historical, predicted, or combined (both) outcomes for all accounts and filter by criteria."
			data={dataValues}
			axisLabels={axisLabels}
			loanBig={props.loanBig}
		/>
	);
}
