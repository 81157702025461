import React, { useState, cloneElement, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
	Button,
	FormControl,
	FormLabel,
	FormControlLabel,
	Grid,
	Paper,
	Radio,
	RadioGroup,
	Typography,
	makeStyles,
	Input,
	rgbToHex,
} from '@material-ui/core';

import {
	useRadioStyles,
	useLabelStyles,
	useButtonStyles,
	CreditScoreSlider,
	useApplyGridStyles,
} from './FilterStyles';
import { useCategoricalRisk } from '../../api/query-hooks';
import formatters from '../../helpers/formatters';

const useStylesTemp = makeStyles({
	root: {
		width: 250,
		marginLeft: 'auto',
	},
	input: {
		width: 62,
		fontSize: '12px',
		color: 'rgb(225, 225, 225)',
	},
});

export default function CategoricalRiskFilters(props) {
	const [queryFilters, setQueryFilters] = useState({
		minAccounts: null,
		maxAccounts: null,
	});

	const { status, data, error, refetch, isFetching } =
		useCategoricalRisk(queryFilters);

	const [feature, setFeature] = useState('vehicleModelName');
	const [accountsInfo, setAccountsInfo] = useState({ min: 0, max: 100000 }); // Range of the slider
	const [featuresInfo, setFeaturesInfo] = useState([]);
	const [accountsValues, setAccountsValues] = useState([0, 10000]); // Value of the slider

	useEffect(() => {
		refetch();
	}, [useSelector((state) => state.data.deal.id)]);

	useEffect(() => {
		if (!data?.accounts?.[0]) return;
		setAccountsInfo(data.accounts[0]); // It starts as an array and the object inside has stuff
		setFeaturesInfo(data.features);
	}, [data]);

	useEffect(() => {
		if (!accountsInfo || !featuresInfo?.[0]) return; // If either accounts and features don't exist go back
		setAccountsValues([+accountsInfo.min, +accountsInfo.max]);
		setFeature(featuresInfo[0].feature);
	}, [accountsInfo, featuresInfo]);

	const radioStyle = useRadioStyles();
	const labelStyle = useLabelStyles();
	const applyGridStle = useApplyGridStyles();

	const classes = useStylesTemp();

	const handleInputChangeMax = (event) => {
		setAccountsValues([
			accountsValues[0],
			event.target.value === '' ? '' : Number(event.target.value),
		]);
	};

	const handleBlur = () => {
		const value = accountsValues[1];
		if (value < 0) {
			setAccountsValues([accountsValues[0], 0]);
		} else if (value > +accountsInfo.max) {
			setAccountsValues([accountsValues[0], accountsInfo.max]);
		}
	};

	const handleInputChangeMin = (event) => {
		setAccountsValues([
			event.target.value === '' ? '' : Number(event.target.value),
			accountsValues[1],
		]);
	};

	const handleFeatureChange = (event) => {
		setFeature(event.target.value);
	};

	const handleClickApply = async () => await refetch();

	const handleAccountsValueChange = (event, newValue) => {
		const [min, max] = newValue;
		setAccountsValues(newValue);
		setQueryFilters((prevState) => ({
			...prevState,
			minAccounts: min,
			maxAccounts: max,
		}));
	};

	const handleHoverApply = useButtonStyles();

	return (
		<>
			{/* Pass down child component and props */}
			{cloneElement(props.children, {
				data,
				status,
				error,
				feature,
				isFetching,
			})}
			{props.hideFilters || !data?.data?.length ? null : ( // Has other meta info along with data such as accounts range etc
				<Paper
					elevation={12}
					className="filter-container"
					style={{
						marginTop: 'auto',
						height: 'auto',
						position: 'relative',
						bottom: '0',
					}}
				>
					<Grid>
						<grid item>
							<Grid
								container
								spacing={2}
								alignItems="top"
								style={{ padding: 10, height: 'auto' }}
							>
								<grid item>
									<Paper
										elevation={3}
										style={{
											marginLeft: 10,
											marginRight: 10,
											padding: 10,
											height: '100%',
										}}
									>
										{/**  FEATURE----------------------------------------------------- */}
										<Grid>
											<grid item>
												<FormControl component="fieldset">
													<FormLabel
														component="legend"
														focused={false}
														classes={{ root: labelStyle.label }}
														style={{
															color: '#fff',
															fontSize: '1rem',
														}}
													>
														Feature{' '}
													</FormLabel>
													<RadioGroup
														aria-label="categorical-risk-feature"
														name="categorical-risk-feature"
														value={feature}
														onChange={handleFeatureChange}
													>
														{featuresInfo.map((f) => {
															return (
																<FormControlLabel
																	value={f.feature}
																	classes={{ label: labelStyle.label }}
																	key={f.feature}
																	control={
																		<Radio
																			classes={{
																				root: radioStyle.radio,
																				checked: radioStyle.checked,
																			}}
																		/>
																	}
																	label={formatters.camelToHuman(f.feature)}
																/>
															);
														})}
													</RadioGroup>
												</FormControl>
											</grid>
										</Grid>
									</Paper>
								</grid>
								<Paper elevation={7} style={{ width: '15em' }}>
									<Grid item>
										<div
											className="credit-score-container"
											style={{ margin: 10 }}
										>
											<Typography
												style={{ marginBottom: '10' }}
												id="range-slider"
											>
												Accounts Range
											</Typography>
											<CreditScoreSlider
												max={+accountsInfo.max}
												min={+accountsInfo.min}
												step={1}
												value={accountsValues}
												onChange={handleAccountsValueChange}
												valueLabelDisplay="auto"
												aria-labelledby="range-slider"
											/>
											<Grid container justify="center">
												<Grid item>
													Min: {'  '}
													<Input
														className={classes.input}
														value={accountsValues[0]}
														onChange={handleInputChangeMin}
														onBlur={handleBlur} // Changes input when it exceeds max or falls below min
														margin="dense"
														inputProps={{
															step: 1,
															min: +accountsInfo.min,
															max: +accountsInfo.max,
															type: 'number',
															'aria-labelledby': 'input-slider',
														}}
													/>
													<br /> <br />
													Max: {'  '}
													<Input
														className={classes.input}
														value={accountsValues[1]}
														onChange={handleInputChangeMax}
														onBlur={handleBlur}
														margin="dense"
														inputProps={{
															step: 1,
															min: +accountsInfo.min,
															max: +accountsInfo.max,
															type: 'number',
															'aria-labelledby': 'input-slider',
														}}
													/>
												</Grid>
												<Grid item>
													<Button
														className={handleHoverApply.button}
														variant="outlined"
														style={{
															width: '50px',
															height: '30px',
															margin: 20,
															padding: 10,
														}}
														onClick={handleClickApply}
													>
														Apply
													</Button>
												</Grid>
											</Grid>
										</div>
									</Grid>
								</Paper>
							</Grid>
						</grid>
					</Grid>
				</Paper>
			)}
		</>
	);
}
