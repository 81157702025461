import React, { useState, cloneElement, useEffect } from 'react';
import {
	FormControl,
	FormLabel,
	FormControlLabel,
	Grid,
	Paper,
	Radio,
	RadioGroup,
	Typography,
} from '@material-ui/core';

import {
	useRadioStyles,
	useLabelStyles,
	useApplyGridStyles,
} from './FilterStyles';
import { colorPalette as colors } from '../../theme';
import { useNetLoss } from '../../api/query-hooks';

export default function CumulativeNetLossFilters(props) {
	const [xAxisValue, setXAxisValue] = useState('MDY');
	const radioStyle = useRadioStyles();
	const labelStyle = useLabelStyles();
	const applyGridStle = useApplyGridStyles();
	const { status, data, error, refetch, isFetching } = useNetLoss();

	const handleXAxisValueChange = (event) => {
		setXAxisValue(event.target.value);
	};

	return (
		<>
			{/* Pass down child component and props */}
			{cloneElement(props.children, {
				xAxisValue,
				data,
				status,
				error,
				refetch,
			})}
			{props.hideFilters || !data?.length ? null : (
				<Grid
					container
					spacing={2}
					alignItems="left"
					justify="top"
					style={{ alignSelf: 'flex-end', marginTop: 'auto' }}
				>
					<Grid item style={{ marginBottom: 20 }}>
						<Paper
							elevation={3}
							style={{ margin: 10, padding: 10, height: '100%' }}
						>
							<FormControl component="fieldset">
								<FormLabel
									focused={false}
									component="legend"
									classes={{ root: labelStyle.label }}
									style={{
										color: '#fff',
										fontSize: '1rem',
									}}
								>
									X-Axis:{' '}
								</FormLabel>
								<RadioGroup
									aria-label="amount-paid-x-axis"
									name="amount-paid-x-axis"
									value={xAxisValue}
									onChange={handleXAxisValueChange}
								>
									<FormControlLabel
										value="MDY"
										classes={{ label: labelStyle.label }}
										control={
											<Radio
												classes={{
													root: radioStyle.radio,
													checked: radioStyle.checked,
												}}
											/>
										}
										label={
											<Typography
												style={{ fontSize: '1rem', color: colors[4] }}
											>
												Date
											</Typography>
										}
									/>
									<FormControlLabel
										value="month-index"
										classes={{ label: labelStyle.label }}
										control={
											<Radio
												classes={{
													root: radioStyle.radio,
													checked: radioStyle.checked,
												}}
											/>
										}
										label={
											<Typography
												style={{ fontSize: '1rem', color: colors[4] }}
											>
												Month Index
											</Typography>
										}
									/>
								</RadioGroup>
							</FormControl>
						</Paper>
					</Grid>
				</Grid>
			)}
		</>
	);
}
