import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import * as ChartGeo from 'chartjs-chart-geo';
import { Chart } from 'chart.js';

import { useGeographicAnalysis } from '../../api/query-hooks';
import LoadingSpinner from '../common/LoadingSpinner';
import States from './states-10m.json';

// Material UI
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import FormHelperText from '@material-ui/core/FormHelperText';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import HelpIcon from '@material-ui/icons/Help';

import { useMapFilterStyles } from '../filters/FilterStyles';
import NoData from '../common/NoData';

const stateAbbreviations = {
	Alaska: 'AK',
	Alabama: 'AL',
	Arkansas: 'AR',
	Arizona: 'AZ',
	California: 'CA',
	Colorado: 'CO',
	Connecticut: 'CT',
	'District of Columbia': 'DC',
	Delaware: 'DE',
	Florida: 'FL',
	Georgia: 'GA',
	Hawaii: 'HI',
	Iowa: 'IA',
	Idaho: 'ID',
	Illinois: 'IL',
	Indiana: 'IN',
	Kansas: 'KS',
	Kentucky: 'KY',
	Louisiana: 'LA',
	Massachusetts: 'MA',
	Maryland: 'MD',
	Maine: 'ME',
	Michigan: 'MI',
	Minnesota: 'MN',
	Missouri: 'MO',
	Mississippi: 'MS',
	Montana: 'MT',
	'North Carolina': 'NC',
	'North Dakota': 'ND',
	Nebraska: 'NE',
	'New Hampshire': 'NH',
	'New Jersey': 'NJ',
	'New Mexico': 'NM',
	Nevada: 'NV',
	'New York': 'NY',
	Ohio: 'OH',
	Oklahoma: 'OK',
	Oregon: 'OR',
	Pennsylvania: 'PA',
	'Rhode Island': 'RI',
	'South Carolina': 'SC',
	'South Dakota': 'SD',
	Tennessee: 'TN',
	Texas: 'TX',
	Utah: 'UT',
	Virginia: 'VA',
	Vermont: 'VT',
	Washington: 'WA',
	Wisconsin: 'WI',
	'West Virginia': 'WV',
	Wyoming: 'WY',
};

const datasetOptions = [
	{ value: 'Average Credit Score', name: 'averageCreditScore' },
	{ value: 'Average Interest Rate', name: 'averageInterestRate' },
	{ value: 'Borrowers', name: 'borrowers' },
];

export default function MapChart(props) {
	const { status, data, error, refetch } = useGeographicAnalysis();
	const [activeDataset, setActiveDataset] = useState('Loading...');
	const [chart, setChart] = useState();
	const [validDatasetOptions, setValidDatasetOptions] = useState([]); // The valid dataset options based on the data
	const chartRef = React.createRef();
	// const mapFilterSTyles = useMapFilterStyles();

	useEffect(() => {
		refetch();
	}, [useSelector((state) => state.data.deal.id)]);

	// Populate valid dataset options by checking which value are null and which are not
	useEffect(() => {
		if (!data?.map) return;
		// Get the values for different options excluding null or undefined
		let borrowersValues = data.map((d) => d.borrowers).filter((d) => d);
		let averageCreditScoreValues = data
			.map((d) => d.averageCreditScore)
			.filter((d) => d);
		let averageInterestRateValues = data
			.map((d) => d.averageInterestRate)
			.filter((d) => d);

		let prospectiveDatasetOptions = datasetOptions.slice(); // The values that could be included

		let removeIdx = 10; // Dummy value to hold index to be removed when checks are passed

		// Remove the ones that don't have values
		if (borrowersValues.length === 0) {
			removeIdx = prospectiveDatasetOptions.findIndex(
				(option) => option.name === 'borrowers',
			); // Find index
			prospectiveDatasetOptions.splice(removeIdx, 1); // Remove value
		}
		// Remove the ones that don't have values
		if (averageCreditScoreValues.length === 0) {
			removeIdx = prospectiveDatasetOptions.findIndex(
				(option) => option.name === 'averageCreditScore',
			); // Find index
			prospectiveDatasetOptions.splice(removeIdx, 1); // Remove value
		}
		// Remove the ones that don't have values
		if (averageInterestRateValues.length === 0) {
			removeIdx = prospectiveDatasetOptions.findIndex(
				(option) => option.name === 'averageInterestRate',
			); // Find index
			prospectiveDatasetOptions.splice(removeIdx, 1); // Remove value
		}

		setValidDatasetOptions(prospectiveDatasetOptions);
	}, [data]);

	function createChart() {
		if (data) {
			const nation = ChartGeo.topojson.feature(States, States.objects.nation)
				.features[0];
			const states = ChartGeo.topojson.feature(
				States,
				States.objects.states,
			).features;
			setChart(
				new Chart(chartRef.current, {
					type: 'choropleth',
					data: {
						labels: states.map((d) => d.properties.name),
						datasets: [
							{
								label: 'States',
								outline: nation,
								data: states.map((d) => ({
									feature: d,
									value: selectActiveDataset(d.properties.name, data),
								})),
							},
						],
					},
					options: {
						responsive: true,
						maintainAspectRatio: false,
						plugins: {
							legend: {
								display: false,
							},
						},
						scale: {
							projection: 'albersUsa',
						},
						geo: {
							colorScale: {
								display: true,
								position: 'bottom',
								quantize: 50,
								legend: {
									position: 'bottom-right',
								},
							},
						},
					},
				}),
			);
		}
	}

	useEffect(() => {
		if (chart) {
			chart.destroy();
		}
		createChart();
	}, [activeDataset, data]);

	useEffect(() => {
		if (data?.map) {
			if (validDatasetOptions[0]?.value)
				setActiveDataset(validDatasetOptions[0].value);
			else setActiveDataset(datasetOptions[0].value);
		}
	}, [validDatasetOptions]);

	// Hide Geo Chart when data is not available (see also Underwriting Insight Page)
	useEffect(() => {
		if (data?.length > 1) {
			// Because it can have NULL column too somehow
			props.setShowGeoChart(true); // Show it (in case user changed the deal)
		} else {
			props.setShowGeoChart(false);
		}
	}, [data]);

	function selectActiveDataset(state, data) {
		for (var i = 0; i < data.length; i++) {
			if (data[i]?.obligorGeographicLocation === stateAbbreviations[state]) {
				switch (activeDataset) {
					case 'Borrowers':
						return parseInt(data[i]?.borrowers);
					case 'Average Credit Score':
						return (
							Math.round(parseFloat(data[i]?.averageCreditScore) * 1e4) / 1e4
						);
					case 'Average Interest Rate':
						return (
							Math.round(parseFloat(data[i]?.averageInterestRate) * 1e4) / 1e4
						);
					default:
						break;
				}
			}
		}
	}

	function handleChangeActiveDataset(event) {
		setActiveDataset(event.target.value);
	}

	if (status === 'loading') return <LoadingSpinner />;
	if (status === 'error') return `Error: ${error.message}`;

	return data.length === 0 ? (
		<NoData
			chartTitle={'Geographic Analysis'}
			chartTitleTooltip="Credit score, interest rate, and number of borrowers at the state level."
		/>
	) : (
		<div style={{ height: '642.5px' }}>
			<div>
				<span style={{ fontSize: '1.7em', fontWeight: 'bold' }}>
					Geographic Analysis
				</span>
				<Tooltip
					placement="right"
					title={
						<Typography style={{ fontSize: '1.4em' }}>
							Credit score, interest rate, and number of borrowers at the state
							level.
						</Typography>
					}
				>
					<IconButton>
						<HelpIcon fontSize="small" />
					</IconButton>
				</Tooltip>
			</div>
			<FormControl style={{ textAlign: 'left' }}>
				<Select value={activeDataset} onChange={handleChangeActiveDataset}>
					{validDatasetOptions.map(({ value }, index) => (
						<MenuItem key={index} value={value}>
							{value}
						</MenuItem>
					))}
				</Select>
				<FormHelperText>Select Dataset</FormHelperText>
			</FormControl>
			<div className="map-chart">
				<canvas ref={chartRef}></canvas>
			</div>
		</div>
	);
}
