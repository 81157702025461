// React
import React, { useState, useEffect } from 'react';

// Query
import { useDelinquencyStatus } from '../../api/query-hooks';

// Common
import NoData from '../common/NoData';
import LoadingSpinner from '../common/LoadingSpinner';
import LineChart from './LineChart';

export default function DelinquencyStatus() {
	const [dataValues, setDataValues] = useState();
	const [axisLabels, setAxisLabels] = useState();
	const [loading, setLoading] = useState(true);
	const [selectedLegend, setSelectedLegend] = useState({
		range_0: true,
		range_0_30: true,
		range_30_60: true,
		range_60_120: true,
		range_120_plus: true,
	});

	const { status, data, error } = useDelinquencyStatus();

	const legendInfo = [
		{
			label: '0%',
			backgroundColor: '#32DB60',
			status: selectedLegend.range_0,
			onClick: () => {
				setSelectedLegend({
					...selectedLegend,
					range_0: !selectedLegend.range_0,
				});
			},
		},
		{
			label: '0 - 30%',
			backgroundColor: '#FFC800',
			status: selectedLegend.range_0_30,
			onClick: () => {
				setSelectedLegend({
					...selectedLegend,
					range_0_30: !selectedLegend.range_0_30,
				});
			},
		},
		{
			label: '30 - 60%',
			backgroundColor: '#39A9DB',
			status: selectedLegend.range_30_60,
			onClick: () => {
				setSelectedLegend({
					...selectedLegend,
					range_30_60: !selectedLegend.range_30_60,
				});
			},
		},
		{
			label: '60 - 120%',
			backgroundColor: '#FFFFFF',
			status: selectedLegend.range_60_120,
			onClick: () => {
				setSelectedLegend({
					...selectedLegend,
					range_60_120: !selectedLegend.range_60_120,
				});
			},
		},
		{
			label: '> 120%',
			backgroundColor: '#ffa64d',
			status: selectedLegend.range_120_plus,
			onClick: () => {
				setSelectedLegend({
					...selectedLegend,
					range_120_plus: !selectedLegend.range_120_plus,
				});
			},
		},
	];

	useEffect(() => {
		if (data) {
			console.log(
				data
					.filter((row) => row.current_delinquency_status_binned === '0-30')
					.map((row) => row.percent),
			);
			let dataValues = [
				{
					label: '0%',
					data: selectedLegend.range_0
						? data
								.filter((row) => row.current_delinquency_status_binned === '0')
								.map((row) => row.percent)
						: null,
					borderColor: '#32DB60',
				},
				{
					label: '0 - 30%',
					data: selectedLegend.range_0_30
						? data
								.filter(
									(row) => row.current_delinquency_status_binned === '0-30',
								)
								.map((row) => row.percent)
						: null,
					borderColor: '#FFC800',
				},
				{
					label: '30 - 60%',
					data: selectedLegend.range_30_60
						? data
								.filter(
									(row) => row.current_delinquency_status_binned === '30-60',
								)
								.map((row) => row.percent)
						: null,
					borderColor: '#39A9DB',
				},
				{
					label: '60 - 120%',
					data: selectedLegend.range_60_120
						? data
								.filter(
									(row) => row.current_delinquency_status_binned === '60-120',
								)
								.map((row) => row.percent)
						: null,
					borderColor: '#FFFFFF',
				},
				{
					label: '> 120%',
					data: selectedLegend.range_120_plus
						? data
								.filter(
									(row) => row.current_delinquency_status_binned === '120+',
								)
								.map((row) => row.percent)
						: null,
					borderColor: '#ffa64d',
				},
			];
			setDataValues(dataValues);
			setAxisLabels(
				data
					.map((row) => row.reporting_period_beginning_date)
					.filter((item, index, self) => self.indexOf(item) == index),
			);
			console.log(dataValues);
			console.log(axisLabels);
		}
	}, [data, selectedLegend]);

	useEffect(() => {
		if (status === 'loading' || !data) {
			setLoading(true);
		} else {
			setLoading(false);
		}
	}, [status, data]);

	if (status === 'error') return `Error: ${error.message}`;

	return loading ? (
		<LoadingSpinner />
	) : !data?.length ? (
		<NoData
			chartTitle="Delinquency Status"
			chartTitleTooltip="Delinquenct status chart."
		/>
	) : (
		<LineChart
			chartTitle="Delinquency Status"
			chartTitleTooltip="Delinquenct status chart."
			data={dataValues}
			axisLabels={axisLabels}
			legendInfo={legendInfo}
			xAxisValue={'Date'}
		/>
	);
}
