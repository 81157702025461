import { useQuery } from 'react-query';
import AxiosClient from './axios-client';
import { useDispatch, useSelector } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';
import {
	setAllowedDeals,
	setAllowedLendingDeals,
} from '../redux/actions/dataActions';

/**
 * Define each query as a custom React Hook based on the react-query
 * useQuery hook.
 */

export function useDeals() {
	const { user } = useAuth0();
	const dispatch = useDispatch();
	const isAdmin = user['http://qa-app.karus.ai/roles'].includes(
		'Super Administrator',
	);
	return useQuery('deals', async () => {
		// Set structured and unstructured deals
		const { data } = await AxiosClient.get(`/deals/`);
		let allowedDeals = [];
		if (isAdmin) {
			data.forEach((deal) => {
				allowedDeals.push(deal.id);
			});
			dispatch(setAllowedDeals(allowedDeals));
		} else {
			AxiosClient.get(`/organization/user/${user.email}`).then(function (
				response,
			) {
				AxiosClient.get(
					`/organization/deals/${response.data[0].organization_id}`,
				).then(function (response) {
					const deals = response.data;
					let allowedDeals = [];
					deals.forEach((deal) => {
						allowedDeals.push(deal.dealsId);
					});
					dispatch(setAllowedDeals(allowedDeals));
				});
			});
		}

		// Set lending deals as well
		const lendingDealsResponse = await AxiosClient.get(`/lending/deals/`);
		let allowedLendingDeals = [];
		if (isAdmin) {
			lendingDealsResponse.data.forEach((deal) => {
				allowedLendingDeals.push(deal.id);
			});
			dispatch(setAllowedLendingDeals(allowedLendingDeals));
		} else {
			AxiosClient.get(`/organization/user/${user.email}`).then(function (
				response,
			) {
				AxiosClient.get(
					`/lending/deals/${response.data[0].organization_id}`,
				).then(function (response) {
					const deals = response.data;
					let allowedLendingDeals = [];
					deals.forEach((deal) => {
						allowedLendingDeals.push(deal.id);
					});
					dispatch(setAllowedLendingDeals(allowedLendingDeals));
				});
			});
		}

		return data;
	});
}

export function useLendingDeals() {
	const { user } = useAuth0();
	const dispatch = useDispatch();
	const isAdmin = user['http://qa-app.karus.ai/roles'].includes(
		'Super Administrator',
	);
	return useQuery('/lending/deals', async () => {
		// Set lending deals
		const { data } = await AxiosClient.get(`/lending/deals/`);
		let allowedLendingDeals = [];
		if (isAdmin) {
			data.forEach((deal) => {
				allowedLendingDeals.push(deal.id);
			});
			dispatch(setAllowedLendingDeals(allowedLendingDeals));
		} else {
			AxiosClient.get(`/organization/user/${user.email}`).then(function (
				response,
			) {
				AxiosClient.get(
					`/lending/deals/${response.data[0].organization_id}`,
				).then(function (response) {
					const deals = response.data;
					let allowedLendingDeals = [];
					deals.forEach((deal) => {
						allowedLendingDeals.push(deal.id);
					});
					dispatch(setAllowedLendingDeals(allowedLendingDeals));
				});
			});
		}

		// Set structured and unstructured deals as well
		const dealsResponse = await AxiosClient.get(`/deals/`);
		let allowedDeals = [];
		if (isAdmin) {
			dealsResponse.data.forEach((deal) => {
				allowedDeals.push(deal.id);
			});
			dispatch(setAllowedDeals(allowedDeals));
		} else {
			AxiosClient.get(`/organization/user/${user.email}`).then(function (
				response,
			) {
				AxiosClient.get(
					`/organization/deals/${response.data[0].organization_id}`,
				).then(function (response) {
					const deals = response.data;
					let allowedDeals = [];
					deals.forEach((deal) => {
						allowedDeals.push(deal.dealsId);
					});
					dispatch(setAllowedDeals(allowedDeals));
				});
			});
		}

		return data;
	});
}

export function useLendingOriginators() {
	return useQuery('lendingOriginators', async () => {
		const { data } = await AxiosClient.get(`/lending/originators`);
		return data;
	});
}

export function usePrivateOpportunities() {
	return useQuery('deals/private', async () => {
		const { data } = await AxiosClient.get(`/deals/private`);
		return data;
	});
}

export function useAllUsers() {
	return useQuery('organizations/users', async () => {
		const { data } = await AxiosClient.get(`/organization/users`);
		return data;
	});
}

export function useOrganizations() {
	return useQuery('organizations', async () => {
		const { data } = await AxiosClient.get(`/organization/`);
		return data;
	});
}

export function useSummary() {
	const dealID = useSelector((state) => state.data.deal.id);
	const dealType = useSelector((state) => state.data.deal.type);
	return useQuery('summary', async () => {
		if (dealType === 'LENDING') {
			const { data } = await AxiosClient.get(`/lending/summary/${dealID}`);
			return data;
		} else {
			const { data } = await AxiosClient.get(
				`/visualization/${dealID}/summary`,
			);
			return data;
		}
	});
}

export function useDealSummary() {
	const dealID = useSelector((state) => state.data.deal.id);
	const dealType = useSelector((state) => state.data.deal.type);
	return useQuery('dealSummary', async () => {
		if (dealType === 'LENDING') {
			const { data } = await AxiosClient.get(`/lending/deal-summary/${dealID}`);
			return data;
		} else {
			const { data } = await AxiosClient.get(
				`/visualization/${dealID}/deal-summary`,
			);
			return data;
		}
	});
}

export function useDealInfo() {
	const dealID = useSelector((state) => state.data.deal.id);
	const dealType = useSelector((state) => state.data.deal.type);
	return useQuery('dealInfo', async () => {
		if (dealType === 'LENDING') {
			const { data } = await AxiosClient.get(`/lending/deal-info/${dealID}`);
			return data;
		} else {
			const { data } = await AxiosClient.get(
				`/visualization/${dealID}/deal-info`,
			);
			return data;
		}
	});
}

export function useRemainder() {
	const dealID = useSelector((state) => state.data.deal.id);
	const dealType = useSelector((state) => state.data.deal.type);
	return useQuery('remainder', async () => {
		if (dealType === 'LENDING') {
			const { data } = await AxiosClient.get(`/lending/remainder/${dealID}`);
			return data;
		} else {
			const { data } = await AxiosClient.get(
				`/visualization/${dealID}/remainder`,
			);
			return data;
		}
	});
}

export function useTranchePayments() {
	const dealID = useSelector((state) => state.data.deal.id);
	const dealType = useSelector((state) => state.data.deal.type);
	return useQuery('tranchePayments', async () => {
		if (dealType === 'LENDING') {
			return [];
		} else {
			const { data } = await AxiosClient.get(
				`/visualization/${dealID}/tranche-payments`,
			);
			return data;
		}
	});
}

export function useTrancheSummaryClass() {
	const dealID = useSelector((state) => state.data.deal.id);
	const dealType = useSelector((state) => state.data.deal.type);
	return useQuery('trancheSummaryClass', async () => {
		if (dealType === 'LENDING') {
			return [];
		} else {
			const { data } = await AxiosClient.get(
				`/visualization/${dealID}/tranche-summary-class`,
			);
			return data;
		}
	});
}

export function useTrancheSummaryMonth() {
	const dealID = useSelector((state) => state.data.deal.id);
	const dealType = useSelector((state) => state.data.deal.type);
	return useQuery('trancheSummaryMonth', async () => {
		if (dealType === 'LENDING') {
			return [];
		} else {
			const { data } = await AxiosClient.get(
				`/visualization/${dealID}/tranche-summary-month`,
			);
			return data;
		}
	});
}

export function useLoanTape() {
	const dealID = useSelector((state) => state.data.deal.id);
	const dealType = useSelector((state) => state.data.deal.type);
	return useQuery('loanTape', async () => {
		if (dealType === 'LENDING') {
			return [];
		} else {
			const { data } = await AxiosClient.get(
				`/visualization/loanTape/${dealID}`,
			);
			return data;
		}
	});
}

export function useAccountLevel() {
	const dealID = useSelector((state) => state.data.deal.id);
	const dealType = useSelector((state) => state.data.deal.type);
	return useQuery('accountLevel', async () => {
		if (dealType === 'LENDING') {
			const { data } = await AxiosClient.get(
				`/lending/account-level/${dealID}`,
			);
			return data;
		} else {
			const { data } = await AxiosClient.get(
				`/visualization/${dealID}/account-level`,
			);
			return data;
		}
	});
}

export function useTransaction() {
	const dealID = useSelector((state) => state.data.deal.id);
	const dealType = useSelector((state) => state.data.deal.type);
	return useQuery('transaction', async () => {
		if (dealType === 'LENDING') {
			const { data } = await AxiosClient.get(`/lending/transaction/${dealID}`);
			return data;
		} else {
			const { data } = await AxiosClient.get(
				`/visualization/${dealID}/transaction`,
			);
			return data;
		}
	});
}

export function useFeatureRisk() {
	const dealID = useSelector((state) => state.data.deal.id);
	const dealType = useSelector((state) => state.data.deal.type);
	return useQuery('featureRisk', async () => {
		if (dealType === 'LENDING') {
			return [];
		} else {
			const { data } = await AxiosClient.get(`/feature/${dealID}/risk`);
			return data;
		}
	});
}

export function useFeatureSelection() {
	const dealID = useSelector((state) => state.data.deal.id);
	const dealType = useSelector((state) => state.data.deal.type);
	return useQuery('featureSelection', async () => {
		if (dealType === 'LENDING') {
			return [];
		} else {
			const { data } = await AxiosClient.get(`/feature/${dealID}/selection`);
			return data;
		}
	});
}

export function useFeatureWeights() {
	const dealID = useSelector((state) => state.data.deal.id);
	const dealType = useSelector((state) => state.data.deal.type);
	return useQuery('featureWeights', async () => {
		if (dealType === 'LENDING') {
			return [];
		} else {
			const { data } = await AxiosClient.get(`/feature/${dealID}/weights`);
			return data;
		}
	});
}

export function usePrincipalAndInterest(params) {
	const dealID = useSelector((state) => state.data.deal.id);
	const dealType = useSelector((state) => state.data.deal.type);
	return useQuery('principalAndInterest', async () => {
		if (dealType === 'LENDING') {
			const { data } = await AxiosClient.get(
				`/lending/principal-and-interest/${dealID}`,
				{ params },
			);
			return data;
		} else {
			const { data } = await AxiosClient.get(
				`/visualization/${dealID}/principal-and-interest`,
				{ params },
			);
			return data;
		}
	});
}

export function useCategoricalRisk(params) {
	const dealID = useSelector((state) => state.data.deal.id);
	const dealType = useSelector((state) => state.data.deal.type);
	return useQuery('categoricalRisk', async () => {
		if (dealType === 'LENDING') {
			const { data } = await AxiosClient.get(
				`/lending/categorical-risk/${dealID}`,
				{ params },
			);
			return data;
		} else {
			const { data } = await AxiosClient.get(
				`/visualization/${dealID}/categorical-risk`,
				{ params },
			);
			return data;
		}
	});
}

export function useDelinquencies(params) {
	const dealID = useSelector((state) => state.data.deal.id);
	const dealType = useSelector((state) => state.data.deal.type);
	return useQuery('delinquencies', async () => {
		if (dealType === 'LENDING') {
			return [];
		} else {
			const { data } = await AxiosClient.get(
				`/visualization/${dealID}/delinquencies`,
				{ params },
			);
			return data;
		}
	});
}

export function usePoolFactor() {
	const dealID = useSelector((state) => state.data.deal.id);
	const dealType = useSelector((state) => state.data.deal.type);
	return useQuery('poolFactor', async () => {
		if (dealType === 'LENDING') {
			const { data } = await AxiosClient.get(`/lending/pool-factor/${dealID}`);
			return data;
		} else {
			const { data } = await AxiosClient.get(
				`/visualization/${dealID}/pool-factor`,
			);
			return data;
		}
	});
}

export function usePredictedPayments() {
	const dealID = useSelector((state) => state.data.deal.id);
	const dealType = useSelector((state) => state.data.deal.type);
	return useQuery('predictedPayments', async () => {
		if (dealType === 'LENDING') {
			return [];
		} else {
			const { data } = await AxiosClient.get(
				`/visualization/predicted-payments/${dealID}`,
			);
			return data;
		}
	});
}

export function usePredictedChargeoffs() {
	const dealID = useSelector((state) => state.data.deal.id);
	const dealType = useSelector((state) => state.data.deal.type);
	return useQuery('predictedChargeoffs', async () => {
		if (dealType === 'LENDING') {
			return [];
		} else {
			const { data } = await AxiosClient.get(
				`/visualization/predicted-chargeoffs/${dealID}`,
			);
			return data;
		}
	});
}

export function usePredictedTransaction() {
	const dealID = useSelector((state) => state.data.deal.id);
	const dealType = useSelector((state) => state.data.deal.type);
	return useQuery('predictedTransaction', async () => {
		if (dealType === 'LENDING') {
			return [];
		} else {
			const { data } = await AxiosClient.get(
				`/visualization/predicted-transaction/${dealID}`,
			);
			return data;
		}
	});
}

export function useBacktestSummary() {
	const dealID = useSelector((state) => state.data.deal.id);
	const dealType = useSelector((state) => state.data.deal.type);
	return useQuery('backtestSummary', async () => {
		if (dealType === 'LENDING') {
			return [];
		} else {
			const { data } = await AxiosClient.get(`/backtest/${dealID}/summary`);
			return data;
		}
	});
}

export function useBacktestScoringTable() {
	const dealID = useSelector((state) => state.data.deal.id);
	const dealType = useSelector((state) => state.data.deal.type);
	return useQuery('backtestScoringTable', async () => {
		if (dealType === 'LENDING') {
			return [];
		} else {
			const { data } = await AxiosClient.get(
				`/backtest/${dealID}/scoring-table`,
			);
			return data;
		}
	});
}

export function useGini() {
	const dealID = useSelector((state) => state.data.deal.id);
	const dealType = useSelector((state) => state.data.deal.type);
	return useQuery('gini', async () => {
		if (dealType === 'LENDING') {
			return [];
		} else {
			const { data } = await AxiosClient.get(`/curves/${dealID}/gini`);
			return data;
		}
	});
}

export function useNetLoss() {
	const dealID = useSelector((state) => state.data.deal.id);
	const dealType = useSelector((state) => state.data.deal.type);
	return useQuery('netLoss', async () => {
		if (dealType === 'LENDING') {
			const { data } = await AxiosClient.get(
				`/lending/cumulative-net-loss/${dealID}`,
			);
			return data;
		} else {
			const { data } = await AxiosClient.get(`/curves/${dealID}/net-loss`);
			return data;
		}
	});
}

export function useAmountPaid(params) {
	const dealID = useSelector((state) => state.data.deal.id);
	const dealType = useSelector((state) => state.data.deal.type);
	return useQuery('amountPaid', async () => {
		if (dealType === 'LENDING') {
			const { data } = await AxiosClient.get(`/lending/amount-paid/${dealID}`, {
				params,
			});
			return data;
		} else {
			const { data } = await AxiosClient.get(
				`/visualization/${dealID}/amount-paid`,
				{ params },
			);
			return data;
		}
	});
}

export function useAmountLost(params) {
	const dealID = useSelector((state) => state.data.deal.id);
	const dealType = useSelector((state) => state.data.deal.type);
	return useQuery('amountLost', async () => {
		if (dealType === 'LENDING') {
			const { data } = await AxiosClient.get(`/lending/amount-lost/${dealID}`, {
				params,
			});
			return data;
		} else {
			const { data } = await AxiosClient.get(
				`/visualization/${dealID}/amount-lost`,
				{ params },
			);
			return data;
		}
	});
}

export function useLoanOutcomes(params) {
	const dealID = useSelector((state) => state.data.deal.id);
	const dealType = useSelector((state) => state.data.deal.type);
	return useQuery('loanOutcomes', async () => {
		if (dealType === 'LENDING') {
			const { data } = await AxiosClient.get(
				`/lending/loan-outcomes/${dealID}`,
				{ params },
			);
			return data;
		} else {
			const { data } = await AxiosClient.get(
				`/visualization/${dealID}/loan-outcomes/`,
				{ params },
			);
			return data;
		}
	});
}

export function useGeographicAnalysis() {
	const dealID = useSelector((state) => state.data.deal.id);
	const dealType = useSelector((state) => state.data.deal.type);
	return useQuery('geographicAnalysis', async () => {
		if (dealType === 'LENDING') {
			const { data } = await AxiosClient.get(
				`/lending/geographic-analysis/${dealID}`,
			);
			return data;
		} else {
			const { data } = await AxiosClient.get(
				`/visualization/${dealID}/geographic-analysis`,
			);
			return data;
		}
	});
}

export function useCurvesScenario() {
	const requestID = useSelector((state) => state.data.requestID);
	return useQuery('curvesScenario', async () => {
		const { data } = await AxiosClient.get(`/scenario/curves/${requestID}`);
		return data;
	});
}

export function usePaymentsScenario() {
	const requestID = useSelector((state) => state.data.requestID);
	return useQuery('paymentsScenario', async () => {
		const { data } = await AxiosClient.get(`/scenario/payments/${requestID}`);
		return data;
	});
}

export function usePoolFactorScenario() {
	const requestID = useSelector((state) => state.data.requestID);
	return useQuery('poolFactorScenario', async () => {
		const { data } = await AxiosClient.get(
			`/scenario/pool-factor/${requestID}`,
		);
		return data;
	});
}

export function useSummaryScenario() {
	const requestID = useSelector((state) => state.data.requestID);
	return useQuery('summaryScenario', async () => {
		const { data } = await AxiosClient.get(`/scenario/summary/${requestID}`);
		return data;
	});
}

export function useTranchePaymentsScenario() {
	const requestID = useSelector((state) => state.data.requestID);
	return useQuery('tranchePaymentsScenario', async () => {
		const { data } = await AxiosClient.get(
			`/scenario/tranche-payments/${requestID}`,
		);
		return data;
	});
}

export function useTrancheSummaryClassScenario() {
	const requestID = useSelector((state) => state.data.requestID);
	return useQuery('trancheSummaryClassScenario', async () => {
		const { data } = await AxiosClient.get(
			`/scenario/tranche-summary-class/${requestID}`,
		);
		return data;
	});
}

export function useScenarioRequests() {
	const { user } = useAuth0();
	const dealId = useSelector((state) => state.data.deal.id);
	return useQuery(
		'scenarioRequests',
		async () => {
			const { data } = await AxiosClient.get(
				`/scenario/requests/${user.email}/${dealId}`,
			);
			return data;
		},
		{
			refetchInterval: 30000,
		},
	);
}

export function useNetLosses() {
	const dealId = useSelector((state) => state.data.deal.id);
	const dealType = useSelector((state) => state.data.deal.type);
	return useQuery('netLosses', async () => {
		if (dealType === 'LENDING') {
			const { data } = await AxiosClient.get(`/lending/net-loss/${dealId}`);
			console.log(data);
			return data;
		} else {
			const { data } = await AxiosClient.get(
				`/visualization/net-loss/${dealId}`,
			);
			return data;
		}
	});
}

export function useDelinquencyStatus() {
	const dealID = useSelector((state) => state.data.deal.id);
	const dealType = useSelector((state) => state.data.deal.type);
	return useQuery('delinquencyStatus', async () => {
		if (dealType === 'LENDING') {
			const { data } = await AxiosClient.get(
				`/lending/delinquency-status/${dealID}`,
			);
			return data;
		} else {
			return [];
		}
	});
}
