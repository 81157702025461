import { QueryClient } from 'react-query';

const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			refetchOnMount: true,
			refetchOnWindowFocus: false,
			refetchOnReconnect: false,
			// staleTime: Infinity, // never invalidate queries automatically
		},
	},
});

export default queryClient;
