import { createMuiTheme } from '@material-ui/core';

const theme = createMuiTheme({
	palette: {
		type: 'dark',
		primary: {
			main: '#32DB60',
		},
	},
	overrides: {
		MuiPaper: {
			root: {
				padding: 10,
			},
		},
	},
	typography: {
		button: {
			textTransform: 'none',
		},
	},
});

export const colorPalette = [
	'#32DB60', //Karus green 0
	'#231E1F', //Raisin Black 1
	'#3F3F3F', //Onyx 2
	'#666666', //Obligor Grey 3
	'#E1e1e1', //Zesty White 4
	'#76E795', //Light Green 5
	'#27BC4F', //dark Pastel Green 6
	'#1C9C3E', //Green Pigment 7
	'#FFC800', //Mikado Yellow 8
	'#CCA000', //Lemon Curry 9
];

export const colorHighContrast = [
	'hsl(86, 70%, 53%)',
	'hsl(106, 70%, 53%)',
	'hsl(166, 70%, 53%)',
	'hsl(196, 70%, 53%)',
	'hsl(226, 70%, 53%)',
	'hsl(256, 70%, 53%)',
	'hsl(286, 70%, 53%)',
	'hsl(316, 70%, 53%)',
	'hsl(346, 70%, 53%)',
];
export const chartColors = {
	green: '#32DB60',
	yellow: '#FFC800',
	white: '#FFFFFF',
	blue: '#39A9DB ',
	//sorry if you don't follow good coding practices
};

export const chartColorsArray = ['#32DB60', '#FFC800', '#FFFFFF', '#39A9DB '];
export const mutedChartColors = ['#22BF4C', '#CCA000', '#E0E0E0', '#238DBE '];

export default theme;
