/* eslint-disable prettier/prettier */
import React, { useState } from 'react';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import { Typography } from '@material-ui/core';
import { useSelector } from 'react-redux';

function importAll(r) {
	return r.keys().map(r);
}

const jsonFiles = importAll(require.context('../UnderwritingJson', false, /\.(json)$/));

export default function Results() {
    const [file] = useState(jsonFiles.find((file) => file.ID === useSelector((state) => state.data.underwritingID)));

	return (
		<>
			<Paper
				elevation={24}
				style={{
					marginTop: '50px',
					minHeight: '50%',
					minWidth: '50%',
					justifyContent: 'center',
					padding: '15px',
					paddingBottom: '20px',
				}}
			>
				<Typography variant="h5">Loan Information ({file.ID})</Typography>
				<Grid
					container
					spacing={2}
					style={{ borderTop: '1px solid grey', marginTop: '3px' }}
				>
                    { file && (
                        <>
                            <Grid item xs={6}>
                                <Typography variant="h6">Model output</Typography>
                                <Grid item xs={12}>
                                    <TextField label='Interest rate' value={file.model_output['Interest rate']} InputLabelProps={{ shrink: true }} fullWidth disabled />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField label='Loan amount' value={file.model_output['Loan amount']} InputLabelProps={{ shrink: true }} fullWidth disabled />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField label='Monthly payment' value={file.model_output['Monthly payment']} InputLabelProps={{ shrink: true }} fullWidth disabled />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField label='LTV' value={file.model_output.LTV} InputLabelProps={{ shrink: true }} fullWidth disabled />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField label='Term' value={file.model_output.Term} InputLabelProps={{ shrink: true }} fullWidth disabled />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField label='Risk quintile' value={file.model_output['Risk quintile']} InputLabelProps={{ shrink: true }} fullWidth disabled />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField label='Solvent probability' value={file.model_output['Solvent probability']} InputLabelProps={{ shrink: true }} fullWidth disabled />
                                </Grid>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography variant="h6">Original underwriting</Typography>
                                <Grid item xs={12}>
                                    <TextField label='Interest rate' value={file.original_underwriting['Interest rate']} InputLabelProps={{ shrink: true }} fullWidth disabled />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField label='Loan amount' value={file.original_underwriting['Loan amount']} InputLabelProps={{ shrink: true }} fullWidth disabled />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField label='Monthly payment' value={file.original_underwriting['Monthly payment']} InputLabelProps={{ shrink: true }} fullWidth disabled />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField label='LTV' value={file.original_underwriting.LTV} InputLabelProps={{ shrink: true }} fullWidth disabled />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField label='Term' value={file.original_underwriting.Term} InputLabelProps={{ shrink: true }} fullWidth disabled />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField label='Risk quintile' value={file.original_underwriting['Risk quintile']} InputLabelProps={{ shrink: true }} fullWidth disabled />
                                </Grid>
                            </Grid>
                        </>
                    )}
				</Grid>
			</Paper>
		</>
	);
}