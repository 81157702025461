// React
import React, { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';

// Queries
import { usePoolFactorScenario } from '../../../api/query-hooks';

// Charts
import {
	Chart,
	CategoryScale,
	BarController,
	BarElement,
	LineController,
	LineElement,
	PointElement,
	Legend,
	Tooltip as TooltipChartPlugin,
} from 'chart.js';

// Common
import { colorPalette as colors } from '../../../theme';
import { chartColors } from '../../../theme';
import LoadingSpinner from '../../common/LoadingSpinner';
import NoData from '../../common/NoData';
import { getFilteredData } from '../../../helpers/helpers';
import { useRadioStyles, useLabelStyles } from '../../filters/FilterStyles';
import '../../styles/legend.scss';

// Material UI
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import HelpIcon from '@material-ui/icons/Help';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import {
	FormControlLabel,
	Radio,
	RadioGroup,
	FormLabel,
	FormControl,
} from '@material-ui/core';
import { max } from 'mathjs';

export default function PoolFactorChart() {
	const { status, data, error, refetch, remove } = usePoolFactorScenario();
	const [chart, setChart] = useState();
	const [labels, setLabels] = useState();
	const [dataSets, setDatasets] = useState();
	const [xAxisValue, setXAxisValue] = useState('MDY');
	const radioStyle = useRadioStyles();
	const labelStyle = useLabelStyles();
	const chartRef = useRef();
	const [selectedLegend, setSelectedLegend] = useState({
		monthlyHistory: true,
		monthlyPredicted: true,
		poolFactor: true,
	});

	useEffect(() => {
		remove();
		refetch();
	}, [
		useSelector((state) => state.data.deal.id),
		useSelector((state) => state.data.requestID),
	]);

	const legendInfo = [
		{
			label: 'Pool Factor',
			backgroundColor: chartColors.green,
			status: selectedLegend.poolFactor,
			onClick: () => {
				setSelectedLegend({
					...selectedLegend,
					poolFactor: !selectedLegend.poolFactor,
				});
			},
		},
		{
			label: `Current Balance, Historical`,
			backgroundColor: chartColors.blue,
			status: selectedLegend.monthlyHistory,
			onClick: () => {
				setSelectedLegend({
					...selectedLegend,
					monthlyHistory: !selectedLegend.monthlyHistory,
				});
			},
		},
		{
			label: 'Current Balance, Predicted',
			backgroundColor: chartColors.yellow,
			status: selectedLegend.monthlyPredicted,
			onClick: () => {
				setSelectedLegend({
					...selectedLegend,
					monthlyPredicted: !selectedLegend.monthlyPredicted,
				});
			},
		},
	];

	function createChart() {
		Chart.register(
			BarController,
			BarElement,
			LineController,
			LineElement,
			PointElement,
			Legend,
			CategoryScale,
		);
		if (data && data.length) {
			let barData = data.map((row) => row.currentBalance);
			let lineData = data.map((row) => row.poolFactor);
			let maxLineVal = (max(barData) / max(barData)) * max(lineData);
			let maxBarVal = max(barData);
			setChart(
				new Chart(chartRef.current, {
					type: 'bar',
					data: {
						labels: labels,
						datasets: dataSets,
					},
					options: {
						responsive: true,
						maintainAspectRatio: true,
						elements: {
							// hide points on line, but retain tooltips
							point: {
								radius: 0,
								hitRadius: 5,
								borderWidth: 0,
								hoverBorderWidth: 0,
								backgroundColor: 'rgba(0, 0, 0, 0.0)',
								color: 'rgba(0, 0, 0, 0.0)',
							},
						},
						plugins: {
							legend: {
								display: false,
								labels: {
									color: '#f0f0f0',
								},
								onHover: function (e, legendItem, legend) {
									e.native.target.style.cursor = 'pointer';
								}.bind(this),
								onLeave: function (e, legendItem, legend) {
									e.native.target.style.cursor = 'default';
								}.bind(this),
							},
						},
						scales: {
							x: {
								axis: 'x',
								grid: {
									drawTicks: false,
									display: true,
									padding: 10,
								},
								display: true,
								beginAtZero: true,
								position: 'right',
								ticks: {
									display: true,
									color: '#e1e1e1',
									font: {
										weight: 'bold',
									},
									padding: 10,
								},
								title: {
									display: true,
									text: 'Date',
								},
							},
							rightAxis: {
								max: maxLineVal,
								position: 'right',
								grid: {
									drawTicks: false,
									display: false,
								},
								beginAtZero: true,
								display: true,
								title: {
									display: true,
									text: 'Pool Factor',
								},
							},
							leftAxis: {
								max: maxBarVal,
								position: 'left',
								grid: {
									drawTicks: false,
									display: true,
								},
								display: true,
								ticks: {
									color: '#e1e1e1',
									font: {
										weight: 'bold',
									},
									display: true,
								},
								title: {
									display: true,
									text: 'Current Balance',
								},
								beginAtZero: true,
								padding: 10,
							},
						},
					},
				}),
			);
		}
	}

	useEffect(() => {
		if (data) {
			if (xAxisValue === 'MDY') {
				setLabels(
					data
						.filter((row) => row.status === 'Predicted')
						.map((row) => row.date),
				);
			} else if (xAxisValue === 'month-index') {
				setLabels(
					data
						.filter((row) => row.status === 'Predicted')
						.map((row) => row.monthNumber),
				);
			}
			setDatasets([
				{
					data: selectedLegend.monthlyHistory
						? data.map(getFilteredData('History', 'status', 'currentBalance'))
						: null,
					backgroundColor: chartColors.blue,
					label: 'Current Balance, History',
					yAxisID: 'leftAxis',
					order: 1,
				},
				{
					data: selectedLegend.monthlyPredicted
						? data.map(getFilteredData('Predicted', 'status', 'currentBalance'))
						: null,
					backgroundColor: chartColors.yellow,
					label: 'Current Balance, Predicted',
					yAxisID: 'leftAxis',
					order: 1,
				},
				{
					type: 'line',
					data: selectedLegend.poolFactor
						? data.map((row) => row.poolFactor)
						: null,
					label: 'Pool Factor',
					yAxisID: 'rightAxis',
					order: 0,
					fill: false,
					borderColor: chartColors.green,
				},
			]);
		}
	}, [data, xAxisValue, selectedLegend]);

	useEffect(() => {
		// Create chart
		if (chart) {
			chart.destroy();
		}
		createChart();
	}, [dataSets, xAxisValue]);

	function handleXAxisValueChange(event) {
		setXAxisValue(event.target.value);
	}

	return status === 'loading' ? (
		<LoadingSpinner />
	) : !data ? (
		<NoData
			chartTitle="Pool Factor Over Time"
			chartTitleTooltip="Pool factor by month or month index."
		/>
	) : (
		<Grid container>
			<Grid item xs={12}>
				<Typography
					style={{
						fontSize: '1.7em',
						fontWeight: 'bold',
						marginBottom: 12,
						position: 'relative',
					}}
				>
					Pool Factor Over Time
					<Tooltip
						placement="right"
						title={
							<Typography style={{ fontSize: '1.4em', paddingTop: -40 }}>
								Pool factor by month or month index.
							</Typography>
						}
					>
						<IconButton>
							<HelpIcon fontSize="small" />
						</IconButton>
					</Tooltip>
				</Typography>
				<div className="legend-container">
					<div className="legend-bar-container">
						{legendInfo.map((legend) => {
							return (
								<li
									key={legend.label}
									onClick={() => {
										legend.onClick();
									}}
									className="legend-item"
								>
									<span
										style={{ backgroundColor: legend.backgroundColor }}
										className="legend-color-bar"
									/>
									<span
										className={
											legend.status
												? 'legend-title'
												: 'legend-title strikethrough'
										}
									>
										{legend.label}
									</span>
								</li>
							);
						})}
					</div>
				</div>
				<canvas ref={chartRef} />
			</Grid>
			<Grid item xs={12}>
				<Paper elevation={4}>
					<FormControl component="fieldset">
						<FormLabel
							component="legend"
							focused={false}
							classes={{ root: labelStyle.label }}
							style={{
								color: '#fff',
								fontSize: '1rem',
							}}
						>
							X-Axis:{' '}
						</FormLabel>
						<RadioGroup
							aria-label="x-axis"
							name="x-axis"
							value={xAxisValue}
							onChange={handleXAxisValueChange}
						>
							<FormControlLabel
								value="MDY"
								control={
									<Radio
										classes={{
											root: radioStyle.radio,
											checked: radioStyle.checked,
										}}
									/>
								}
								label={
									<Typography style={{ fontSize: '1rem', color: colors[4] }}>
										Date
									</Typography>
								}
							/>
							<FormControlLabel
								value="month-index"
								control={
									<Radio
										classes={{
											root: radioStyle.radio,
											checked: radioStyle.checked,
										}}
									/>
								}
								label={
									<Typography style={{ fontSize: '1rem', color: colors[4] }}>
										Month Index
									</Typography>
								}
							/>
						</RadioGroup>
					</FormControl>
				</Paper>
			</Grid>
		</Grid>
	);
}
