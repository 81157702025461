import { configureStore } from '@reduxjs/toolkit';
import { dataReducer } from './reducers/dataReducer';

// add all reducers to 'reducers' object to supply to store
const reducers = {
	data: dataReducer,
};

const store = configureStore({
	reducer: reducers,
});

export default store;
