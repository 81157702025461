import React from 'react';

import { ThemeProvider } from '@material-ui/core';
import { createMuiTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import HelpIcon from '@material-ui/icons/Help';
import { makeStyles } from '@material-ui/core/styles';

import LegendTooltip from './LegendTooltip';

import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles((theme) => ({
	layout: {
		width: '50px',
		height: '50px',
		display: 'flex',
		'& > * + *': {
			marginLeft: theme.spacing(2),
		},
	},
}));

const theme = createMuiTheme({
	palette: {
		type: 'dark',
		primary: { main: '#32DB60' },
	},
});

export default function NoData(props) {
	const classes = useStyles;

	return (
		<ThemeProvider theme={theme}>
			<Typography
				style={{
					fontSize: '1.7em',
					fontWeight: 'bold',
					marginBottom: 12,
					position: 'relative',
				}}
			>
				{props.chartTitle}
				<Tooltip
					placement="right"
					title={
						<Typography style={{ fontSize: '1.4em' }}>
							{props.chartTitleTooltip}
						</Typography>
					}
				>
					<IconButton>
						<HelpIcon fontSize="small" />
					</IconButton>
				</Tooltip>
			</Typography>
			<Grid container className={classes.layout} justify="center">
				<Grid
					item
					style={{
						paddingBottom: '168px',
						paddingTop: '168px',
					}}
				>
					<Typography variant="h5">No Data Available.</Typography>
				</Grid>
			</Grid>
		</ThemeProvider>
	);
}
