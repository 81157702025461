// React
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

// Queries
import { useSummaryScenario } from '../../../api/query-hooks';

// Common
import { colorPalette as colors } from '../../../theme';
import formatters from '../../../helpers/formatters';
import LoadingSpinner from '../../common/LoadingSpinner';
import NoData from '../../common/NoData';
import '../../styles/legend.scss';

// Material UI
import Paper from '@material-ui/core/Paper';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import HelpIcon from '@material-ui/icons/Help';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/core';
import MUIDataTable from 'mui-datatables';

export default function SummaryTable() {
	const { status, data, error, refetch, remove } = useSummaryScenario();

	useEffect(() => {
		remove();
		refetch();
	}, [
		useSelector((state) => state.data.deal.id),
		useSelector((state) => state.data.requestID),
	]);

	const theme = createMuiTheme({
		palette: {
			type: 'dark',
		},
		overrides: {
			MUIDataTableHeaderCell: {
				fontSize: '1.7em',
			},
			MUIDataTableBodyRow: {
				root: {
					'&:nth-child(odd)': {
						backgroundColor: '#666666',
					},
				},
			},
			MuiTableCell: {
				root: {
					padding: '5px',
					fontSize: '1.2em',
				},
			},
			MUIDataTableToolbar: {
				icon: {
					'&:hover': {
						color: '#32DB60',
					},
				},
			},
		},
	});

	const columns = [
		{
			name: 'field',
			label: 'Field',
		},
		{
			name: 'active',
			label: 'Active',
		},
	];

	const options = {
		elevation: 0,
		filter: false,
		selectableRows: 'none',
		search: false,
		download: false,
		print: false,
		viewColumns: false,
		pagination: false,
		rowsPerPage: 15,
	};

	function isValid(value) {
		if (
			typeof value === 'undefined' ||
			value === null ||
			value === 'No active accounts'
		) {
			return false;
		} else {
			return true;
		}
	}

	if (data && data.length) {
		data.forEach((e) => {
			switch (e.field) {
				case 'Principal collected':
					e.active = isValid(e.active)
						? formatters.toCurrency(e.active)
						: 'Values not available';
					break;
				case 'Interest collected':
					e.active = isValid(e.active)
						? formatters.toCurrency(e.active)
						: 'Values not available';
					break;
				case 'Charge-off amount':
					e.active = isValid(e.active)
						? formatters.toCurrency(e.active)
						: 'Values not available';
					break;
				case 'Recovered amount':
					e.active = isValid(e.active)
						? formatters.toCurrency(e.active)
						: 'Values not available';
					break;
				case 'Total collected':
					e.active = isValid(e.active)
						? formatters.toCurrency(e.active)
						: 'Values not available';
					break;
				case 'Charge-off rate':
					e.active = isValid(e.active)
						? formatters.toPercentage(e.active)
						: 'Values not available';
					break;
				case 'Recovery rate':
					e.active = isValid(e.active)
						? formatters.toPercentage(e.active)
						: 'Values not available';
					break;
				case 'Discount rate':
					e.active = isValid(e.active)
						? formatters.toPercentage(e.active)
						: 'Values not available';
					break;
				case 'Fair value':
					e.active = isValid(e.active)
						? formatters.toCurrency(e.active)
						: 'Values not available';
					break;
				case 'Fair value price':
					e.active = isValid(e.active)
						? formatters.toPercentage(e.active)
						: 'Values not available';
					break;
				default:
					break;
			}
		});
	}

	if (status === 'loading') return <LoadingSpinner />;

	// Display NoData component instead.
	// if (status === 'error') return `Error: ${error.message}`;

	return !data ? (
		<Paper>
			<NoData
				chartTitle="Summary"
				chartTitleTooltip="Analysis of all accounts within a deal, combining closed account performance and active account predicted performance through final maturity date."
			/>
		</Paper>
	) : (
		<ThemeProvider theme={theme}>
			<MUIDataTable
				title={
					<Typography
						style={{
							fontSize: '1.7em',
							fontWeight: 'bold',
							marginBottom: 12,
						}}
					>
						Summary{' '}
						<Tooltip
							placement="right"
							title={
								<Typography style={{ fontSize: '1.4em' }}>
									Analysis of all accounts within a deal, combining closed
									account performance and active account predicted performance
									through final maturity date.
								</Typography>
							}
						>
							<IconButton>
								<HelpIcon fontSize="small" />
							</IconButton>
						</Tooltip>
					</Typography>
				}
				data={data}
				columns={columns}
				options={options}
			/>
		</ThemeProvider>
	);
}
