import React, { useState, useEffect } from 'react';

import BarChart from './BarChart';
import { chartColors as colors } from '../../theme';
import { getFilteredData } from '../../helpers/helpers';
import formatters from '../../helpers/formatters';

import LoadingSpinner from '../common/LoadingSpinner';

import { std, min, max } from 'mathjs';

import { green } from '@material-ui/core/colors';
import NoData from '../common/NoData';
export default function PrincipalAndInterest(props) {
	const [axisLabels, setAxisLabels] = useState([]);
	const [dataValues, setDataValues] = useState([]);
	const { status, data, error, xAxisValue, yAxisValue, isFetching } = props;
	const [filteredData, setFilteredData] = useState([]);
	const [selectedLegend, setSelectedLegend] = useState({
		principalHistory: true,
		principalPredicted: true,
		interestHistory: true,
		interestPredicted: true,
	});
	const [yaxisInfo, setYaxisInfo] = useState({
		minValue: 0,
	});
	const [loading, setLoading] = useState(true);

	const setAxis = () => {
		// maxValToCut = (filteredData.max() - filteredData.min())*0.16
		// to_cut = 0.7*filteredData.std()
		// to_cut = min(to_cut, maxValToCut)  # If it's greater than maxValToCut, choose maxValToCut
		// to_cut = max(to_cut, 100)  # The min is 100
		// minVal = filteredData.min() - to_cut
		// minVal = max(minVal, 0)  # At least 0 (can't be negative)
		if (filteredData?.map && data?.map && filteredData.length && data.length) {
			// Only if they exist and have some data in it (otherwise mathjs throws error)
			let chartData = [];
			filteredData.forEach(
				// data for filtered columns
				(d) => {
					chartData.push(+d.actualPrincipalCollected);
					chartData.push(+d.actualInterestCollected);
				},
			);
			let globalchartData = [];
			data.forEach(
				// All data
				(d) => {
					globalchartData.push(+d.actualPrincipalCollected);
					globalchartData.push(+d.actualInterestCollected);
				},
			);
			let maxValToCut = (max(chartData) - min(chartData)) * 0.14; // The Maximum value to cut based on the range
			let toCut = std(chartData) * 0.8; // The preliminary value to cut from min value in chartData
			toCut = min(toCut, maxValToCut); // It can't be greater than the max value to cut we found
			toCut = max(toCut, 10); // It can't be less than 10 either
			let minBalanceVal = min(chartData) - toCut; // Get the min value for the chart
			minBalanceVal = max(minBalanceVal, 0); // It can't be less than 0 either
			setYaxisInfo({
				minValue: minBalanceVal,
			});
		}
	};
	useEffect(() => {
		if (data?.map !== undefined)
			setFilteredData(
				data
					.map(getFilteredData(['Predicted', 'History'], 'status', null))
					.filter((datum) => datum !== null),
			);
	}, [data]);

	useEffect(setAxis, [filteredData]);

	const resetXAxis = () => {
		setFilteredData(data);
	};

	const removeXAxis = (sectionName) => {
		if (sectionName === 'History') {
			setFilteredData(
				data
					.map(getFilteredData(['Predicted', ''], 'status', null))
					.filter((datum) => datum !== null),
			);
		}
		if (sectionName === 'Predicted') {
			setFilteredData(
				data
					.map(getFilteredData(['History', ''], 'status', null))
					.filter((datum) => datum !== null),
			);
		}
	};

	const seriesLabels = ['Principal', 'Interest'];

	useEffect(() => {
		if (filteredData?.map !== undefined) {
			const labels = formatters.dataToDateString(filteredData);
			const datasets = [];
			datasets.push({
				label: seriesLabels[0] + ', History',
				backgroundColor: colors.blue,
				fill: false,
				data: selectedLegend.principalHistory
					? filteredData.map(
							getFilteredData('History', 'status', 'actualPrincipalCollected'),
					  )
					: null,
			});
			datasets.push({
				label: seriesLabels[0] + ', Predicted',
				backgroundColor: colors.yellow,
				fill: false,
				data: selectedLegend.principalPredicted
					? filteredData.map(
							getFilteredData(
								'Predicted',
								'status',
								'actualPrincipalCollected',
							),
					  )
					: null,
			});
			datasets.push({
				label: seriesLabels[1] + ', History',
				backgroundColor: colors.white,
				fill: false,
				data: selectedLegend.interestHistory
					? filteredData.map(
							getFilteredData('History', 'status', 'actualInterestCollected'),
					  )
					: null,
			});
			datasets.push({
				label: seriesLabels[1] + ', Predicted',
				backgroundColor: colors.green,
				fill: false,
				data: selectedLegend.interestPredicted
					? filteredData.map(
							getFilteredData('Predicted', 'status', 'actualInterestCollected'),
					  )
					: null,
			});
			setAxisLabels(labels);
			setDataValues(datasets);
			setLoading(false);
		}
	}, [filteredData, selectedLegend]);

	// Props updated to re-render axis labels when parent filter wrapper changes state
	useEffect(() => {
		if (!filteredData?.map) return;
		if (props.xAxisValue === 'MDY') {
			const labels = formatters.dataToDateString(filteredData);
			setAxisLabels(labels);
		} else if (props.xAxisValue === 'month-index') {
			const labels = formatters.dataToMonthSequence(filteredData);
			setAxisLabels(labels);
		}
	}, [props.yAxisValue, props.xAxisValue, filteredData]);

	useEffect(() => {
		if (status === 'loading' || !data || isFetching) setLoading(true);
	}, [isFetching, status, data]);

	if (status === 'error') return `Error: ${error.message}`;

	const legendInfo = [
		{
			label: seriesLabels[0] + ', History',
			backgroundColor: colors.blue,
			status: selectedLegend.principalHistory,
			onClick: () => {
				if (
					selectedLegend.principalHistory &&
					!selectedLegend.interestHistory
				) {
					// If this is the last history currently and it was clicked
					removeXAxis('History'); // Remove that section from X axis
				} else {
					resetXAxis();
				}
				setSelectedLegend({
					...selectedLegend,
					principalHistory: !selectedLegend.principalHistory,
				});
				setAxis();
			},
		},
		{
			label: seriesLabels[0] + ', Predicted',
			backgroundColor: colors.yellow,
			status: selectedLegend.principalPredicted,
			onClick: () => {
				if (
					selectedLegend.principalPredicted &&
					!selectedLegend.interestPredicted
				) {
					// If we are showing only this as predicted currently and it was clicked
					removeXAxis('Predicted'); // Remove that section from X axis
				} else {
					resetXAxis(); // Reset the X axis
				}
				setSelectedLegend({
					...selectedLegend,
					principalPredicted: !selectedLegend.principalPredicted,
				});
				setAxis();
			},
		},
		{
			label: seriesLabels[1] + ', History',
			backgroundColor: colors.white,
			status: selectedLegend.interestHistory,
			onClick: () => {
				if (
					selectedLegend.interestHistory &&
					!selectedLegend.principalHistory
				) {
					// If this is the last history currently and it was clicked
					removeXAxis('History'); // Remove that section from X axis
				} else {
					resetXAxis();
				}
				setSelectedLegend({
					...selectedLegend,
					interestHistory: !selectedLegend.interestHistory,
				});
				setAxis();
			},
		},
		{
			label: seriesLabels[1] + ', Predicted',
			backgroundColor: colors.green,
			status: selectedLegend.interestPredicted,
			onClick: () => {
				if (
					selectedLegend.interestPredicted &&
					!selectedLegend.principalPredicted
				) {
					// If we are showing only this as predicted currently and it was clicked
					removeXAxis('Predicted'); // Remove that section from X axis
				} else {
					resetXAxis(); // Reset the X axis
				}
				setSelectedLegend({
					...selectedLegend,
					interestPredicted: !selectedLegend.interestPredicted,
				});
				setAxis();
			},
		},
	];

	return loading ? (
		<LoadingSpinner />
	) : !data?.length ? (
		<NoData
			chartTitle="Principal and Interest"
			chartTitleTooltip="Principal and interest payments aggregated for all accounts on a monthly basis. Review both historical and predicted cashflows."
		/>
	) : (
		<BarChart
			chartTitle="Principal and Interest"
			chartTitleTooltip="Principal and interest payments aggregated for all accounts on a monthly basis. Review both historical and predicted cashflows."
			data={dataValues}
			axisLabels={axisLabels}
			xAxisValue={xAxisValue}
			vertical={true}
			tickLabelFormat="M"
			legendInfo={legendInfo}
			barMin={yaxisInfo.minValue}
			paiBig={props.paiBig}
		/>
	);
}
