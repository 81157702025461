// React
import React, { useEffect } from 'react';

// Redux, Query
import { useSelector } from 'react-redux';
import {
	useTranchePayments,
	useTrancheSummaryClass,
	useTrancheSummaryMonth,
} from '../../api/query-hooks';

// Common
import formatters from '../../helpers/formatters';
import LoadingSpinner from '../common/LoadingSpinner';
import NoData from '../common/NoData';

// Material-UI
import { ThemeProvider } from '@material-ui/core';
import { createMuiTheme } from '@material-ui/core/styles';
import MUIDataTable from 'mui-datatables';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import HelpIcon from '@material-ui/icons/Help';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

const theme = createMuiTheme({
	palette: {
		type: 'dark',
	},
	overrides: {
		MUIDataTableHeadCell: {
			data: {
				fontSize: '0.7em',
				textAlign: 'center',
			},
		},
		MUIDataTableBodyRow: {
			root: {
				'&:nth-child(odd)': {
					backgroundColor: '#666666',
				},
			},
		},
		MuiTableCell: {
			root: {
				padding: '2px',
				fontSize: '0.7em',
				textAlign: 'left',
			},
		},
		MUIDataTableToolbar: {
			icon: {
				'&:hover': {
					color: '#32DB60',
				},
			},
		},
	},
});

const tranchePaymentsColumns = [
	{
		name: 'reporting_end_date_r',
		label: 'Reporting End Date',
		options: {
			filter: true,
			customBodyRender: (value) => (value ? value : `Values not available`),
		},
	},
	{
		name: 'data_status',
		label: 'Data Status',
		options: {
			filter: true,
			customBodyRender: (value) => (value ? value : `Values not available`),
		},
	},
	{
		name: 'class',
		label: 'Class',
		options: {
			filter: true,
			customBodyRender: (value) => (value ? value : `Values not available`),
		},
	},
	{
		name: 'cuisp',
		label: 'CUISP',
		options: {
			filter: true,
			customBodyRender: (value) => (value ? value : `Values not available`),
		},
	},
	{
		name: 'actual_principal_collected_amount',
		label: 'Actual Principal Collected Amount',
		options: {
			filter: false,
			customBodyRender: (value) =>
				value ? formatters.toCurrency(value) : `Values not available`,
		},
	},
	{
		name: 'actual_interest_collected_amount',
		label: 'Actual Interest Collected Amount',
		options: {
			filter: false,
			customBodyRender: (value) =>
				value ? formatters.toCurrency(value) : `Values not available`,
		},
	},
	{
		name: 'total_actual_amount_paid',
		label: 'Total Actual Amount Paid',
		options: {
			filter: false,
			customBodyRender: (value) =>
				value ? formatters.toCurrency(value) : `Values not available`,
		},
	},
	{
		name: 'sum_principal_interest',
		label: 'Sum Principal Interest',
		options: {
			filter: false,
			customBodyRender: (value) =>
				value ? formatters.toCurrency(value) : `Values not available`,
		},
	},
	{
		name: 'initial_note_principal_balance',
		label: 'Initial Note Principal Balance',
		options: {
			filter: false,
			customBodyRender: (value) =>
				value ? formatters.toCurrency(value) : `Values not available`,
		},
	},
	{
		name: 'note_rate',
		label: 'Note Rate',
		options: {
			filter: false,
			customBodyRender: (value) =>
				value ? `${value}%` : `Values not available`,
		},
	},
	{
		name: 'principal_distribution',
		label: 'Principal Distribution',
		options: {
			filter: false,
			customBodyRender: (value) =>
				value ? formatters.toCurrency(value) : `Values not available`,
		},
	},
	{
		name: 'beginning_note_principal_balance',
		label: 'Beginning Note Principal Balance',
		options: {
			filter: false,
			customBodyRender: (value) =>
				value ? formatters.toCurrency(value) : `Values not available`,
		},
	},
	{
		name: 'interest_distribution',
		label: 'Interest Distribution',
		options: {
			filter: false,
			customBodyRender: (value) =>
				value ? formatters.toCurrency(value) : `Values not available`,
		},
	},
	{
		name: 'pass_through_distribution',
		label: 'Pass Through Distribution',
		options: {
			filter: false,
			customBodyRender: (value) =>
				value ? formatters.toCurrency(value) : `Values not available`,
		},
	},
	{
		name: 'total_distribution',
		label: 'Total Distribution',
		options: {
			filter: false,
			customBodyRender: (value) =>
				value ? formatters.toCurrency(value) : `Values not available`,
		},
	},
	{
		name: 'ending_note_principal_balance',
		label: 'Ending Note Principal Balance',
		options: {
			filter: false,
			customBodyRender: (value) =>
				value ? formatters.toCurrency(value) : `Values not available`,
		},
	},
	{
		name: 'interest_period',
		label: 'Interest Period',
		options: {
			filter: false,
			customBodyRender: (value) =>
				value ? formatters.toCurrency(value) : `Values not available`,
		},
	},
];

function TranchePaymentsTable() {
	const { status, data, error, refetch } = useTranchePayments();

	useEffect(() => {
		refetch();
	}, [useSelector((state) => state.data.deal.id)]);

	if (status === 'loading') return <LoadingSpinner />;
	if (status === 'error') return `Error: ${error.message}`;

	const options = {
		elevation: 0,
		filterType: 'checkbox',
		sortFilterList: true,
		selectableRows: 'none',
		rowsPerPage: 25,
		rowsPerPageOptions: [25, 50, 100],
	};

	return data.length === 0 ? (
		<NoData
			chartTitle="Tranche Payments"
			chartTitleTooltip="Month by month historical and predicted tranche payments. Historical payments are taken from securitization release documents and predicted payments are derived from Karus ML cashflow models."
		/>
	) : (
		<MUIDataTable
			title={
				<Typography
					style={{
						fontSize: '1.7em',
						fontWeight: 'bold',
						marginBottom: 12,
					}}
				>
					Tranche Payments{' '}
					<Tooltip
						placement="right"
						title={
							<Typography style={{ fontSize: '1.4em' }}>
								Month by month historical and predicted tranche payments.
								Historical payments are taken from securitization release
								documents and predicted payments are derived from Karus ML
								cashflow models.
							</Typography>
						}
					>
						<IconButton>
							<HelpIcon fontSize="small" />
						</IconButton>
					</Tooltip>
				</Typography>
			}
			columns={tranchePaymentsColumns}
			data={data}
			options={options}
		/>
	);
}

const trancheSummaryClassColumns = [
	{
		name: 'class',
		label: 'Class',
		options: {
			filter: false,
			customBodyRender: (value) => (value ? value : `Values not available`),
		},
	},
	{
		name: 'original_balance',
		label: 'Original Balance',
		options: {
			filter: false,
			customBodyRender: (value) =>
				value ? formatters.toCurrency(value) : `Values not available`,
		},
	},
	{
		name: 'interest_rate',
		label: 'Interest Rate',
		options: {
			filter: false,
			customBodyRender: (value) =>
				value ? `${value}%` : `Values not available`,
		},
	},
	{
		name: 'principal_paid',
		label: 'Principal Paid',
		options: {
			filter: false,
			customBodyRender: (value) =>
				value ? formatters.toCurrency(value) : `Values not available`,
		},
	},
	{
		name: 'interest_paid',
		label: 'Interest Paid',
		options: {
			filter: false,
			customBodyRender: (value) =>
				value ? formatters.toCurrency(value) : `Values not available`,
		},
	},
	{
		name: 'paid',
		label: 'Paid',
		options: {
			filter: false,
			customBodyRender: (value) => (value ? 'True' : 'False'), // TODO: This is a boolean, we will have to add a null check later
		},
	},
	{
		name: 'payoff_date',
		label: 'Payoff Date',
		options: {
			filter: false,
			customBodyRender: (value) => (value ? value : `Values not available`),
		},
	},
];

function TrancheSummaryClassTable() {
	const { status, data, error, refetch } = useTrancheSummaryClass();

	useEffect(() => {
		refetch();
	}, [useSelector((state) => state.data.deal.id)]);

	if (status === 'loading') return <LoadingSpinner />;
	if (status === 'error') return `Error: ${error.message}`;

	const options = {
		elevation: 0,
		filter: false,
		selectableRows: 'none',
		search: false,
		download: false,
		print: false,
		viewColumns: false,
		pagination: false,
		rowsPerPage: 15,
	};

	return data.length === 0 ? (
		<NoData
			chartTitle="Tranche Summary Class"
			chartTitleTooltip="Summary of tranche principal and coupon payments. This table combines current tranche payments from securitization release documents and Karus predicted payment schedules past the settle date."
		/>
	) : (
		<MUIDataTable
			title={
				<Typography
					style={{
						fontSize: '1.7em',
						fontWeight: 'bold',
						marginBottom: 12,
					}}
				>
					Tranche Summary Class{' '}
					<Tooltip
						placement="right"
						title={
							<Typography style={{ fontSize: '1.4em' }}>
								Summary of tranche principal and coupon payments. This table
								combines current tranche payments from securitization release
								documents and Karus predicted payment schedules past the settle
								date.
							</Typography>
						}
					>
						<IconButton>
							<HelpIcon fontSize="small" />
						</IconButton>
					</Tooltip>
				</Typography>
			}
			columns={trancheSummaryClassColumns}
			data={data}
			options={options}
		/>
	);
}

const trancheSummaryMonthColumns = [
	{
		name: 'month',
		label: 'Month',
	},
	{
		name: 'exhibit_99',
		label: 'Exhibit 99',
		options: {
			filter: false,
			customBodyRender: (value) =>
				value ? formatters.toCurrency(value) : `Values not available`,
		},
	},
	{
		name: 'karus',
		label: 'Karus',
		options: {
			filter: false,
			customBodyRender: (value) =>
				value ? formatters.toCurrency(value) : `Values not available`,
		},
	},
	{
		name: 'difference',
		label: 'Difference',
		options: {
			filter: false,
			customBodyRender: (value) =>
				value ? formatters.toCurrency(value) : `Values not available`,
		},
	},
];

function TrancheSummaryMonthTable() {
	const { status, data, error, refetch } = useTrancheSummaryMonth();

	useEffect(() => {
		refetch();
	}, [useSelector((state) => state.data.deal.id)]);

	if (status === 'loading') return <LoadingSpinner />;
	if (status === 'error') return `Error: ${error.message}`;

	const options = {
		elevation: 0,
		filter: false,
		selectableRows: 'none',
		search: false,
		download: false,
		print: false,
		viewColumns: false,
		pagination: true,
		rowsPerPage: 15,
	};

	return data.length === 0 ? (
		<NoData
			chartTitle="Tranche Summary Month"
			chartTitleTooltip="Reported tranche distributions from securitization release documents and total inflows on a monthly basis. The inflows are derived from monthly data releases and predicted inflows for all active accounts."
		/>
	) : (
		<MUIDataTable
			title={
				<Typography
					style={{
						fontSize: '1.7em',
						fontWeight: 'bold',
						marginBottom: 12,
					}}
				>
					Tranche Summary Month{' '}
					<Tooltip
						placement="right"
						title={
							<Typography style={{ fontSize: '1.4em' }}>
								Reported tranche distributions from securitization release
								documents and total inflows on a monthly basis. The inflows are
								derived from monthly data releases and predicted inflows for all
								active accounts.
							</Typography>
						}
					>
						<IconButton>
							<HelpIcon fontSize="small" />
						</IconButton>
					</Tooltip>
				</Typography>
			}
			columns={trancheSummaryMonthColumns}
			data={data}
			options={options}
		/>
	);
}

export default function Tranche() {
	return (
		<ThemeProvider theme={theme}>
			<Grid container spacing={4}>
				<Grid item xs={12} style={{ width: '80vw', overflow: 'none' }}>
					<Paper
						elevation={24}
						style={{
							padding: 10,
							width: '100%',
							maxWidth: '100%',
							overflowX: 'auto',
						}}
					>
						<TrancheSummaryClassTable />
					</Paper>
				</Grid>
				<Grid item xs={12} style={{ width: '80vw', overflow: 'none' }}>
					<Paper
						elevation={24}
						style={{
							padding: 10,
							width: '100%',
							maxWidth: '100%',
							overflowX: 'auto',
						}}
					>
						<TranchePaymentsTable />
					</Paper>
				</Grid>
				<Grid item xs={12} style={{ width: '80vw', overflow: 'none' }}>
					<Paper
						elevation={24}
						style={{
							padding: 10,
							width: '100%',
							maxWidth: '100%',
							overflowX: 'auto',
						}}
					>
						<TrancheSummaryMonthTable />
					</Paper>
				</Grid>
			</Grid>
		</ThemeProvider>
	);
}
