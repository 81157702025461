import React from 'react';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

import Filter from './filter';
import PredictedOutcomes from '../graphs/PredictedOutcomes';
import PredictedPayments from '../graphs/AmountPaid';
import PredictedChargeoffs from '../graphs/AmountLost';

export default function Predictions() {
	return (
		<>
			{/* <Filter /> */}
			<Grid container spacing={2}>
				<Grid item xs={12} lg={6}>
					<Paper elevation={24}>
						<PredictedOutcomes />
					</Paper>
				</Grid>
				<Grid item xs={12} lg={6}>
					<Paper elevation={24}>
						<PredictedPayments />
					</Paper>
				</Grid>
				<Grid item xs={12} lg={6}>
					<Paper elevation={24}>
						<PredictedChargeoffs />
					</Paper>
				</Grid>
			</Grid>
		</>
	);
}
