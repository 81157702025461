import React, { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import './header.css';
import karusLogo from '../../assets/karus-white.svg';
import { NavLink, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchFilterOptions, setDeal } from '../../redux/actions/dataActions';
import { useDeals, useLendingDeals } from '../../api/query-hooks';
import LoadingSpinner from '../common/LoadingSpinner';

// Material UI
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Menu from '@material-ui/core/Menu';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import CircularProgress from '@material-ui/core/CircularProgress';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';

// Material UI Icons
import SettingsIcon from '@material-ui/icons/Settings';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import GroupIcon from '@material-ui/icons/Group';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

const Profile = () => {
	const { user, isAuthenticated, isLoading } = useAuth0();
	if (isLoading) {
		return <LoadingSpinner />;
	}
	return (
		isAuthenticated && (
			<div style={{ height: '30px', width: '30px' }}>
				{/* {user.email} */}
				<img
					src={user.picture}
					style={{ borderRadius: '50%', height: '30px', width: '30px' }}
				/>
			</div>
		)
	);
};

const LogoutButton = () => {
	const { logout } = useAuth0();
	return (
		// Redirects the user to the login page upon logout
		<a onClick={() => logout({ returnTo: window.location.origin })}>
			&ensp;Log Out
		</a>
	);
};

const DealSelect = ({ deal, name, getDealType }) => {
	// Structured and unstructured deals
	const dealsResponse = useDeals();
	const [deals, setDeals] = useState([]);
	const allowedDeals = useSelector((state) => state.data.allowedDeals);

	// Lending deals
	const lendingDealsResponse = useLendingDeals();
	const [lendingDeals, setLendingDeals] = useState([]);
	const allowedLendingDeals = useSelector(
		(state) => state.data.allowedLendingDeals,
	);

	// Originators
	const [unstructuredOriginators, setUnstructuredOriginators] = useState([]);
	const [structuredOriginators, setStructuredOriginators] = useState([]);
	const [lendingOriginators, setLendingOriginators] = useState([]);

	// Selected originators and deals
	const [selectedOriginator, setSelectedOriginator] = useState();
	const [selectedDeal, setSelectedDeal] = useState();
	const [dealType, setDealType] = useState();

	const [visible, setVisible] = useState(false);
	const dispatch = useDispatch();

	useEffect(() => {
		if (dealsResponse.data && lendingDealsResponse.data) {
			setUnstructuredOriginators(
				dealsResponse.data
					.filter((deal) => deal.type === 'UNSTRUCTURED')
					.map((deal) => deal.originator_name)
					.filter((item, index, self) => self.indexOf(item) == index),
			);
			setStructuredOriginators(
				dealsResponse.data
					.filter((deal) => deal.type === 'STRUCTURED')
					.map((deal) => deal.originator_name)
					.filter((item, index, self) => self.indexOf(item) == index),
			);
			setLendingOriginators(
				lendingDealsResponse.data
					.filter((deal) => allowedLendingDeals.includes(deal.id))
					.map((deal) => deal.originator_name)
					.filter((item, index, self) => self.indexOf(item) == index),
			);
			setDeals(dealsResponse.data);
			setLendingDeals(lendingDealsResponse.data);
		}
	}, [dealsResponse.data, lendingDealsResponse.data]);

	function handleChangeDealType(event) {
		setDealType(event.target.value);
	}

	function handleChangeOriginator(event, value) {
		if (value) {
			setSelectedOriginator(value);
		} else {
			setSelectedOriginator();
		}
	}

	function handleChangeDeal(event, value) {
		if (value) {
			value.type === 'LENDING'
				? setSelectedDeal(lendingDeals.find((deal) => deal.name === value.name))
				: setSelectedDeal(deals.find((deal) => deal.name === value.name));
		} else {
			setSelectedDeal(null);
		}
	}

	function handleSubmit() {
		getDealType(dealType);
		dispatch(fetchFilterOptions(selectedDeal.id)).then(() => {
			dispatch(setDeal(selectedDeal));
		});
	}

	function toggleVisibility() {
		visible ? setVisible(false) : setVisible(true);
	}

	return (
		<>
			{visible && deals && deals.length ? (
				<Grid container alignItems="flex-end">
					<Grid item>
						<Button
							onClick={toggleVisibility}
							style={{
								borderColor: '#32DB60',
								marginRight: 16,
							}}
						>
							<ChevronLeftIcon />
						</Button>
					</Grid>
					<Grid item>
						<FormControl style={{ paddingRight: '16px', width: 200 }}>
							<InputLabel>Deal Type</InputLabel>
							<Select value={dealType ?? ''} onChange={handleChangeDealType}>
								<MenuItem value={'UNSTRUCTURED'}>Auto Receivables</MenuItem>
								<MenuItem value={'STRUCTURED'}>Auto ABS</MenuItem>
								<MenuItem
									disabled={allowedLendingDeals.length === 0}
									value={'LENDING'}
								>
									Karus Lending
								</MenuItem>
							</Select>
						</FormControl>
					</Grid>
					<Grid item>
						<Autocomplete
							style={{ paddingRight: '16px', width: 250 }}
							disabled={!dealType}
							options={(() => {
								switch (dealType) {
									case 'LENDING':
										return lendingOriginators;
									case 'STRUCTURED':
										return structuredOriginators;
									case 'UNSTRUCTURED':
										return unstructuredOriginators;
									default:
										return [];
								}
							})()}
							onChange={handleChangeOriginator}
							renderInput={(originator) => (
								<TextField {...originator} label={'Originator'} />
							)}
						/>
					</Grid>
					<Grid item>
						<Autocomplete
							style={{ paddingRight: '16px', width: 275, paddingTop: 4 }}
							disabled={!selectedOriginator}
							options={
								dealType === 'LENDING'
									? lendingDeals.filter(
											(deal) => deal.originator_name === selectedOriginator,
									  )
									: deals.filter(
											(deal) => deal.originator_name === selectedOriginator,
									  )
							}
							onChange={handleChangeDeal}
							getOptionDisabled={
								dealType === 'LENDING'
									? (deal) => !allowedLendingDeals.includes(deal.id)
									: (deal) => !allowedDeals.includes(deal.id)
							}
							getOptionLabel={(deal) => deal.name}
							renderInput={(params) => <TextField {...params} label={'Deal'} />}
							renderOption={(deal) => {
								return (
									<li>
										{dealType === 'LENDING'
											? allowedLendingDeals.includes(deal.id)
												? `${deal.name}`
												: `${deal.name} - Contact Karus for access`
											: allowedDeals.includes(deal.id)
											? `${deal.name}`
											: `${deal.name} - Contact Karus for access`}
									</li>
								);
							}}
						/>
					</Grid>
					<Grid item>
						<Button
							style={{
								color: '#ffffff',
								backgroundColor: '#32DB60',
								marginTop: 8,
							}}
							onClick={handleSubmit}
						>
							Select Deal
						</Button>
					</Grid>
				</Grid>
			) : (
				<div style={{ display: 'flex', alignItems: 'flex-center' }}>
					<Button
						onClick={toggleVisibility}
						style={{
							borderColor: '#32DB60',
						}}
					>
						<Typography
							style={{ paddingTop: 5 }}
							variant="subtitle1"
							gutterBottom
						>
							{(() => {
								switch (deal) {
									case 'LENDING':
										return 'Karus Lending - ' + name;
									case 'STRUCTURED':
										return 'Auto ABS - ' + name;
									case 'UNSTRUCTURED':
										return 'Auto Receivables Opportunities - ' + name;
									default:
										return name;
								}
							})()}
						</Typography>
						<ChevronRightIcon />
					</Button>
				</div>
			)}
		</>
	);
};

export default function Header({ getDealType }) {
	const [anchor, setAnchor] = useState();
	const [selectedPage, setSelectedPage] = useState();

	const previousDeal = useSelector((state) => state.data.deal);
	const deal = useSelector((state) => state.data.deal.type);
	const name = useSelector((state) => state.data.deal.name);
	const loadingStatus = useSelector((state) => state.data.dealStatus);

	const history = useHistory();
	const open = Boolean(anchor);
	const { logout, user } = useAuth0();

	// User roles provided to access token through a rule declared in the Karus Auth0 dashboard.
	const isAdmin = user['http://qa-app.karus.ai/roles'].includes(
		'Super Administrator',
	);

	const isUser = user['http://qa-app.karus.ai/roles'].includes('Client User');

	useEffect(() => {
		const getUserMetadata = async () => {
			const USER_ID = user.sub;
			try {
				const userDetailsByIdUrl = `https://karus.us.auth0.com/api/v2/users/${USER_ID}`;
				const metadataResponse = await fetch(userDetailsByIdUrl);
				console.log(metadataResponse);
				console.log(user);
			} catch (err) {
				console.log(err.message);
			}
		};
		getUserMetadata();
	}, []);

	useEffect(() => {
		switch (window.location.pathname) {
			case '/':
				setSelectedPage('/');
				break;
			case '/underwriting-form':
				setSelectedPage('/underwriting-form');
				break;
			case '/inbox':
				setSelectedPage('/inbox');
				break;
			case '/secure-data-upload':
				setSelectedPage('/secure-data-upload');
				break;
			default:
				setSelectedPage('/overview');
				break;
		}
	}, [window.location.pathname]);

	const handleClick = (event) => {
		setAnchor(event.currentTarget);
	};

	const handleClose = () => {
		setAnchor(null);
	};

	const handleLogout = () => {
		setAnchor(null);
		logout({ returnTo: window.location.origin });
	};

	function handleChangeRoute(event) {
		history.push(event.target.value);
	}

	function handleAddOrganization() {
		history.push('/org/add-organization');
	}

	function handleAddUsers() {
		history.push('/org/add-users');
	}

	function handleEditOrganizations() {
		history.push('/org/edit-organizations');
	}

	return (
		<AppBar color="#424242">
			<Toolbar>
				<NavLink to="/">
					<img
						src={karusLogo}
						style={{
							height: '40px',
						}}
					/>
				</NavLink>
				{previousDeal && window.location.pathname !== '/' && (
					<>
						<Divider
							orientation="vertical"
							flexItem
							style={{ marginLeft: 20, marginVertical: 20 }}
						/>
						<div
							style={{
								display: 'inline-flex',
								alignItems: 'center',
								justifyContent: 'center',
							}}
						>
							<FormControl style={{ marginLeft: '20px' }}>
								<InputLabel>Feature</InputLabel>
								<Select
									value={selectedPage}
									style={{
										width: 160,
										marginVertical: 20,
									}}
									onChange={handleChangeRoute}
								>
									<MenuItem value={'/'}>Deal Select</MenuItem>
									<MenuItem value={'/overview'}>Credit Analytics</MenuItem>
									{isAdmin && (
										<MenuItem value={'/underwriting-form'}>
											Underwriting
										</MenuItem>
									)}
									<MenuItem value={'/secure-data-upload'}>
										Secure Data Upload
									</MenuItem>
								</Select>
							</FormControl>
						</div>
					</>
				)}
				{previousDeal && window.location.pathname !== '/' && (
					<>
						<Divider
							orientation="vertical"
							flexItem
							style={{ marginLeft: 20, marginRight: 20, marginVertical: 20 }}
						/>
						<div>
							<DealSelect deal={deal} name={name} getDealType={getDealType} />
						</div>
						<Divider
							orientation="vertical"
							flexItem
							style={{ marginLeft: 20 }}
						/>
					</>
				)}
				{loadingStatus === 'loading' && (
					<div
						style={{
							paddingLeft: 15,
							display: window.location.pathname !== '/' ? null : 'none',
						}}
					>
						<CircularProgress />
					</div>
				)}
				<div
					style={{
						marginLeft: 'auto',
						display: 'inline-flex',
						alignItems: 'center',
						justifyContent: 'center',
						marginVertical: 20,
					}}
				>
					<IconButton onClick={handleClick}>
						<SettingsIcon />
					</IconButton>
					<Menu anchorEl={anchor} open={open} onClose={handleClose}>
						{isAdmin && (
							<>
								<MenuItem onClick={handleAddOrganization}>
									<GroupAddIcon />
									&ensp;Create New Organization
								</MenuItem>
								<MenuItem onClick={handleAddUsers}>
									<PersonAddIcon />
									&ensp;Add Users
								</MenuItem>
								<MenuItem onClick={handleEditOrganizations}>
									<GroupIcon />
									&ensp;Edit Organizations
								</MenuItem>
							</>
						)}
						<MenuItem onClick={handleClose}>
							<AccountTreeIcon />
							&ensp;Account Controls
						</MenuItem>
						<MenuItem onClick={handleLogout}>
							<ExitToAppIcon />
							<LogoutButton />
						</MenuItem>
					</Menu>
					<Profile />
				</div>
			</Toolbar>
		</AppBar>
	);
}
