// React
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setRequestID } from '../../../redux/actions/dataActions';

// Queries
import { useScenarioRequests, useSummary } from '../../../api/query-hooks';
import AxiosClient from '../../../api/axios-client';

// Common
import { colorPalette as colors } from '../../../theme';
import formatters from '../../../helpers/formatters';
import LoadingSpinner from '../../common/LoadingSpinner';
import { useButtonStyles } from '../../filters/FilterStyles';
import '../../styles/legend.scss';

// Visualizations
import SummaryTable from './ScenarioSummaryTable';
import TrancheSummaryClassTable from './ScenarioTrancheSummary';
import TranchePaymentsTable from './ScenarioTranchePayments';
import AmountPaidChart from './ScenarioAmountPaid';
import AmountLostChart from './ScenarioAmountLost';
import PoolFactorChart from './ScenarioPoolFactor';
import CumulativeNetLossChart from './ScenarioCumulativeNetLoss';

// Material UI
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import HelpIcon from '@material-ui/icons/Help';
import IconButton from '@material-ui/core/IconButton';
import Slider from '@material-ui/core/Slider';
import Divider from '@material-ui/core/Divider';
import ToggleButton from '@material-ui/lab/ToggleButton';
import { makeStyles } from '@material-ui/core/styles';
import { Input } from '@material-ui/core';
import { useAuth0 } from '@auth0/auth0-react';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/core';
import MUIDataTable from 'mui-datatables';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

const tableStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
		borderStyle: 'solid',
		borderColor: colors[3],
		borderRadius: '5px',
		color: colors[0],
		margin: 2,
		fontSize: 30,
	},
	input: {
		textAlign: 'center',
		justifyContent: 'center',
	},
	visualization: {
		flexGrow: 1,
		borderStyle: 'solid',
		borderColor: colors[3],
		height: 40,
		borderRadius: '5px',
		color: colors[0],
		fontSize: 30,
	},
	navigationTextContainer: {
		display: 'flex',
		alignItems: 'center',
		flexDirection: 'column',
		textAlign: 'center',
		marginBottom: 40,
		width: '100%',
	},
	navigationTitle: {
		display: 'flex',
		marginTop: 0,
		marginBottom: 10,
	},
	p: {
		color: colors[4],
		fontSize: 17,
		marginTop: -5,
		marginBottom: 3,
	},
}));

const requestStyles = makeStyles((theme) => ({
	layout: {
		width: '50px',
		height: '50px',
		display: 'flex',
		'& > * + *': {
			marginLeft: theme.spacing(2),
		},
	},
}));

const theme = createMuiTheme({
	palette: {
		type: 'dark',
		primary: { main: '#32DB60' },
	},
});

function RequestTable() {
	const name = useSelector((state) => state.data.deal.name);
	const scenarioRequests = useScenarioRequests();
	const classes = requestStyles;
	const dispatch = useDispatch();

	useEffect(() => {
		scenarioRequests.remove();
		scenarioRequests.refetch();
	}, []);

	useEffect(() => {
		scenarioRequests.remove();
		scenarioRequests.refetch();
	}, [useSelector((state) => state.data.deal.id)]);

	// TODO: Set up so that the request ID is reset whenever the deal changes.
	// We do not want to display data for one deal's request ID when a different deal is selected.
	function handleChangeRequest(e) {
		dispatch(setRequestID(e.currentTarget.value));
	}

	const options = {
		elevation: 0,
		filter: false,
		selectableRows: 'none',
		search: false,
		download: false,
		print: false,
		viewColumns: false,
	};

	const columns = [
		{
			name: 'date',
			label: 'Date Submitted',
		},
		{
			name: 'charge_off_incidence_rate',
			label: 'Charge-Off Rate',
		},
		{
			name: 'recovery_rate',
			label: 'Recovery Rate',
		},
		{
			name: 'discount_rate',
			label: 'Discount Rate',
		},
		{
			name: 'status',
			label: 'Status',
			options: {
				filter: false,
				customBodyRenderLite: function setStatus(dataIndex) {
					if (scenarioRequests.data) {
						const value = scenarioRequests.data[dataIndex].status;
						const requestId = scenarioRequests.data[dataIndex].request_id;
						switch (value) {
							case 'pending':
								return (
									<Typography style={{ color: 'goldenrod' }}>
										{value}
									</Typography>
								);
							case 'complete':
								return (
									<>
										<Typography style={{ color: '#32DB60' }}>
											{value}
											<Button
												style={{
													marginLeft: 40,
													color: '#ffffff',
													backgroundColor: '#32DB60',
												}}
												value={requestId}
												onClick={handleChangeRequest}
											>
												View
											</Button>
										</Typography>
									</>
								);
							case 'failed':
								return (
									<Typography style={{ color: 'crimson' }}>{value}</Typography>
								);
							default:
								return <p>{value}</p>;
						}
					}
				},
			},
		},
	];

	return !scenarioRequests.data ? (
		<Paper>
			<ThemeProvider theme={theme}>
				<Typography
					style={{
						fontSize: '1.7em',
						fontWeight: 'bold',
						marginBottom: 12,
						position: 'relative',
					}}
				>
					Requests - {name}{' '}
					<Tooltip
						placement="right"
						title={
							<Typography style={{ fontSize: '1.4em' }}>
								List of all requests for this deal.
							</Typography>
						}
					>
						<IconButton>
							<HelpIcon fontSize="small" />
						</IconButton>
					</Tooltip>
				</Typography>
				<Grid container className={classes.layout} justify="center">
					<Grid
						item
						style={{
							paddingBottom: '168px',
							paddingTop: '168px',
						}}
					>
						<Typography variant="h5">
							No requests have been submitted for {name}.
						</Typography>
					</Grid>
				</Grid>
			</ThemeProvider>
		</Paper>
	) : (
		<MUIDataTable
			title={
				<Typography
					style={{
						fontSize: '1.7em',
						fontWeight: 'bold',
						marginBottom: 12,
					}}
				>
					Requests - {name}{' '}
					<Tooltip
						placement="right"
						title={
							<Typography style={{ fontSize: '1.4em' }}>
								List of all requests for this deal.
							</Typography>
						}
					>
						<IconButton>
							<HelpIcon fontSize="small" />
						</IconButton>
					</Tooltip>
				</Typography>
			}
			options={options}
			columns={columns}
			data={scenarioRequests.data}
		/>
	);
}

export default function ScenarioAnalysis() {
	const [originalChargeOffRate, setOriginalChargeOffRate] = useState();
	const [originalRecoveryRate, setOriginalRecoveryRate] = useState();
	const [originalDiscountRate, setOriginalDiscountRate] = useState();

	const [chargeOffRate, setChargeOffRate] = useState();
	const [recoveryRate, setRecoveryRate] = useState();
	const [discountRate, setDiscountRate] = useState();

	const [deal, setDeal] = useState(useSelector((state) => state.data.deal.id));
	const [dealName, setDealName] = useState(
		useSelector((state) => state.data.deal.name),
	);

	const [amountPaidSize, setAmountPaidSize] = useState(6);
	const [amountLostSize, setAmountLostSize] = useState(6);
	const [poolFactorSize, setPoolFactorSize] = useState(6);
	const [cumulativeNetLossSize, setCumulativeNetLossSize] = useState(6);

	const [open, setOpen] = useState(false);

	const { status, data, error, refetch } = useSummary();
	const scenarioRequests = useScenarioRequests();

	const classes = tableStyles();
	const buttonStyle = useButtonStyles();

	useEffect(() => {
		refetch();
	}, [useSelector((state) => state.data.deal.id)]);

	useEffect(() => {
		if (data) {
			setOriginalChargeOffRate(
				formatters.toPercentage(
					data.filter(
						(row) =>
							row.field === 'Charge-off rate' ||
							row.field === 'Charged off rate',
					)[0].current,
				),
			);
			setOriginalRecoveryRate(
				formatters.toPercentage(
					data.filter((row) => row.field === 'Recovery rate')[0].current,
				),
			);
			setOriginalDiscountRate(
				formatters.toPercentage(
					data.filter((row) => row.field === 'Discount rate')[0].current,
				),
			);
		}
	}, [data]);

	// Sliders

	function handleSliderChangeChargeOff(event, newValue) {
		setChargeOffRate(newValue);
	}

	function handleSliderChangeRecovery(event, newValue) {
		setRecoveryRate(newValue);
	}
	function handleSliderChangeDiscount(event, newValue) {
		setDiscountRate(newValue);
	}

	// Enlarge / Shrink chart components

	const amountPaidHandle = () => {
		amountPaidSize === 6 ? setAmountPaidSize(12) : setAmountPaidSize(6);
	};

	const amountLostHandle = () => {
		amountLostSize === 6 ? setAmountLostSize(12) : setAmountLostSize(6);
	};

	const poolFactorHandle = () => {
		poolFactorSize === 6 ? setPoolFactorSize(12) : setPoolFactorSize(6);
	};

	const cumulativeNetLossHandle = () => {
		cumulativeNetLossSize === 6
			? setCumulativeNetLossSize(12)
			: setCumulativeNetLossSize(6);
	};

	// POST request
	const { user } = useAuth0();
	function handleSubmit() {
		AxiosClient.post('/scenario/create-request', {
			user: user.email,
			deal: deal,
			dealName: dealName,
			chargeOffIncidenceRate: chargeOffRate,
			recoveryRate: recoveryRate,
			discountRate: discountRate,
		}).then(() => {
			scenarioRequests.refetch();
			setOpen(true);
		});
	}

	// Submit popup

	function handleClose(event, reason) {
		if (reason === 'clickaway') {
			return;
		}
		setOpen(false);
	}

	function Alert(props) {
		return <MuiAlert elevation={6} variant="filled" {...props} />;
	}

	if (status === 'loading') return <LoadingSpinner />;
	if (status === 'error') return `Error: ${error.message}`;

	return (
		<>
			<Grid container>
				<Grid item xs={12}>
					<Paper>
						<Typography
							style={{
								fontSize: '1.7em',
								fontWeight: 'bold',
								marginBottom: 12,
							}}
						>
							Karus predicted values{' '}
							<Tooltip
								placement="right"
								title={
									<Typography style={{ fontSize: '1.4em' }}>
										Original values produced by Karus ML models.
									</Typography>
								}
							>
								<IconButton>
									<HelpIcon fontSize="small" />
								</IconButton>
							</Tooltip>
						</Typography>
						<Grid container className={classes.root} spacing={2} xs={12}>
							<Grid
								item
								className={classes.item}
								style={{ paddingLeft: 24, paddingRight: 24 }}
								xs
							>
								<h5 className={classes.navigationTitle}>Charge-off Rate</h5>
								<p className={classes.p}>{originalChargeOffRate}</p>
							</Grid>
							<Divider orientation="vertical" flexItem />
							<Grid
								item
								className={classes.item}
								style={{ paddingLeft: 24, paddingRight: 24 }}
								xs
							>
								<h5 className={classes.navigationTitle}>Recovery Rate</h5>
								<p className={classes.p}>{originalRecoveryRate}</p>
							</Grid>
							<Divider orientation="vertical" flexItem />
							<Grid
								item
								className={classes.item}
								style={{ paddingLeft: 24, paddingRight: 24 }}
								xs
							>
								<h5 className={classes.navigationTitle}>Discount Rate</h5>
								<p className={classes.p}>{originalDiscountRate}</p>
							</Grid>
						</Grid>
					</Paper>
					<br />
					<Paper>
						<Typography
							style={{
								fontSize: '1.7em',
								fontWeight: 'bold',
								marginBottom: 12,
							}}
						>
							Updated values{' '}
							<Tooltip
								placement="right"
								title={
									<Typography style={{ fontSize: '1.4em' }}>
										Custom values applied to Karus ML models.
									</Typography>
								}
							>
								<IconButton>
									<HelpIcon fontSize="small" />
								</IconButton>
							</Tooltip>
						</Typography>
						<Grid container className={classes.root} spacing={2} xs={12}>
							<Grid
								item
								className={classes.item}
								style={{ paddingLeft: 24, paddingRight: 24 }}
								xs
							>
								<h5 id="charge-off-slider" className={classes.navigationTitle}>
									Charge-off Rate
								</h5>
								<Grid item xs={12}>
									<Slider
										aria-labelledby="charge-off-slider"
										value={
											typeof chargeOffRate === 'number' ? chargeOffRate : 0
										}
										valueLabelDisplay="auto"
										onChange={handleSliderChangeChargeOff}
										step={0.01}
										min={0}
										max={1}
									/>
								</Grid>
								<Grid item>
									<Input
										value={chargeOffRate}
										style={{ width: '10vw' }}
										inputProps={{
											step: 0.01,
											type: 'number',
											'aria-labelledby': 'charge-off-slider',
										}}
										onChange={(e) => {
											var value = parseFloat(e.target.value);
											if (value > 1) value = 1;
											if (value < 0) value = 0;
											// Get the decimal portion of the float to limit input to 2 decimal places
											if (formatters.numDecimals(value) <= 2) {
												setChargeOffRate(value);
											}
										}}
									/>
								</Grid>
							</Grid>
							<Divider orientation="vertical" flexItem />
							<Grid
								item
								className={classes.item}
								style={{ paddingLeft: 24, paddingRight: 24 }}
								xs
							>
								<h5
									id="recovery-input-slider"
									className={classes.navigationTitle}
								>
									Recovery Rate
								</h5>
								<Grid item xs={12}>
									<Slider
										aria-labelledby="recovery-slider"
										value={typeof recoveryRate === 'number' ? recoveryRate : 0}
										valueLabelDisplay="auto"
										onChange={handleSliderChangeRecovery}
										step={0.01}
										min={0}
										max={1}
									/>
								</Grid>
								<Grid item>
									<Input
										value={recoveryRate}
										style={{ width: '10vw' }}
										inputProps={{
											step: 0.01,
											type: 'number',
											'aria-labelledby': 'recovery-slider',
										}}
										onChange={(e) => {
											var value = parseFloat(e.target.value);
											if (value > 1) value = 1;
											if (value < 0) value = 0;
											// Get the decimal portion of the float to limit input to 2 decimal places
											if (formatters.numDecimals(value) <= 2) {
												setRecoveryRate(value);
											}
										}}
									/>
								</Grid>
							</Grid>
							<Divider orientation="vertical" flexItem />
							<Grid
								item
								className={classes.item}
								style={{ paddingLeft: 24, paddingRight: 24 }}
								xs
							>
								<h5
									id="discount-input-slider"
									className={classes.navigationTitle}
								>
									Discount Rate
								</h5>
								<Grid item xs={12}>
									<Slider
										aria-labelledby="discount-slider"
										value={typeof discountRate === 'number' ? discountRate : 0}
										valueLabelDisplay="auto"
										onChange={handleSliderChangeDiscount}
										step={0.01}
										min={0}
										max={1}
									/>
								</Grid>
								<Grid item>
									<Input
										name="discountRate"
										value={discountRate}
										style={{ width: '10vw' }}
										inputProps={{
											step: 0.01,
											type: 'number',
											'aria-labelledby': 'discount-slider',
										}}
										onChange={(e) => {
											var value = parseFloat(e.target.value);
											if (value > 1) value = 1;
											if (value < 0) value = 0;
											// Get the decimal portion of the float to limit input to 2 decimal places
											if (formatters.numDecimals(value) <= 2) {
												setDiscountRate(value);
											}
										}}
									/>
								</Grid>
							</Grid>
						</Grid>
						<Grid item container justify="flex-end" xs={12}>
							{chargeOffRate && recoveryRate && discountRate ? (
								<Button
									style={{
										color: '#ffffff',
										backgroundColor: colors[6],
										textAlign: 'center',
										margin: 2,
									}}
									onClick={handleSubmit}
								>
									Submit
								</Button>
							) : (
								<Button
									disabled
									variant="contained"
									style={{
										color: '#ffffff',
									}}
								>
									Submit
								</Button>
							)}
						</Grid>
					</Paper>
					<br />
					<Snackbar open={open} aonClose={handleClose}>
						<Alert onClose={handleClose} severity="success">
							Request has been submitted. The Requests table will update
							automatically when completed (est. time - 20 minutes)
						</Alert>
					</Snackbar>
					<br />
					<RequestTable />
					<br />
					<hr />
					<br />
				</Grid>
			</Grid>
			<Grid container spacing={2} xs={12}>
				<Grid item xs={12}>
					<SummaryTable />
				</Grid>
				{useSelector((state) => state.data.deal.type) === 'STRUCTURED' && (
					<>
						<Grid item xs={12}>
							<TrancheSummaryClassTable />
						</Grid>
						<Grid item xs={12}>
							<TranchePaymentsTable />
						</Grid>
					</>
				)}
				<Grid item xs={amountPaidSize}>
					<ToggleButton
						className={buttonStyle.button}
						selected={true}
						variant="outlined"
						aria-label
						style={{
							width: '90px',
							height: '30px',
							float: 'right',
							marginLeft: 15,
							marginBottom: -60,
							marginTop: 5,
							marginRight: 5,
							zIndex: 5,
						}}
						onClick={amountPaidHandle}
					>
						{amountPaidSize === 6 ? 'Enlarge' : 'Shrink'}
					</ToggleButton>
					<Paper>
						<AmountPaidChart />
					</Paper>
				</Grid>
				<Grid item xs={amountLostSize}>
					<ToggleButton
						className={buttonStyle.button}
						selected={true}
						variant="outlined"
						aria-label
						style={{
							width: '90px',
							height: '30px',
							float: 'right',
							marginLeft: 15,
							marginBottom: -60,
							marginTop: 5,
							marginRight: 5,
							zIndex: 5,
						}}
						onClick={amountLostHandle}
					>
						{amountLostSize === 6 ? 'Enlarge' : 'Shrink'}
					</ToggleButton>
					<Paper style={{ height: '100%' }}>
						<AmountLostChart />
					</Paper>
				</Grid>
				<Grid item xs={poolFactorSize}>
					<ToggleButton
						className={buttonStyle.button}
						selected={true}
						variant="outlined"
						aria-label
						style={{
							width: '90px',
							height: '30px',
							float: 'right',
							marginLeft: 15,
							marginBottom: -60,
							marginTop: 5,
							marginRight: 5,
							zIndex: 5,
						}}
						onClick={poolFactorHandle}
					>
						{poolFactorSize === 6 ? 'Enlarge' : 'Shrink'}
					</ToggleButton>
					<Paper>
						<PoolFactorChart />
					</Paper>
				</Grid>
				<Grid item xs={cumulativeNetLossSize}>
					<ToggleButton
						className={buttonStyle.button}
						selected={true}
						variant="outlined"
						aria-label
						style={{
							width: '90px',
							height: '30px',
							float: 'right',
							marginLeft: 15,
							marginBottom: -60,
							marginTop: 5,
							marginRight: 5,
							zIndex: 5,
						}}
						onClick={cumulativeNetLossHandle}
					>
						{cumulativeNetLossSize === 6 ? 'Enlarge' : 'Shrink'}
					</ToggleButton>
					<Paper>
						<CumulativeNetLossChart />
					</Paper>
				</Grid>
			</Grid>
		</>
	);
}
