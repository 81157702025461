// React
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

// Queries
import { useTranchePaymentsScenario } from '../../../api/query-hooks';

// Common
import formatters from '../../../helpers/formatters';
import LoadingSpinner from '../../common/LoadingSpinner';
import NoData from '../../common/NoData';
import '../../styles/legend.scss';

// Material UI
import Paper from '@material-ui/core/Paper';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import HelpIcon from '@material-ui/icons/Help';
import IconButton from '@material-ui/core/IconButton';
import { createMuiTheme } from '@material-ui/core/styles';
import {} from '@material-ui/core';
import MUIDataTable from 'mui-datatables';
import { ThemeProvider } from '@material-ui/styles';

export default function TranchePaymentsTable() {
	const { status, data, error, refetch, remove } = useTranchePaymentsScenario();

	useEffect(() => {
		remove();
		refetch();
	}, [
		useSelector((state) => state.data.deal.id),
		useSelector((state) => state.data.requestID),
	]);

	const theme = createMuiTheme({
		palette: {
			type: 'dark',
		},
		overrides: {
			MUIDataTableHeadCell: {
				data: {
					fontSize: '0.7em',
					textAlign: 'center',
				},
			},
			MUIDataTableBodyRow: {
				root: {
					'&:nth-child(odd)': {
						backgroundColor: '#666666',
					},
				},
			},
			MuiTableCell: {
				root: {
					padding: '2px',
					fontSize: '0.7em',
					textAlign: 'left',
				},
			},
			MUIDataTableToolbar: {
				icon: {
					'&:hover': {
						color: '#32DB60',
					},
				},
			},
		},
	});

	const columns = [
		{
			name: 'reportingPeriodEndingDateR',
			label: 'Reporting Period Ending Date',
		},
		{
			name: 'dataStatus',
			label: 'Data Status',
		},
		{
			name: 'actualPrincipalCollectedAmount',
			label: 'Actual Principal Collected Amount',
			options: {
				filter: false,
				customBodyRender: (value) =>
					value ? formatters.toCurrency(value) : `Values not available`,
			},
		},
		{
			name: 'actualInterestCollectedAmount',
			label: 'Actual Interest Collected Amount',
			options: {
				filter: false,
				customBodyRender: (value) =>
					value ? formatters.toCurrency(value) : `Values not available`,
			},
		},
		{
			name: 'actualTotal',
			label: 'Actual Total',
			options: {
				filter: false,
				customBodyRender: (value) =>
					value ? formatters.toCurrency(value) : `Values not available`,
			},
		},
		{
			name: 'class',
			label: 'Class',
		},
		{
			name: 'initialNotePrincipalBalance',
			label: 'Initial Note Principal Balance',
			options: {
				filter: false,
				customBodyRender: (value) =>
					value ? formatters.toCurrency(value) : `Values not available`,
			},
		},
		{
			name: 'noteRate',
			label: 'Note Rate',
			options: {
				filter: false,
				customBodyRender: (value) =>
					value ? formatters.toPercentage(value) : `Values not available`,
			},
		},
		{
			name: 'principalDistribution',
			label: 'Principal Distribution',
			options: {
				filter: false,
				customBodyRender: (value) =>
					value ? formatters.toCurrency(value) : `Values not available`,
			},
		},
		{
			name: 'beginningNotePrincipalBalance',
			label: 'Beginning Note Principal Balance',
			options: {
				filter: false,
				customBodyRender: (value) =>
					value ? formatters.toCurrency(value) : `Values not available`,
			},
		},
		{
			name: 'interestDistribution',
			label: 'Interest Distribution',
			options: {
				filter: false,
				customBodyRender: (value) =>
					value ? formatters.toCurrency(value) : `Values not available`,
			},
		},
		{
			name: 'totalDistribution',
			label: 'Total Distribution',
			options: {
				filter: false,
				customBodyRender: (value) =>
					value ? formatters.toCurrency(value) : `Values not available`,
			},
		},
		{
			name: 'endingNotePrincipalBalance',
			label: 'Ending Note Principal Balance',
			options: {
				filter: false,
				customBodyRender: (value) =>
					value ? formatters.toCurrency(value) : `Values not available`,
			},
		},
		{
			name: 'interestPeriod',
			label: 'Interest Period',
			options: {
				filter: false,
				customBodyRender: (value) =>
					value ? formatters.toCurrency(value) : `Values not available`,
			},
		},
	];

	const options = {
		elevation: 0,
		filter: false,
		selectableRows: 'none',
	};

	if (status === 'loading') return <LoadingSpinner />;

	// Display NoData component instead.
	// if (status === 'error') return `Error: ${error.message}`;

	return !data ? (
		<Paper>
			<NoData
				chartTitle="Tranche Payments"
				chartTitleTooltip="Month by month historical and predicted tranche
				payments. Historical payments are taken from
				securitization release documents and predicted payments
				are derived from Karus ML cashflow models."
			/>
		</Paper>
	) : (
		<ThemeProvider theme={theme}>
			<MUIDataTable
				title={
					<Typography
						style={{
							fontSize: '1.7em',
							fontWeight: 'bold',
							marginBottom: 12,
						}}
					>
						Tranche Payments{' '}
						<Tooltip
							placement="right"
							title={
								<Typography style={{ fontSize: '1.4em' }}>
									Month by month historical and predicted tranche payments.
									Historical payments are taken from securitization release
									documents and predicted payments are derived from Karus ML
									cashflow models.
								</Typography>
							}
						>
							<IconButton>
								<HelpIcon fontSize="small" />
							</IconButton>
						</Tooltip>
					</Typography>
				}
				data={data}
				columns={columns}
				options={options}
			/>
		</ThemeProvider>
	);
}
