import { createReducer } from '@reduxjs/toolkit';
import {
	fetchFilterOptions,
	setDeal,
	setDealStatus,
	setModule,
	setIsAdmin,
	setUnderwritingID,
	setRequestID,
	setAllowedDeals,
	setAllowedLendingDeals,
} from '../actions/dataActions';

// declare initial state to set up state.data on initial render
const initialState = {
	deal: '',
	dealStatus: '',
	module: '',
	underwritingID: '15508450-2021-2', // We can remove this
	requestID: '',
	isAdmin: false,
	filterOptions: {
		obligorGeographicLocations: [],
		vehicleOptions: [],
	},
	allowedDeals: [],
	allowedLendingDeals: [],
};

// createReducer() sets up the data reducer to listen for actions of type data/* and update state.data accordingly
// using with "Map Object" notation to handle logic for each action.type
export const dataReducer = createReducer(initialState, {
	[setDeal.type]: (state, action) => {
		// can directly modify state since redux-toolkit makes use of 'imer' library
		// action.payload should contain the PK id for the chosen deal from the landing page
		state.deal = action.payload;
	},
	[setDealStatus.type]: (state, action) => {
		state.dealStatus = action.payload;
	},
	[setModule.type]: (state, action) => {
		state.module = action.payload;
	},
	[setUnderwritingID.type]: (state, action) => {
		state.underwritingID = action.payload;
	},
	[setRequestID.type]: (state, action) => {
		state.requestID = action.payload;
	},
	[setIsAdmin.type]: (state, action) => {
		state.isAdmin = action.payload;
	},
	// Display loading spinner when querying deal
	[fetchFilterOptions.pending]: (state, action) => {
		state.dealStatus = 'loading';
	},
	// Set filter options if deal query is successful
	[fetchFilterOptions.fulfilled]: (state, action) => {
		const options = {};
		action.payload.vehicleOptions.forEach((obj) => {
			const objMake =
				obj.make[0].toUpperCase() + obj.make.slice(1).toLowerCase();
			if (!options[objMake]) {
				options[objMake] = { [obj.model]: { [obj.year]: true } };
			} else {
				if (!options[objMake][obj.model]) {
					options[objMake][obj.model] = { [obj.year]: true };
				} else {
					if (!options[objMake][obj.model][obj.year]) {
						options[objMake][obj.model][obj.year] = true;
					}
				}
			}
		});

		state.filterOptions = {
			// ...action.payload,
			obligorGeographicLocations: action.payload.obligorGeographicLocations.map(
				(locObj) => locObj.obligorGeographicLocation,
			),
			vehicleOptions: options,
		};

		state.dealStatus = 'succeeded';
	},
	// Display error message if deal query is unsuccessful
	[fetchFilterOptions.rejected]: (state, action) => {
		state.dealStatus = 'failed';
		state.error = action.error.message;
	},
	[setAllowedDeals.type]: (state, action) => {
		state.allowedDeals = action.payload;
	},
	[setAllowedLendingDeals.type]: (state, action) => {
		state.allowedLendingDeals = action.payload;
	},
});
