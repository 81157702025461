// React
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

// Axios
import AxiosClient from '../../../api/axios-client';

// Common
import { colorPalette as colors } from '../../../theme';

// Material UI
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
	root: {
		width: '50vw',
		margin: '0 auto',
	},
	header: {
		textAlign: 'center',
		color: colors[0],
	},
	lineBreak: {
		color: '#616161',
	},
	p: {
		color: colors[4],
		fontSize: 17,
		marginTop: -5,
		marginBottom: 3,
		padding: '16px',
		fontStyle: 'italic',
	},
	backArrow: {
		color: colors[5],
	},
}));

export default function PrivateOpportunity(props) {
	const [opportunity, setOpportunity] = useState();

	const classes = useStyles();
	const history = useHistory();

	function handleReturn() {
		history.goBack();
	}

	return (
		<Paper className={classes.root}>
			<Grid container>
				<Grid item xs={12}>
					<h1 className={classes.header}>{props.location.state.name}</h1>
					<hr className={classes.lineBreak} />
					<br />
				</Grid>
				<Grid item xs={12}>
					<h6 className={classes.p}>{props.location.state.description}</h6>
				</Grid>
				<Grid item xs={12}>
					<IconButton onClick={handleReturn}>
						<ArrowBackIcon className={classes.backArrow} />
					</IconButton>
				</Grid>
			</Grid>
		</Paper>
	);
}
