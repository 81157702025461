/* eslint-disable prettier/prettier */
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { setUnderwritingID } from '../../../redux/actions/dataActions';

function importAll(r) {
	return r.keys().map(r);
}

const jsonFiles = importAll(require.context('../UnderwritingJson', false, /\.(json)$/));

export default function ID() {
    const [file, setFile] = useState();
    const dispatch = useDispatch();
    let underwritingID = useSelector((state) => state.data.underwritingID);

    useEffect(() => {
        setFile(jsonFiles.find((file) => file.ID === underwritingID));
    }, [underwritingID])

    function handleChangeId(event) {
        setFile(jsonFiles.find((file) => file.ID === event.target.value));
        dispatch(setUnderwritingID(event.target.value));
    }

	return (
		<>
			<Paper
				elevation={24}
				style={{
					marginTop: '50px',
					minHeight: '50%',
					minWidth: '50%',
					justifyContent: 'center',
					padding: '15px',
					paddingBottom: '20px',
				}}
			>
				<Typography variant="h5">Select ID</Typography>
                <Grid
					container
					spacing={2}
					style={{ borderTop: '1px solid grey', marginTop: '3px' }}
				>
                {file && (
                    <>
                        <Grid item xs={12}>
                            <FormControl>
                                <InputLabel>ID</InputLabel>
                                <Select
                                    value={file.ID ?? ''}
                                    onChange={handleChangeId}
                                >
                                    {jsonFiles.map((file) => (
                                        <MenuItem key={file.ID} value={file.ID}>
                                            {file.ID}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <TextField label="State" value={file.inputs.State} InputLabelProps={{ shrink: true }} fullWidth disabled />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField label="Interest rate type" value={file.inputs['Interest rate type']} InputLabelProps={{ shrink: true }} fullWidth disabled />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField label="New or used" value={file.inputs['New or used']} InputLabelProps={{ shrink: true }} fullWidth disabled />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField label="Vehicle type" value={file.inputs['Vehicle type']} InputLabelProps={{ shrink: true }} fullWidth disabled />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField label="Vehicle manufacturer" value={file.inputs['Vehicle manufacturer']} InputLabelProps={{ shrink: true }} fullWidth disabled />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField label="Vehicle model" value={file.inputs['Vehicle model']} InputLabelProps={{ shrink: true }} fullWidth disabled />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField label="Vehicle year" value={file.inputs['Vehicle year']} InputLabelProps={{ shrink: true }} fullWidth disabled />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField label="Vehicle value" value={`$${file.inputs['Vehicle value']}`} InputLabelProps={{ shrink: true }} fullWidth disabled />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField label="Co-obligor" value={file.inputs['Co-obligor']} InputLabelProps={{ shrink: true }} fullWidth disabled />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField label="Monthly income" value={`$${file.inputs['Monthly income']}`} InputLabelProps={{ shrink: true }} fullWidth disabled />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField label="Credit score type" value={file.inputs['Credit score type']} InputLabelProps={{ shrink: true }} fullWidth disabled />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField label="Credit score" value={file.inputs['Credit score']} InputLabelProps={{ shrink: true }} fullWidth disabled />
                        </Grid>
                    </>
                )}
                </Grid>
			</Paper>
		</>
	);
}
