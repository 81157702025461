// React
import React, { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';

// Queries
import { usePaymentsScenario } from '../../../api/query-hooks';

// Charts
import {
	Chart,
	CategoryScale,
	BarController,
	BarElement,
	LineController,
	LineElement,
	PointElement,
	Legend,
	Tooltip as TooltipChartPlugin,
} from 'chart.js';

// Common
import { colorPalette as colors } from '../../../theme';
import { chartColors } from '../../../theme';
import LoadingSpinner from '../../common/LoadingSpinner';
import NoData from '../../common/NoData';
import { getCumulativeSumArray } from '../../../helpers/helpers';
import { useRadioStyles, useLabelStyles } from '../../filters/FilterStyles';
import '../../styles/legend.scss';

// Material UI
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import HelpIcon from '@material-ui/icons/Help';
import IconButton from '@material-ui/core/IconButton';
import {
	FormControlLabel,
	Radio,
	RadioGroup,
	FormLabel,
	FormControl,
} from '@material-ui/core';

export default function AmountPaidChart() {
	const [chart, setChart] = useState();
	const [labels, setLabels] = useState();
	const [dataSets, setDatasets] = useState();
	const [xAxisValue, setXAxisValue] = useState('MDY');
	const [yAxisValue, setYAxisValue] = useState('Actual Total');
	const [chartTitle, setChartTitle] = useState('Actual Total');
	const [selectedLegend, setSelectedLegend] = useState({
		monthlyPredicted: true,
		cumulative: true,
	});

	const radioStyle = useRadioStyles();
	const labelStyle = useLabelStyles();
	const { status, data, error, refetch, remove } = usePaymentsScenario();
	const chartRef = useRef();

	useEffect(() => {
		remove();
		refetch();
	}, [
		useSelector((state) => state.data.deal.id),
		useSelector((state) => state.data.requestID),
	]);

	const legendInfo = [
		{
			label: `Cumulative ${yAxisValue}`,
			backgroundColor: chartColors.green,
			status: selectedLegend.cumulative,
			onClick: () => {
				setSelectedLegend({
					...selectedLegend,
					cumulative: !selectedLegend.cumulative,
				});
			},
		},
		{
			label: `Monthly ${yAxisValue}, Predicted`,
			backgroundColor: chartColors.yellow,
			status: selectedLegend.monthlyPredicted,
			onClick: () => {
				setSelectedLegend({
					...selectedLegend,
					monthlyPredicted: !selectedLegend.monthlyPredicted,
				});
			},
		},
	];

	function createChart() {
		Chart.register(
			CategoryScale,
			BarController,
			BarElement,
			LineController,
			LineElement,
			PointElement,
			Legend,
			TooltipChartPlugin,
		);
		if (data) {
			setChart(
				new Chart(chartRef.current, {
					type: 'bar',
					data: {
						labels: labels,
						datasets: dataSets,
					},
					options: {
						responsive: true,
						elements: {
							// hide points on line, but retain tooltips
							point: {
								radius: 0,
								hitRadius: 5,
								borderWidth: 0,
								hoverBorderWidth: 0,
								backgroundColor: 'rgba(0, 0, 0, 0.0)',
								borderColor: 'rgba(0, 0, 0, 0.0)',
							},
						},
						plugins: {
							legend: {
								display: false,
								labels: {
									color: '#f0f0f0',
								},
								onHover: function (e, legendItem, legend) {
									e.native.target.style.cursor = 'pointer';
								}.bind(this),
								onLeave: function (e, legendItem, legend) {
									e.native.target.style.cursor = 'default';
								}.bind(this),
								padding: 20,
							},
						},
						scales: {
							x: {
								axis: 'x',
								grid: {
									drawTicks: true,
									display: true,
									zeroLineColor: '#e1e1e1',
									offsetGridLines: true,
								},
								ticks: {
									color: '#e1e1e1',
									font: {
										weight: 'bold',
									},
									beginAtZero: true,
									padding: 10,
									display: true,
								},
								options: {
									stacked: true,
									reverse: true,
								},
								title: {
									display: true,
									text: 'Date',
								},
							},
							leftAxis: {
								position: 'left',
								grid: {
									drawTicks: false,
									display: true,
									zeroLineColor: '#e1e1e1',
								},
								ticks: {
									font: {
										weight: 'bold',
									},
									color: '#e1e1e1',
									beginAtZero: true,
									padding: 10,
								},
								title: {
									display: true,
									text: 'Actual Total',
								},
							},
							rightAxis: {
								position: 'right',
								min: 0,
								grid: {
									drawTicks: false,
									display: false,
									color: '#e1e1e1',
								},
								ticks: {
									font: {
										weight: 'bold',
									},
									color: '#e1e1e1',
									beginAtZero: true,
									padding: 10,
									crossAlign: 'near',
								},
								title: {
									display: true,
									text: 'Running Sum of Actual Total',
								},
							},
						},
					},
				}),
			);
		}
	}

	useEffect(() => {
		if (data) {
			if (xAxisValue === 'MDY') {
				setLabels(
					data
						.filter((row) => row.dataStatus === 'Predicted')
						.map((row) => row.reportingPeriodBeginningDateR),
				);
			} else if (xAxisValue === 'month-index') {
				setLabels(
					data
						.filter((row) => row.dataStatus === 'Predicted')
						.map((row) => row.monthIndex),
				);
			}
			if (yAxisValue === 'Actual Total') {
				setDatasets([
					{
						data: selectedLegend.monthlyPredicted
							? data
									.filter((row) => row.dataStatus === 'Predicted')
									.map((row) => row.actualTotal)
							: null,
						backgroundColor: chartColors.yellow,
						label: `Monthly Amount Paid, Predicted`,
						yAxisID: 'leftAxis',
						order: 1,
					},
					{
						type: 'line',
						data: selectedLegend.cumulative
							? getCumulativeSumArray(
									data
										.filter((row) => row.dataStatus === 'Predicted')
										.map((row) => row.actualTotal)
										.map(Number),
							  )
							: null,
						label: `Cumulative Amount Paid`,
						yAxisID: 'rightAxis',
						order: 0,
						fill: false,
						borderColor: chartColors.green,
					},
				]);
			} else if (yAxisValue === 'Principal') {
				setDatasets([
					{
						data: selectedLegend.monthlyPredicted
							? data
									.filter((row) => row.dataStatus === 'Predicted')
									.map((row) => row.actualPrincipalCollectedAmount)
							: null,
						backgroundColor: chartColors.yellow,
						label: `Monthly Principal, Predicted`,
						yAxisID: 'leftAxis',
						order: 1,
					},
					{
						type: 'line',
						data: selectedLegend.cumulative
							? getCumulativeSumArray(
									data
										.filter((row) => row.dataStatus === 'Predicted')
										.map((row) => row.actualPrincipalCollectedAmount)
										.map(Number),
							  )
							: null,
						label: `Cumulative Principal`,
						yAxisID: 'rightAxis',
						order: 0,
						fill: false,
						borderColor: chartColors.green,
					},
				]);
			} else if (yAxisValue === 'Interest') {
				setDatasets([
					{
						data: selectedLegend.monthlyPredicted
							? data
									.filter((row) => row.dataStatus === 'Predicted')
									.map((row) => row.actualInterestCollectedAmount)
							: null,
						backgroundColor: chartColors.yellow,
						label: `Monthly Interest, Predicted`,
						yAxisID: 'leftAxis',
						order: 1,
					},
					{
						type: 'line',
						data: selectedLegend.cumulative
							? getCumulativeSumArray(
									data
										.filter((row) => row.dataStatus === 'Predicted')
										.map((row) => row.actualInterestCollectedAmount)
										.map(Number),
							  )
							: null,
						label: `Cumulative Interest`,
						yAxisID: 'rightAxis',
						order: 0,
						fill: false,
						borderColor: chartColors.green,
					},
				]);
			}
		}
	}, [data, xAxisValue, yAxisValue, selectedLegend]);

	useEffect(() => {
		if (chart) {
			chart.destroy();
		}
		createChart();
		setChartTitle(yAxisValue);
	}, [dataSets, xAxisValue, yAxisValue]);

	function handleXAxisValueChange(event) {
		setXAxisValue(event.target.value);
	}

	function handleYAxisValueChange(event) {
		setYAxisValue(event.target.value);
	}

	return status === 'loading' ? (
		<LoadingSpinner />
	) : !data ? (
		<NoData
			chartTitle={'Amount Paid'}
			chartTitleTooltip="Payment inflows (principal, interest, recovered amount) broken out by historical and predicted monthly cashflows. Toggle the payment fields and filter by criteria."
		/>
	) : (
		<Grid container>
			<Grid item xs={12}>
				<Typography
					style={{
						fontSize: '1.7em',
						fontWeight: 'bold',
						marginBottom: 12,
						position: 'relative',
					}}
				>
					Amount Paid ({chartTitle})
					<Tooltip
						placement="right"
						title={
							<Typography style={{ fontSize: '1.4em', paddingTop: -40 }}>
								Payment inflows (principal, interest, recovered amount) broken
								out by historical and predicted monthly cashflows. Toggle the
								payment fields and filter by criteria.
							</Typography>
						}
					>
						<IconButton>
							<HelpIcon fontSize="small" />
						</IconButton>
					</Tooltip>
				</Typography>
				<div className="legend-container">
					<div className="legend-bar-container">
						{legendInfo.map((legend) => {
							return (
								<li
									key={legend.label}
									onClick={() => {
										legend.onClick();
									}}
									className="legend-item"
								>
									<span
										style={{ backgroundColor: legend.backgroundColor }}
										className="legend-color-bar"
									/>
									<span
										className={
											legend.status
												? 'legend-title'
												: 'legend-title strikethrough'
										}
									>
										{legend.label}
									</span>
								</li>
							);
						})}
					</div>
				</div>
				<canvas ref={chartRef} />
			</Grid>
			<Grid item xs={12}>
				<Paper elevation={4}>
					<FormControl component="fieldset">
						<FormLabel
							component="legend"
							focused={false}
							classes={{ root: labelStyle.label }}
							style={{
								color: '#fff',
								fontSize: '1rem',
							}}
						>
							X-Axis:{' '}
						</FormLabel>
						<RadioGroup
							aria-label="x-axis"
							name="x-axis"
							value={xAxisValue}
							onChange={handleXAxisValueChange}
						>
							<FormControlLabel
								value="MDY"
								control={
									<Radio
										classes={{
											root: radioStyle.radio,
											checked: radioStyle.checked,
										}}
									/>
								}
								label={
									<Typography style={{ fontSize: '1rem', color: colors[4] }}>
										Date
									</Typography>
								}
							/>
							<FormControlLabel
								value="month-index"
								control={
									<Radio
										classes={{
											root: radioStyle.radio,
											checked: radioStyle.checked,
										}}
									/>
								}
								label={
									<Typography style={{ fontSize: '1rem', color: colors[4] }}>
										Month Index
									</Typography>
								}
							/>
						</RadioGroup>
					</FormControl>
					<FormControl component="fieldset">
						<FormLabel
							component="legend"
							focused={false}
							classes={{ root: labelStyle.label }}
							style={{
								color: '#fff',
								fontSize: '1rem',
							}}
						>
							Data:{' '}
						</FormLabel>
						<RadioGroup
							aria-label="y-axis"
							name="y-axis"
							value={yAxisValue}
							onChange={handleYAxisValueChange}
						>
							<FormControlLabel
								value="Actual Total"
								control={
									<Radio
										classes={{
											root: radioStyle.radio,
											checked: radioStyle.checked,
										}}
									/>
								}
								label={
									<Typography style={{ fontSize: '1rem', color: colors[4] }}>
										Actual Total
									</Typography>
								}
							/>
							<FormControlLabel
								value="Principal"
								control={
									<Radio
										classes={{
											root: radioStyle.radio,
											checked: radioStyle.checked,
										}}
									/>
								}
								label={
									<Typography style={{ fontSize: '1rem', color: colors[4] }}>
										Principal
									</Typography>
								}
							/>
							<FormControlLabel
								value="Interest"
								control={
									<Radio
										classes={{
											root: radioStyle.radio,
											checked: radioStyle.checked,
										}}
									/>
								}
								label={
									<Typography style={{ fontSize: '1rem', color: colors[4] }}>
										Interest
									</Typography>
								}
							/>
						</RadioGroup>
					</FormControl>
				</Paper>
			</Grid>
		</Grid>
	);
}
