import React, { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import AxiosClient from '../../api/axios-client';

import { chartColors as colors } from '../../theme';

// Material-UI
import { makeStyles, withStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import { DropzoneArea, DropzoneAreaBase } from 'material-ui-dropzone';

const useStyles = makeStyles({
	root: {
		width: '100%',
		margin: '12px',
	},
	content: {
		height: '100%',
		margin: '0 auto',
	},
	upload: {
		width: '50vw',
		minWidth: '400px',
		margin: '0 auto',
	},
	button: {
		backgroundColor: colors.green,
		color: colors.white,
	},
	list: {
		backgroundColor: '#666666',
		marginTop: '16px',
		marginBottom: '16px',
		padding: '16px',
		height: 400,
		overflow: 'auto',
	},
	listItem: {
		border: '1px solid #E1E1E1',
		borderRadius: '6px',
		padding: '16px',
		marginBottom: '4px',
	},
	previewChip: {
		minWidth: 160,
		maxWidth: 210,
	},
});

const BorderLinearProgress = withStyles((theme) => ({
	root: {
		height: 10,
		borderRadius: 5,
	},
	colorPrimary: {
		backgroundColor:
			theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
	},
	bar: {
		borderRadius: 5,
		backgroundColor: colors.green,
	},
}))(LinearProgress);

export default function Inbox() {
	const [files, setFiles] = useState([]);
	const [filesExist, setFilesExist] = useState();
	const [uploadComplete, setUploadComplete] = useState(false);
	const [progress, setProgress] = React.useState(0);
	const classes = useStyles();
	const { user } = useAuth0();
	const data = new FormData();

	// Disable upload button when there are no files in the files list
	useEffect(() => {
		if (files.length === 0) {
			setFilesExist(false);
		} else {
			setFilesExist(true);
		}
	}, [files]);

	function handleSelect(event) {
		// console.log(event);
		// Reset progress bar and UI if file was previously uploaded
		setProgress(0);
		setUploadComplete(false);
		// Iterate through selected files
		Object.values(event).forEach((file) => {
			// Filter files which are already in the files list to avoid duplicates
			if (!files.some((element) => element.name === file.name)) {
				// Add to files list
				setFiles((files) => [...files, file]);
			}
		});
	}

	const handleAdd = (newFiles) => {
		newFiles = newFiles.filter(
			(file) => !files.find((f) => f.data === file.data),
		);
		const newSpecificFiles = newFiles.map((file) => file.file);
		// console.log(newSpecificFiles);
		const oldFiles = files.map((file) => file.file);
		// console.log(...oldFiles);
		setFiles([...files, ...newFiles]);
	};

	// console.log(data);

	const handleDelete = (deleted) => {
		setFiles(files.filter((f) => f !== deleted));
	};

	function handleRemove(name) {
		// Remove file with the selected name from the files list
		setFiles(files.filter((file) => file.name !== name));
	}

	function handleSubmit(event) {
		event.preventDefault();
		for (const key of Object.keys(files)) {
			data.append('files', files[key]);
		}
		data.append('email', user.email);
		AxiosClient.post('/inbox/file-upload', data, {
			onUploadProgress: function (progressEvent) {
				var percentprogress = Math.round(
					(progressEvent.loaded * 100) / progressEvent.total,
				);
				setProgress(percentprogress);
			},
		});
		setUploadComplete(true);
		setFiles([]);
	}

	return (
		<>
			<Paper elevation={24} classes={{ root: classes.content }}>
				<Grid
					classes={{ root: classes.upload }}
					alignContent={'center'}
					justifyContent={'center'}
					style={{
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'center',
						position: 'absolute',
						left: '50%',
						top: '50%',
						transform: 'translate(-50%, -50%)',
					}}
				>
					<Grid item xs={12}>
						{/*<Button*/}
						{/*	variant="contained"*/}
						{/*	component="label"*/}
						{/*	classes={{ root: classes.button }}*/}
						{/*>*/}
						{/*	Choose Files*/}
						{/*	<input type="file" hidden onChange={handleSelect} multiple />*/}
						{/*</Button>*/}
					</Grid>
					<Grid item xs={12} style={{ paddingTop: 20, paddingBottom: 20 }}>
						{/*<List classes={{ root: classes.list }}>*/}
						{/*	{files.map((file) => (*/}
						{/*		<ListItem key={file.name} classes={{ root: classes.listItem }}>*/}
						{/*			<InsertDriveFileIcon style={{ marginRight: '8px' }} />*/}
						{/*			<div>{file.name}</div>*/}
						{/*			<ListItemSecondaryAction>*/}
						{/*				<IconButton*/}
						{/*					onClick={() => handleRemove(file.name)}*/}
						{/*					edge="end"*/}
						{/*				>*/}
						{/*					<CloseIcon />*/}
						{/*				</IconButton>*/}
						{/*			</ListItemSecondaryAction>*/}
						{/*		</ListItem>*/}
						{/*	))}*/}
						{/*</List>*/}

						<DropzoneArea
							// onAdd={handleAdd}
							accepted={[
								'.xlsx',
								'.xls',
								'.ods',
								'.xltx',
								'.xlsm',
								'.xlsb',
								'.txt',
								'.csv',
								'.prn',
							]}
							onDelete={handleDelete}
							onChange={handleSelect}
							showPreviews={true}
							showPreviewsInDropzone={false}
							useChipsForPreview
							previewGridProps={{
								container: { spacing: 1, direction: 'row', padding: 50 },
							}}
							previewChipProps={{ classes: { root: classes.previewChip } }}
							previewText="Selected files"
							dropzoneText={'Drag and drop your file(s) here or click'}
							maxFileSize={10000000000}
							showAlerts={true}
							alertSnackbarProps={{ autoHideDuration: 700 }}
							filesLimit={100}
						/>

						{/*<DropzoneAreaBase*/}
						{/*	fileObjects={files}*/}
						{/*	onAdd={handleAdd}*/}
						{/*	onDelete={handleDelete}*/}
						{/*	filesLimit={10}*/}
						{/*	useChipsForPreview*/}
						{/*/>*/}
					</Grid>
					<Grid item xs={12}>
						{filesExist ? (
							<Button
								variant="contained"
								component="label"
								onClick={handleSubmit}
								classes={{ root: classes.button }}
							>
								Upload
							</Button>
						) : (
							<Button
								variant="contained"
								component="label"
								disabled
								classes={{ root: classes.button }}
							>
								Upload
							</Button>
						)}
					</Grid>
					{uploadComplete ? <div>Files Sent!</div> : null}
					{progress ? (
						<div className={classes.root}>
							<BorderLinearProgress variant="determinate" value={progress} />
						</div>
					) : null}
				</Grid>
			</Paper>
		</>
	);
}
