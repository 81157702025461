// React
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

// Query
import { useLendingDeals } from '../../../api/query-hooks';
import {
	fetchFilterOptions,
	setDeal,
	setRequestID,
} from '../../../redux/actions/dataActions';

// Common
import MainLoadingSpinner from '../../common/MainLoadingSpinner';
import { colorPalette as colors } from '../../../theme';

// Material UI
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
	root: {
		width: '50vw',
		margin: '0 auto',
	},
	header: {
		textAlign: 'center',
	},
	lineBreak: {
		color: '#616161',
	},
	content: {
		margin: '0 auto',
	},
	button: {
		marginTop: '2vh',
		color: '#ffffff',
		backgroundColor: '#32DB60',
	},
	backArrow: {
		color: colors[5],
	},
	p: {
		color: colors[4],
		fontSize: 17,
		marginTop: -5,
		marginBottom: 3,
		padding: '16px',
		fontStyle: 'italic',
	},
}));

export default function Lending() {
	const classes = useStyles();
	const history = useHistory();
	const dispatch = useDispatch();

	const [originators, setOriginators] = useState([]);
	const [selectedOriginator, setSelectedOriginator] = useState();
	const [deals, setDeals] = useState([]);
	const [selectedDeal, setSelectedDeal] = useState();
	const [moveLoading, setMoveLoading] = useState(false);
	const [open, setOpen] = useState(false);

	const { data, status, error } = useLendingDeals();

	const allowedDeals = useSelector((state) => state.data.allowedLendingDeals);

	useEffect(() => {
		if (data) {
			setOriginators(
				data
					.filter((deal) => allowedDeals.includes(deal.id))
					.map((deal) => deal.originator_name)
					.filter((item, index, self) => self.indexOf(item) == index),
			);
			setDeals(data);
		}
	}, [data, allowedDeals]);

	function handleChangeOriginator(event, value) {
		if (value) {
			setSelectedOriginator(value);
		} else {
			setSelectedOriginator();
		}
	}

	function handleChangeDeal(event, value) {
		if (value) {
			setSelectedDeal(value);
		} else {
			setSelectedDeal();
		}
	}

	function handleSubmit() {
		if (selectedDeal) {
			setMoveLoading(true);
			dispatch(fetchFilterOptions(selectedDeal.id)).then(() => {
				dispatch(setDeal(selectedDeal));
				dispatch(setRequestID(''));
				history.push('/overview');
			});
		} else {
			setOpen(true);
		}
	}

	function handleClose(event, reason) {
		if (reason === 'clickaway') {
			return;
		}
		setOpen(false);
	}

	function handleReturn() {
		history.goBack();
	}

	if (status === 'loading') return <MainLoadingSpinner />;
	if (status === 'error') return `Error: ${error.message}`;

	return (
		<Paper className={classes.root}>
			{data && data.length && allowedDeals.length !== 0 ? (
				<Grid container>
					<Grid item xs={12}>
						<h1 className={classes.header}>Karus Lending</h1>
						<hr className={classes.lineBreak} />
						<br />
						<Grid item xs={6} className={classes.content}>
							<Autocomplete
								onChange={handleChangeOriginator}
								options={originators}
								renderInput={(originator) => (
									<TextField {...originator} label={'Originator'} />
								)}
							/>
						</Grid>
						<Grid item xs={6} className={classes.content}>
							<Autocomplete
								disabled={!selectedOriginator}
								onChange={handleChangeDeal}
								options={deals.filter(
									(deal) => deal.originator_name == selectedOriginator,
								)}
								getOptionDisabled={(deal) => !allowedDeals.includes(deal.id)}
								getOptionLabel={(deal) => deal.name}
								renderInput={(deal) => <TextField {...deal} label={'Deal'} />}
								renderOption={(deal) => {
									return (
										<>
											{allowedDeals.includes(deal.id)
												? `${deal.name}`
												: `${deal.name} - Contact Karus for access`}
										</>
									);
								}}
							/>
						</Grid>
					</Grid>
					<Grid item xs={6} className={classes.content}>
						<Button
							variant="contained"
							className={classes.button}
							onClick={handleSubmit}
						>
							Submit
						</Button>
					</Grid>
					<Grid item xs={12}>
						<IconButton onClick={handleReturn}>
							<ArrowBackIcon className={classes.backArrow} />
						</IconButton>
					</Grid>
					<Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
						<Alert onClose={handleClose} severity="error">
							Please Select Deal to Continue
						</Alert>
					</Snackbar>
				</Grid>
			) : (
				<Grid container>
					<Grid item xs={12}>
						<h1 className={classes.header}>Karus Lending</h1>
						<hr className={classes.lineBreak} />
						<br />
					</Grid>
					<Grid item xs={12}>
						<h6 className={classes.p}>
							Karus Lending enables institutions to offer competitive funding on
							retail auto purchases and refinancings. Through our extensive
							dealer network, auto borrowers are sourced and scored using Karus’
							proprietary machine-learning decisioning tool. Karus&apos; AI
							credit models open up financing opportunities for underbanked
							borrowers which have been disqualified by traditional credit
							agencies. For the first time, Karus powered institutions are able
							to participate in a newly democratized lending ecosystem that
							offers unparalleled opportunities for risk-adjusted returns while
							also benefiting dealers and end consumers.
						</h6>
					</Grid>
					<Grid item xs={12}>
						<IconButton onClick={handleReturn}>
							<ArrowBackIcon className={classes.backArrow} />
						</IconButton>
					</Grid>
				</Grid>
			)}
		</Paper>
	);
}
