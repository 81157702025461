import React, { useState, useEffect } from 'react';

import LineChart from './LineChart';
import { chartColorsArray as colors } from '../../theme';
import LoadingSpinner from '../common/LoadingSpinner';
import NoData from '../common/NoData';
import formatters from '../../helpers/formatters';

export default function Delinquencies(props) {
	const { status, data, error, xAxisValue, isFetching } = props;
	const [axisLabels, setAxisLabels] = useState([]);
	const [dataValues, setDataValues] = useState([]);
	// Flattened state for x-axis filter
	const [flattened, setFlattened] = useState();
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		if (data?.map !== undefined) {
			const seriesLabels = [
				'Current',
				'Less than 30 days',
				'30-60 days',
				'60+ days',
			];
			const rawLabels = [...new Set(data.map((d) => d.date))];

			const flattenedData = rawLabels.map((date) => {
				const numCurrent =
					+data.find(
						(f) => f.delinquentCategory === 'Current' && f.date === date,
					)?.delinquenciesCount || 0;
				const numLessThan30 =
					+data.find(
						(f) =>
							f.delinquentCategory === 'Delinquent (less than 30 days)' &&
							f.date === date,
					)?.delinquenciesCount || 0;
				const num30Days =
					+data.find(
						(f) =>
							f.delinquentCategory === 'Delinquent (30-60 days)' &&
							f.date === date,
					)?.delinquenciesCount || 0;
				const num60Days =
					+data.find(
						(f) =>
							f.delinquentCategory === 'Delinquent (60 days+)' &&
							f.date === date,
					)?.delinquenciesCount || 0;
				const total = numLessThan30 + num30Days + num60Days + numCurrent;
				return {
					date,
					pctCurrent: numCurrent / total,
					pctLessThan30: numLessThan30 / total,
					pct30Days: num30Days / total,
					pct60Days: num60Days / total,
				};
			});
			// Flattened state for x-axis filter
			setFlattened(flattenedData);

			const labels = [
				...new Set(
					// flattenedData.map((d) => new Date(d.date).toLocaleDateString()),
					formatters.dataToDateString(flattenedData),
				),
			];

			// build datasets for each series
			const datasets = [];

			datasets.push({
				label: seriesLabels[0],
				borderColor: colors[0],
				fill: false,
				data: flattenedData.map((d) => +d.pctCurrent),
			});

			datasets.push({
				label: seriesLabels[1],
				borderColor: colors[1],
				fill: false,
				data: flattenedData.map((d) => +d.pctLessThan30),
			});

			datasets.push({
				label: seriesLabels[2],
				borderColor: colors[2],
				fill: false,
				data: flattenedData.map((d) => +d.pct30Days),
			});

			datasets.push({
				label: seriesLabels[3],
				borderColor: colors[3],
				fill: false,
				data: flattenedData.map((d) => +d.pct60Days),
			});

			setAxisLabels(labels);
			setDataValues(datasets);
			setLoading(false);
		}
	}, [data]);

	// Props updated to re-render axis labels when parent filter wrapper changes state
	useEffect(() => {
		if (!data || !flattened) return;
		if (props.xAxisValue === 'MDY') {
			const labels = formatters.dataToDateString(flattened);
			setAxisLabels(labels);
		} else if (props.xAxisValue === 'month-index') {
			const labels = formatters.dataToMonthSequence(flattened);
			setAxisLabels(labels);
		}
	}, [xAxisValue, data]);

	useEffect(() => {
		if (status === 'loading' || !data || isFetching) setLoading(true);
		else {
			setLoading(false);
		}
	}, [isFetching, status, data, axisLabels]);

	if (status === 'error') return `Error: ${error.message}`;

	return loading ? (
		<LoadingSpinner />
	) : !data?.length ? (
		<NoData
			chartTitle="Delinquencies Over Time"
			chartTitleTooltip="Percent of accounts at differnent levels of delinquencies."
		/>
	) : (
		<LineChart
			chartTitle="Delinquencies Over Time"
			chartTitleTooltip="Percent of accounts at differnent levels of delinquencies."
			data={dataValues}
			axisLabels={axisLabels}
			xAxisValue={xAxisValue}
			delBig={props.delBig}
		/>
	);
}
