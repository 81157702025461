import React, { useState, cloneElement, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Radio, FormControl, Grid, Paper, TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';

import { useRadioStyles, useApplyGridStyles } from './FilterStyles';
import { useFeatureRisk } from '../../api/query-hooks';
import { colorPalette as colors } from '../../theme';
import formatters from '../../helpers/formatters';

export default function PoolFactorFilters(props) {
	const { status, data, error, refetch } = useFeatureRisk();
	const [selectedData, setSelectedData] = useState();
	const [features, setFeatures] = useState();
	const [label, setLabel] = useState();

	const radioStyle = useRadioStyles();
	const applyGridStle = useApplyGridStyles();

	useEffect(() => {
		refetch();
	}, [useSelector((state) => state.data.deal.id)]);

	useEffect(() => {
		if (data && data.length) {
			const featureNames = [
				...new Set(data.map((d) => formatters.camelToHuman(d.feature))),
			].sort();
			setFeatures(featureNames);
			if (featureNames.includes('Ltv')) {
				setSelectedData(data.filter((d) => d.feature === 'ltv'));
				setLabel('Ltv');
			} else if (
				featureNames.includes('Original Interest Rate Percentage Loc')
			) {
				setSelectedData(
					data.filter((d) => d.feature === 'originalInterestRatePercentageLoc'),
				);
				setLabel('Original Interest Rate Percentage Loc');
			} else if (featureNames.includes('Obligor Credit Score Loc')) {
				setSelectedData(
					data.filter((d) => d.feature === 'obligorCreditScoreLoc'),
				);
				setLabel('Obligor Credit Score Loc');
			} else {
				setSelectedData([data[0]]);
				setLabel(formatters.camelToHuman(data[0].feature));
			}
		}
	}, [data]);

	const handleDataSelection = (event, value) => {
		const updatedData = data.filter(
			(d) => d.feature === formatters.humanToCamel(value),
		);
		setSelectedData(updatedData);
	};

	return (
		<>
			{/* Pass down child component and props */}
			{cloneElement(props.children, {
				status,
				data: selectedData,
				error,
			})}
			{!data?.length ? null : (
				<Paper
					elevation={3}
					style={{
						marginLeft: 10,
						marginRight: 10,
						padding: 10,
						marginTop: 'auto',
						height: 'auto',
						position: 'relative',
						bottom: '0',
					}}
				>
					<Grid container spacing={2} alignItems="top">
						<FormControl
							component="fieldset"
							style={{
								float: 'right',
								overflow: 'visible',
								padding: 10,
								width: '100%',
							}}
						>
							<Autocomplete
								onChange={handleDataSelection}
								onOpen={() => setLabel('Feature')}
								id="Feature Tracking Selector"
								style={{ width: '100%', borderBlockColor: colors[0] }}
								options={features}
								getOptionLabel={(option) => option}
								closeIcon={null}
								renderOption={(option, { selected }) => (
									<React.Fragment>
										<Radio
											classes={{
												root: radioStyle.radio,
												checked: radioStyle.checked,
											}}
											checked={selected}
										/>
										{option}
									</React.Fragment>
								)}
								renderInput={(params) => (
									<TextField
										{...params}
										variant="outlined"
										label={label}
										fullWidth
									/>
								)}
							/>
						</FormControl>
					</Grid>
				</Paper>
			)}
		</>
	);
}
