import React from 'react';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

import Filter from './filter';
import PoolFactorChart from '../graphs/PoolFactorChart';

export default function PoolFactor() {
	return (
		<>
			{/* <Filter /> */}
			<Grid container spacing={2}>
				<Grid item xs={12} lg={6}></Grid>
				<Grid item xs={12} lg={6}>
					<Paper elevation={24}>
						<PoolFactorChart />
					</Paper>
				</Grid>
			</Grid>
		</>
	);
}
