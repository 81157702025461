import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { useGini } from '../../api/query-hooks';
import LineChart from './LineChart';

import LoadingSpinner from '../common/LoadingSpinner';
import { chartColorsArray as colors } from '../../theme';
import NoData from '../common/NoData';

export default function LorenzCurves() {
	const { status, data, error, refetch } = useGini();
	const [axisLabels, setAxisLabels] = useState([]);
	const [dataValues, setDataValues] = useState([]);
	var count = 0;

	useEffect(() => {
		refetch();
	}, [useSelector((state) => state.data.deal.id)]);

	useEffect(() => {
		if (data?.map) {
			// get array of unique values for series (source)
			const seriesLabels = [...new Set(data.map((d) => d.source))];
			data.sort((a, b) => parseFloat(a.bin) - parseFloat(b.bin));
			// get array of unique, ASC sorted x-axis values (bin)
			const labels = [
				...new Set(
					data
						.map((d) => +d.bin)
						.sort((a, b) => parseFloat(a.bin) - parseFloat(b.bin)),
				),
			];

			// build datasets for each series (bin, percentChargeoffs)
			const datasets = [];
			seriesLabels.forEach((label, i) => {
				datasets.push({
					label,
					borderColor: colors[i],
					fill: false,
					data: data
						.filter((f) => f.source === label)
						.map((d) => +d.percentChargeOffs),
				});
			});

			setAxisLabels(labels);
			setDataValues(datasets);
		}
	}, [data]);

	if (status === 'loading' || !data) return <LoadingSpinner />;
	if (status === 'error') return `Error: ${error.message}`;

	return !dataValues?.length ? (
		<NoData
			chartTitle="Lorenz Curves"
			chartTitleTooltip="The lorenz curves compare Karus ML model performance vs. credit score and interest rate with respect to predicting charged-off amounts. Karus ML models consistently outperform traditional underwriting methods across prime, near-prime, and subprime markets."
		/>
	) : (
		<LineChart
			chartTitle="Lorenz Curves"
			chartTitleTooltip="The lorenz curves compare Karus ML model performance vs. credit score and interest rate with respect to predicting charged-off amounts. Karus ML models consistently outperform traditional underwriting methods across prime, near-prime, and subprime markets."
			data={dataValues}
			axisLabels={axisLabels}
			chartContainerOverrides="screen-expand-chart"
		/>
	);
}
