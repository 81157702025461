import React, { useState } from 'react';
import AxiosClient from '../../../api/axios-client';
import { useOrganizations } from '../../../api/query-hooks';

// Material UI
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import PersonIcon from '@material-ui/icons/Person';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
	list: {
		backgroundColor: '#666666',
		marginTop: '16px',
		marginBottom: '16px',
		padding: '16px',
		height: 300,
		overflow: 'auto',
	},
	listItem: {
		border: '1px solid #E1E1E1',
		borderRadius: '6px',
		padding: '16px',
		marginBottom: '4px',
	},
});

export default function AddUsers() {
	const [users, setUsers] = useState([]);
	const [currentUser, setCurrentUser] = useState({
		name: '',
		email: '',
		isAdmin: false,
		organizationId: '',
	});
	const { data } = useOrganizations();
	const classes = useStyles();

	// Change name, email, organizationId
	function handleChange(event) {
		setCurrentUser({
			...currentUser,
			[event.target.name]: event.target.value,
		});
	}

	// Change isAdmin
	function handleChangeBoolean(event) {
		setCurrentUser({
			...currentUser,
			isAdmin: event.target.checked,
		});
	}

	function handleAdd() {
		if (
			!users.some((e) => e.email == currentUser.email) &&
			currentUser.name !== '' &&
			currentUser.email !== '' &&
			currentUser.organizationId !== ''
		) {
			setUsers((users) => [...users, currentUser]);
			setCurrentUser({
				name: '',
				email: '',
				isAdmin: false,
				organizationId: '',
			});
		}
	}

	function handleRemove(email) {
		setUsers(users.filter((user) => user.email !== email));
	}

	function handleSubmit() {
		AxiosClient.post('/organization/create-users', {
			users: users,
		});

		setUsers([]);
	}

	return (
		<div style={{ width: '50vw', margin: '0 auto' }}>
			<br />
			<h1>Add Users</h1>
			<br />
			<hr style={{ color: '#616161' }} />
			<br />
			<Paper elevation={24} style={{ textAlign: 'center' }}>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<List classes={{ root: classes.list }}>
							{users.map((user) => (
								<ListItem key={user.email} classes={{ root: classes.listItem }}>
									<PersonIcon style={{ marginRight: '8px' }} />
									<div>
										{user.name} ({user.email})
									</div>
									<ListItemSecondaryAction>
										<IconButton
											onClick={() => handleRemove(user.email)}
											edge="end"
										>
											<CloseIcon />
										</IconButton>
									</ListItemSecondaryAction>
								</ListItem>
							))}
						</List>
					</Grid>
					<Grid item xs={6}>
						<FormGroup>
							{data && data.length ? (
								<FormControl>
									<InputLabel>Organization</InputLabel>
									<Select
										name="organizationId"
										value={currentUser.organizationId}
										onChange={handleChange}
									>
										{data.map((organization, index) => (
											<MenuItem key={index} value={organization.id}>
												{organization.name}
											</MenuItem>
										))}
									</Select>
								</FormControl>
							) : (
								<span>Could not load organization data.</span>
							)}
							<TextField
								name="name"
								value={currentUser.name}
								onChange={handleChange}
								label="Name"
								fullwidth
							/>
							<TextField
								name="email"
								value={currentUser.email}
								onChange={handleChange}
								label="Email"
								fullwidth
							/>
						</FormGroup>
						<Button
							variant="contained"
							style={{
								marginTop: '2vh',
								color: '#ffffff',
								backgroundColor: '#32DB60',
							}}
							onClick={handleAdd}
						>
							Add User
						</Button>
					</Grid>
					<Grid item xs={6}>
						{users.length != 0 ? (
							<Button
								variant="contained"
								style={{
									marginTop: '2vh',
									color: '#ffffff',
									backgroundColor: '#32DB60',
								}}
								onClick={handleSubmit}
							>
								Submit Users
							</Button>
						) : (
							<Button
								variant="contained"
								style={{
									marginTop: '2vh',
									color: '#ffffff',
								}}
								disabled
							>
								Submit Users
							</Button>
						)}
					</Grid>
				</Grid>
			</Paper>
		</div>
	);
}
