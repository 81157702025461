import React, { useState, cloneElement } from 'react';
import {
	FormControl,
	FormLabel,
	FormControlLabel,
	Grid,
	Paper,
	Radio,
	RadioGroup,
	Slider,
	Typography,
} from '@material-ui/core';

import { useRadioStyles, useLabelStyles } from './FilterStyles';
import { usePoolFactor } from '../../api/query-hooks';

import { colorPalette as colors } from '../../theme';

export default function PoolFactorFilters(props) {
	const [xAxisValue, setXAxisValue] = useState('MDY');
	const radioStyle = useRadioStyles();
	const labelStyle = useLabelStyles();
	const [creditSliderValue, setCreditSliderValue] = useState([0, 850]);
	const { status, data, error, isFetching, refetch } = usePoolFactor();

	const handleXAxisValueChange = (event) => {
		setXAxisValue(event.target.value);
	};
	const handleCreditSliderChange = (event, newValue) => {
		setCreditSliderValue(newValue);
	};
	return (
		<>
			{/* Pass down child component and props */}
			{cloneElement(props.children, {
				xAxisValue,
				status,
				data,
				error,
				isFetching,
				refetch,
			})}
			{props.hideFilters || !data?.length ? null : (
				<Grid
					container
					spacing={2}
					alignItems="left"
					justify="top"
					style={{
						bottom: 0,
						left: 0,
						alignSelf: 'flex-end',
						marginTop: 'auto',
					}}
				>
					<Grid item style={{ marginBottom: 20 }}>
						<Paper
							elevation={3}
							style={{ margin: 10, padding: 10, height: '100%' }}
						>
							<FormControl component="fieldset">
								<FormLabel
									focused={false}
									component="legend"
									classes={{ root: labelStyle.label }}
									style={{
										color: '#fff',
										fontSize: '1rem',
									}}
								>
									X-Axis:{' '}
								</FormLabel>
								<RadioGroup
									aria-label="amount-paid-x-axis"
									name="amount-paid-x-axis"
									value={xAxisValue}
									onChange={handleXAxisValueChange}
								>
									<FormControlLabel
										value="MDY"
										classes={{ label: labelStyle.label }}
										control={
											<Radio
												classes={{
													root: radioStyle.radio,
													checked: radioStyle.checked,
												}}
											/>
										}
										label={
											<Typography
												style={{ fontSize: '1rem', color: colors[4] }}
											>
												Date
											</Typography>
										}
									/>
									<FormControlLabel
										value="month-index"
										classes={{ label: labelStyle.label }}
										control={
											<Radio
												classes={{
													root: radioStyle.radio,
													checked: radioStyle.checked,
												}}
											/>
										}
										label={
											<Typography
												style={{ fontSize: '1rem', color: colors[4] }}
											>
												Month Index
											</Typography>
										}
									/>
								</RadioGroup>
							</FormControl>
						</Paper>
					</Grid>
				</Grid>
			)}
		</>
	);
}
