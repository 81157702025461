import React, { useEffect, useState, cloneElement } from 'react';
import { useSelector } from 'react-redux';
import {
	Button,
	Checkbox,
	Divider,
	FormControl,
	FormLabel,
	FormControlLabel,
	Grid,
	Paper,
	Radio,
	RadioGroup,
	TextField,
	Typography,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

import {
	useRadioStyles,
	useLabelStyles,
	useButtonStyles,
	useLayoutStyles,
	CreditScoreSlider,
	InterestRateSlider,
	useApplyGridStyles,
} from './FilterStyles';

import { usePrincipalAndInterest } from '../../api/query-hooks';
import collections from '../../helpers/collections';
import { colorPalette as colors } from '../../theme';
import {
	filterModelsByManufacturers,
	filterYearsByModels,
} from '../../helpers/helpers';
import { makeStyles } from '@material-ui/core/styles';

export default function PrincipalAndInterestFilters(
	props,
	hideFilters = false,
) {
	const useStyles = makeStyles((theme) => ({
		container: {
			display: 'Grid',
			GridTemplateColumns: 'repeat(12, 1fr)',
			GridGap: theme.spacing(3),
		},
		paper: {
			padding: theme.spacing(1),
			textAlign: 'center',
			color: theme.palette.text.secondary,
			whiteSpace: 'nowrap',
			marginBottom: theme.spacing(1),
		},
		divider: {
			margin: theme.spacing(2, 0),
		},
	}));
	const classes = useStyles();
	const [queryFilters, setQueryFilters] = useState({
		minCreditScore: null,
		maxCreditScore: null,
		minInterestRate: null,
		maxInterestRate: null,
		states: null,
		vehicleManufacturers: null,
		vehicleModels: null,
		vehicleYears: null,
	});
	const { status, data, error, refetch, isFetching } =
		usePrincipalAndInterest(queryFilters);
	const { obligorGeographicLocations, vehicleOptions } = useSelector(
		(state) => state.data.filterOptions,
	);

	const [xAxisValue, setXAxisValue] = useState('MDY');
	const [creditValues, setCreditValues] = useState([0, 850]);
	const [interestValues, setInterestValues] = useState([0.0, 0.35]);

	// Vehicle autocomplete options change depending on selected parents
	const vehicleManufacturer = Object.keys(vehicleOptions);
	const [vehicleModel, setVehicleModel] = useState([]);
	const [vehicleYear, setVehicleYear] = useState([]);

	// Selected models/years are cleared on parent change for frontend data integrity
	const [selectedModels, setSelectedModels] = useState([]);
	const [selectedYears, setSelectedYears] = useState([]);

	const radioStyle = useRadioStyles();
	const labelStyle = useLabelStyles();
	const layoutStyle = useLayoutStyles();
	const applyGridStle = useApplyGridStyles();

	useEffect(() => {
		refetch();
	}, [useSelector((state) => state.data.deal.id)]);

	useEffect(() => {
		filterModelsByManufacturers(
			queryFilters.vehicleManufacturers,
			vehicleOptions,
			setVehicleModel,
		);
	}, [queryFilters.vehicleManufacturers]);

	useEffect(() => {
		filterYearsByModels(
			queryFilters.vehicleModels,
			queryFilters.vehicleManufacturers,
			vehicleOptions,
			setVehicleYear,
		);
	}, [queryFilters.vehicleModels]);

	const handleXAxisValueChange = (event) => {
		setXAxisValue(event.target.value);
	};

	const handleCreditSliderChange = (event, newValue) => {
		const [min, max] = newValue;
		setCreditValues(newValue);
		setQueryFilters((prevState) => ({
			...prevState,
			minCreditScore: min,
			maxCreditScore: max,
		}));
	};

	const handleInterestSliderChange = (event, newValue) => {
		const [min, max] = newValue;
		setInterestValues(newValue);
		setQueryFilters((prevState) => ({
			...prevState,
			minInterestRate: min,
			maxInterestRate: max,
		}));
	};

	const handleManufacturerSelection = (event, values) => {
		setQueryFilters((prevState) => ({
			...prevState,
			vehicleManufacturers: values,
			vehicleModels: null, // Clear children query filter values
			vehicleYears: null,
		}));
		setSelectedModels([]); // Clear children autocomplete UI values
		setSelectedYears([]);
	};

	const handleModelSelection = (event, values) => {
		setQueryFilters((prevState) => ({
			...prevState,
			vehicleModels: values,
			vehicleYears: null, // Clear children query filter values
		}));
		setSelectedModels(values); // Set selected autocomplete UI values
		setSelectedYears([]); // Clear children autocomplete UI values
	};

	const handleYearSelection = (event, values) => {
		setQueryFilters((prevState) => ({
			...prevState,
			vehicleYears: values,
		}));
		setSelectedYears(values); // Set selected autocomplete UI values
	};

	const handleStateCheck = (event, values) => {
		setQueryFilters((prevState) => ({
			...prevState,
			states: values,
		}));
	};

	const handleClickApply = async () => await refetch();

	const handleHoverApply = useButtonStyles();

	return (
		<>
			{/* Pass down child component and props */}
			{cloneElement(props.children, {
				data,
				status,
				error,
				xAxisValue,
				isFetching,
			})}
			{!props.hideFilters || !data?.length ? null : (
				<Grid
					container
					direction="row"
					justify="space-evenly"
					alignItems="stretch"
					style={{ alignSelf: 'flex-end', marginTop: 'auto' }}
				>
					<Grid
						item
						md={3}
						style={{
							marginTop: 10,
							marginBottom: 10,
						}}
					>
						{/**Chart data and x axis start----------------------------------------------------------------------- */}
						<Paper elevation={12} style={{ height: '100%' }}>
							<Grid container direction="column" spacing>
								<Grid item>
									<FormControl component="fieldset">
										<FormLabel
											component="legend"
											focused={false}
											classes={{ root: labelStyle.label }}
											style={{
												color: '#fff',
												fontSize: '1rem',
											}}
										>
											X-Axis:{' '}
										</FormLabel>
										<RadioGroup
											aria-label="amount-paid-x-axis"
											name="amount-paid-x-axis"
											value={xAxisValue}
											onChange={handleXAxisValueChange}
										>
											<FormControlLabel
												value="MDY"
												classes={{ label: labelStyle.label }}
												control={
													<Radio
														classes={{
															root: radioStyle.radio,
															checked: radioStyle.checked,
														}}
													/>
												}
												label={
													<Typography
														style={{ fontSize: '1rem', color: colors[4] }}
													>
														Date
													</Typography>
												}
											/>
											<FormControlLabel
												value="month-index"
												classes={{ label: labelStyle.label }}
												control={
													<Radio
														classes={{
															root: radioStyle.radio,
															checked: radioStyle.checked,
														}}
													/>
												}
												label={
													<Typography
														style={{ fontSize: '1rem', color: colors[4] }}
													>
														Month Index
													</Typography>
												}
											/>
										</RadioGroup>
									</FormControl>
								</Grid>
							</Grid>
						</Paper>
						{/**Chart data and x axis end----------------------------------------------------------------------- */}
					</Grid>
					<Grid item md={9}>
						<Paper
							elevation={12}
							style={{
								margin: 10,
								padding: 10,
							}}
						>
							<Grid
								container
								justifyContent="space-between"
								alignItems="flex-end"
								alignContent="row"
								className={applyGridStle.root}
							>
								<Grid item>
									<Grid container justify="center" spacing={2}>
										<Grid item>
											{/**Vehicle Filter Selector start----------------------------------------------------------------------- */}
											<div>
												<FormLabel
													focused={false}
													component="legend"
													style={{ color: '#fff' }}
													className="FormLabelState"
												>
													Vehicle Filter Selector
												</FormLabel>
												<Grid>
													<Grid item>
														<div style={{ paddingTop: 10, width: 234 }}>
															<Autocomplete
																onChange={handleManufacturerSelection}
																multiple="true"
																id="carManufacturer"
																options={vehicleManufacturer}
																renderInput={(params) => (
																	<TextField
																		{...params}
																		variant="outlined"
																		label="Manufacturer"
																	/>
																)}
															/>
														</div>
													</Grid>
													<Grid item>
														<div style={{ paddingTop: 10, width: 234 }}>
															<Autocomplete
																onChange={handleModelSelection}
																multiple="true"
																id="carMake"
																options={vehicleModel} //changes on carManufacturer selection
																disabled={
																	!queryFilters.vehicleManufacturers ||
																	!queryFilters.vehicleManufacturers.length
																		? true
																		: false
																}
																renderInput={(params) => (
																	<TextField
																		{...params}
																		variant="outlined"
																		label="Model"
																	/>
																)}
															/>
														</div>
													</Grid>
													<Grid item>
														<div style={{ paddingTop: 10, width: 234 }}>
															<Autocomplete
																onChange={handleYearSelection}
																multiple="true"
																id="carYears"
																options={vehicleYear} //changes on carMake selection
																disabled={
																	!queryFilters.vehicleModels ||
																	!queryFilters.vehicleModels.length
																		? true
																		: false
																}
																renderInput={(params) => (
																	<TextField
																		{...params}
																		variant="outlined"
																		label="Year"
																	/>
																)}
															/>
														</div>
													</Grid>
												</Grid>
											</div>
											{/**Vehicle Filter Selector end----------------------------------------------------------------------- */}
										</Grid>
										<Grid item className={classes.root}>
											<Grid container direction="column" justify="center">
												<Grid item>
													{/**geographic filter start----------------------------------------------------------------------- */}
													<FormControl
														component="fieldset"
														style={{
															float: 'right',
															overflow: 'auto',
															padding: 10,
														}}
													>
														<FormLabel
															focused={false}
															component="legend"
															style={{ color: '#fff' }}
															className="FormLabelState"
														>
															Geographic Location Selector
														</FormLabel>
														<Autocomplete
															onChange={handleStateCheck}
															multiple
															id="Geographic Location Selector"
															style={{
																width: 234,
																borderBlockColor: colors[0],
															}}
															options={obligorGeographicLocations}
															disableCloseOnSelect
															getOptionLabel={(option) =>
																collections.stateNames[option] || option
															}
															renderOption={(option, { selected }) => (
																<React.Fragment>
																	<Checkbox
																		icon={
																			<CheckBoxOutlineBlankIcon fontSize="small" />
																		}
																		checkedIcon={
																			<CheckBoxIcon
																				fontSize="small"
																				style={{ color: colors[0] }}
																			/>
																		}
																		style={{ marginRight: 8 }}
																		checked={selected}
																	/>
																	{collections.stateNames[option] || option}
																</React.Fragment>
															)}
															renderInput={(params) => (
																<TextField
																	{...params}
																	variant="outlined"
																	label="States"
																/>
															)}
														/>
													</FormControl>

													{/**geographic filter end----------------------------------------------------------------------- */}
												</Grid>
												<Grid item>
													{/**slider filter start----------------------------------------------------------------------- */}
													<div
														className="credit-score-container"
														style={{ margin: 10 }}
													>
														<Typography
															style={{ marginBottom: '10' }}
															id="range-slider"
														>
															Credit Score Range
														</Typography>
														<CreditScoreSlider
															max={850}
															min={0}
															step={1}
															value={creditValues}
															onChange={handleCreditSliderChange}
															valueLabelDisplay="auto"
															aria-labelledby="range-slider"
														/>
													</div>
													<div
														className="interest-rate-container"
														style={{ margin: 10 }}
													>
														<Typography
															style={{ marginBottom: '10' }}
															id="range-slider"
														>
															Interest Rate Range
														</Typography>
														<InterestRateSlider
															max={0.35}
															min={0.0}
															step={0.0001}
															value={interestValues}
															onChange={handleInterestSliderChange}
															valueLabelDisplay="auto"
															aria-labelledby="range-slider"
														/>
													</div>
													{/**slider filter end----------------------------------------------------------------------- */}
												</Grid>
											</Grid>
										</Grid>
									</Grid>
								</Grid>
								<Grid item>
									{/**Apply button ----------------------------------------------------------------------- */}
									<Button
										className={handleHoverApply.button}
										variant="outlined"
										style={{
											width: '50px',
											height: '30px',
											float: 'right',
											marginLeft: 30,
										}}
										onClick={handleClickApply}
									>
										Apply
									</Button>
								</Grid>
							</Grid>
						</Paper>
					</Grid>
				</Grid>
			)}
		</>
	);
}
