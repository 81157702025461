import React from 'react';

import { ThemeProvider } from '@material-ui/core';
import { createMuiTheme } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles((theme) => ({
	circle: {
		width: '50px',
		height: '50px',
		display: 'flex',
		'& > * + *': {
			marginLeft: theme.spacing(2),
		},
	},
}));

const theme = createMuiTheme({
	palette: {
		type: 'dark',
		primary: { main: '#32DB60' },
	},
});

const LoadingSpinner = () => {
	const classes = useStyles;

	return (
		<ThemeProvider theme={theme}>
			<Grid container className={classes.circle} justify="center">
				<Grid
					item
					style={{
						paddingBottom: '168px',
						paddingTop: '168px',
					}}
				>
					<CircularProgress />
				</Grid>
			</Grid>
		</ThemeProvider>
	);
};

export default LoadingSpinner;
