import React, { Component, useState } from 'react';
import { connect } from 'react-redux';
import { Route, Switch, NavLink, withRouter } from 'react-router-dom';
import './dashboard.css';

// UI Components
import Header from './header';
import Footer from './footer';

// Page Components
import LandingPage from './landingpage';
import Overview from './Overview';
import Opportunities from './LandingPage/Opportunities';
import ABS from './LandingPage/ABS';
import Lending from './LandingPage/Lending';
import Tranche from './Tranche';
import LoanTape from './LoanTape';
import Performance from './Performance';
import Comparison from './Comparison';
import UnderwritingForm from './UnderwritingForm';
import Backtesting from './Backtesting';
import UnderwritingInsight from './UnderwritingInsight';
import AddOrganization from './Organization/AddOrganization';
import AddUsers from './Organization/AddUsers';
import EditOrganizations from './Organization/EditOrganizations';
import PoolFactor from './PoolFactor';
import Predictions from './Predictions';
import ModelingInsights from './ModelingInsights';
import Inbox from './Inbox';

// Material UI
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';

// Sidebar icons
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

// Sidebar item icons
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import AssessmentIcon from '@material-ui/icons/Assessment';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ShowChartIcon from '@material-ui/icons/ShowChart';
import AssignmentIcon from '@material-ui/icons/Assignment';
import ViewListIcon from '@material-ui/icons/ViewList';
import MultilineChartIcon from '@material-ui/icons/MultilineChart';
import FilterIcon from '@material-ui/icons/Filter';

import ScrollToTop from '../common/ScrollToTop';
import PrivateOpportunity from './LandingPage/PrivateOpportunity';
import ScenarioAnalysis from './ScenarioAnalysis/ScenarioAnalysis';
import NetLoss from './NetLoss';

const drawerWidth = 240;

const styles = (theme) => ({
	root: {
		marginTop: 65, // this is the app bar height
		zIndex: 101,
		display: 'flex',
		textAlign: 'left',
		position: 'fixed',
	},
	hide: {
		display: 'none',
	},
	drawer: {
		flexShrink: 0,
	},
	drawerPaper: {
		overflowX: 'hidden',
		overflowY: 'hidden',
		top: '65px', // this is the app bar height
		position: 'sticky',
		padding: '0',
		whiteSpace: 'nowrap',
		width: drawerWidth,
		textDecoration: 'none',
		color: '#231E1F',
		fontSize: '1.2em',
		backgroundColor: '#3f3f3f',
		transition: theme.transitions.create('width', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
	},
	drawerPaperClose: {
		fontSize: '0',
		padding: '0',
		transition: theme.transitions.create('width', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
		width: theme.spacing(7),
		[theme.breakpoints.up('sm')]: {
			width: theme.spacing(9),
		},
	},
	content: {
		paddingLeft: '90px',
		paddingTop: '105px',
		paddingRight: '18px',
		textAlign: 'left',
		flexGrow: 1,
		backgroundColor: theme.palette.background.default,
	},
});

class Dashboard extends Component {
	constructor(props) {
		super(props);
		this.state = {
			open: false,
			hoverDisable: false,
			headerDealType: '',
		};
		this.handleDrawerOpen = this.handleDrawerOpen.bind(this);
		this.handleDrawerClose = this.handleDrawerClose.bind(this);
		this.handleDrawerToggle = this.handleDrawerToggle.bind(this);
	}

	handleDrawerToggle() {
		this.setState({ open: !this.state.open });
		this.setState({ hoverDisable: !this.state.hoverDisable });
	}

	handleDrawerOpen() {
		this.setState({ open: true });
	}

	handleDrawerClose() {
		this.setState({ open: false });
	}

	render() {
		const { classes } = this.props;
		const landingPagePaths = [
			'/',
			'/opportunities',
			'/abs',
			'/lending',
			'/private',
		];
		const getDealType = (dealType) => {
			this.setState({ headerDealType: dealType });
			console.log(dealType);
		};
		if (
			!this.props.deal &&
			!landingPagePaths.includes(window.location.pathname)
		) {
			this.props.history.push('/');
		}
		if (
			this.state.headerDealType === 'UNSTRUCTURED' &&
			window.location.pathname === '/tranche'
		) {
			this.props.history.push('/overview');
		}
		return (
			<div className="dashboard-container">
				<Header getDealType={getDealType} />
				<div className={classes.root}>
					{/* if statement here */}
					{window.location.pathname !== '/underwriting-form' &&
						!landingPagePaths.includes(window.location.pathname) && (
							<Drawer
								variant="permanent"
								classes={{
									paper: classNames(
										classes.drawerPaper,
										!this.state.open && classes.drawerPaperClose,
									),
								}}
								open={this.state.open}
							>
								<div
									className={classes.toolbar}
									style={{ margin: '12px', padding: '0', marginTop: '45px' }}
								>
									<IconButton onClick={this.handleDrawerToggle}>
										{this.state.open ? (
											<ChevronLeftIcon />
										) : (
											<ChevronRightIcon />
										)}
									</IconButton>
								</div>
								<Divider />
								<List style={{ margin: '12px', padding: '0' }}>
									<Tooltip
										title="Overview"
										disableHoverListener={this.state.hoverDisable}
									>
										<NavLink
											exact
											to="/overview"
											className="nav-link  hover-link"
											activeClassName="active-link"
										>
											<IconButton className="nav-button">
												<AssignmentIcon />{' '}
											</IconButton>
											<span className="nav-text">Overview</span>
										</NavLink>
									</Tooltip>
								</List>
								<Divider />
								{this.props.deal.type === 'UNSTRUCTURED' ? null : (
									<List style={{ margin: '12px', padding: '0' }}>
										<Tooltip
											title="Tranche"
											disableHoverListener={this.state.hoverDisable}
										>
											<NavLink
												exact
												to="/tranche"
												className="nav-link  hover-link"
												activeClassName="active-link"
											>
												<IconButton className="nav-button">
													<AssessmentIcon />{' '}
												</IconButton>
												<span className="nav-text">Tranche</span>
											</NavLink>
										</Tooltip>
									</List>
								)}
								<Divider />
								<List style={{ margin: '12px', padding: '0' }}>
									<Tooltip
										title="Loan Tape"
										disableHoverListener={this.state.hoverDisable}
									>
										<NavLink
											exact
											to="/loan-tape"
											className="nav-link  hover-link"
											activeClassName="active-link"
										>
											<IconButton className="nav-button">
												<ViewListIcon />{' '}
											</IconButton>
											<span className="nav-text">Loan Tape</span>
										</NavLink>
									</Tooltip>
								</List>
								<Divider />
								<List style={{ margin: '12px', padding: '0' }}>
									<Tooltip
										title="Current and Predicted Performance"
										disableHoverListener={this.state.hoverDisable}
									>
										<NavLink
											to="/current-performance"
											className="nav-link  hover-link"
											activeClassName="active-link"
										>
											<IconButton className="nav-button">
												<ShowChartIcon />{' '}
											</IconButton>
											<span className="nav-text">Performance</span>
										</NavLink>
									</Tooltip>
								</List>
								<Divider />
								<List style={{ margin: '12px', padding: '0' }}>
									<Tooltip
										title="Backtesting"
										disableHoverListener={this.state.hoverDisable}
									>
										<NavLink
											to="/Backtesting"
											className="nav-link  hover-link"
											activeClassName="active-link"
										>
											<IconButton className="nav-button">
												<ArrowBackIcon />{' '}
											</IconButton>
											<span className="nav-text">Backtesting</span>
										</NavLink>
									</Tooltip>
								</List>
								<Divider />
								<List style={{ margin: '12px', padding: '0' }}>
									<Tooltip
										title="Modeling Insight"
										disableHoverListener={this.state.hoverDisable}
									>
										<NavLink
											to="/modeling-insights"
											className="nav-link hover-link"
											activeClassName="active-link"
										>
											<IconButton className="nav-button">
												<TrendingUpIcon />{' '}
											</IconButton>
											<span className="nav-text">Modeling Insights</span>
										</NavLink>
									</Tooltip>
								</List>
								<Divider />
								<List style={{ margin: '12px', padding: '0' }}>
									<Tooltip
										title="Underwriting Insight"
										disableHoverListener={this.state.hoverDisable}
									>
										<NavLink
											to="/UnderwritingInsight"
											className="nav-link  hover-link"
											activeClassName="active-link"
										>
											<IconButton className="nav-button">
												<EqualizerIcon />{' '}
											</IconButton>
											<span className="nav-text">Underwriting Insight</span>
										</NavLink>
									</Tooltip>
								</List>
								<Divider />
								{/* TEMPORARILY REMOVE COMPARISON PAGE */}
								{/* <List style={{ margin: '12px', padding: '0' }}>
								<Tooltip title="Comparison">
									<NavLink
										to="/comparison"
										className="nav-link  hover-link"
										activeClassName="active-link"
									>
										<IconButton className="nav-button">
											<CompareArrowsIcon />{' '}
										</IconButton>
										<span className="nav-text">Compare Visualizations</span>
									</NavLink>
								</Tooltip>
							</List>
							<Divider /> */}
								<List style={{ margin: '12px', padding: '0' }}>
									<Tooltip
										title="Net Loss"
										disableHoverListener={this.state.hoverDisable}
									>
										<NavLink
											to="/NetLoss"
											className="nav-link  hover-link"
											activeClassName="active-link"
										>
											<IconButton className="nav-button">
												<FilterIcon />{' '}
											</IconButton>
											<span className="nav-text">Net Loss</span>
										</NavLink>
									</Tooltip>
								</List>
								<Divider />
								{this.props.deal.type === 'STRUCTURED' && (
									<>
										<List style={{ margin: '12px', padding: '0' }}>
											<Tooltip
												title="Scenario Analysis"
												disableHoverListener={this.state.hoverDisable}
											>
												<NavLink
													to="/ScenarioAnalysis"
													className="nav-link  hover-link"
													activeClassName="active-link"
												>
													<IconButton className="nav-button">
														<MultilineChartIcon />{' '}
													</IconButton>
													<span className="nav-text">Scenario Analysis</span>
												</NavLink>
											</Tooltip>
										</List>
										<Divider />
									</>
								)}
								<List
									style={{ margin: '12px', padding: '0', height: '100vw' }}
								></List>
							</Drawer>
						)}
				</div>
				<main className={classes.content}>
					<ScrollToTop />
					<Switch>
						<Route exact path="/" component={LandingPage} />
						<Route path="/overview" component={Overview} />
						<Route path="/opportunities" component={Opportunities} />
						<Route path="/private" component={PrivateOpportunity} />
						<Route path="/abs" component={ABS} />
						<Route path="/lending" component={Lending} />
						<Route path="/dashboard" component={Overview} />
						<Route path="/tranche" component={Tranche} />
						<Route path="/secure-data-upload" component={Inbox} />
						<Route path="/org/add-organization" component={AddOrganization} />
						<Route path="/org/add-users" component={AddUsers} />
						<Route
							path="/org/edit-organizations"
							component={EditOrganizations}
						/>
						<Route path="/loan-tape" component={LoanTape} />
						<Route path="/modeling-insights" component={ModelingInsights} />
						<Route path="/underwriting-form" component={UnderwritingForm} />
						<Route path="/Backtesting" component={Backtesting} />
						<Route
							path="/UnderwritingInsight"
							component={UnderwritingInsight}
						/>
						<Route path="/ScenarioAnalysis" component={ScenarioAnalysis} />
						<Route path="/NetLoss" component={NetLoss} />
						<Route path="/current-performance" component={Performance} />
						<Route path="/pool-factor" component={PoolFactor} />
						<Route path="/predictions" component={Predictions} />
						<Route path="/comparison" component={Comparison} />
					</Switch>
				</main>
				<Footer />
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		deal: state.data.deal,
		filterOptions: state.data.filterOptions,
	};
};

const StyledDashboard = withStyles(styles, { withTheme: true })(Dashboard);

export default withRouter(connect(mapStateToProps)(StyledDashboard));
