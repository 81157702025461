import React, { useEffect, useState, useRef } from 'react';
import {
	Chart,
	CategoryScale,
	BarController,
	BarElement,
	Legend,
	Tooltip as TooltipChartPlugin,
} from 'chart.js';
import PropTypes from 'prop-types';

// tooltip imports
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import HelpIcon from '@material-ui/icons/Help';
Chart.defaults.color = '#f0f0f0';

import '../styles/Chart.scss';
import LegendTooltip from '../common/LegendTooltip';

import { legendGenerator } from '../../helpers/helpers';

export default function BarChart(props) {
	const chartRef = useRef();
	const [chart, setChart] = useState();

	// Separate ref to chart solely for rendering fixed x-axis for scroll
	const xAxisRef = useRef();
	const [xAxis, setXAxis] = useState();

	const createChart = () => {
		Chart.register(
			CategoryScale,
			BarController,
			BarElement,
			Legend,
			TooltipChartPlugin,
		);
		if (props.data && props.axisLabels) {
			setChart(
				new Chart(chartRef.current, {
					type: 'bar',
					data: {
						labels: props.axisLabels,
						datasets: props.data,
					},
					options: {
						responsive: true,
						maintainAspectRatio: false,
						indexAxis: props.vertical ? 'x' : 'y', // Vertical or horizontal
						plugins: {
							legend: {
								display: props.showLegend ? true : false,
								labels: {
									color: '#f0f0f0',
								},
								onHover: function (e, legendItem, legend) {
									e.native.target.style.cursor = 'pointer';
								}.bind(this),
								onLeave: function (e, legendItem, legend) {
									e.native.target.style.cursor = 'default';
								}.bind(this),
							},
							tooltip: {
								callbacks: {
									label: function (context) {
										let label = context.dataset.label || '';
										if (label) label += ': ';
										if (context.parsed.y !== null) {
											label += context.parsed.x.toFixed(2);
										}
										return label;
									},
								},
							},
						},
						scales: {
							x: {
								display: false, // Hide x-axis altogether, but still configure to align with custom x-axis
								grid: {
									drawTicks: true,
									display: true,
								},
								ticks: {
									font: {
										weight: 'bold',
									},
									padding: 10,
									min: 0,
									stepSize: 0.5,
								},
								title: {
									display: true,
									text:
										// Temporary conditional logic for adding axis labels
										props.xAxisValue === 'MDY'
											? 'Date'
											: props.chartTitle.split(' ')[0] === 'Feature'
											? 'Weight'
											: props.xAxisValue === 'month-index'
											? 'Month Index'
											: '',
								},
							},
							y: {
								min: props.barMin,
								grid: {
									drawTicks: true,
									display: true,
								},
								ticks: {
									font: {
										weight: 'bold',
									},
									padding: 10,
								},
								title: {
									display: true,
									text: 'Feature',
								},
							},
						},
					},
				}),
			);
		}
	};

	// Function that creates chart with similar configuration for alignment, but only show x-axis using Sass
	const createXAxis = () => {
		Chart.register(
			CategoryScale,
			BarController,
			BarElement,
			Legend,
			TooltipChartPlugin,
		);
		if (props.data && props.axisLabels) {
			setXAxis(
				new Chart(xAxisRef.current, {
					type: 'bar',
					data: {
						labels: props.axisLabels,
						datasets: props.data,
					},
					options: {
						responsive: true,
						maintainAspectRatio: false,
						indexAxis: props.vertical ? 'x' : 'y', // Vertical or horizontal
						plugins: {
							legend: {
								display: props.showLegend ? true : false,
								labels: {
									color: '#f0f0f0',
								},
								onHover: function (e, legendItem, legend) {
									e.native.target.style.cursor = 'pointer';
								}.bind(this),
								onLeave: function (e, legendItem, legend) {
									e.native.target.style.cursor = 'default';
								}.bind(this),
							},
							tooltip: {
								callbacks: {
									label: function (context) {
										let label = context.dataset.label || '';
										if (label) label += ': ';
										if (context.parsed.y !== null) {
											label += context.parsed.x.toFixed(2);
										}
										return label;
									},
								},
							},
						},
						scales: {
							x: {
								position: 'top',
								grid: {
									drawTicks: true,
									display: true,
								},
								ticks: {
									font: {
										weight: 'bold',
									},
									padding: 10,
									min: 0,
									stepSize: 0.5,
								},
								// Remove x-axis label because it renders above x-axis, not below
							},
							y: {
								min: props.barMin,
								grid: {
									drawTicks: true,
									display: true,
								},
								ticks: {
									font: {
										weight: 'bold',
									},
									padding: 10,
								},
								title: {
									display: true,
									text: 'Feature',
								},
							},
						},
					},
				}),
			);
		}
	};

	useEffect(() => {
		if (chart) chart.destroy();
		createChart();
		if (xAxis) xAxis.destroy();
		createXAxis();
	}, [props.data, props.axisLabels]);
	return (
		<>
			{' '}
			{props.hideTop ? null : (
				<Typography
					style={{
						fontSize: '1.7em',
						fontWeight: 'bold',
						marginBottom: 12,
						position: 'relative',
					}}
				>
					{props.chartTitle}
				</Typography>
			)}
			{legendGenerator(props.legendInfo)}
			<div>
				<div className="feature-weight-chart-wrapper">
					<div className="feature-weight-chart-area-wrapper">
						<canvas ref={chartRef} />
					</div>
				</div>
				<div className="feature-weight-x-axis-wrapper">
					<div className="feature-weight-x-axis-area-wrapper">
						<canvas ref={xAxisRef} />
					</div>
				</div>
				{/* Custom x-axis label */}
				<div className="feature-weight-x-axis-label-wrapper">
					<Typography align="center" style={{ fontSize: '0.85em' }}>
						Weight
					</Typography>
				</div>
			</div>
		</>
	);
}

BarChart.propTypes = {
	labels: PropTypes.array,
};
