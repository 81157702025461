import React, { useState, useEffect } from 'react';

import PieChart from './PieChart';
import { chartColors as colors } from '../../theme';

export default function PredictedOutcomes() {
	const axisLabels = ['Solvent', 'Default'];
	const dataValues = [
		{
			data: [0.9842, 0.0158],
			backgroundColor: [colors[0], colors[1]],
		},
	];

	return (
		<PieChart
			chartTitle="Predicted Outcomes"
			chartTitleTooltip="Predicted default vs. solvent loans using Karus ML."
			data={dataValues}
			axisLabels={axisLabels}
		/>
	);
}
