// React
import React, { useEffect, useState, useRef } from 'react';

// Chart.js
import {
	Chart,
	CategoryScale,
	BarController,
	BarElement,
	LineController,
	LineElement,
	PointElement,
	Tooltip as TooltipChartPlugin,
} from 'chart.js';

// Material-UI
import {
	Typography,
	Tooltip,
	IconButton,
	makeStyles,
	createStyles,
} from '@material-ui/core';
import HelpIcon from '@material-ui/icons/Help';

// Stylesheets
import '../styles/Chart.scss';

Chart.defaults.color = '#f0f0f0';

export default function FeatureTrackingChart(props) {
	const chartRef = useRef();
	const [chart, setChart] = useState();
	const classes = useStyles();

	const createChart = () => {
		Chart.register(
			CategoryScale,
			BarController,
			BarElement,
			LineController,
			LineElement,
			PointElement,
			TooltipChartPlugin,
		);

		if (props.data && props.axisLabels) {
			setChart(
				new Chart(chartRef.current, {
					type: 'bar',
					data: {
						labels: props.axisLabels,
						datasets: props.dataSets,
					},
					options: {
						responsive: true,
						maintainAspectRatio: false,
						elements: {
							point: {
								radius: 0,
								hitRadius: 5,
								borderWidth: 0,
								hoverBorderWidth: 0,
								backgroundColor: 'rgba(0, 0, 0, 0.0)',
								borderColor: 'rgba(0, 0, 0, 0.0)',
							},
						},
						plugins: {
							legend: {
								position: 'top',
								display: true,
								labels: {
									color: '#f0f0f0',
								},
								onHover: function (e, legendItem, legend) {
									e.native.target.style.cursor = 'pointer';
								}.bind(this),
								onLeave: function (e, legendItem, legend) {
									e.native.target.style.cursor = 'default';
								}.bind(this),
							},
						},
						scales: {
							x: {
								axis: 'x',
								grid: {
									drawTicks: true,
									display: true,
									zeroLineColor: '#e1e1e1',
									offsetGridLines: true,
								},
								ticks: {
									color: '#e1e1e1',
									font: {
										weight: 'bold',
									},
									beginAtZero: true,
									padding: 10,
									maxTicksLimit: 20,
								},
								options: {
									stacked: true,
									reverse: true,
								},
								title: {
									display: true,
									text: 'Range',
								},
							},
							leftAxis: {
								position: 'left',
								grid: {
									drawTicks: true,
									display: true,
									zeroLineColor: '#e1e1e1',
								},
								display: true,
								ticks: {
									font: {
										weight: 'bold',
									},
									color: '#e1e1e1',
									beginAtZero: true,
									padding: 10,
								},
								title: {
									display: true,
									text: 'Mean Target',
								},
							},
							rightAxis: {
								position: 'right',
								min: 0,
								grid: {
									drawTicks: false,
									display: false,
								},
								ticks: {
									font: {
										weight: 'bold',
									},
									color: '#e1e1e1',
									beginAtZero: true,
									padding: 10,
									crossAlign: 'near',
								},
								title: {
									display: true,
									text: 'Loans Per Bin',
								},
							},
						},
					},
				}),
			);
		}
	};

	useEffect(() => {
		if (chart) chart.destroy();
		createChart();
	}, [props.data, props.axisLabels]);

	return (
		<>
			<Typography className={classes.title}>
				{props.chartTitle}
				<Tooltip
					placement="right"
					title={
						<Typography className={classes.tooltip}>
							{props.chartTitleTooltip}
						</Typography>
					}
				>
					<IconButton>
						<HelpIcon fontSize="small" />
					</IconButton>
				</Tooltip>
			</Typography>
			<div
				style={{ position: 'relative' }}
				className={
					props.chartContainerOverrides
						? props.chartContainerOverrides
						: 'chart-container'
				}
			>
				<canvas ref={chartRef} />
			</div>
		</>
	);
}

const useStyles = makeStyles((theme) =>
	createStyles({
		title: {
			fontSize: '1.7em',
			fontWeight: 'bold',
			marginBottom: 12,
			position: 'relative',
		},
		tooltip: {
			fontSize: '1.4em',
		},
	}),
);
