import React, { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

import Filter from './filter';
import Delinquencies from '../graphs/Delinquencies';
import PoolFactorChart from '../graphs/PoolFactorChart';
import LoanOutcomes from '../graphs/LoanOutcomes';
import PrincipalAndInterest from '../graphs/PrincipalAndInterest';
import AmountPaid from '../graphs/AmountPaid';
import AmountLost from '../graphs/AmountLost';
import Button from '@material-ui/core/Button';

import ToggleButton from '@material-ui/lab/ToggleButton';
import {
	AmountPaidFilters,
	AmountLostFilters,
	PoolFactorFilters,
	PrincipalAndInterestFilters,
	LoanOutcomesFilters,
	DelinquenciesFilters,
} from '../filters';
import CumulativeNetLossFilters from '../filters/CumulativeNetLossFilters';
import CumulativeNetLossChart from '../graphs/CumulativeNetLossChart';
import { useButtonStyles } from '../filters/FilterStyles';
import DelinquencyStatus from '../graphs/DelinquencyStatus';
export default function Performance() {
	const handleHoverApply = useButtonStyles();
	const [ope, setOpe] = useState(100);
	// Delinquencies chart should be hidden if the deal is unstructured.
	const [dealType, setDealType] = useState(
		useSelector((state) => state.data.deal.type),
	);
	const [amountBig, setAmountBig] = useState('');

	const [amountPaidSize, setAmountPaidSize] = useState(6);
	const [amountPaidHidden, setAmountPaidHidden] = useState(true);
	const amountPaidHandle = () => {
		amountPaidSize === 6 ? setAmountPaidSize(12) : setAmountPaidSize(6);
		amountPaidHidden === false
			? setAmountPaidHidden(true)
			: setAmountPaidHidden(false);
		amountBig === '' ? setAmountBig(600) : setAmountBig('');
	};
	const [lostBig, setLostBig] = useState('');
	const [amountLostSize, setAmountLostSize] = useState(6);
	const [amountLostHidden, setAmountLostHidden] = useState(true);
	const amountLostHandle = () => {
		amountLostSize === 6 ? setAmountLostSize(12) : setAmountLostSize(6);
		amountLostHidden === false
			? setAmountLostHidden(true)
			: setAmountLostHidden(false);
		lostBig === '' ? setLostBig(600) : setLostBig('');
	};
	const [poolBig, setPoolBig] = useState('');
	const [poolSize, setPoolSize] = useState(6);
	const [poolSizeHidden, setPoolHiddden] = useState(false);
	const poolHandle = () => {
		poolSize === 6 ? setPoolSize(12) : setPoolSize(6);
		poolSizeHidden === false ? setPoolHiddden(true) : setPoolHiddden(false);
		poolBig === '' ? setPoolBig(600) : setPoolBig('');
	};
	const [netBig, setNetBig] = useState('');
	const [cumulativeNetLossSize, setCumulativeNetLossSize] = useState(6);
	const [cumulativeNetLossHidden, setCumulativeNetLossHidden] = useState(false);
	const CumulativeNetLossHandle = () => {
		cumulativeNetLossSize === 6
			? setCumulativeNetLossSize(12)
			: setCumulativeNetLossSize(6);
		cumulativeNetLossHidden === false
			? setCumulativeNetLossHidden(true)
			: setCumulativeNetLossHidden(false);
		netBig === '' ? setNetBig(600) : setNetBig('');
	};
	const [paiBig, setPaiBig] = useState('');
	const [principalSize, setPrincipalSize] = useState(6);
	const [principalHidden, setPrincipalHidden] = useState(true);
	const PrincipalHandle = () => {
		principalSize === 6 ? setPrincipalSize(12) : setPrincipalSize(6);
		principalHidden === false
			? setPrincipalHidden(true)
			: setPrincipalHidden(false);
		paiBig === '' ? setPaiBig(600) : setPaiBig('');
	};
	const [delBig, setDelBig] = useState('');
	const [delinquenciesSize, setDelinquenciesSize] = useState(6);
	const [delinquenciesHidden, setDelinquenciesHidden] = useState(true);
	const delinquenciesHandle = () => {
		delinquenciesSize === 6
			? setDelinquenciesSize(12)
			: setDelinquenciesSize(6);
		delinquenciesHidden === false
			? setDelinquenciesHidden(true)
			: setDelinquenciesHidden(false);

		delBig === '' ? setDelBig(600) : setDelBig('');
	};

	const size = 400;
	const [loanBig, setLoanBig] = useState(size);
	const [loanSize, setLoanSize] = useState(6);
	const [loanHidden, setLoanHidden] = useState(true);
	const loanHandle = () => {
		loanSize === 6 ? setLoanSize(12) : setLoanSize(6);
		loanHidden === false ? setLoanHidden(true) : setLoanHidden(false);

		loanBig === size ? setLoanBig(600) : setLoanBig(size);
	};
	useEffect(() => {
		amountLostSize === 6 ? window.scrollBy(0, -900) : window.scrollBy(0, 790);
	}, [amountLostSize]);
	useEffect(() => {
		cumulativeNetLossSize === 6
			? window.scrollBy(0, -649)
			: window.scrollBy(0, 649);
	}, [cumulativeNetLossSize]);
	useEffect(() => {
		loanSize === 6 ? window.scrollBy(0, 0) : window.scrollBy(0, 822.5);
		/*-------------------------------
		change true scroll by to:
			window.scrollBy(0, -822.5) 
		when Delinquencies is added back
		-------------------------------*/
	}, [loanSize]);
	return (
		<>
			{/* <Filter /> */}
			<Grid container spacing={4}>
				<Grid item xs={12} md={amountPaidSize}>
					<Paper
						elevation={24}
						style={{
							height: '100%',
							display: 'flex',
							flexDirection: 'column',
						}}
					>
						<div style={{ float: 'right' }}>
							<ToggleButton
								className={handleHoverApply.button}
								selected={true}
								variant="outlined"
								aria-label
								style={{
									width: '90px',
									height: '30px',
									float: 'right',
									marginLeft: 15,
									marginBottom: -60,
									marginTop: 5,
									zIndex: 5,
								}}
								onClick={amountPaidHandle}
							>
								{amountPaidSize === 6 ? 'Enlarge' : 'Shrink'}
							</ToggleButton>
						</div>
						{/*trying to pass height to pareto chart, adjust height of the document there*/}
						<AmountPaidFilters hideFilters={amountPaidHidden}>
							<AmountPaid yeeHaw={amountBig} />
						</AmountPaidFilters>
					</Paper>
				</Grid>
				<Grid item xs={12} md={amountLostSize}>
					<Paper
						elevation={24}
						style={{
							height: '100%',
							display: 'flex',
							flexDirection: 'column',
						}}
					>
						<div style={{ float: 'right' }}>
							<ToggleButton
								className={handleHoverApply.button}
								variant="outlined"
								selected={true}
								style={{
									width: '90px',
									height: '30px',
									float: 'right',
									marginLeft: 15,
									marginBottom: -60,
									marginTop: 5,
									zIndex: 5,
								}}
								onClick={amountLostHandle}
							>
								{amountLostSize === 6 ? 'Enlarge' : 'Shrink'}
							</ToggleButton>
						</div>
						<AmountLostFilters hideFilters={amountLostHidden}>
							<AmountLost yeeHaw={lostBig} />
						</AmountLostFilters>
					</Paper>
				</Grid>
				<Grid item xs={12} md={poolSize}>
					<Paper
						elevation={24}
						style={{
							height: '100%',
							display: 'flex',
							flexDirection: 'column',
						}}
					>
						<div style={{ float: 'right' }}>
							<ToggleButton
								className={handleHoverApply.button}
								variant="outlined"
								selected={true}
								style={{
									width: '90px',
									height: '30px',
									float: 'right',
									marginLeft: 15,
									marginBottom: -60,
									marginTop: 5,
									zIndex: 5,
								}}
								onClick={poolHandle}
							>
								{poolSize === 6 ? 'Enlarge' : 'Shrink'}
							</ToggleButton>
						</div>

						<PoolFactorFilters hideFilters={poolSizeHidden}>
							<PoolFactorChart poolBig={poolBig} />
						</PoolFactorFilters>
					</Paper>
				</Grid>
				<Grid item xs={12} md={cumulativeNetLossSize}>
					<Paper
						elevation={24}
						style={{
							height: '100%',
							display: 'flex',
							flexDirection: 'column',
						}}
					>
						<div style={{ float: 'right' }}>
							<ToggleButton
								className={handleHoverApply.button}
								variant="outlined"
								selected={true}
								style={{
									width: '90px',
									height: '30px',
									float: 'right',
									marginLeft: 15,
									marginBottom: -60,
									marginTop: 5,
									zIndex: 5,
								}}
								onClick={CumulativeNetLossHandle}
							>
								{cumulativeNetLossSize === 6 ? 'Enlarge' : 'Shrink'}
							</ToggleButton>
						</div>

						<CumulativeNetLossFilters hideFilters={cumulativeNetLossHidden}>
							<CumulativeNetLossChart netBig={netBig} />
						</CumulativeNetLossFilters>
					</Paper>
				</Grid>
				<Grid item xs={12} md={principalSize}>
					<Paper
						elevation={24}
						style={{
							height: '100%',
							display: 'flex',
							flexDirection: 'column',
						}}
					>
						<div style={{ float: 'right' }}>
							<ToggleButton
								className={handleHoverApply.button}
								variant="outlined"
								selected={true}
								style={{
									width: '90px',
									height: '30px',
									float: 'right',
									marginLeft: 15,
									marginBottom: -60,
									marginTop: 5,
									zIndex: 5,
								}}
								onClick={PrincipalHandle}
							>
								{principalSize === 6 ? 'Enlarge' : 'Shrink'}
							</ToggleButton>
						</div>

						<PrincipalAndInterestFilters hideFilters={principalHidden}>
							<PrincipalAndInterest paiBig={paiBig} />
						</PrincipalAndInterestFilters>
					</Paper>
				</Grid>
				<Grid item xs={12} md={loanSize}>
					<Paper
						elevation={24}
						style={{
							height: '100%',
							display: 'flex',
							flexDirection: 'column',
						}}
					>
						<div style={{ float: 'right' }}>
							<ToggleButton
								className={handleHoverApply.button}
								variant="outlined"
								selected={true}
								style={{
									width: '90px',
									height: '30px',
									float: 'right',
									marginLeft: 15,
									marginBottom: -60,
									marginTop: 5,
									zIndex: 5,
								}}
								onClick={loanHandle}
							>
								{loanSize === 6 ? 'Enlarge' : 'Shrink'}
							</ToggleButton>
						</div>
						<LoanOutcomesFilters hideFilters={loanHidden}>
							<LoanOutcomes loanBig={loanBig} />
						</LoanOutcomesFilters>
					</Paper>
				</Grid>
				{/* 
				{dealType == 'STRUCTURED' && (
					<Grid item xs={12} md={delinquenciesSize}>
						<Paper
							elevation={24}
							style={{
								height: '100%',
								display: 'flex',
								flexDirection: 'column',
							}}
						>
							<div style={{ float: 'right' }}>
								<ToggleButton
								className={handleHoverApply.button}
									variant="outlined"
								selected={true}
									style={{
										width: '90px',
										height: '30px',
										float: 'right',
										marginLeft: 15,
										marginBottom: -60,
										marginTop: 5,
										zIndex: 5,
									}}
									onClick={delinquenciesHandle}
								>
									{delinquenciesSize === 6 ? 'Enlarge' : 'Shrink'}
									Enlarge
								</ToggleButton>
							</div>
							<DelinquenciesFilters hideFilters={delinquenciesHidden}>
								<Delinquencies delBig={delBig} />
							</DelinquenciesFilters>
						</Paper>
					</Grid>
				)} */}
				<Grid item xs={12} md={delinquenciesSize}>
					<Paper
						elevation={24}
						style={{
							height: '100%',
							display: 'flex',
							flexDirection: 'column',
						}}
					>
						<div style={{ float: 'right' }}>
							<ToggleButton
								className={handleHoverApply.button}
								variant="outlined"
								selected={true}
								style={{
									width: '90px',
									height: '30px',
									float: 'right',
									marginLeft: 15,
									marginBottom: -60,
									marginTop: 5,
									zIndex: 5,
								}}
								onClick={delinquenciesHandle}
							>
								{delinquenciesSize === 6 ? 'Enlarge' : 'Shrink'}
							</ToggleButton>
						</div>
						<DelinquencyStatus />
					</Paper>
				</Grid>
			</Grid>
		</>
	);
}
