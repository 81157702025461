import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import AxiosClient from '../../api/axios-client';

// creating action creators with createAction() from redux-toolkit
export const setDeal = createAction('data/setDeal');
export const setDealStatus = createAction('data/setDealStatus');
export const setModule = createAction('data/setModule');
export const setUnderwritingID = createAction('data/setUnderwritingID');
export const setRequestID = createAction('data/setRequestID');
export const setIsAdmin = createAction('data/setIsAdmin');
export const setAllowedDeals = createAction('data/setAllowedDeals');
export const setAllowedLendingDeals = createAction(
	'data/setAllowedLendingDeals',
);
export const setFilterOptions = createAction('data/setFilterOptions');

export const fetchFilterOptions = createAsyncThunk(
	'data/fetchFilterOptions',
	async (dealID) => {
		const response = await AxiosClient.get(
			`/visualization/${dealID}/filter-options`,
		);
		return response.data;
	},
);
