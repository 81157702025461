import React, { useState } from 'react';
// tooltip imports
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import HelpIcon from '@material-ui/icons/Help';

import Paper from '@material-ui/core/Paper';
import { useSelector } from 'react-redux';

import LegendTooltip from '../../src/components/common/LegendTooltip';
export const getFilteredData = (
	validFilterValues, // Values that classify as good or correct filter values. An array. Such as: ['Predicted', 'History']
	filterProperty, // The property to filter on for the data value. Such as 'status'
	returnProperty, // The property of data to return. Such as 'currentBalance'
) => {
	if (returnProperty === null) {
		// If we need to give the whole object
		return (datum) =>
			validFilterValues.includes(datum[filterProperty]) ? datum : null;
	} else {
		// Return property exist so we need to give one value
		return (datum) =>
			validFilterValues.includes(datum[filterProperty])
				? datum[returnProperty]
				: null;
	}
};

export const legendGenerator = (legendInfo) => {
	// const dealType = useSelector((state) => state.data.deal.type);
	if (!legendInfo?.map) return; // legendInfo is not initialized yet

	return (
		<div className="legend-container">
			<div className="legend-bar-container">
				{legendInfo.map((legend) => {
					return (
						<li
							key={legend.label}
							onClick={() => {
								legend.onClick();
							}}
							className="legend-item"
						>
							<span
								style={{ backgroundColor: legend.backgroundColor }}
								className="legend-color-bar"
							/>
							<span
								className={
									legend.status ? 'legend-title' : 'legend-title strikethrough'
								}
							>
								{legend.label}
							</span>
						</li>
					);
				})}
			</div>
		</div>
	);
};

export const getCumulativeSumArray = (data) => {
	let arr = [];
	data.forEach((d, i) => {
		if (i === 0) arr.push(d);
		else arr.push(d + arr[i - 1]);
	});
	return arr;
};

// vehicle filters
export const filterModelsByManufacturers = (
	queryFiltersVehicleManufacturers,
	vehicleOptions,
	setVehicleModel,
) => {
	if (!queryFiltersVehicleManufacturers) return;
	const models = [];
	// iterate thru query params makes
	for (const make of queryFiltersVehicleManufacturers) {
		// push every model for each make
		models.push(...Object.keys(vehicleOptions[make]));
	}
	setVehicleModel(models);
};

export const filterYearsByModels = (
	queryFiltersVehicleModels,
	queryFiltersVehicleManufacturers,
	vehicleOptions,
	setVehicleYear,
) => {
	if (!queryFiltersVehicleModels) return;
	const years = {};
	for (const make of queryFiltersVehicleManufacturers) {
		// iterate thru query params models for each iteration of makes
		for (const model of queryFiltersVehicleModels) {
			// edge case for multiple selection: skip if make doesn't have model (i.e. Dodge doesn't have Civic)
			if (!vehicleOptions[make][model]) continue;
			// set key:value pairs of model:array of years for each model
			years[model] = Object.keys(vehicleOptions[make][model]);
		}
	}
	// get nested array of years from obj values to iterate and filter intersecting years
	const yearsByModel = Object.values(years);
	// store iterable map of key:value pairs of year:count of occurrence
	const memo = new Map();
	for (const modelYears of yearsByModel) {
		for (const year of modelYears) {
			memo.has(year) ? memo.set(year, memo.get(year) + 1) : memo.set(year, 1);
		}
	}
	const result = [];
	memo.forEach((value, key) => {
		// iterate thru map and filter out years that are common to all selected models
		if (value === queryFiltersVehicleModels.length) result.push(key);
	});
	setVehicleYear(result.sort((a, b) => Number(a) - Number(b)));
};
