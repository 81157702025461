import React, { useState } from 'react';
import {
	Button,
	FormControl,
	FormGroup,
	FormLabel,
	FormControlLabel,
	Grid,
	Paper,
	makeStyles,
	ButtonGroup,
	withStyles,
	Switch,
} from '@material-ui/core';

import { colorPalette } from '../../theme';
const Switchh = withStyles({
	root: {
		height: '32px',
	},
	thumb: {
		height: '14px',
		width: '14px',
	},
	switchBase: {
		color: colorPalette[0],
		'&$checked': {
			color: colorPalette[0],
		},
		'&$checked + $track': {
			opacity: 1,
			border: `1px solid ${colorPalette[0]}`,
			borderRadius: 16 / 2,
			backgroundColor: colorPalette[5],
		},
	},
	checked: {},
	track: {
		border: `1px solid ${colorPalette[0]}`,
		borderRadius: 16 / 2,
		opacity: 1,
		backgroundColor: colorPalette[2],
		width: 27,
	},
})(Switch);

export default function StateFilter(props) {
	const [state, setState] = useState({
		AK: false,
		AL: false,
		AR: false,
		AS: false,
		AZ: false,
		CA: false,
		CO: false,
		CT: false,
		DC: false,
		DE: false,
		FL: false,
		GA: false,
		GU: false,
		HI: false,
		IA: false,
		ID: false,
		IL: false,
		IN: false,
		KS: false,
		KY: false,
		LA: false,
		MA: false,
		MD: false,
		ME: false,
		MI: false,
		MN: false,
		MO: false,
		MP: false,
		MS: false,
		MT: false,
		NC: false,
		ND: false,
		NE: false,
		NH: false,
		NJ: false,
		NM: false,
		NV: false,
		NY: false,
		OH: false,
		OK: false,
		OR: false,
		PA: false,
		PR: false,
		RI: false,
		SC: false,
		SD: false,
		TN: false,
		TX: false,
		UM: false,
		UT: false,
		VA: false,
		VI: false,
		VT: false,
		WA: false,
		WI: false,
		WV: false,
		WY: false,
	});

	const handleChange = (event) => {
		setState({ ...state, [event.target.name]: event.target.checked });
	};

	const handleCollectiveChange = (allTrue) => {
		let newState = { ...state };
		Object.keys(newState).forEach((key) => (newState[key] = allTrue));
		setState(newState);
	};

	const useStyles = makeStyles((theme) => ({
		root: {
			margin: 'auto',
			textDecorationColor: colorPalette[0],
		},
		cardHeader: {
			padding: theme.spacing(1, 2),
		},
		list: {
			width: 200,
			height: 230,
			overflow: 'auto',
		},
		button: {
			margin: theme.spacing(0.5, 0),
		},
		ButtonGroup: {
			backgroundColor: colorPalette[0],
		},
		FormLabelState: {
			backgroundColor: colorPalette[0],
			color: colorPalette[0],
		},
	}));

	const classes = useStyles();

	return (
		<Paper
			elevation={3}
			style={{
				marginTop: 'auto',
				height: 'auto',
				position: 'relative',
				bottom: '0',
				marginLeft: 10,
				marginRight: 10,
				padding: 10,
			}}
		>
			<Grid container spacing={2} alignItems="top">
				<Grid item>
					<FormControl
						component="fieldset"
						style={{
							float: 'right',
							height: '250px',
							width: '200px',
							overflow: 'auto',
							padding: 10,
						}}
					>
						<FormLabel
							focused={false}
							component="legend"
							style={{ color: '#fff', paddingTop: 10 }}
							className="FormLabelState"
						>
							Geographic Location Selector
						</FormLabel>
						<div className={classes.root}>
							<ButtonGroup
								orientation="horizontal"
								aria-label="vertical outlined primary button group"
							>
								<Button
									style={{ width: '50px', height: '30px' }}
									onClick={() => handleCollectiveChange(true)}
								>
									All
								</Button>
								<Button
									style={{ width: '50px', height: '30px' }}
									onClick={() => handleCollectiveChange(false)}
								>
									None
								</Button>
							</ButtonGroup>{' '}
						</div>
						<FormGroup style={{ width: 120 }}>
							<FormControlLabel
								control={
									<Switchh
										checked={state.AK}
										onChange={handleChange}
										name="AK"
									/>
								}
								label="Alabama"
							/>
							<FormControlLabel
								control={
									<Switchh
										checked={state.AL}
										onChange={handleChange}
										name="AL"
									/>
								}
								label="Alaska"
							/>
							<FormControlLabel
								control={
									<Switchh
										checked={state.AR}
										onChange={handleChange}
										name="AR"
									/>
								}
								label="American Samoa"
							/>
							<FormControlLabel
								control={
									<Switchh
										checked={state.AS}
										onChange={handleChange}
										name="AS"
									/>
								}
								label="Arizona"
							/>
							<FormControlLabel
								control={
									<Switchh
										checked={state.AZ}
										onChange={handleChange}
										name="AZ"
									/>
								}
								label="Arkansas"
							/>
							<FormControlLabel
								control={
									<Switchh
										checked={state.CA}
										onChange={handleChange}
										name="CA"
									/>
								}
								label="California"
							/>
							<FormControlLabel
								control={
									<Switchh
										checked={state.CO}
										onChange={handleChange}
										name="CO"
									/>
								}
								label="Colorado"
							/>
							<FormControlLabel
								control={
									<Switchh
										checked={state.CT}
										onChange={handleChange}
										name="CT"
									/>
								}
								label="Connecticut"
							/>
							<FormControlLabel
								control={
									<Switchh
										checked={state.DC}
										onChange={handleChange}
										name="DC"
									/>
								}
								label="Delaware"
							/>
							<FormControlLabel
								control={
									<Switchh
										checked={state.DE}
										onChange={handleChange}
										name="DE"
									/>
								}
								label="District of Columbia"
							/>
							<FormControlLabel
								control={
									<Switchh
										checked={state.FL}
										onChange={handleChange}
										name="FL"
									/>
								}
								label="Florida"
							/>
							<FormControlLabel
								control={
									<Switchh
										checked={state.GA}
										onChange={handleChange}
										name="GA"
									/>
								}
								label="Georgia"
							/>
							<FormControlLabel
								control={
									<Switchh
										checked={state.GU}
										onChange={handleChange}
										name="GU"
									/>
								}
								label="Guam"
							/>
							<FormControlLabel
								control={
									<Switchh
										checked={state.HI}
										onChange={handleChange}
										name="HI"
									/>
								}
								label="Hawaii"
							/>
							<FormControlLabel
								control={
									<Switchh
										checked={state.IA}
										onChange={handleChange}
										name="IA"
									/>
								}
								label="Idaho"
							/>
							<FormControlLabel
								control={
									<Switchh
										checked={state.ID}
										onChange={handleChange}
										name="ID"
									/>
								}
								label="Illinois"
							/>
							<FormControlLabel
								control={
									<Switchh
										checked={state.IL}
										onChange={handleChange}
										name="IL"
									/>
								}
								label="Indiana"
							/>
							<FormControlLabel
								control={
									<Switchh
										checked={state.IN}
										onChange={handleChange}
										name="IN"
									/>
								}
								label="Iowa"
							/>
							<FormControlLabel
								control={
									<Switchh
										checked={state.KS}
										onChange={handleChange}
										name="KS"
									/>
								}
								label="Kansas"
							/>
							<FormControlLabel
								control={
									<Switchh
										checked={state.KY}
										onChange={handleChange}
										name="KY"
									/>
								}
								label="Kentucky"
							/>
							<FormControlLabel
								control={
									<Switchh
										checked={state.LA}
										onChange={handleChange}
										name="LA"
									/>
								}
								label="Louisiana"
							/>
							<FormControlLabel
								control={
									<Switchh
										checked={state.MA}
										onChange={handleChange}
										name="MA"
									/>
								}
								label="Maine"
							/>
							<FormControlLabel
								control={
									<Switchh
										checked={state.MD}
										onChange={handleChange}
										name="MD"
									/>
								}
								label="Maryland"
							/>
							<FormControlLabel
								control={
									<Switchh
										checked={state.ME}
										onChange={handleChange}
										name="ME"
									/>
								}
								label="Massachusetts"
							/>
							<FormControlLabel
								control={
									<Switchh
										checked={state.MI}
										onChange={handleChange}
										name="MI"
									/>
								}
								label="Michigan"
							/>
							<FormControlLabel
								control={
									<Switchh
										checked={state.MN}
										onChange={handleChange}
										name="MN"
									/>
								}
								label="Minnesota"
							/>
							<FormControlLabel
								control={
									<Switchh
										checked={state.MO}
										onChange={handleChange}
										name="MO"
									/>
								}
								label="Minor Outlying Islands"
							/>
							<FormControlLabel
								control={
									<Switchh
										checked={state.MP}
										onChange={handleChange}
										name="MP"
									/>
								}
								label="Mississippi"
							/>
							<FormControlLabel
								control={
									<Switchh
										checked={state.MS}
										onChange={handleChange}
										name="MS"
									/>
								}
								label="Missouri"
							/>
							<FormControlLabel
								control={
									<Switchh
										checked={state.MT}
										onChange={handleChange}
										name="MT"
									/>
								}
								label="Montana"
							/>
							<FormControlLabel
								control={
									<Switchh
										checked={state.NC}
										onChange={handleChange}
										name="NC"
									/>
								}
								label="Nebraska"
							/>
							<FormControlLabel
								control={
									<Switchh
										checked={state.ND}
										onChange={handleChange}
										name="ND"
									/>
								}
								label="Nevada"
							/>
							<FormControlLabel
								control={
									<Switchh
										checked={state.NE}
										onChange={handleChange}
										name="NE"
									/>
								}
								label="New Hampshire"
							/>
							<FormControlLabel
								control={
									<Switchh
										checked={state.NH}
										onChange={handleChange}
										name="NH"
									/>
								}
								label="New Jersey"
							/>
							<FormControlLabel
								control={
									<Switchh
										checked={state.NJ}
										onChange={handleChange}
										name="NJ"
									/>
								}
								label="New Mexico"
							/>
							<FormControlLabel
								control={
									<Switchh
										checked={state.NM}
										onChange={handleChange}
										name="NM"
									/>
								}
								label="New York"
							/>
							<FormControlLabel
								control={
									<Switchh
										checked={state.NV}
										onChange={handleChange}
										name="NV"
									/>
								}
								label="North Carolina"
							/>
							<FormControlLabel
								control={
									<Switchh
										checked={state.NY}
										onChange={handleChange}
										name="NY"
									/>
								}
								label="North Dakota"
							/>
							<FormControlLabel
								control={
									<Switchh
										checked={state.OH}
										onChange={handleChange}
										name="OH"
									/>
								}
								label="Northern Mariana Islands"
							/>
							<FormControlLabel
								control={
									<Switchh
										checked={state.OK}
										onChange={handleChange}
										name="OK"
									/>
								}
								label="Ohio"
							/>
							<FormControlLabel
								control={
									<Switchh
										checked={state.OR}
										onChange={handleChange}
										name="OR"
									/>
								}
								label="Oklahoma"
							/>
							<FormControlLabel
								control={
									<Switchh
										checked={state.PA}
										onChange={handleChange}
										name="PA"
									/>
								}
								label="Oregon"
							/>
							<FormControlLabel
								control={
									<Switchh
										checked={state.PR}
										onChange={handleChange}
										name="PR"
									/>
								}
								label="Pennsylvania"
							/>
							<FormControlLabel
								control={
									<Switchh
										checked={state.RI}
										onChange={handleChange}
										name="RI"
									/>
								}
								label="Puerto Rico"
							/>
							<FormControlLabel
								control={
									<Switchh
										checked={state.SC}
										onChange={handleChange}
										name="SC"
									/>
								}
								label="Rhode Island"
							/>
							<FormControlLabel
								control={
									<Switchh
										checked={state.SD}
										onChange={handleChange}
										name="SD"
									/>
								}
								label="South Carolina"
							/>
							<FormControlLabel
								control={
									<Switchh
										checked={state.TN}
										onChange={handleChange}
										name="TN"
									/>
								}
								label="South Dakota"
							/>
							<FormControlLabel
								control={
									<Switchh
										checked={state.TX}
										onChange={handleChange}
										name="TX"
									/>
								}
								label="Tennessee"
							/>
							<FormControlLabel
								control={
									<Switchh
										checked={state.UM}
										onChange={handleChange}
										name="UM"
									/>
								}
								label="Texas"
							/>
							<FormControlLabel
								control={
									<Switchh
										checked={state.UT}
										onChange={handleChange}
										name="UT"
									/>
								}
								label="U.S. Virgin Islands"
							/>
							<FormControlLabel
								control={
									<Switchh
										checked={state.VA}
										onChange={handleChange}
										name="VA"
									/>
								}
								label="Utah"
							/>
							<FormControlLabel
								control={
									<Switchh
										checked={state.VI}
										onChange={handleChange}
										name="VI"
									/>
								}
								label="Vermont"
							/>
							<FormControlLabel
								control={
									<Switchh
										checked={state.VT}
										onChange={handleChange}
										name="VT"
									/>
								}
								label="Virginia"
							/>
							<FormControlLabel
								control={
									<Switchh
										checked={state.WA}
										onChange={handleChange}
										name="WA"
									/>
								}
								label="Washington"
							/>
							<FormControlLabel
								control={
									<Switchh
										checked={state.WI}
										onChange={handleChange}
										name="WI"
									/>
								}
								label="West Virginia"
							/>
							<FormControlLabel
								control={
									<Switchh
										checked={state.WV}
										onChange={handleChange}
										name="WV"
									/>
								}
								label="Wisconsin"
							/>
							<FormControlLabel
								control={
									<Switchh
										checked={state.WY}
										onChange={handleChange}
										name="WY"
									/>
								}
								label="Wyoming"
							/>
						</FormGroup>
					</FormControl>
				</Grid>
			</Grid>
		</Paper>
	);
}
