import React, { Component, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { ThemeProvider } from '@material-ui/core';
import { createMuiTheme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import MUIDataTable from 'mui-datatables';
import formatters from '../../helpers/formatters';

// tooltip imports
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import HelpIcon from '@material-ui/icons/Help';

import {
	useBacktestSummary,
	useBacktestScoringTable,
} from '../../api/query-hooks';
import LoadingSpinner from '../common/LoadingSpinner';

import NoData from '../common/NoData';
/* 
Data componens: backtest summary, back test scoring table
filtering: 
	Yes - but need to recalc for predictions. Future function.
folder:
	karus_abs/auto/{originator_name}/{securitization_name}/outputs/
files: 
	backtest_summary.csv
	backtest_scoring_table.csv
text: 
	Backtest results for sample of securitization loans 
	Backtest quintile charge-off percents
data notes:
	Keep fields order as found in the file 
	order from lowest karus risk score to highest
Fields: fields, values 
		originator, securitization, karus_risk_score, default_rate, number_of_accounts
*/

const summaryColumns = [
	{
		name: 'field',
		label: 'Field',
	},
	{
		name: 'actual',
		label: 'Actual',
	},
	{
		name: 'predicted',
		label: 'Predicted',
	},
];

const scoringColumns = [
	{
		name: 'originator',
		label: 'Originator',
		options: {
			customBodyRender: (value) =>
				formatters.isValid(value) ? value : `Values not available`,
		},
	},
	{
		name: 'securitization',
		label: 'Securitization',
		options: {
			customBodyRender: (value) =>
				formatters.isValid(value) ? value : `Values not available`,
		},
	},
	{
		name: 'account',
		label: 'Accounts',
		options: {
			customBodyRender: (value) => (value ? formatters.addCommas(value) : ``),
		},
	},
	{
		name: 'karusRiskScore',
		label: 'Borrower Segment',
		options: {
			customBodyRender: (value) =>
				formatters.isValid(value) ? value : `Values not available`,
		},
	},
	{
		name: 'defaultRate',
		label: 'Default Rate',
		options: {
			customBodyRender: (value) =>
				value ? formatters.toPercentage(value) : ``,
		},
	},
	{
		name: 'averageInterestRate',
		label: 'Average Interest Rate',
		options: {
			customBodyRender: (value) =>
				value ? formatters.toPercentage(value) : ``,
		},
	},
	{
		name: 'averageCreditScore',
		label: 'Average Credit Score',
		options: {
			customBodyRender: (value, tableMeta, updateValue) =>
				formatters.isValid(value)
					? (+value).toFixed(2)
					: 'Values not available',
		},
	},
	{
		name: 'originalBalance',
		label: 'Original Balance',
		options: {
			customBodyRender: (value) => (value ? formatters.toCurrency(value) : ``),
		},
	},
	{
		name: 'totalCollected',
		label: 'Total Collected',
		options: {
			customBodyRender: (value) => (value ? formatters.toCurrency(value) : ``),
		},
	},
	{
		name: 'presentValue',
		label: 'Present Value',
		options: {
			customBodyRender: (value) => (value ? formatters.toCurrency(value) : ``),
		},
	},
	{
		name: 'netPresentValue',
		label: 'Net Present Value',
		options: {
			customBodyRender: (value) => (value ? formatters.toCurrency(value) : ``),
		},
	},
	{
		name: 'fvPrice',
		label: 'FV Price',
		options: {
			customBodyRender: (value, tableMeta, updateValue) =>
				formatters.isValid(value)
					? (+value).toFixed(4)
					: 'Values not available',
		},
	},
	{
		name: 'irr',
		label: 'IRR',
		options: {
			customBodyRender: (value) =>
				value ? formatters.toPercentage(value) : ``,
		},
	},
];

const options = {
	elevation: 0,
	filter: false,
	selectableRows: 'none',
	search: false,
	download: false,
	print: false,
	viewColumns: false,
	pagination: false,
	rowsPerPage: 15,
};

const theme = createMuiTheme({
	palette: {
		type: 'dark',
	},
	overrides: {
		MUIDataTableHeaderCell: {
			fontSize: '1.7em',
		},
		MUIDataTableBodyRow: {
			root: {
				'&:nth-child(odd)': {
					backgroundColor: '#666666',
				},
			},
		},
		MuiTableCell: {
			root: {
				padding: '5px',
				fontSize: '1.2em',
			},
		},
		MUIDataTableToolbar: {
			icon: {
				'&:hover': {
					color: '#32DB60',
				},
			},
		},
	},
});

const BacktestSummaryTable = () => {
	const { status, data, error, refetch } = useBacktestSummary();

	useEffect(() => {
		refetch();
	}, [useSelector((state) => state.data.deal.id)]);

	if (status === 'loading' || !data) return <LoadingSpinner />;
	if (status === 'error') return `Error: ${error.message}`;

	data.forEach((e) => {
		switch (e.field) {
			case 'Number of accounts':
				e.actual = formatters.addCommas(e.actual);
				e.predicted = formatters.addCommas(e.predicted);
				break;
			case 'Original balance':
				e.actual = formatters.toCurrency(e.actual);
				e.predicted = formatters.toCurrency(e.predicted);
				break;
			case 'Principal collected':
				e.actual = formatters.toCurrency(e.actual);
				e.predicted = formatters.toCurrency(e.predicted);
				break;
			case 'Interest collected':
				e.actual = formatters.toCurrency(e.actual);
				e.predicted = formatters.toCurrency(e.predicted);
				break;
			case 'Charge-off amount':
				e.actual = formatters.toCurrency(e.actual);
				e.predicted = formatters.toCurrency(e.predicted);
				break;
			case 'Recovered amount':
				e.actual = formatters.toCurrency(e.actual);
				e.predicted = formatters.toCurrency(e.predicted);
				break;
			case 'Recovered collected':
				e.actual = formatters.toCurrency(e.actual);
				e.predicted = formatters.toCurrency(e.predicted);
				break;
			case 'Total collected':
				e.actual = formatters.toCurrency(e.actual);
				e.predicted = formatters.toCurrency(e.predicted);
				break;
			case 'Discount rate': //Does this need to be a percentage?
				e.actual = formatters.toPercentage(e.actual);
				e.predicted = formatters.toPercentage(e.predicted);
				break;
			case 'Fair value': //Does this need to be a currency?
				e.actual = formatters.addCommas(e.actual);
				e.predicted = formatters.addCommas(e.predicted);
				break;
			case 'Fair value price':
				e.actual = formatters.toDecimal(e.actual);
				e.predicted = formatters.toDecimal(e.predicted);
				break;
			case 'Yield (at par)':
				e.actual = formatters.toPercentage(e.actual);
				e.predicted = formatters.toPercentage(e.predicted);
				break;
			default:
				break;
		}
	});

	return data.length === 0 ? (
		<NoData
			chartTitle="Backtesting Summary"
			chartTitleTooltip="Actual vs. predicted performance over evaluation “hold-out” accounts specific to each deal. Karus updates these predictions on a regular basis as each new round of payment history becomes available. Karus aims to minimize the difference between actuals and predicted values."
		/>
	) : (
		<MUIDataTable
			title={
				<Typography
					style={{
						fontSize: '1.7em',
						fontWeight: 'bold',
						marginBottom: 12,
					}}
				>
					Backtesting Summary{' '}
					<Tooltip
						placement="right"
						title={
							<Typography style={{ fontSize: '1.4em' }}>
								Actual vs. predicted performance over evaluation “hold-out”
								accounts specific to each deal. Karus updates these predictions
								on a regular basis as each new round of payment history becomes
								available. Karus aims to minimize the difference between actuals
								and predicted values.
							</Typography>
						}
					>
						<IconButton>
							<HelpIcon fontSize="small" />
						</IconButton>
					</Tooltip>
				</Typography>
			}
			columns={summaryColumns}
			data={data}
			options={options}
		/>
	);
};

const BacktestScoringTable = () => {
	const { status, data, error, refetch } = useBacktestScoringTable();

	useEffect(() => {
		refetch();
	}, [useSelector((state) => state.data.deal.id)]);

	if (status === 'loading') return <LoadingSpinner />;
	if (status === 'error') return `Error: ${error.message}`;

	return data.length === 0 ? (
		<NoData
			chartTitle="Backtesting Scoring"
			chartTitleTooltip="Analysis of Karus’ ML model segmentation capability. Accounts are segmented into quintiles of risk as predicted by Karus ML models. Karus then calculates default rates and account performance for each quintile. Note the cascading default rate which displays the accuracy of our models."
		/>
	) : (
		<MUIDataTable
			title={
				<Typography
					style={{
						fontSize: '1.7em',
						fontWeight: 'bold',
						marginBottom: 12,
					}}
				>
					Backtesting Scoring{' '}
					<Tooltip
						placement="right"
						title={
							<Typography style={{ fontSize: '1.4em' }}>
								Analysis of Karus&#39; ML model segmentation capability.
								Accounts are segmented into quintiles of risk as predicted by
								Karus ML models. Karus then calculates default rates and account
								performance for each quintile. Note the cascading default rate
								which displays the accuracy of our models.
							</Typography>
						}
					>
						<IconButton>
							<HelpIcon fontSize="small" />
						</IconButton>
					</Tooltip>
				</Typography>
			}
			columns={scoringColumns}
			data={data}
			options={options}
		/>
	);
};

export default class Overview extends Component {
	render() {
		return (
			<ThemeProvider theme={theme}>
				<Grid container spacing={4}>
					<Grid item xs={12} style={{ width: '80vw', overflow: 'none' }}>
						<Paper
							elevation={24}
							style={{
								padding: 10,
								width: '100%',
								maxWidth: '100%',
								overflowX: 'auto',
							}}
						>
							<BacktestSummaryTable />
						</Paper>
					</Grid>
					<Grid item xs={12} style={{ width: '80vw', overflow: 'none' }}>
						<Paper
							elevation={24}
							style={{
								padding: 10,
								width: '100%',
								maxWidth: '100%',
								overflowX: 'auto',
							}}
						>
							<BacktestScoringTable />
						</Paper>
					</Grid>
				</Grid>
			</ThemeProvider>
		);
	}
}
