import React, { useEffect, useState, useRef } from 'react';
import {
	Chart,
	CategoryScale,
	BarController,
	BarElement,
	LineController,
	LineElement,
	PointElement,
	Legend,
	Tooltip as TooltipChartPlugin,
} from 'chart.js';
import PropTypes from 'prop-types';

// tooltip imports
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import HelpIcon from '@material-ui/icons/Help';
import { chartColors as colors } from '../../theme';
import { getFilteredData } from '../../helpers/helpers';
Chart.defaults.color = '#f0f0f0';

import '../styles/Chart.scss';
import LegendTooltip from '../common/LegendTooltip';

import { legendGenerator } from '../../helpers/helpers';

import { round } from 'mathjs';

export default function ParetoChart(props) {
	const chartRef = useRef();
	const [chart, setChart] = useState();

	const createChart = () => {
		Chart.register(
			CategoryScale,
			BarController,
			BarElement,
			LineController,
			LineElement,
			PointElement,
			Legend,
			TooltipChartPlugin,
		);

		if (props.data && props.axisLabels) {
			setChart(
				new Chart(chartRef.current, {
					type: 'bar',
					data: {
						labels: props.axisLabels,
						datasets: props.dataSets,
					},
					options: {
						responsive: true,
						maintainAspectRatio: false,
						elements: {
							// hide points on line, but retain tooltips
							point: {
								radius: 0,
								hitRadius: 5,
								borderWidth: 0,
								hoverBorderWidth: 0,
								backgroundColor: 'rgba(0, 0, 0, 0.0)',
								borderColor: 'rgba(0, 0, 0, 0.0)',
							},
						},
						plugins: {
							legend: {
								display: false,
								labels: {
									color: '#f0f0f0',
								},
								onHover: function (e, legendItem, legend) {
									e.native.target.style.cursor = 'pointer';
								}.bind(this),
								onLeave: function (e, legendItem, legend) {
									e.native.target.style.cursor = 'default';
								}.bind(this),
							},
						},
						scales: {
							x: {
								axis: 'x',
								grid: {
									drawTicks: true,
									display: true,
									zeroLineColor: '#e1e1e1',
									offsetGridLines: true,
								},
								ticks: {
									color: '#e1e1e1',
									font: {
										weight: 'bold',
									},
									beginAtZero: true,
									padding: 10,
									display: true,
								},
								options: {
									stacked: true,
									reverse: true,
								},
								title: {
									display: true,
									text:
										// Temporary conditional logic for adding axis labels
										props.xAxisValue === 'MDY'
											? 'Date'
											: props.xAxisValue === 'month-index'
											? 'Month Index'
											: '',
								},
							},
							leftAxis: {
								position: 'left',
								min: props.barMin,
								grid: {
									drawTicks: false,
									display: true,
									zeroLineColor: '#e1e1e1',
								},
								display: true,
								ticks: {
									font: {
										weight: 'bold',
									},
									color: '#e1e1e1',
									beginAtZero: true,
									padding: 10,
									callback: (value) =>
										(props.tickLabelFormatLeft === 'K') | (value < 1000000)
											? `${round(value / 1000)}K`
											: props.tickLabelFormatLeft === 'M'
											? `${round(value / 1000000)}M`
											: value,
								},
								title: {
									display: true,
									text:
										// Temporary conditional logic for adding axis labels
										props.chartTitle.split(' ')[1] === 'Paid'
											? 'Actual Total'
											: props.chartTitle.split(' ')[1] === 'Lost'
											? 'Net Loss'
											: '',
								},
							},
							rightAxis: {
								position: 'right',
								min: 0,
								// max: props.cumulativeMax,
								grid: {
									drawTicks: false,
									display: false,
									color: '#e1e1e1',
								},
								ticks: {
									font: {
										weight: 'bold',
									},
									color: '#e1e1e1',
									beginAtZero: true,
									padding: 10,
									crossAlign: 'near',
									callback: (value) =>
										props.tickLabelFormatRight === 'K'
											? `${round(value / 1000)}K`
											: props.tickLabelFormatRight === 'M'
											? `${round(value / 1000000)}M`
											: value,
								},
								title: {
									display: true,
									text:
										// Temporary conditional logic for adding axis labels
										props.chartTitle.split(' ')[1] === 'Paid'
											? 'Running Sum of Actual Total'
											: props.chartTitle.split(' ')[1] === 'Lost'
											? 'Running Sum of Net Loss'
											: '',
								},
							},
						},
					},
				}),
			);
		}
	};

	useEffect(() => {
		if (chart) chart.destroy();
		createChart();
	}, [props.data, props.axisLabels, props.dataField, props.dataSets]);

	return (
		<>
			<Typography
				style={{
					fontSize: '1.7em',
					fontWeight: 'bold',
					marginBottom: 12,
					position: 'relative',
				}}
			>
				{props.chartTitle}
				<Tooltip
					placement="left"
					title={
						<Typography style={{ fontSize: '1.4em', paddingTop: -40 }}>
							{props.chartTitleTooltip}
						</Typography>
					}
				>
					<IconButton>
						<HelpIcon fontSize="small" />
					</IconButton>
				</Tooltip>
			</Typography>
			<div style={{ justifySelf: 'left' }}>
				{legendGenerator(props.legendInfo)}
			</div>
			<div
				style={{ position: 'relative', height: props.ope }}
				className="chart-container"
			>
				<canvas ref={chartRef} />
			</div>
		</>
	);
}

ParetoChart.propTypes = {
	labels: PropTypes.array,
};
