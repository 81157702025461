import React, { Component, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { ThemeProvider } from '@material-ui/core';
import { createMuiTheme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import MUIDataTable from 'mui-datatables';
import {
	useAccountLevel,
	useStaticTable,
	useTransaction,
} from '../../api/query-hooks';

// tooltip imports
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import HelpIcon from '@material-ui/icons/Help';
import LoadingSpinner from '../common/LoadingSpinner';

import NoData from '../common/NoData';

const options = {
	elevation: 0,
	filterType: 'checkbox',
	selectableRows: 'none',
	rowsPerPage: 25,
	rowsPerPageOptions: [25, 50, 100],
	download: false,
	print: false,
};

const theme = createMuiTheme({
	palette: {
		type: 'dark',
	},
	overrides: {
		MUIDataTableHeadCell: {
			data: {
				fontSize: '0.7em',
				textAlign: 'center',
			},
		},
		MUIDataTableBodyRow: {
			root: {
				'&:nth-child(odd)': {
					backgroundColor: '#666666',
				},
			},
		},
		MuiTableCell: {
			root: {
				padding: '2px',
				fontSize: '0.7em',
				textAlign: 'left',
			},
		},
		MUIDataTableToolbar: {
			icon: {
				'&:hover': {
					color: '#32DB60',
				},
			},
		},
	},
});

function toCurrency(value) {
	return `$${parseFloat(value)
		.toFixed(2)
		.toString()
		.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;
}

function toPercentage(value) {
	return `${parseFloat(value).toFixed(2)}%`;
}

function isValid(value) {
	if (
		typeof value === 'undefined' ||
		value === null ||
		value === 'No active accounts' ||
		value === ''
	) {
		return false;
	} else {
		return true;
	}
}

const transactionColumns = [
	{
		name: 'originator_name',
		label: 'Originator Name',
		options: {
			customBodyRender: (value) =>
				isValid(value) ? value : `Values not available`,
		},
	},
	{
		name: 'reporting_period_begin_date_r',
		label: 'Reporting Period Begin Date',
		options: {
			customBodyRender: (value) =>
				isValid(value) ? value : `Values not available`,
		},
	},
	{
		name: 'reporting_period_end_date_r',
		label: 'Reporting Period End Date',
		options: {
			customBodyRender: (value) =>
				isValid(value) ? value : `Values not available`,
		},
	},
	{
		name: 'month_number',
		label: 'Month Number',
		options: {
			customBodyRender: (value) =>
				isValid(value) ? value : `Values not available`,
		},
	},
	{
		name: 'actual_principal_collected_amount',
		label: 'Actual Principal Collected Amt',
		options: {
			customBodyRender: (value) =>
				isValid(value) ? toCurrency(value) : 'Values not available',
		},
	},
	{
		name: 'actual_interest_collected_amount',
		label: 'Actual Interest Collected Amt',
		options: {
			customBodyRender: (value) =>
				isValid(value) ? toCurrency(value) : `Values not available`,
		},
	},
	{
		name: 'chargedoff_principal_amount',
		label: 'Chargedoff Principal Amount',
		options: {
			customBodyRender: (value) =>
				isValid(value) ? toCurrency(value) : `Values not available`,
		},
	},
	{
		name: 'actual_total',
		label: 'Actual Total',
		options: {
			customBodyRender: (value) =>
				isValid(value) ? toCurrency(value) : `Values not available`,
		},
	},
	{
		name: 'net_loss',
		label: 'Net Loss',
		options: {
			customBodyRender: (value) =>
				isValid(value) ? toCurrency(value) : `Values not available`,
		},
	},
	{
		name: 'delinquent_category',
		label: 'Delinquent Category',
		options: {
			customBodyRender: (value) =>
				isValid(value) ? value : `Values not available`,
		},
	},
	{
		name: 'data_status',
		label: 'Data Status',
		options: {
			customBodyRender: (value) =>
				isValid(value) ? value : `Values not available`,
		},
	},
];

const TransactionTable = () => {
	const { status, data, error, refetch } = useTransaction();

	useEffect(() => {
		refetch();
	}, [useSelector((state) => state.data.deal.id)]);

	if (status === 'loading') return <LoadingSpinner />;
	if (status === 'error') return `Error: ${error.message}`;

	return data.length === 0 ? (
		<NoData
			chartTitle="Transaction Level Detail"
			chartTitleTooltip="Combined and standardized monthly transaction data. This includes payment, charged-off, and recovery amounts."
		/>
	) : (
		<MUIDataTable
			title={
				<Typography
					style={{
						fontSize: '1.7em',
						fontWeight: 'bold',
						marginBottom: 12,
					}}
				>
					Transaction Level Detail{' '}
					<Tooltip
						placement="right"
						title={
							<Typography style={{ fontSize: '1.4em' }}>
								Combined and standardized monthly transaction data. This
								includes payment, charged-off, and recovery amounts.
							</Typography>
						}
					>
						<IconButton>
							<HelpIcon fontSize="small" />
						</IconButton>
					</Tooltip>
				</Typography>
			}
			columns={transactionColumns}
			data={data}
			options={options}
		/>
	);
};

const staticColumns = [
	{
		name: 'actualinterestcollectedamount',
		label: 'Actual Interest Collected Amount',
		options: {
			customBodyRender: (value) =>
				isValid(value) ? toCurrency(value) : `Values not available`,
		},
	},
	{
		name: 'actualprincipalcollectedamount',
		label: 'Actual Principal Collected Amount',
		options: {
			customBodyRender: (value) =>
				isValid(value) ? toCurrency(value) : `Values not available`,
		},
	},
	{
		name: 'actualtotal',
		label: 'Actual Total',
		options: {
			customBodyRender: (value) =>
				isValid(value) ? toCurrency(value) : `Values not available`,
		},
	},
	{
		name: 'chargedoffprincipalamount',
		label: 'Charged Off Principal Amount',
		options: {
			customBodyRender: (value) =>
				isValid(value) ? toCurrency(value) : `Values not available`,
		},
	},
	{
		name: 'chargedoffprincipalamountadjusted',
		label: 'Charged Off Principal Amount Adjusted',
		options: {
			customBodyRender: (value) =>
				isValid(value) ? value : `Values not available`,
		},
	},
	{
		name: 'contractcashdown',
		label: 'Contract Cash Down',
		options: {
			customBodyRender: (value) =>
				isValid(value) ? value : `Values not available`,
		},
	},
	{
		name: 'contracttotaldeferreddown',
		label: 'Contract Total Deferred Down',
		options: {
			customBodyRender: (value) =>
				isValid(value) ? value : `Values not available`,
		},
	},
	{
		name: 'coobligorindicator',
		label: 'Co-Obligor Indicator',
		options: {
			customBodyRender: (value) =>
				isValid(value) ? value : `Values not available`,
		},
	},
	{
		name: 'currentbalance',
		label: 'Current Balance',
		options: {
			customBodyRender: (value) =>
				isValid(value) ? toCurrency(value) : `Values not available`,
		},
	},
	{
		name: 'datastatus',
		label: 'Data Status',
		options: {
			customBodyRender: (value) =>
				isValid(value) ? value : `Values not available`,
		},
	},
	{
		name: 'dealoriginalbalance',
		label: 'Deal Original Balance',
		options: {
			customBodyRender: (value) =>
				isValid(value) ? value : `Values not available`,
		},
	},
	{
		name: 'defaultprediction',
		label: 'Default Prediction',
		options: {
			customBodyRender: (value) =>
				isValid(value) ? value : `Values not available`,
		},
	},
	{
		name: 'defaultprob',
		label: 'Default Prob',
		options: {
			customBodyRender: (value) =>
				isValid(value) ? toPercentage(value) : `Values not available`,
		},
	},
	{
		name: 'id',
		label: 'ID',
		options: {
			customBodyRender: (value) =>
				isValid(value) ? value : `Values not available`,
		},
	},
	{
		name: 'interestcalculationtypecodem',
		label: 'Interest Calculation Type Code M',
		options: {
			customBodyRender: (value) =>
				isValid(value) ? value : `Values not available`,
		},
	},
	{
		name: 'irr',
		label: 'IRR',
		options: {
			customBodyRender: (value) =>
				isValid(value) ? toPercentage(value * 100) : `Values not available`,
		},
	},
	{
		name: 'loanmaturitydate',
		label: 'Loan Maturity Date',
		options: {
			customBodyRender: (value) =>
				isValid(value) ? value : `Values not available`,
		},
	},
	{
		name: 'monthnumber',
		label: 'Month Number',
		options: {
			customBodyRender: (value) =>
				isValid(value) ? value : `Values not available`,
		},
	},
	{
		name: 'netloss',
		label: 'Net Loss',
		options: {
			customBodyRender: (value) =>
				isValid(value) ? toCurrency(value) : `Values not available`,
		},
	},
	{
		name: 'npv',
		label: 'NPV',
		options: {
			customBodyRender: (value) =>
				isValid(value) ? toCurrency(value) : `Values not available`,
		},
	},
	{
		name: 'numberofpayments',
		label: 'Number of Payments',
		options: {
			customBodyRender: (value) =>
				isValid(value) ? value : `Values not available`,
		},
	},
	{
		name: 'obligorcreditscore',
		label: 'Obligor Credit Score',
		options: {
			customBodyRender: (value) =>
				isValid(value) ? value : `Values not available`,
		},
	},
	{
		name: 'obligorcreditscoretype',
		label: 'Obligor Credit Score Type',
		options: {
			customBodyRender: (value) =>
				isValid(value) ? value : `Values not available`,
		},
	},
	{
		name: 'obligoremploymentincome',
		label: 'Obligor Employment Income',
		options: {
			customBodyRender: (value) =>
				isValid(value) ? value : `Values not available`,
		},
	},
	{
		name: 'obligoremploymentverificationcodem',
		label: 'Obligor Employment Verification Code M',
		options: {
			customBodyRender: (value) =>
				isValid(value) ? value : `Values not available`,
		},
	},
	{
		name: 'obligorgeographiclocation',
		label: 'Obligor Geographic Location',
		options: {
			customBodyRender: (value) =>
				isValid(value) ? value : `Values not available`,
		},
	},
	{
		name: 'obligorzipcode',
		label: 'Obligor Zip Code',
		options: {
			customBodyRender: (value) =>
				isValid(value) ? value : `Values not available`,
		},
	},
	{
		name: 'originalfirstpaymentdater',
		label: 'Original First Payment Date R',
		options: {
			customBodyRender: (value) =>
				isValid(value) ? value : `Values not available`,
		},
	},
	{
		name: 'originalinterestratepercentage',
		label: 'Original Interest Rate Percentage',
		options: {
			customBodyRender: (value) =>
				isValid(value) ? toPercentage(value * 100) : `Values not available`,
		},
	},
	{
		name: 'originatlinterestratetypecodem',
		label: 'Original Interest Rate Type Code M',
		options: {
			customBodyRender: (value) =>
				isValid(value) ? value : `Values not available`,
		},
	},
	{
		name: 'originalloanamount',
		label: 'Original Loan Amount',
		options: {
			customBodyRender: (value) =>
				value ? toCurrency(value) : `Values not available`,
		},
	},
	{
		name: 'originationdate',
		label: 'Origination Date',
		options: {
			customBodyRender: (value) =>
				isValid(value) ? value : `Values not available`,
		},
	},
	{
		name: 'originationdater',
		label: 'Origination Date R',
		options: {
			customBodyRender: (value) =>
				isValid(value) ? value : `Values not available`,
		},
	},
	{
		name: 'originatorname',
		label: 'Originator Name',
		options: {
			customBodyRender: (value) =>
				isValid(value) ? value : `Values not available`,
		},
	},
	{
		name: 'outcome',
		label: 'Outcome',
		options: {
			customBodyRender: (value) =>
				isValid(value) ? value : `Values not available`,
		},
	},
	{
		name: 'paymenttoincomepercentage',
		label: 'Payment to Income Percentage',
		options: {
			customBodyRender: (value) =>
				isValid(value) ? toPercentage(value * 100) : `Values not available`,
		},
	},
	{
		name: 'paymenttypecode',
		label: 'Payment Type Code',
		options: {
			customBodyRender: (value) =>
				isValid(value) ? value : `Values not available`,
		},
	},
	{
		name: 'pv fv',
		label: 'PV FV',
		options: {
			customBodyRender: (value) =>
				isValid(value) ? value : `Values not available`,
		},
	},
	{
		name: 'pv(fv)',
		label: 'PV(FV)',
		options: {
			customBodyRender: (value) =>
				isValid(value) ? toCurrency(value) : `Values not available`,
		},
	},
	{
		name: 'recoveredamount',
		label: 'Recovered Amount',
		options: {
			customBodyRender: (value) =>
				isValid(value) ? toCurrency(value) : `Values not available`,
		},
	},
	{
		name: 'securitization',
		label: 'Securitization',
		options: {
			customBodyRender: (value) =>
				isValid(value) ? value : `Values not available`,
		},
	},
	{
		name: 'securitizationoriginalbalance',
		label: 'riginal Balance',
		options: {
			customBodyRender: (value) =>
				isValid(value) ? toCurrency(value) : `Values not available`,
		},
	},
	{
		name: 'servicingfeepercentage',
		label: 'Servicing Fee Percentage',
		options: {
			customBodyRender: (value) =>
				isValid(value) ? toPercentage(value * 100) : `Values not available`,
		},
	},
	{
		name: 'solventprob',
		label: 'Solvent Prob',
		options: {
			customBodyRender: (value) =>
				isValid(value) ? toPercentage(value) : `Values not available`,
		},
	},
	{
		name: 'sourcedeescription',
		label: 'Source Description',
		options: {
			customBodyRender: (value) =>
				isValid(value) ? value : `Values not available`,
		},
	},
	{
		name: 'totalactualamountpaid',
		label: 'Total Actual Amount Paid',
		options: {
			customBodyRender: (value) =>
				isValid(value) ? toCurrency(value) : `Values not available`,
		},
	},
	{
		name: 'underwritingindicator',
		label: 'Underwriting Indicator',
		options: {
			customBodyRender: (value) =>
				isValid(value) ? value : `Values not available`,
		},
	},
	{
		name: 'unifiedoutcome',
		label: 'Unified Outcome',
		options: {
			customBodyRender: (value) =>
				isValid(value) ? value : `Values not available`,
		},
	},
	{
		name: 'vehicleacquiredprice',
		label: 'Vehicle Acquired Price',
		options: {
			customBodyRender: (value) =>
				isValid(value) ? value : `Values not available`,
		},
	},
	{
		name: 'vehicleaquiredprice',
		label: 'Vehicel Acquired Price',
		options: {
			customBodyRender: (value) =>
				isValid(value) ? value : `Values not available`,
		},
	},
	{
		name: 'vehiclemanufacturername',
		label: 'Vehicle Manufacturer Name',
		options: {
			customBodyRender: (value) =>
				isValid(value) ? value : `Values not available`,
		},
	},
	{
		name: 'vehiclemileage',
		label: 'Vehicle Mileage',
		options: {
			customBodyRender: (value) =>
				isValid(value) ? value : `Values not available`,
		},
	},
	{
		name: 'vehiclemodelname',
		label: 'Vehicle Model Name',
		options: {
			customBodyRender: (value) =>
				isValid(value) ? value : `Values not available`,
		},
	},
	{
		name: 'vehiclemodelyear',
		label: 'Vehicle Model Year',
		options: {
			customBodyRender: (value) =>
				isValid(value) ? value : `Values not available`,
		},
	},
	{
		name: 'vehiclenewusedcodem',
		label: 'Vehicle New Used Code M',
		options: {
			customBodyRender: (value) =>
				isValid(value) ? value : `Values not available`,
		},
	},
	{
		name: 'vehicletypecodem',
		label: 'Vehicle Type Code M',
		options: {
			customBodyRender: (value) =>
				isValid(value) ? value : `Values not available`,
		},
	},
	{
		name: 'vehiclevalueamount',
		label: 'Vehicle Value Amount',
		options: {
			customBodyRender: (value) =>
				isValid(value) ? toCurrency(value) : `Values not available`,
		},
	},
	{
		name: 'vehiclevaluesourcecodem',
		label: 'Vehicle Value Source Code M',
		options: {
			customBodyRender: (value) =>
				isValid(value) ? value : `Values not available`,
		},
	},
	{
		name: 'vehiclevin',
		label: 'Vehicle VIN',
		options: {
			customBodyRender: (value) =>
				isValid(value) ? value : `Values not available`,
		},
	},
	{
		name: 'warrantyprice',
		label: 'Warranty Price',
		options: {
			customBodyRender: (value) =>
				isValid(value) ? value : `Values not available`,
		},
	},
	{
		name: 'zerobalanceeffectivedater',
		label: 'Zero Balance Effective Date R',
		options: {
			customBodyRender: (value) =>
				isValid(value) ? value : `Values not available`,
		},
	},
];

function getNonNullColumnsData(data) {
	let prospectiveColumns = Object.keys(data[0]);
	let goodColumns = [];
	// For every data entry
	data.forEach((row) => {
		// For every column in prospectiveColumn
		let index = prospectiveColumns.length - 1;
		while (index > 0) {
			let column = prospectiveColumns[index];
			// Check if the column in the data is not null
			if (row[column] !== null && !(row[column] === '')) {
				// If yes, push the prospectiveColumn in goodColumn and remove from prospective column
				goodColumns.push(column);
				prospectiveColumns.splice(index, 1);
			}
			index -= 1;
		}
	});

	let goodData = [];

	// For every data entry
	data.forEach((row) => {
		// Get the good columns of it
		let goodRow = goodColumns.reduce(
			// eslint-disable-next-line no-prototype-builtins
			(acc, k) => ({ ...acc, ...(row.hasOwnProperty(k) && { [k]: row[k] }) }),
			{},
		);
		// Push into good data
		goodData.push(goodRow);
	});

	return goodData;
}

function getNonNullColumns(tableColumns, goodColumns) {
	let goodTableColumns = tableColumns.filter((tableColumn) =>
		goodColumns.includes(tableColumn.name),
	);
	return goodTableColumns;
}

const AccountLevel = () => {
	const { status, data, error, refetch } = useAccountLevel();

	useEffect(() => {
		refetch();
	}, [useSelector((state) => state.data.deal.id)]);

	if (status === 'loading') return <LoadingSpinner />;
	if (status === 'error') return `Error: ${error.message}`;
	let goodData = getNonNullColumnsData(data);

	let goodTableColumns = getNonNullColumns(
		staticColumns,
		Object.keys(goodData[0]),
	);

	return data.length === 0 ? (
		<NoData
			chartTitle="Account Level Detail"
			chartTitleTooltip="Monthly transaction data rolled up to the account level with historical performance for closed accounts and Karus predicted performance for active accounts."
		/>
	) : (
		<MUIDataTable
			title={
				<Typography
					style={{
						fontSize: '1.7em',
						fontWeight: 'bold',
						marginBottom: 12,
					}}
				>
					Account Level Detail{' '}
					<Tooltip
						placement="right"
						title={
							<Typography style={{ fontSize: '1.4em' }}>
								Monthly transaction data rolled up to the account level with
								historical performance for closed accounts and Karus predicted
								performance for active accounts.
							</Typography>
						}
					>
						<IconButton>
							<HelpIcon fontSize="small" />
						</IconButton>
					</Tooltip>
				</Typography>
			}
			columns={goodTableColumns}
			data={goodData}
			options={options}
		/>
	);
};

export default class LoanTape extends Component {
	render() {
		return (
			<ThemeProvider theme={theme}>
				<Grid container spacing={4} style={{ marginTop: '-15' }}>
					<Grid item xs={12} style={{ width: '80vw', overflow: 'none' }}>
						<Paper
							elevation={24}
							style={{
								padding: 10,
								width: '100%',
								maxWidth: '100%',
								overflowX: 'auto',
							}}
						>
							<AccountLevel />
						</Paper>
					</Grid>
					<Grid item xs={12} style={{ width: '80vw', overflow: 'none' }}>
						<Paper
							elevation={24}
							style={{
								padding: 10,
								width: '100%',
								maxWidth: '100%',
								overflowX: 'auto',
							}}
						>
							<TransactionTable />
						</Paper>
					</Grid>
				</Grid>
			</ThemeProvider>
		);
	}
}
