import React, { useState } from 'react';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';
import FormHelperText from '@material-ui/core/FormHelperText';
import Delinquencies from '../graphs/Delinquencies';
import FeatureRisk from '../graphs/FeatureRisk';
import FeatureWeights from '../graphs/FeatureWeights';
import LorenzCurves from '../graphs/LorenzCurves';
import PoolFactorChart from '../graphs/PoolFactorChart';
import PredictedChargeoffs from '../graphs/AmountLost';
import PredictedOutcomes from '../graphs/PredictedOutcomes';
import PredictedPayments from '../graphs/AmountPaid';

class Chart extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			shown: true,
			loanPool: null,
			chart: null,
		};
		this.handleChangeLoanPool = this.handleChangeLoanPool.bind(this);
		this.handleChangeChart = this.handleChangeChart.bind(this);
		this.handleRemoveChart = this.handleRemoveChart.bind(this);
		this.handleRestoreChart = this.handleRestoreChart.bind(this);
	}

	handleChangeLoanPool(event) {
		var selectedLoanPool = event.target.value;
		switch (selectedLoanPool) {
			case 'Ally 2019 - 4':
				this.setState(() => ({ loanPool: 'Ally 2019 - 4' }));
				break;
			default:
				break;
		}
	}

	handleChangeChart(event) {
		var selectedChart = event.target.value;
		switch (selectedChart) {
			case 'Delinquencies':
				this.setState(() => ({ chart: <Delinquencies /> }));
				break;
			case 'Feature Tracking':
				this.setState(() => ({ chart: <FeatureRisk /> }));
				break;
			case 'Feature Weights':
				this.setState(() => ({ chart: <FeatureWeights /> }));
				break;
			case 'Lorenz Curve':
				this.setState(() => ({ chart: <LorenzCurves /> }));
				break;
			case 'Pool Factor':
				this.setState(() => ({ chart: <PoolFactorChart /> }));
				break;
			case 'Predicted Chargeoffs':
				this.setState(() => ({ chart: <PredictedChargeoffs /> }));
				break;
			case 'Predicted Outcomes':
				this.setState(() => ({ chart: <PredictedOutcomes /> }));
				break;
			case 'Predicted Payments':
				this.setState(() => ({ chart: <PredictedPayments /> }));
				break;
			default:
				break;
		}
	}

	handleRemoveChart() {
		this.setState(() => ({
			shown: false,
		}));
	}

	handleRestoreChart() {
		this.setState(() => ({
			shown: true,
		}));
	}

	render() {
		return (
			<>
				{this.state.shown ? (
					<Grid item xs={12} lg={6}>
						<Paper elevation={24}>
							<div style={{ display: 'flex', justifyContent: 'space-between' }}>
								<FormControl>
									<Select
										value={this.state.loanPool}
										onChange={this.handleChangeLoanPool}
									>
										<MenuItem value={'Ally 2019 - 4'}>Ally 2019 - 4</MenuItem>
									</Select>
									<FormHelperText>Select Loan Pool</FormHelperText>
								</FormControl>
								<FormControl>
									<Select
										value={this.state.chart}
										onChange={this.handleChangeChart}
									>
										<MenuItem value={'Delinquencies'}>Delinquencies</MenuItem>
										<MenuItem value={'Feature Fit Term'}>
											Feature Fit Term
										</MenuItem>
										<MenuItem value={'Feature Importance Term'}>
											Feature Importance Term
										</MenuItem>
										<MenuItem value={'Lorenz Curve'}>Lorenz Curve</MenuItem>
										<MenuItem value={'Pool Factor'}>Pool Factor</MenuItem>
										<MenuItem value={'Predicted Chargeoffs'}>
											Predicted Chargeoffs
										</MenuItem>
										<MenuItem value={'Predicted Outcomes'}>
											Predicted Outcomes
										</MenuItem>
										<MenuItem value={'Predicted Payments'}>
											Predicted Payments
										</MenuItem>
									</Select>
									<FormHelperText>Select Visualization</FormHelperText>
								</FormControl>
								<Button onClick={this.handleRemoveChart}>Remove Chart</Button>
							</div>
							{this.state.chart}
						</Paper>
					</Grid>
				) : (
					<Grid item xs={12} lg={6}>
						<Button onClick={this.handleRestoreChart}>Restore Chart</Button>
					</Grid>
				)}
			</>
		);
	}
}

export default function PoolFactor() {
	return (
		<Grid container spacing={4}>
			<Chart />
			<Chart />
			<Chart />
			<Chart />
		</Grid>
	);
}
