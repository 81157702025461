import React, { useState, useEffect } from 'react';

import ParetoChart from './ParetoChart';
import LoadingSpinner from '../common/LoadingSpinner';
import formatters from '../../helpers/formatters';
import NoData from '../common/NoData';
import { getFilteredData, getCumulativeSumArray } from '../../helpers/helpers';
import { chartColors as colors } from '../../theme';
import { std, min, max, round } from 'mathjs';

export default function AmountLost(props) {
	const [axisLabels, setAxisLabels] = useState([]);
	const { status, data, error, xAxisValue, yAxisValue, isFetching } = props;
	const [selectedLegend, setSelectedLegend] = useState({
		cumulative: true,
		monthlyHistory: true,
		monthlyPredicted: true,
	});
	const [dataSets, setDatasets] = useState([]);
	const [filteredData, setFilteredData] = useState([]);
	const [chartMetaInfo, setChartMetaInfo] = useState({
		dataField: 'chargedoff_principal_amount',
		chartLabel: 'Charged-Off',
		chartTitle: 'Amount Lost (Charged-Off)',
	});
	const [yaxisInfo, setYaxisInfo] = useState({
		barMin: 0,
		cumulativeMin: 0,
		cumulativeMax: 0,
	});
	const [loading, setLoading] = useState(true);

	const setAxisInfo = () => {
		// maxValToCut = (filteredData.max() - filteredData.min())*0.16
		// to_cut = 0.7*filteredData.std()
		// to_cut = min(to_cut, maxValToCut)  # If it's greater than maxValToCut, choose maxValToCut
		// to_cut = max(to_cut, 100)  # The min is 100
		// minVal = filteredData.min() - to_cut
		// minVal = max(minVal, 0)  # At least 0 (can't be negative)
		if (filteredData?.map && data?.map && filteredData.length && data.length) {
			// Only if they exist and have some data in it (otherwise mathjs throws error)
			let balanceData = filteredData.map(
				// Balance data for filtered columns
				(d) => +d[chartMetaInfo.dataField],
			);
			let globalMontlyData = data.map(
				// All Balance data
				(d) => +d[chartMetaInfo.dataField],
			);
			let globalCumulativeData = getCumulativeSumArray(
				props.data.map((datum) => +datum[chartMetaInfo.dataField]),
			);
			let maxValToCut = (max(balanceData) - min(balanceData)) * 0.14; // The Maximum value to cut based on the range
			let toCut = std(balanceData) * 0.8; // The preliminary value to cut from min value in balanceData
			toCut = min(toCut, maxValToCut); // It can't be greater than the max value to cut we found
			toCut = max(toCut, 10); // It can't be less than 10 either
			let minBarVal = min(balanceData) - toCut; // Get the min value for the chart
			minBarVal = round(max(minBarVal, 0)); // It can't be less than 0 either and have it rounded
			let minCumulativeVal =
				(minBarVal / max(globalMontlyData)) * max(globalCumulativeData); // Minimum Pool value is the equivalent portion of minBarVal for the balance to pool data
			minCumulativeVal = max(minCumulativeVal, 0); // Must be atleast 0
			let maxCumulativeVal =
				(max(balanceData) / max(globalMontlyData)) * max(globalCumulativeData); // Max Pool value is the equivalent portion of max balance val for the balance to pool data
			setYaxisInfo({
				barMin: minBarVal,
				cumulativeMin: maxCumulativeVal,
				cumulativeMax: minCumulativeVal,
			});
		}
	};
	useEffect(() => {
		if (data?.map !== undefined)
			setFilteredData(
				data
					.map(getFilteredData(['Predicted', 'History'], 'status', null))
					.filter((datum) => datum !== null),
			);
	}, [data]);

	useEffect(setAxisInfo, [filteredData]);

	const resetXAxis = () => {
		setFilteredData(data);
	};

	const removeXAxis = (sectionName) => {
		if (sectionName === 'History') {
			setFilteredData(
				data
					.map(getFilteredData(['Predicted', ''], 'status', null))
					.filter((datum) => datum !== null),
			);
		}
		if (sectionName === 'Predicted') {
			setFilteredData(
				data
					.map(getFilteredData(['History', ''], 'status', null))
					.filter((datum) => datum !== null),
			);
		}
	};

	// Props updated to re-render axis labels when parent filter wrapper changes state
	useEffect(() => {
		if (!data?.map) return;
		let dataValues = [
			{
				data: selectedLegend.monthlyHistory
					? filteredData.map(
							getFilteredData('History', 'status', chartMetaInfo.dataField),
					  )
					: null,
				backgroundColor: colors.blue,
				label: `Monthly ${chartMetaInfo.chartTitle}, History`,
				yAxisID: 'leftAxis',
				order: 1,
			},
			{
				data: selectedLegend.monthlyPredicted
					? filteredData.map(
							getFilteredData('Predicted', 'status', chartMetaInfo.dataField),
					  )
					: null,
				backgroundColor: colors.yellow,
				label: `Monthly ${chartMetaInfo.chartTitle}, Predicted`,
				yAxisID: 'leftAxis',
				order: 1,
			},
			{
				type: 'line',
				data: selectedLegend.cumulative
					? getCumulativeSumArray(
							filteredData.map((datum) => +datum[chartMetaInfo.dataField]),
					  )
					: null,
				label: `Cumulative ${chartMetaInfo.chartTitle}`,
				yAxisID: 'rightAxis',
				order: 0,
				fill: false,
				borderColor: colors.green,
			},
		];
		setDatasets(dataValues);
		setLoading(false);
	}, [data, chartMetaInfo, selectedLegend]);

	useEffect(() => {
		if (xAxisValue === 'MDY') {
			const labels = formatters.dataToDateString(filteredData);
			setAxisLabels(labels);
		} else if (xAxisValue === 'month-index') {
			const labels = formatters.dataToMonthSequence(filteredData);
			setAxisLabels(labels);
		}
		// Data field and chart label updated when component re-renders from props update
		if (yAxisValue === 'chargedoff') {
			setChartMetaInfo({
				...chartMetaInfo,
				dataField: 'chargedoff_principal_amount',
				chartLabel: 'Charged-Off',
				chartTitle: 'Amount Lost',
			});
		} else if (yAxisValue === 'net-loss') {
			setChartMetaInfo({
				...chartMetaInfo,
				dataField: 'netLoss',
				chartLabel: 'Net Loss',
				chartTitle: 'Amount Lost',
			});
		}
	}, [yAxisValue, xAxisValue, filteredData]);

	useEffect(() => {
		if (status === 'loading' || !data || isFetching) setLoading(true);
		else setLoading(false);
	}, [isFetching, status, data]);

	if (status === 'error') return `Error: ${error.message}`;

	const legendInfo = [
		{
			label: `Monthly ${chartMetaInfo.chartTitle}, History`,
			backgroundColor: colors.blue,
			status: selectedLegend.monthlyHistory,
			onClick: () => {
				if (selectedLegend.monthlyHistory) {
					// If we are showing history currently and it was clicked
					removeXAxis('History'); // Remove that section from X axis
				} else {
					resetXAxis();
				}
				setSelectedLegend({
					...selectedLegend,
					monthlyHistory: !selectedLegend.monthlyHistory,
				});
			},
		},
		{
			label: `Cumulative ${chartMetaInfo.chartTitle}`,
			backgroundColor: colors.green,
			status: selectedLegend.cumulative,
			onClick: () => {
				setSelectedLegend({
					...selectedLegend,
					cumulative: !selectedLegend.cumulative,
				});
			},
		},
		{
			label: `Monthly ${chartMetaInfo.chartTitle}, Predicted`,
			backgroundColor: colors.yellow,
			status: selectedLegend.monthlyPredicted,
			onClick: () => {
				if (selectedLegend.monthlyPredicted) {
					// If we are showing history currently and it was clicked
					removeXAxis('Predicted'); // Remove that section from X axis
				} else {
					resetXAxis(); // Reset the X axis
				}
				setSelectedLegend({
					...selectedLegend,
					monthlyPredicted: !selectedLegend.monthlyPredicted,
				});
			},
		},
	];

	return loading ? (
		<LoadingSpinner />
	) : !data?.length ? (
		<NoData
			chartTitle={`${chartMetaInfo.chartTitle} (${chartMetaInfo.chartLabel})`}
			chartTitleTooltip="Charged-off amount and net loss by month. Toggle the loss fields and filter by criteria."
		/>
	) : (
		<ParetoChart
			chartTitle={`${chartMetaInfo.chartTitle} (${chartMetaInfo.chartLabel})`}
			chartTitleTooltip="Charged-off amount and net loss by month. Toggle the loss fields and filter by criteria."
			data={data}
			axisLabels={axisLabels}
			xAxisValue={xAxisValue}
			tickLabelFormatLeft={yaxisInfo.cumulativeMin >= 1000000 ? 'M' : 'K'}
			tickLabelFormatRight="M"
			dataField={chartMetaInfo.dataField}
			dataSets={dataSets}
			legendInfo={legendInfo}
			barMin={yaxisInfo.barMin}
			cumulativeMax={yaxisInfo.cumulativeMax}
			cumulativeMin={yaxisInfo.cumulativeMin}
			ope={props.yeeHaw}
		/>
	);
}
