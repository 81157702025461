import { chartColors as colors } from '../../theme';
import { colorPalette } from '../../theme';

import {
	Slider,
	Switch,
	withStyles,
	makeStyles,
	TextField,
} from '@material-ui/core';
import { fade } from '@material-ui/core/styles/colorManipulator';
import Autocomplete from '@material-ui/lab/Autocomplete';

const useRadioStyles = makeStyles({
	radio: {
		'&': {
			// color: chartColors.green,
			// backgroundColor: chartColors.green,
		},
		'&$checked': {
			color: colors.green,
			'&:hover': {
				backgroundColor: fade(colors.green, 0.1),
			},
		},
		'&:hover': {
			backgroundColor: fade(colors.green, 0.1),
		},
		'& svg': {
			width: '0.5em',
			height: '0.5em',
		},
	},
	checked: {},
});

const useApplyGridStyles = makeStyles((theme) => ({
	root: {
		[theme.breakpoints.down('xl')]: {
			justifyContent: 'center',
		},
		[theme.breakpoints.up('xl')]: {
			justifyContent: 'space-evenly',
		},
	},
}));

const useLabelStyles = makeStyles({
	label: {
		fontSize: '12px',
	},
});
const karusBoxShadow =
	'0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.13),0 0 0 1px rgba(0,0,0,0.02)';

const useSwitchStyles = makeStyles({
	root: {
		margin: 'auto',
		textDecorationColor: colorPalette[0],
	},
	FormLabelState: {
		backgroundColor: colorPalette[0],
		color: colorPalette[0],
	},
});
const useButtonStyles = makeStyles({
	button: {
		'&:hover': {
			color: '#1C9C3E',
			border: '1px solid #1C9C3E',
		},
	},
});

const useMapFilterStyles = withStyles({
	root: {
		overflow: 'visible',
	},
});

const useLayoutStyles = makeStyles((theme) => ({
	container: {
		display: 'Grid',
		GridTemplateColumns: 'repeat(12, 1fr)',
		GridGap: theme.spacing(3),
	},
	paper: {
		padding: theme.spacing(1),
		textAlign: 'center',
		color: theme.palette.text.secondary,
		whiteSpace: 'nowrap',
		marginBottom: theme.spacing(1),
	},
	divider: {
		margin: theme.spacing(2, 0),
	},
}));

const CreditScoreSlider = withStyles({
	root: {
		color: '#1C9C3E',
		height: 2,
		padding: '15px 0',
	},
	thumb: {
		height: 14,
		width: 14,
		backgroundColor: '#32DB60',
		marginTop: -6,
		marginLeft: -6,
		'&:focus, &:hover, &$active': {
			boxShadow:
				'0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.3),0 0 0 1px rgba(0,0,0,0.02)',
			// Reset on touch devices, it doesn't add specificity
			'@media (hover: none)': {
				boxShadow: karusBoxShadow,
			},
		},
	},
	active: {},
	valueLabel: {
		left: 'calc(-50% + -2px)',
		top: -30,
		'& *': {
			background: 'solid',
			color: '#000',
			backgroundColor: '#32DB60',
		},
	},
	track: {
		height: 2,
	},
	rail: {
		height: 2,
		opacity: 0.5,
		backgroundColor: '#bfbfbf',
	},
	mark: {
		backgroundColor: '#bfbfbf',
		height: 8,
		width: 1,
		marginTop: -3,
	},
	markActive: {
		opacity: 1,
		backgroundColor: '#bfbfbf',
	},
})(Slider);

const InterestRateSlider = withStyles({
	root: {
		color: '#1C9C3E',
		height: 2,
		padding: '15px 0',
	},
	thumb: {
		boxShadow: karusBoxShadow,
		height: 14,
		width: 14,
		backgroundColor: '#32DB60',
		marginTop: -6,
		marginLeft: -6,
		'&:focus, &:hover, &$active': {
			boxShadow: 'inherit',
		},
	},
	active: {},
	valueLabel: {
		left: 'calc(-50% + -2px)',
		top: -30,
		'& *': {
			background: 'solid',
			color: '#000',
			backgroundColor: '#32DB60',
		},
	},
	track: {
		height: 2,
	},
	rail: {
		height: 2,
		opacity: 0.5,
		backgroundColor: '#bfbfbf',
	},
	mark: {
		backgroundColor: '#bfbfbf',
		height: 8,
		width: 1,
		marginTop: -3,
	},
	markActive: {
		opacity: 1,
		backgroundColor: '#bfbfbf',
	},
})(Slider);

const StateSwitch = withStyles({
	root: {
		height: '32px',
	},
	thumb: {
		height: '14px',
		width: '14px',
	},
	switchBase: {
		color: colorPalette[0],
		'&$checked': {
			color: colorPalette[0],
		},
		'&$checked + $track': {
			opacity: 1,
			border: `1px solid ${colorPalette[0]}`,
			borderRadius: 16 / 2,
			backgroundColor: colorPalette[5],
		},
	},
	checked: {},
	track: {
		border: `1px solid ${colorPalette[0]}`,
		borderRadius: 16 / 2,
		opacity: 1,
		backgroundColor: colorPalette[2],
		width: 27,
	},
})(Switch);
const VehicleFilter = withStyles({
	size: 'small',
})(Autocomplete);

export {
	useRadioStyles,
	useLabelStyles,
	useSwitchStyles,
	useButtonStyles,
	useLayoutStyles,
	CreditScoreSlider,
	InterestRateSlider,
	StateSwitch,
	VehicleFilter,
	useMapFilterStyles,
	useApplyGridStyles,
};
