import React, { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

import FeatureWeights from '../graphs/FeatureWeights';
import LorenzCurves from '../graphs/LorenzCurves';
import AxiosClient from '../../api/axios-client';

export default function ModelingInsights() {
	const { user } = useAuth0();
	const isAdmin = user['http://qa-app.karus.ai/roles'].includes(
		'Super Administrator',
	);
	const [hasAccess, setHasAccess] = useState();

	async function getAccess() {
		const response = await AxiosClient.get(
			`/organization/access/${user.email}`,
		);
		if (response.data.length) {
			setHasAccess(response.data[0].hasAdditionalAccess);
		}
	}

	useEffect(() => {
		getAccess();
	}, []);

	return (
		<>
			{!isAdmin && !hasAccess ? (
				<>
					<div
						style={{
							position: 'absolute',
							zIndex: '10',
							textDecoration: 'solid underline #32DB60 4px',
						}}
					>
						<h1>Contact Karus for access</h1>
					</div>
					<Grid container spacing={2} style={{ filter: 'blur(6px)' }}>
						<Grid item xs={12}>
							<Paper elevation={24}>
								<LorenzCurves />
							</Paper>
						</Grid>
						<Grid item xs={12}>
							<Paper elevation={24}>
								<FeatureWeights />
							</Paper>
						</Grid>
					</Grid>
				</>
			) : (
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<Paper elevation={24}>
							<LorenzCurves />
						</Paper>
					</Grid>
					<Grid item xs={12}>
						<Paper elevation={24}>
							<FeatureWeights />
						</Paper>
					</Grid>
				</Grid>
			)}
		</>
	);
}
