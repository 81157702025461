// React
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
	fetchFilterOptions,
	setDeal,
	setRequestID,
} from '../../../redux/actions/dataActions';

// Query
import { useDeals } from '../../../api/query-hooks';

// Common
import MainLoadingSpinner from '../../common/MainLoadingSpinner';
import { colorPalette as colors } from '../../../theme';

// Material UI
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
	root: {
		width: '50vw',
		margin: '0 auto',
	},
	header: {
		textAlign: 'center',
	},
	lineBreak: {
		color: '#616161',
	},
	content: {
		margin: '0 auto',
	},
	button: {
		marginTop: '2vh',
		color: '#ffffff',
		backgroundColor: '#32DB60',
	},
	backArrow: {
		color: colors[5],
	},
}));

function Alert(props) {
	return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function ABS() {
	const classes = useStyles();
	const history = useHistory();
	const dispatch = useDispatch();
	const { status, data, error } = useDeals();
	const allowedDeals = useSelector((state) => state.data.allowedDeals);

	const [originators, setOriginators] = useState([]);
	const [selectedOriginator, setSelectedOriginator] = useState();
	const [deals, setDeals] = useState([]);
	const [selectedDeal, setSelectedDeal] = useState();
	const [moveLoading, setMoveLoading] = useState(false);
	const [open, setOpen] = useState(false);

	useEffect(() => {
		if (data && data.length) {
			setOriginators(
				data
					.filter((deal) => deal.type == 'STRUCTURED')
					.map((deal) => deal.originator_name)
					.filter((item, index, self) => self.indexOf(item) == index),
			);
			setDeals(data.filter((deal) => deal.type == 'STRUCTURED'));
		}
	}, [data]);

	function handleChangeOriginator(event, value) {
		if (value) {
			setSelectedOriginator(value);
		} else {
			setSelectedOriginator();
		}
	}

	function handleChangeDeal(event, value) {
		if (value) {
			setSelectedDeal(value);
		} else {
			setSelectedDeal();
		}
	}

	function handleSubmit() {
		if (selectedDeal) {
			setMoveLoading(true);
			dispatch(fetchFilterOptions(selectedDeal.id)).then(() => {
				dispatch(setDeal(selectedDeal));
				dispatch(setRequestID(''));
				history.push('/overview');
			});
		} else {
			setOpen(true);
		}
	}

	function handleClose(event, reason) {
		if (reason === 'clickaway') {
			return;
		}
		setOpen(false);
	}

	function handleReturn() {
		history.goBack();
	}

	if (status === 'loading') return <MainLoadingSpinner />;
	if (status === 'error') return `Error: ${error.message}`;

	return moveLoading ? (
		<MainLoadingSpinner />
	) : (
		<Paper className={classes.root}>
			<Grid container>
				<Grid item xs={12}>
					<h1 className={classes.header}>Auto ABS</h1>
					<hr className={classes.lineBreak} />
					<br />
					<Grid item xs={6} className={classes.content}>
						<Autocomplete
							onChange={handleChangeOriginator}
							options={originators}
							renderInput={(originator) => (
								<TextField {...originator} label={'Originator'} />
							)}
						/>
					</Grid>
					<Grid item xs={6} className={classes.content}>
						<Autocomplete
							disabled={!selectedOriginator}
							onChange={handleChangeDeal}
							options={deals.filter(
								(deal) => deal.originator_name == selectedOriginator,
							)}
							getOptionDisabled={(deal) => !allowedDeals.includes(deal.id)}
							getOptionLabel={(deal) => deal.name}
							renderInput={(deal) => <TextField {...deal} label={'Deal'} />}
							renderOption={(deal) => {
								return (
									<li>
										{allowedDeals.includes(deal.id)
											? `${deal.name}`
											: `${deal.name} - Contact Karus for access`}
									</li>
								);
							}}
						/>
					</Grid>
				</Grid>
				<Grid item xs={6} className={classes.content}>
					<Button
						variant="contained"
						className={classes.button}
						onClick={handleSubmit}
					>
						Submit
					</Button>
				</Grid>
				<Grid item xs={12}>
					<IconButton onClick={handleReturn}>
						<ArrowBackIcon className={classes.backArrow} />
					</IconButton>
				</Grid>
			</Grid>
			<Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
				<Alert onClose={handleClose} severity="error">
					Please Select Deal to Continue
				</Alert>
			</Snackbar>
		</Paper>
	);
}
