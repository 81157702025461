import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import MixedBarLineChart from './MixedBarLineChart';
import { chartColors as colors } from '../../theme';
import { getFilteredData } from '../../helpers/helpers';

import LoadingSpinner from '../common/LoadingSpinner';
import formatters from '../../helpers/formatters';

import { std, min, max } from 'mathjs';
import NoData from '../common/NoData';

export default function CumulativeNetLossChart(props) {
	const { status, data, error, refetch, isFetching } = props;
	const [filteredData, setFilteredData] = useState([]);
	const [axisLabels, setAxisLabels] = useState([]);
	const [dataValues, setDataValues] = useState([]);
	const [maxValueOfDatafinal, setMaxValueOfData] = useState([]);
	const [selectedLegend, setSelectedLegend] = useState({
		history: true,
		predicted: true,
		cumulativePercent: true,
	});
	const [yaxisInfo, setYaxisInfo] = useState({
		barMin: 0,
		cumulativeMin: 0,
		cumulativeMax: 1.0,
	});

	const setAxis = () => {
		// maxValToCut = (filteredData.max() - filteredData.min())*0.16
		// to_cut = 0.7*filteredData.std()
		// to_cut = min(to_cut, maxValToCut)  # If it's greater than maxValToCut, choose maxValToCut
		// to_cut = max(to_cut, 100)  # The min is 100
		// minVal = filteredData.min() - to_cut
		// minVal = max(minVal, 0)  # At least 0 (can't be negative)
		if (filteredData?.map && data?.map && filteredData.length && data.length) {
			// Only if they exist and have some data in it (otherwise mathjs throws error)
			let barData = filteredData.map(
				// Balance data for filtered columns
				(d) => +d.cumulative_net_loss,
			);
			let globalBarData = data.map(
				// All Balance data
				(d) => +d.cumulative_net_loss,
			);
			let globalLineData = data.map((d) => +d.cumulative_net_loss_percent); // The global pool data
			let maxValToCut = (max(barData) - min(barData)) * 0.14; // The Maximum value to cut based on the range
			let toCut = std(barData) * 0.8; // The preliminary value to cut from min value in barData
			toCut = min(toCut, maxValToCut); // It can't be greater than the max value to cut we found
			toCut = max(toCut, 10); // It can't be less than 10 either
			let minBarVal = min(barData) - toCut; // Get the min value for the chart
			minBarVal = max(minBarVal, 0); // It can't be less than 0 either
			let minLineVal = (minBarVal / max(globalBarData)) * max(globalLineData); // Minimum Pool value is the equivalent portion of minBarVal for the balance to pool data
			let maxLineVal =
				(max(barData) / max(globalBarData)) * max(globalLineData); // Max Pool value is the equivalent portion of max balance val for the balance to pool data
			setYaxisInfo({
				barMin: minBarVal,
				cumulativeMax: maxLineVal,
				cumulativeMin: minLineVal,
			});
		}
	};

	useEffect(() => {
		refetch();
	}, [useSelector((state) => state.data.deal.id)]);

	useEffect(() => {
		if (data?.map !== undefined)
			setFilteredData(
				data
					.map(getFilteredData(['Predicted', 'History'], 'status', null))
					.filter((datum) => datum !== null),
			);
	}, [data]);

	useEffect(setAxis, [filteredData]);

	const resetXAxis = () => {
		setFilteredData(data);
	};

	const removeXAxis = (sectionName) => {
		if (sectionName === 'History') {
			setFilteredData(
				data
					.map(getFilteredData(['Predicted', ''], 'status', null))
					.filter((datum) => datum !== null),
			);
		}
		if (sectionName === 'Predicted') {
			setFilteredData(
				data
					.map(getFilteredData(['History', ''], 'status', null))
					.filter((datum) => datum !== null),
			);
		}
	};

	useEffect(() => {
		if (filteredData?.map !== undefined) {
			const labels = formatters.dataToDateString(filteredData);
			const datasets = [];
			var maxValueOfData = 0;
			filteredData.map((d) => {
				parseInt(d.cumulative_net_loss) >= maxValueOfData
					? (maxValueOfData = d.cumulative_net_loss)
					: '';
			});
			datasets.push({
				label: 'Cumulative Net Loss, History',
				//yAxisID: 'leftAxis',
				data: selectedLegend.history
					? filteredData.map(
							getFilteredData('History', 'status', 'cumulative_net_loss'),
					  )
					: null,
				order: 1,
				backgroundColor: colors.blue,
			});
			datasets.push({
				label: 'Cumulative Net Loss, Predicted',
				data: selectedLegend.predicted
					? filteredData.map(
							getFilteredData('Predicted', 'status', 'cumulative_net_loss'),
					  )
					: null,
				order: 1,
				backgroundColor: colors.yellow,
			});
			datasets.push({
				type: 'line',
				label: 'Cumulative Net Loss Percent',
				yAxisID: 'leftAxis',
				data: selectedLegend.cumulativePercent
					? filteredData.map((datum) => +datum.cumulative_net_loss_percent)
					: null,
				order: 0,
				fill: false,
				borderColor: colors.green,
			});
			setAxisLabels(labels);
			setDataValues(datasets);
			setMaxValueOfData(maxValueOfData);
		}
	}, [filteredData, selectedLegend]);

	// Props updated to re-render axis labels when parent filter wrapper changes state
	useEffect(() => {
		if (!filteredData?.map) return;
		let labels;
		if (props.xAxisValue === 'MDY') {
			labels = formatters.dataToDateString(filteredData);
			setAxisLabels(labels);
		} else if (props.xAxisValue === 'month-index') {
			labels = formatters.dataToMonthSequence(filteredData);
			setAxisLabels(labels);
		}
	}, [props.yAxisValue, props.xAxisValue, filteredData]);

	if (status === 'loading' || !data || isFetching) return <LoadingSpinner />;
	if (status === 'error') return `Error: ${error.message}`;

	const legendInfo = [
		{
			label: 'Cumulative Net Loss, History',
			backgroundColor: colors.blue,
			status: selectedLegend.history,
			onClick: () => {
				if (selectedLegend.history) {
					// If we are showing history currently and it was clicked
					removeXAxis('History'); // Remove that section from X axis
				} else {
					resetXAxis();
				}
				setSelectedLegend({
					...selectedLegend,
					history: !selectedLegend.history,
				});
				setAxis();
			},
		},
		{
			label: 'Cumulative Net Loss Percent',
			backgroundColor: colors.green,
			status: selectedLegend.cumulativePercent,
			onClick: () => {
				setSelectedLegend({
					...selectedLegend,
					cumulativePercent: !selectedLegend.cumulativePercent,
				});
			},
		},
		{
			label: 'Cumulative Net Loss, Predicted',
			backgroundColor: colors.yellow,
			status: selectedLegend.predicted,
			onClick: () => {
				if (selectedLegend.predicted) {
					// If we are showing history currently and it was clicked
					removeXAxis('Predicted'); // Remove that section from X axis
				} else {
					resetXAxis(); // Reset the X axis
				}
				setSelectedLegend({
					...selectedLegend,
					predicted: !selectedLegend.predicted,
				});
				setAxis();
			},
		},
	];
	return dataValues?.length === 0 ? (
		<NoData
			chartTitle="Cumulative Net Loss"
			chartTitleTooltip="Cumulative net loss by month or month index."
		/>
	) : (
		<MixedBarLineChart
			chartTitle="Cumulative Net Loss"
			chartTitleTooltip="Cumulative net loss by month or month index."
			data={dataValues}
			axisLabels={axisLabels}
			xAxisValue={props.xAxisValue}
			tickLabelFormat="M"
			loanPoolMaxValue={maxValueOfDatafinal}
			legendInfo={legendInfo}
			barMin={yaxisInfo.barMin}
			lineMax={yaxisInfo.cumulativeMax}
			lineMin={yaxisInfo.cumulativeMin}
			barTitle={'Cumulative Net Loss'}
			lineTitle={'Cumulative Net Loss Percent'}
			netBig={props.netBig}
		/>
	);
}
