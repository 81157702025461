import React, { Component, useEffect, useState } from 'react';

import { ThemeProvider } from '@material-ui/core';
import { createMuiTheme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import MUIDataTable from 'mui-datatables';
import { withRouter } from 'react-router-dom';
import { useSelector, connect } from 'react-redux';
import formatters from '../../helpers/formatters';
import { colorPalette as colors } from '../../theme';
import { makeStyles } from '@material-ui/core/styles';

// tooltip imports
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import HelpIcon from '@material-ui/icons/Help';

import NoData from '../common/NoData';
import LoadingSpinner from '../common/LoadingSpinner';
import KeyMetrics from './KeyMetrics';

import { useSummary, useDealSummary, useDealInfo } from '../../api/query-hooks';
import Divider from '@material-ui/core/Divider';

const options = {
	elevation: 0,
	filter: false,
	selectableRows: 'none',
	search: false,
	download: false,
	print: false,
	viewColumns: false,
	pagination: false,
	rowsPerPage: 15,
};

const theme = createMuiTheme({
	palette: {
		type: 'dark',
	},
	overrides: {
		MUIDataTableHeaderCell: {
			fontSize: '1.7em',
		},
		MUIDataTableBodyRow: {
			root: {
				'&:nth-child(odd)': {
					backgroundColor: '#666666',
				},
			},
		},
		MuiTableCell: {
			root: {
				padding: '5px',
				fontSize: '1.2em',
			},
		},
		MUIDataTableToolbar: {
			icon: {
				'&:hover': {
					color: '#32DB60',
				},
			},
		},
	},
});

const summaryColumns = [
	{
		name: 'field',
		label: 'Field',
	},
	{
		name: 'current',
		label: 'Closed',
	},
	{
		name: 'active',
		label: 'Active',
	},
];

const SummaryTable = () => {
	const { status, data, error, refetch } = useSummary();

	useEffect(() => {
		refetch();
	}, [useSelector((state) => state.data.deal.id)]);

	if (status === 'loading') return <LoadingSpinner />;
	if (status === 'error') return `Error: ${error.message}`;

	function isValid(value) {
		if (
			typeof value === 'undefined' ||
			value === null ||
			value === 'No active accounts'
		) {
			return false;
		} else {
			return true;
		}
	}

	data.forEach((e) => {
		switch (e.field) {
			case 'Originator':
				e.current = isValid(e.current) ? e.current : 'Values not available';
				e.active = isValid(e.active) ? e.active : 'Values not available';
				break;
			case 'Deal':
				e.current = isValid(e.current) ? e.current : 'Values not available';
				e.active = isValid(e.active) ? e.active : 'Values not available';
				break;
			case 'Settle date':
				e.current = isValid(e.current) ? e.current : 'Values not available';
				e.active = isValid(e.active) ? e.active : 'Values not available';
				break;
			case 'Total accounts':
				e.current = formatters.addCommas(e.current);
				e.active = formatters.addCommas(e.active);
				break;
			case 'Closed accounts':
				e.current = formatters.addCommas(e.current);
				e.active = formatters.addCommas(e.active);
				break;
			case 'Active accounts':
				e.current = formatters.addCommas(e.current);
				e.active = formatters.addCommas(e.active);
				break;
			case 'Weighted credit score':
				e.current = formatters.addCommas(e.current);
				e.active = formatters.addCommas(e.active);
				break;
			case 'Weighted interest rate':
				e.current = formatters.toPercentage(e.current);
				e.active = formatters.toPercentage(e.active);
				break;
			case 'Original balance':
				e.current = formatters.toCurrency(e.current);
				e.active = formatters.toCurrency(e.active);
				break;
			case 'Current balance':
				e.current = formatters.toCurrency(e.current);
				e.active = formatters.toCurrency(e.active);
				break;
			case 'Pool factor':
				e.current = formatters.toPercentage(e.current);
				e.active = formatters.toPercentage(e.active);
				break;
			case 'Principal collected':
				e.current = formatters.toCurrency(e.current);
				e.active = formatters.toCurrency(e.active);
				break;
			case 'Interest collected':
				e.current = formatters.toCurrency(e.current);
				e.active = formatters.toCurrency(e.active);
				break;
			case 'Charge-off amount':
				e.current = formatters.toCurrency(e.current);
				e.active = formatters.toCurrency(e.active);
				break;
			case 'Recovered amount':
				e.current = formatters.toCurrency(e.current);
				e.active = formatters.toCurrency(e.active);
				break;
			case 'Total collected':
				e.current = formatters.toCurrency(e.current);
				e.active = formatters.toCurrency(e.active);
				break;
			case 'Charge-off rate' || 'Charged off rate':
				e.current = formatters.toPercentage(e.current);
				e.active = formatters.toPercentage(e.active);
				break;
			case 'Recovery rate':
				e.current = formatters.toPercentage(e.current);
				e.active = formatters.toPercentage(e.active);
				break;
			case 'Discount rate':
				e.current = formatters.toPercentage(e.current);
				e.active = formatters.toPercentage(e.active);
				break;
			case 'Fair value':
				e.current = formatters.toCurrency(e.current);
				e.active = formatters.toCurrency(e.active);
				break;
			case 'Fair value price':
				e.current = formatters.toDouble(e.current);
				e.active = formatters.toDouble(e.active);
				break;
			case 'Yield (at par)':
				e.current = formatters.toPercentage(e.current);
				e.active = formatters.toPercentage(e.active);
				break;
			default:
				break;
		}
	});

	return data.length === 0 ? (
		<NoData
			chartTitle="Closed and active performance"
			chartTitleTooltip="Comparison of closed account performance and active account predicted performance."
		/>
	) : (
		<>
			<Typography
				style={{
					fontSize: '1.7em',
					fontWeight: 'bold',
					marginBottom: 12,
				}}
			>
				Closed and Active Performance{' '}
				<Tooltip
					placement="right"
					title={
						<Typography style={{ fontSize: '1.4em' }}>
							Comparison of closed account performance and active account
							predicted performance.
						</Typography>
					}
				>
					<IconButton>
						<HelpIcon fontSize="small" />
					</IconButton>
				</Tooltip>
			</Typography>
			<MUIDataTable columns={summaryColumns} data={data} options={options} />
		</>
	);
};

const dealSummaryColumns = [
	{
		name: 'field',
		label: 'Field',
	},
	{
		name: 'value',
		label: 'Value',
	},
];

function DealSummaryTable() {
	const { status, data, error, refetch } = useDealSummary();

	useEffect(() => {
		refetch();
	}, [useSelector((state) => state.data.deal.id)]);

	if (status === 'loading') return <LoadingSpinner />;
	if (status === 'error') return `Error: ${error.message}`;

	function isValid(value) {
		if (
			typeof value === 'undefined' ||
			value === null ||
			value === 'No active accounts'
		) {
			return false;
		} else {
			return true;
		}
	}

	data.forEach((e) => {
		switch (e.field) {
			case 'Originator':
				e.value = isValid(e.value) ? e.value : 'Values not available';
				break;
			case 'Securitization':
				e.value = isValid(e.value) ? e.value : 'Values not available';
				break;
			case 'Accounts':
				e.value = isValid(e.value)
					? formatters.addCommas(e.value)
					: 'Values not available';
				break;
			case 'Principal collected':
				e.value = isValid(e.value)
					? formatters.toCurrency(e.value)
					: 'Values not available';
				break;
			case 'Interest collected':
				e.value = isValid(e.value)
					? formatters.toCurrency(e.value)
					: 'Values not available';
				break;
			case 'Charged-off amount':
				e.value = isValid(e.value)
					? formatters.toCurrency(e.value)
					: 'Values not available';
				break;
			case 'Recovered amount':
				e.value = isValid(e.value)
					? formatters.toCurrency(e.value)
					: 'Values not available';
				break;
			case 'Total collected':
				e.value = isValid(e.value)
					? formatters.toCurrency(e.value)
					: 'Values not available';
				break;
			case 'Discount rate':
				e.value = isValid(e.value)
					? formatters.toPercentage(e.value)
					: 'Values not available';
				break;
			case 'Charge-off rate' || 'Charged off rate':
				e.value = isValid(e.value)
					? formatters.toPercentage(e.value)
					: 'Values not available';
				break;
			case 'Recovery rate':
				e.value = isValid(e.value)
					? formatters.toPercentage(e.value)
					: 'Values not available';
				break;
			case 'Fair value':
				e.value = isValid(e.value)
					? formatters.toCurrency(e.value)
					: 'Values not available';
				break;
			case 'Fair value price':
				e.value = isValid(e.value)
					? formatters.toPercentage(e.value)
					: 'Values not available';
				break;
			case 'Yield (at par)':
				e.value = isValid(e.value)
					? formatters.toPercentage(e.value)
					: 'Values not available';
				break;
			default:
				break;
		}
	});

	return data.length === 0 ? (
		<NoData
			chartTitle="Deal Summary"
			chartTitleTooltip="Analysis of all accounts within a deal, combining closed account performance and active account predicted performance through final maturity date."
		/>
	) : (
		<>
			<Typography
				style={{
					fontSize: '1.7em',
					fontWeight: 'bold',
					marginBottom: 12,
				}}
			>
				Deal Summary{' '}
				<Tooltip
					placement="right"
					title={
						<Typography style={{ fontSize: '1.4em' }}>
							Analysis of all accounts within a deal, combining closed account
							performance and active account predicted performance through final
							maturity date.
						</Typography>
					}
				>
					<IconButton>
						<HelpIcon fontSize="small" />
					</IconButton>
				</Tooltip>
			</Typography>
			<MUIDataTable
				columns={dealSummaryColumns}
				data={data}
				options={options}
			/>
		</>
	);
}

function DealInfoTable() {
	const { status, data, error, refetch } = useDealInfo();
	const summaryResponse = useSummary();

	const [tableData, setTableData] = useState([]);

	const useStyles = makeStyles((theme) => ({
		root: {
			flexGrow: 1,
			borderStyle: 'solid',
			borderColor: colors[3],
			padding: 5,
			borderRadius: '5px',
			color: colors[0],
			fontSize: 30,
			minWidth: 1080,
		},
		paper: {
			padding: theme.spacing(2),
			textAlign: 'center',
			color: colors[0],
			margin: 10,
			fontSize: 18,
		},
		p: {
			color: colors[4],
			fontSize: 15,
			marginTop: -5,
			marginBottom: 3,
		},
		h1: {
			paddingLeft: 5,
			marginTop: 10,
			fontSize: '23px',
		},
		item: {
			padding: 10,
			paddingLeft: 20,
		},
	}));

	const classes = useStyles();

	useEffect(() => {
		if (data && summaryResponse.data) {
			setTableData(data);

			// Extract discount rate from useSummary return object, set active value as value, add to tableData
			let row = summaryResponse.data.find(
				(row) => row.field.toLowerCase() === 'discount rate',
			);
			if (row) {
				let discountRate = {
					field: row.field,
					value: row.active,
				};
				setTableData((tableData) => [...tableData, discountRate]);
			}
		}
	}, [data, summaryResponse.data]);

	useEffect(() => {
		refetch();
	}, [useSelector((state) => state.data.deal.id)]);

	if (status === 'loading') return <LoadingSpinner />;
	if (status === 'error') return `Error: ${error.message}`;

	function isValid(value) {
		if (
			typeof value === 'undefined' ||
			value === null ||
			value === 'No active accounts' ||
			value === '' // check for empty string in data value
		) {
			return false;
		} else {
			return true;
		}
	}

	tableData.forEach((e) => {
		switch (e.field) {
			case 'Originator':
				e.value = isValid(e.value) ? e.value : 'Values not available';
				break;
			case 'Securitization':
				e.value = isValid(e.value) ? e.value : 'Values not available';
				break;
			case 'Total accounts':
				e.value = isValid(e.value)
					? formatters.addCommas(e.value)
					: 'Values not available';
				break;
			case 'Closed accounts':
				e.value = isValid(e.value)
					? formatters.addCommas(e.value)
					: 'Values not available';
				break;
			case 'Active accounts':
				e.value = isValid(e.value)
					? formatters.addCommas(e.value)
					: 'Values not available';
				break;
			case 'Original balance':
				e.value = isValid(e.value)
					? formatters.toCurrency(e.value)
					: 'Values not available';
				break;
			case 'Current balance':
				e.value = isValid(e.value)
					? formatters.toCurrency(e.value)
					: 'Values not available';
				break;
			case 'Pool factor':
				e.value = isValid(e.value)
					? formatters.toPercentage(e.value)
					: 'Values not available';
				break;
			case 'Settle date':
				e.value = isValid(e.value) ? e.value : 'Values not available';
				break;
			case 'Weighted credit score':
				e.value = isValid(e.value) ? e.value : 'Values not available';
				break;
			case 'Weighted interest rate':
				e.value = isValid(e.value)
					? formatters.toPercentage(e.value)
					: 'Values not available';
				break;
			// This is already getting formatted in the Summary table
			case 'Discount rate':
				e.value = isValid(e.value) ? e.value : 'Values not available';
				break;
			default:
				break;
		}
	});

	return data.length === 0 ? (
		<NoData
			chartTitle="Deal Info"
			chartTitleTooltip="Deal summary information."
		/>
	) : (
		<Paper elevation={0}>
			<Typography
				style={{
					fontSize: '1.7em',
					fontWeight: 'bold',
					marginBottom: 12,
				}}
			>
				Deal Info
				<Tooltip
					placement="right"
					title={
						<Typography style={{ fontSize: '1.4em' }}>
							Deal summary information.
						</Typography>
					}
				>
					<IconButton>
						<HelpIcon fontSize="small" />
					</IconButton>
				</Tooltip>
			</Typography>
			{tableData && tableData.length && (
				<Grid container direction={'row'} className={classes.root}>
					{tableData.map((item, index) => {
						return (
							<>
								<Grid item className={classes.item} key={index} xs={3}>
									{item.value}
									<p className={classes.p}>{item.field}</p>
								</Grid>
								{/*<Grid item key={index} xs={1}>*/}
								{/*	<Divider orientation={'vertical'} />*/}
								{/*</Grid>*/}
							</>
						);
					})}
				</Grid>
			)}
		</Paper>
	);
}

class Overview extends Component {
	constructor(props) {
		super(props);
		this.state = {
			open: false,
		};
	}

	render() {
		return (
			<ThemeProvider theme={theme}>
				<Grid container spacing={4}>
					<Grid item xs={12} style={{ width: '80vw', overflow: 'none' }}>
						<Paper
							elevation={24}
							style={{
								padding: 10,
								width: '100%',
								maxWidth: '100%',
								overflowX: 'auto',
							}}
						>
							<DealInfoTable />
						</Paper>
					</Grid>
					<Grid item xs={12}>
						<KeyMetrics data={this.props} />
					</Grid>
					<Grid item xs={12} style={{ width: '80vw', overflow: 'none' }}>
						<Paper
							elevation={24}
							style={{
								padding: 10,
								width: '100%',
								maxWidth: '100%',
								overflowX: 'auto',
							}}
						>
							<DealSummaryTable />
						</Paper>
					</Grid>
					<Grid item xs={12} style={{ width: '80vw', overflow: 'none' }}>
						<Paper
							elevation={24}
							style={{
								padding: 10,
								width: '100%',
								maxWidth: '100%',
								overflowX: 'auto',
							}}
						>
							<SummaryTable />
						</Paper>
					</Grid>
				</Grid>
			</ThemeProvider>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		deal: state.data.deal,
	};
};

export default withRouter(connect(mapStateToProps)(Overview));
