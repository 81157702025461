import axios from 'axios';

// creates instance of axios client setting the baseURL for all axios requests
const AxiosClient = axios.create({
	withCredentials: true,
});

axios
	.get('/config.json')
	.then((res) => {
		AxiosClient.defaults.baseURL = res.data.apiBaseUrl;
	})
	.catch((err) => console.log('Error during initialization', err));

export default AxiosClient;
