import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import FeatureWeightsChart from './FeatureWeightsChart';
import { useFeatureWeights } from '../../api/query-hooks';
import { chartColorsArray as colors } from '../../theme';

import LoadingSpinner from '../common/LoadingSpinner';
import NoData from '../common/NoData';
import formatters from '../../helpers/formatters';

export default function FeatureWeights() {
	const { status, data, error, refetch } = useFeatureWeights();
	const [axisLabels, setAxisLabels] = useState([]);
	const [dataValues, setDataValues] = useState([]);

	useEffect(() => {
		refetch();
	}, [useSelector((state) => state.data.deal.id)]);

	useEffect(() => {
		if (data?.map) {
			const labels = data.map((row) => formatters.camelToHuman(row.features));
			const datasets = [];
			datasets.push({
				label: 'Weight',
				backgroundColor: colors[0],
				fill: false,
				data: data.map((d) => +d.weight),
			});
			setAxisLabels(labels);
			setDataValues(datasets);
		}
	}, [data]);

	if (status === 'loading' || !data) return <LoadingSpinner />;
	if (status === 'error') return `Error: ${error.message}`;

	return data.length === 0 ? (
		<NoData
			chartTitle="Feature Weights"
			chartTitleTooltip="The weightings of select features included in our ML models. Weightings are relative and sum to 100."
		/>
	) : (
		<FeatureWeightsChart
			chartTitle="Feature Weights"
			chartTitleTooltip="The weightings of select features included in our ML models. Weightings are relative and sum to 100."
			tickLabelFormat="feature"
			data={dataValues}
			axisLabels={axisLabels}
			// chartContainerOverrides="screen-expand-chart"
		/>
	);
}
