// React
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

// Queries
import { useTrancheSummaryClassScenario } from '../../../api/query-hooks';

// Common
import formatters from '../../../helpers/formatters';
import LoadingSpinner from '../../common/LoadingSpinner';
import NoData from '../../common/NoData';
import '../../styles/legend.scss';

// Material UI
import Paper from '@material-ui/core/Paper';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import HelpIcon from '@material-ui/icons/Help';
import IconButton from '@material-ui/core/IconButton';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/core';
import MUIDataTable from 'mui-datatables';

export default function TrancheSummaryClassTable() {
	const { status, data, error, refetch, remove } =
		useTrancheSummaryClassScenario();

	useEffect(() => {
		remove();
		refetch();
	}, [
		useSelector((state) => state.data.deal.id),
		useSelector((state) => state.data.requestID),
	]);

	const theme = createMuiTheme({
		palette: {
			type: 'dark',
		},
		overrides: {
			MUIDataTableHeaderCell: {
				fontSize: '1.7em',
			},
			MUIDataTableBodyRow: {
				root: {
					'&:nth-child(odd)': {
						backgroundColor: '#666666',
					},
				},
			},
			MuiTableCell: {
				root: {
					padding: '5px',
					fontSize: '1.2em',
				},
			},
			MUIDataTableToolbar: {
				icon: {
					'&:hover': {
						color: '#32DB60',
					},
				},
			},
		},
	});

	const columns = [
		{
			name: 'class',
			label: 'Class',
		},
		{
			name: 'originalBalance',
			label: 'Original Balance',
			options: {
				filter: false,
				customBodyRender: (value) =>
					value ? formatters.toCurrency(value) : `Values not available`,
			},
		},
		{
			name: 'interestRate',
			label: 'Interest Rate',
			options: {
				filter: false,
				customBodyRender: (value) =>
					value ? formatters.toPercentage(value) : `Values not available`,
			},
		},
		{
			name: 'principalPaid',
			label: 'Principal Paid',
			options: {
				filter: false,
				customBodyRender: (value) =>
					value ? formatters.toCurrency(value) : `Values not available`,
			},
		},
		{
			name: 'interestPaid',
			label: 'Interest Paid',
			options: {
				filter: false,
				customBodyRender: (value) =>
					value ? formatters.toCurrency(value) : `Values not available`,
			},
		},
		{
			name: 'paid',
			label: 'Paid',
			options: {
				filter: false,
				customBodyRender: (value) => (value ? 'True' : 'False'),
			},
		},
		{
			name: 'payoffDate',
			label: 'Payoff Date',
		},
	];

	const options = {
		elevation: 0,
		filter: false,
		selectableRows: 'none',
		search: false,
		download: false,
		print: false,
		viewColumns: false,
		pagination: false,
		rowsPerPage: 15,
	};

	if (status === 'loading') return <LoadingSpinner />;

	// Display NoData component instead.
	// if (status === 'error') return `Error: ${error.message}`;

	return !data ? (
		<Paper>
			<NoData
				chartTitle="Tranche Summary Class"
				chartTitleTooltip="Summary of tranche principal and coupon payments. This
				table combines current tranche payments from
				securitization release documents and Karus predicted
				payment schedules past the settle date."
			/>
		</Paper>
	) : (
		<ThemeProvider theme={theme}>
			<MUIDataTable
				title={
					<Typography
						style={{
							fontSize: '1.7em',
							fontWeight: 'bold',
							marginBottom: 12,
						}}
					>
						Tranche Summary Class{' '}
						<Tooltip
							placement="right"
							title={
								<Typography style={{ fontSize: '1.4em' }}>
									Summary of tranche principal and coupon payments. This table
									combines current tranche payments from securitization release
									documents and Karus predicted payment schedules past the
									settle date.
								</Typography>
							}
						>
							<IconButton>
								<HelpIcon fontSize="small" />
							</IconButton>
						</Tooltip>
					</Typography>
				}
				data={data}
				columns={columns}
				options={options}
			/>
		</ThemeProvider>
	);
}
