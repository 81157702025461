import React, { useState, useEffect } from 'react';

import FeatureTrackingChart from './FeatureTrackingChart';
import { chartColors as colors } from '../../theme';

import LoadingSpinner from '../common/LoadingSpinner';
import NoData from '../common/NoData';

export default function FeatureRisk(props) {
	const { status, data, error } = props;
	const [axisLabels, setAxisLabels] = useState([]);
	const [dataSets, setDataSets] = useState([]);

	useEffect(() => {
		if (data?.map) {
			const labels = data.map((d) => d.range);
			const dataValues = [
				{
					type: 'line',
					data: data.map((d) => +d.mean_target),
					label: 'Mean Target',
					borderColor: colors.yellow,
					yAxisId: 'leftAxis',
				},
				{
					data: data.map((d) => +d.objects_per_bin),
					label: 'Loans Per Bin',
					backgroundColor: colors.green,
					yAxisID: 'rightAxis',
				},
			];
			setAxisLabels(labels);
			setDataSets(dataValues);
		}
	}, [data]);

	if (status === 'loading' || !data) return <LoadingSpinner />;
	if (status === 'error') return `Error: ${error.message}`;

	return !dataSets?.length ? (
		<NoData
			chartTitle="Feature Tracking"
			chartTitleTooltip="Detailed default rates for different values of numerical features included in the Karus ML models."
		/>
	) : (
		<FeatureTrackingChart
			chartTitle="Feature Tracking"
			chartTitleTooltip="Detailed default rates for different values of numerical features included in the Karus ML models."
			data={data}
			dataSets={dataSets}
			axisLabels={axisLabels}
			chartContainerOverrides="screen-expand-chart"
		/>
	);
}
