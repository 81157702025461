import React, { useEffect, useState, useRef } from 'react';
import {
	Chart,
	BarController,
	BarElement,
	LineController,
	LineElement,
	PointElement,
	Tooltip as ChartToolip,
	Legend,
	CategoryScale,
} from 'chart.js';

// tooltip imports
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import HelpIcon from '@material-ui/icons/Help';

import { legendGenerator } from '../../helpers/helpers';

import '../styles/legend.scss';
import '../styles/Chart.scss';
import LegendTooltip from '../common/LegendTooltip';

import { round } from 'mathjs';

Chart.defaults.color = '#f0f0f0';
export default function MixedBarLineChart(props) {
	const chartRef = useRef();
	const [chart, setChart] = useState();

	const createChart = () => {
		Chart.register(
			BarController,
			BarElement,
			LineController,
			LineElement,
			PointElement,
			ChartToolip,
			Legend,
			CategoryScale,
		);
		if (props.data && props.axisLabels) {
			setChart(
				new Chart(chartRef.current, {
					type: 'bar',
					data: {
						labels: props.axisLabels,
						datasets: props.data,
					},
					options: {
						responsive: true,
						maintainAspectRatio: false,
						elements: {
							// hide points on line, but retain tooltips
							point: {
								radius: 0,
								hitRadius: 5,
								borderWidth: 0,
								hoverBorderWidth: 0,
								backgroundColor: 'rgba(0, 0, 0, 0.0)',
								color: 'rgba(0, 0, 0, 0.0)',
							},
						},
						plugins: {
							legend: {
								display: false,
								labels: {
									color: '#f0f0f0',
								},
								position: 'top',
								onHover: function (e, legendItem, legend) {
									e.native.target.style.cursor = 'pointer';
								}.bind(this),
								onLeave: function (e, legendItem, legend) {
									e.native.target.style.cursor = 'default';
								}.bind(this),
								onClick: function (e, legendItem, legend) {
									const index = legendItem.datasetIndex;
									const ci = legend.chart;
									if (ci.isDatasetVisible(index)) {
										ci.hide(index);
										legendItem.hidden = true;
										if (legendItem.text.includes('History')) {
											props.removeXAxis('History');
										} else {
											props.removeXAxis('Predicted');
										}
									} else {
										ci.show(index);
										legendItem.hidden = false;
										props.resetXAxis();
									}
								},
							},
						},
						scales: {
							x: {
								axis: 'x',
								grid: {
									drawTicks: false,
									display: true,
									padding: 10,
								},
								display: true,
								beginAtZero: true,
								position: 'right',
								helpers: {
									callback: (value) =>
										props.tickLabelFormat === 'K'
											? `${round(value / 1000)}K`
											: props.tickLabelFormat === 'M'
											? `${round(value / 1000000)}M`
											: value,
								},
								ticks: {
									display: true,
									color: '#e1e1e1',
									font: {
										weight: 'bold',
									},
									padding: 10,
									userCallback(label, index, labels) {
										// only show if whole number
										if (Math.floor(label) === label) {
											return label;
										}
									},
								},
								title: {
									display: true,
									text:
										// Temporary conditional logic for adding axis labels
										props.xAxisValue === 'MDY'
											? 'Date'
											: props.xAxisValue === 'month-index'
											? 'Month Index'
											: '',
								},
							},
							rightAxis: {
								max: props.loanPoolMaxValue,
								min: props.barMin,
								position: 'left',
								grid: {
									drawTicks: false,
									display: false,
								},
								beginAtZero: true,
								display: true,
								ticks: {
									font: {
										weight: 'bold',
									},
									padding: 10,
									color: '#e1e1e1',
									callback: (value) =>
										props.tickLabelFormat === 'K'
											? `${Math.floor(value / 1000)}K`
											: props.tickLabelFormat === 'M'
											? `${Math.floor(value / 1000000)}M`
											: Math.floor(value),
								},
								title: {
									display: true,
									text: props.barTitle,
								},
							},
							leftAxis: {
								//axis: 'y',
								position: 'right',
								min: props.lineMin,
								max: props.lineMax,
								grid: {
									drawTicks: false,
									display: true,
								},
								display: true,
								ticks: {
									color: '#e1e1e1',
									font: {
										weight: 'bold',
									},
									display: true,
								},
								title: {
									display: true,
									text: props.lineTitle,
								},
								beginAtZero: true,
								padding: 10,
								callback: (value) =>
									props.tickLabelFormat === 'K'
										? `${value / 1000}K`
										: props.tickLabelFormat === 'M'
										? `${value / 1000000}M`
										: value,
							},
						},
					},
				}),
			);
		}
	};

	useEffect(() => {
		if (chart) chart.destroy();
		createChart();
	}, [props.data, props.axisLabels]);

	return (
		<>
			<Typography
				style={{
					fontSize: '1.7em',
					fontWeight: 'bold',
					marginBottom: 12,
					position: 'relative',
				}}
			>
				{props.chartTitle}
				<Tooltip
					placement="right"
					title={
						<Typography style={{ fontSize: '1.4em' }}>
							{props.chartTitleTooltip}
						</Typography>
					}
				>
					<IconButton>
						<HelpIcon fontSize="small" />
					</IconButton>
				</Tooltip>
			</Typography>

			{legendGenerator(props.legendInfo)}

			<div
				style={{ position: 'relative', height: props.poolBig || props.netBig }}
				className="chart-container"
			>
				<canvas ref={chartRef} />
			</div>
		</>
	);
}
