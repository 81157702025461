import './App.css';
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { ThemeProvider, CssBaseline } from '@material-ui/core';
import { useAuth0 } from '@auth0/auth0-react';
import { Auth0Provider } from '@auth0/auth0-react';
import axios from 'axios';

import theme from './theme';
import Login from './components/login/login';
import Dashboard from './components/dashboard/Dashboard';
import AxiosClient from './api/axios-client';

import MainLoadingSpinner from './components/common/MainLoadingSpinner';

function App() {
	const [loading, setLoading] = useState(true);
	const [config, setConfig] = useState();

	useEffect(() => {
		axios
			.get('/config.json')
			.then((res) => {
				setConfig(res.data);
				setLoading(false);
			})
			.catch((err) => console.log('Error during initialization', err));
	}, []);

	if (loading || !config) return 'Loading...';

	return (
		<Auth0Provider
			domain={config.auth0.domain}
			clientId={config.auth0.clientId}
			redirectUri={config.auth0.redirectUri}
			audience={config.auth0.audience}
		>
			<ThemeProvider theme={theme}>
				<CssBaseline />
				<Router>
					<div className="App">
						<AuthWrapper>
							<Dashboard />
						</AuthWrapper>
					</div>
				</Router>
			</ThemeProvider>
		</Auth0Provider>
	);
}

function AuthWrapper(props) {
	const { isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0();
	if (isLoading) return <MainLoadingSpinner />;
	if (!isAuthenticated) return <Login />;

	// add Auth0 JWT token to all requests made through AxiosClient
	if (isAuthenticated) {
		getAccessTokenSilently().then((token) => {
			AxiosClient.defaults.headers['Authorization'] = `Bearer ${token}`;
		});
	}

	return props.children;
}

export default App;
