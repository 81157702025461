import './index.css';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { QueryClientProvider } from 'react-query';
import queryClient from './api/query-client';

// === REDUX ===
import { Provider } from 'react-redux';
import store from './redux/store';

const {
	REACT_APP_AUTH0_DOMAIN,
	REACT_APP_AUTH0_CLIENT_ID,
	REACT_APP_AUTH0_REDIRECT_URI,
	REACT_APP_AUTH0_AUDIENCE,
} = process.env;

ReactDOM.render(
	<QueryClientProvider client={queryClient}>
		<Provider store={store}>
			<App />
		</Provider>
	</QueryClientProvider>,
	document.getElementById('root'),
);
