import React, { useEffect, useState, useRef } from 'react';
import {
	Chart,
	CategoryScale,
	LinearScale,
	LineController,
	LineElement,
	PointElement,
	Tooltip as TooltipChartPlugin,
} from 'chart.js';

// tooltip imports
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import HelpIcon from '@material-ui/icons/Help';
import { colorPalette as colors } from '../../theme';
import { legendGenerator } from '../../helpers/helpers';
Chart.defaults.color = '#f0f0f0';

import '../styles/Chart.scss';
import LegendTooltip from '../common/LegendTooltip';

export default function LineChart(props) {
	const chartRef = useRef();
	const [chart, setChart] = useState();

	const createChart = () => {
		Chart.register(
			CategoryScale,
			LinearScale,
			LineController,
			LineElement,
			PointElement,
			TooltipChartPlugin,
		);

		if (props.data && props.axisLabels) {
			setChart(
				new Chart(chartRef.current, {
					type: 'line',
					data: {
						labels: props.axisLabels,
						datasets: props.data,
					},
					options: {
						responsive: true,
						maintainAspectRatio: false,
						elements: {
							point: {
								radius: 0,
								hitRadius: 5,
								borderWidth: 0,
								hoverBorderWidth: 0,
								backgroundColor: 'rgba(0, 0, 0, 0.0)',
								borderColor: 'rgba(0, 0, 0, 0.0)',
							},
						},
						plugins: {
							legend: {
								display: props.showLegend ? true : false,
								labels: {
									color: '#f0f0f0',
								},
								onHover: function (e, legendItem, legend) {
									e.native.target.style.cursor = 'pointer';
								}.bind(this),
								onLeave: function (e, legendItem, legend) {
									e.native.target.style.cursor = 'default';
								}.bind(this),
							},
						},
						scales: {
							y: {
								ticks: {
									font: {
										weight: 'bold',
									},
									beginAtZero: true,
									padding: 10,
									callback: (value) =>
										props.tickLabelFormat === 'K'
											? `${value / 1000}K`
											: props.tickLabelFormat === 'M'
											? `${value / 1000000}M`
											: value,
								},
								grid: {
									drawTicks: true,
									display: true,
									zeroLineColor: '#e1e1e1',
								},
								title: {
									display: true,
									text:
										// Temporary conditional logic for adding axis labels
										props.chartTitle.split(' ')[0] === 'Delinquencies'
											? '% of Total Number of Records'
											: props.chartTitle.split(' ')[0] === 'Gini'
											? 'Percent Charge Offs'
											: props.chartTitle === 'Delinquency Status'
											? 'Percent'
											: '',
								},
							},
							x: {
								grid: {
									drawTicks: true,
									display: true,
									zeroLineColor: '#e1e1e1',
								},
								ticks: {
									font: {
										weight: 'bold',
									},
									beginAtZero: true,
									padding: 10,
									maxTicksLimit: 20,
								},
								title: {
									display: true,
									// Temporary conditional logic for adding axis labels
									text:
										props.xAxisValue === 'MDY'
											? 'Date'
											: props.xAxisValue === 'month-index'
											? 'Month Index'
											: props.chartTitle === 'Gini'
											? 'Bin'
											: props.xAxisValue === 'Date'
											? 'Date'
											: '',
								},
							},
						},
					},
				}),
			);
		}
	};

	useEffect(() => {
		if (chart) chart.destroy();
		createChart();
	}, [props.data, props.axisLabels]);

	return (
		<>
			<Typography
				style={{
					fontSize: '1.7em',
					fontWeight: 'bold',
					marginBottom: 12,
					position: 'relative',
				}}
			>
				{props.chartTitle}
				<Tooltip
					placement="right"
					title={
						<Typography style={{ fontSize: '1.4em' }}>
							{props.chartTitleTooltip}
						</Typography>
					}
				>
					<IconButton>
						<HelpIcon fontSize="small" />
					</IconButton>
				</Tooltip>
			</Typography>
			{legendGenerator(props.legendInfo)}
			<div
				style={{ position: 'relative', height: props.delBig }}
				className={
					props.chartContainerOverrides
						? props.chartContainerOverrides
						: 'chart-container'
				}
			>
				<canvas ref={chartRef} />
			</div>
		</>
	);
}
