import React, { useState } from 'react';
import AxiosClient from '../../../api/axios-client';
import { useDeals, useLendingOriginators } from '../../../api/query-hooks';

// Material UI
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import PersonIcon from '@material-ui/icons/Person';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
	list: {
		backgroundColor: '#666666',
		marginTop: '16px',
		marginBottom: '16px',
		padding: '16px',
		height: 200,
		overflow: 'auto',
	},
	listItem: {
		border: '1px solid #E1E1E1',
		borderRadius: '6px',
		padding: '16px',
		marginBottom: '4px',
	},
});

export default function AddOrganization() {
	// Organization
	const [organization, setOrganization] = useState({
		name: '',
		hasAdditionalAccess: false,
	});

	// Users
	const [currentUser, setCurrentUser] = useState({
		name: '',
		email: '',
		isAdmin: false,
		organizationId: '',
	});
	const [users, setUsers] = useState([]);

	// Deals
	const [currentDeal, setCurrentDeal] = useState({
		id: '',
		name: '',
		type: '',
	});
	const [deals, setDeals] = useState([]);

	// Originators
	const [currentOriginator, setCurrentOriginator] = useState({
		id: '',
		name: '',
	});
	const [lendingOriginators, setLendingOriginators] = useState([]);

	const dealsResponse = useDeals();
	const lendingResponse = useLendingOriginators();
	const classes = useStyles();

	// Organization

	function handleChangeOrganizationName(event) {
		setOrganization({
			...organization,
			[event.target.name]: event.target.value,
		});
	}

	function handleChangeOrganizationAccess(event) {
		setOrganization({
			...organization,
			[event.target.name]: event.target.checked,
		});
	}

	// User

	function handleChangeUser(event) {
		setCurrentUser({
			...currentUser,
			[event.target.name]: event.target.value,
		});
	}

	function handleRemoveUser(email) {
		setUsers(users.filter((user) => user.email !== email));
	}

	function handleAddUser() {
		if (currentUser.email !== '' && currentUser.name !== '') {
			setUsers((users) => [...users, currentUser]);
			setCurrentUser({
				name: '',
				email: '',
				isAdmin: false,
				organizationId: '',
			});
		}
	}

	// Deal

	function handleChangeDeal(event) {
		setCurrentDeal(event.target.value);
	}

	function handleRemoveDeal(id) {
		setDeals(deals.filter((deal) => deal.id !== id));
	}

	function handleAddDeal() {
		if (!deals.some((deal) => deal.id == currentDeal.id)) {
			setDeals((deals) => [...deals, currentDeal]);
		}

		setCurrentDeal({
			name: '',
			id: '',
		});
	}

	// Originator

	function handleChangeOriginator(event) {
		setCurrentOriginator(event.target.value);
	}

	function handleRemoveOriginator(id) {
		setLendingOriginators(
			lendingOriginators.filter((originator) => originator.id !== id),
		);
	}

	function handleAddOriginator() {
		if (
			!lendingOriginators.some(
				(originator) => originator.id == currentOriginator.id,
			)
		) {
			setLendingOriginators((originators) => [
				...originators,
				currentOriginator,
			]);
		}
	}

	async function handleSubmit() {
		AxiosClient.post('/organization/create-organization', {
			name: organization.name,
			hasAdditionalAccess: organization.hasAdditionalAccess,
			deals: deals.map((deal) => deal.id),
			lendingOriginators: lendingOriginators.map((originator) => originator.id),
		})
			.then(() => {
				AxiosClient.get(`/organization/id/${organization.name}`).then(
					(response) => {
						const organizationId = response.data[0].id;
						const updatedState = users;
						updatedState.forEach((user) => {
							user.organizationId = organizationId;
						});
						setUsers(updatedState);
						AxiosClient.post('organization/create-users', {
							users: users,
						})
							.then(() => {
								setOrganization({
									name: '',
									hasAdditionalAccess: false,
								});
								setUsers([]);
								setDeals([]);
								setLendingOriginators([]);
							})
							.catch((error) => {
								console.log(error.response);
							});
					},
				);
			})
			.catch((error) => {
				console.log(error.response);
			});
	}

	return (
		<div style={{ width: '80vw', margin: '0 auto' }}>
			<br />
			<h1>Add Organization</h1>
			<br />
			<hr style={{ color: '#616161' }} />
			<br />
			<Paper elevation={24} style={{ textAlign: 'center' }}>
				<Grid container spacing={2}>
					<Grid item xs={4}>
						<h2>Users</h2>
						<List classes={{ root: classes.list }}>
							{users.map((user) => (
								<ListItem key={user.name} classes={{ root: classes.listItem }}>
									<PersonIcon style={{ marginRight: '8px' }} />
									<div>
										{user.name} ({user.email})
									</div>
									<ListItemSecondaryAction>
										<IconButton
											onClick={() => handleRemoveUser(user.email)}
											edge="end"
										>
											<CloseIcon />
										</IconButton>
									</ListItemSecondaryAction>
								</ListItem>
							))}
						</List>
						<TextField
							name="name"
							value={currentUser.name}
							onChange={handleChangeUser}
							label="Name"
							fullwidth
						/>
						<br />
						<TextField
							name="email"
							value={currentUser.email}
							onChange={handleChangeUser}
							label="Email"
							fullwidth
						/>
						<br />
						<Button
							variant="contained"
							style={{
								marginTop: '2vh',
								color: '#ffffff',
								backgroundColor: '#32DB60',
							}}
							onClick={handleAddUser}
						>
							Add User
						</Button>
					</Grid>
					<Grid item xs={4}>
						<h2>Deals</h2>
						<List classes={{ root: classes.list }}>
							{deals.map((deal) => (
								<ListItem key={deal.id} classes={{ root: classes.listItem }}>
									<PersonIcon style={{ marginRight: '8px' }} />
									<div>{deal.name}</div>
									<ListItemSecondaryAction>
										<IconButton
											onClick={() => handleRemoveDeal(deal.id)}
											edge="end"
										>
											<CloseIcon />
										</IconButton>
									</ListItemSecondaryAction>
								</ListItem>
							))}
						</List>
						<FormControl>
							<InputLabel>Deals</InputLabel>
							<Select
								style={{ width: '20vw' }}
								value={currentDeal}
								onChange={handleChangeDeal}
							>
								{dealsResponse.data &&
									dealsResponse.data.map((deal, index) => (
										<MenuItem key={index} value={deal}>
											{deal.name}
										</MenuItem>
									))}
							</Select>
						</FormControl>
						<br />
						<Button
							variant="contained"
							style={{
								marginTop: '2vh',
								color: '#ffffff',
								backgroundColor: '#32DB60',
							}}
							onClick={handleAddDeal}
						>
							Add Deal
						</Button>
					</Grid>
					<Grid item xs={4}>
						<h2>Lending Originators</h2>
						<List classes={{ root: classes.list }}>
							{lendingOriginators.map((originator) => (
								<ListItem
									key={originator.id}
									classes={{ root: classes.listItem }}
								>
									<PersonIcon style={{ marginRight: '8px' }} />
									<div>{originator.name}</div>
									<ListItemSecondaryAction>
										<IconButton
											onClick={() => handleRemoveOriginator(originator.id)}
											edge="end"
										>
											<CloseIcon />
										</IconButton>
									</ListItemSecondaryAction>
								</ListItem>
							))}
						</List>
						<FormControl>
							<InputLabel>Originators</InputLabel>
							<Select
								style={{ width: '20vw' }}
								value={currentOriginator}
								onChange={handleChangeOriginator}
							>
								{lendingResponse.data &&
									lendingResponse.data.map((originator, index) => (
										<MenuItem key={index} value={originator}>
											{originator.name}
										</MenuItem>
									))}
							</Select>
						</FormControl>
						<br />
						<Button
							variant="contained"
							style={{
								marginTop: '2vh',
								color: '#ffffff',
								backgroundColor: '#32DB60',
							}}
							onClick={handleAddOriginator}
						>
							Add Lending Originator
						</Button>
					</Grid>
					<Grid item xs={8}>
						<TextField
							name="name"
							value={organization.name}
							onChange={handleChangeOrganizationName}
							label="Organization name"
							fullwidth
						/>
					</Grid>
					<Grid item xs={4}>
						<FormControlLabel
							name="hasAdditionalAccess"
							value={organization.hasAdditionalAccess}
							onChange={handleChangeOrganizationAccess}
							control={<Checkbox color="primary" />}
							label="Has access to Underwriting and Modelling Insight tabs"
							labelPlacement="end"
						/>
					</Grid>
					<Grid item xs={12}>
						{organization.name !== '' &&
						users.length !== 0 &&
						deals.length !== 0 ? (
							<Button
								variant="contained"
								style={{
									marginTop: '2vh',
									color: '#ffffff',
									backgroundColor: '#32DB60',
								}}
								onClick={handleSubmit}
							>
								Create Organization
							</Button>
						) : (
							<Button
								variant="contained"
								style={{
									marginTop: '2vh',
									color: '#ffffff',
								}}
								disabled
							>
								Create Organization
							</Button>
						)}
					</Grid>
				</Grid>
			</Paper>
		</div>
	);
}
