import React, { Component } from 'react';

import './footer.css';

export default class Sidebar extends Component {
	render() {
		return (
			<div className="dashboard-footer">
				<p>&copy; 2021 Karus</p>
			</div>
		);
	}
}
